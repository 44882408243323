.customer-landing-blogs-title {
  display: flex;
  padding: 20px;
  justify-content: center;
  font-family: Mulish;
  font-size: 32px;
  font-weight: 700;
  line-height: 40.16px;
  letter-spacing: 0.04em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.customer-landing-blogs-btns {
  display: flex;
  padding: 20px;
  justify-content: center;
  gap: 10px;
}
.customer-landing-blogs-btn1 {
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  display: flex;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  padding: 4px 16px 4px 16px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #fe585b;
  cursor: pointer;
  letter-spacing: 0.04em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 100%, 1);
}
.customer-landing-blogs-btn2 {
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  padding: 4px 24px 4px 24px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #fe585b;
  color: #fe585b;
  letter-spacing: 0.04em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.customerBlogsPage-cards {
  padding: 20px 90px 20px 90px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 40px;
  justify-content: space-between;
}

.customerBlogsPage-cards > div {
  flex: 1 1 calc(25% - 10px);
  max-width: calc(25% - 10px);
  box-sizing: border-box;
  position: relative;
}
.customerBlogsPage-cards-title-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.customerBlogsPage-cards-title1 {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 33%, 1);
}
.customerBlogsPage-cards-title2 {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.57px;
  text-align: left;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 24%, 1);
}
.customerBlogsPage-cards-title3 {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.06px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 0.53);
}
.customerBlogsPage-cards-title2-span {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.57px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
  cursor: pointer;
}
.blogimages-raedmore-img-sec {
  padding: 20px 90px 20px 90px;
}
.blogs-read-more-sec {
  padding: 0px 90px 0px 90px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.image-of-readmore {
  width: 100%;
  height: 500px;
}
.image-of-readmore-t1 {
  font-family: Mulish;
  font-size: 40px;
  font-weight: 500;
  line-height: 50.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.image-of-readmore-t2 {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.redamore-heading-customer-landingcards {
  padding: 20px 90px 0px 90px;
  font-family: Mulish;
  font-size: 32px;
  font-weight: 700;
  line-height: 40.16px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
@media screen and (max-width: 598px) {
  .customer-landing-blogs-title {
    padding: 0px;
  }
  .customer-landing-blogs-btn1 {
    font-size: 12px;
  }
  .customer-landing-blogs-btn2 {
    font-size: 12px;
  }
  .customerBlogsPage-cards {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .customerBlogsPage-cards > div {
    max-width: 100%;
  }
  .blogs-read-more-sec {
    padding: 20px;
  }
  .image-of-readmore {
    width: 100%;
    height: auto;
  }
  .redamore-heading-customer-landingcards {
    padding: 0px 0px 10px 20px;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .customer-landing-blogs-title {
    padding: 0px;
  }
  .customerBlogsPage-cards {
    padding: 20px;
  }
  .blogs-read-more-sec {
    padding: 20px;
  }
  .image-of-readmore {
    width: 100%;
    height: 300px;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  .customer-landing-blogs-title {
    padding: 0px;
  }
  .customerBlogsPage-cards {
    padding: 20px;
  }
  .blogs-read-more-sec {
    padding: 20px;
  }
}
