.buyer-forgot-right{
    width: 100%;
    background: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.buyer-reset-page-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5%;

}
 .byr-forgot-password-phone .MuiOutlinedInput-root {
    width: 75%;
    min-width: 330px;
        /* border-radius: 100px; */
        min-height: 50px;
        height: 2rem;
        background-color: white;
        /* background: none; */
        padding-left: 8%;
        background-size: 3%;
        font-weight: 300 !important;
        font-size: 1.1rem;
        /* margin: 3%; */
        color: rgba(112, 112, 112, 0.72);
        /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
        /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    }
    .byr-forgot-password-phone
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border-radius: 4px !important;
    }
    .byr-forgot-phone-icon {
        position: absolute;
        left: 13%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.2rem;
        top: 40%;
    }
    .buyer-reset-page-email-field .MuiFormHelperText-root {
        bottom: -46%;
        font-size: 12px;
        letter-spacing: 0.04em;
        left: -2%;
        position: absolute;
    }
    .buyer-reset-page-email-field{
        /* width: 80%;
    max-width: 100%;
    min-width: 300px; */
    position: relative;
    margin: 0.5rem auto;
    }
    .buyer-reset-page-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.5rem;
    }
    .BuyerForgotImg{
        width: 95%;
    }
    .byr-page-logo{
        width: 100%;
        margin-top: 5%;
    
    }
    .byr-forgot-password-title{
        display: flex;
        justify-content:flex-start;
        align-items: flex-start;
        color: rgba(0, 0, 0, 0.8);
        font-size: 30px;
        margin-left: 10%;
    }
    @media only screen and (min-width: 1300px){

    }