.GC-purchase{
    width: 680px;
height: 140px;
/* top: 346px;
left: 376px;
gap: 0px; */
font-family: "Barlow";
border-radius: 8px 8px 0px 0px;
opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: top;
    /* background-color: rgba(255, 244, 235, 1); */
   background-color: white;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* margin: 0 auto; */
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
   padding-top: 20px;

}
.GC-Image{
    width: 135px;
height: 110px;
top: 366px;
left: 411px;
gap: 0px;
border-radius: 5px 0px 0px 0px;
opacity: 1;


}
.all-purchase-detail {
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap:5%;
  
}


.purchase-detail1{
    width: 111px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 25px;

}
.purchase-detail2{
    width: 111px;
    height: 50px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.purchase-detail3 {
    width: 60px;
    height: 50px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
}
.purchase-detail4{
    width: 145px;
    height: 50px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.DeliveryStatus{
    font-size: 0.8rem;
    text-transform: capitalize;
    color: green;
}
@media screen and (max-width:800px) {
    .GC-purchase {
        width: 100%;
        height: 120px;
        flex-direction:  row;;
        align-items: center;
        padding: 0rem;
        /* width: 75%; */
        min-width: 330px;
        text-align: left;
        /* margin-left: -3%; */
        margin: 0rem auto 1.5rem auto;
        opacity: 1;
      }
      .GC-Image{
        width: 105px;
    height: 100px;
    top: 366px;
    left: 411px;
    gap: 0px;
    border-radius: 5px 0px 0px 0px;
    opacity: 1;
    
    
    }
    .purchase-detail1{
        width: 111px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
    
    }
    .purchase-detail2{
        width: 111px;
        height: 50px;
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    }
    .purchase-detail3{
        width: 42px;
        height: 50px;
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    }
    .purchase-detail4{
        width: 129px;
        height: 50px;
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    }
    .DeliveryStatus{
        font-size: 0.8rem;
        text-transform: capitalize;
        color: green;
    }
}

@media screen and (max-width:530px) {
    .GC-purchase {
        width: 389px;
        height: 79px;
        flex-direction:  row;
        align-items: center;
        padding: 0rem;
        /* width: 75%; */
        min-width: 330px;
        text-align: left;
        /* margin-left: -3%; */
        justify-content: space-evenly;
        margin: 0rem auto 1.5rem auto;
        gap: 20px;
      }
      .GC-Image{
        width: 55px;
    height: 53px;
    top: 32px;
    margin-left: 5px;
    gap: 0px;
    border-radius: 5px 0px 0px 0px;
    opacity: 1;
    
    
    }
    .all-purchase-detail{
        display: flex;
        flex-direction: row;
    }
    .purchase-detail1{
        width: 91px;
        height: 55px;
        display: flex;
        font-size: 10px;
        line-height: 15px;
        flex-direction: column;
        justify-content: center;
        margin-top: 0px;
    
    }
    .purchase-detail2{
        width: 93px;
        height: 85px;
        display: flex;
        flex-direction: column;
        margin-top: 36px;
        font-size: 10px;
        line-height: 15px;
    }
    .purchase-detail3{
        width: 31px;
        height: 85px;
        display: flex;
        flex-direction: column;
        margin-top: 36px;
        font-size: 10px;
        line-height: 15px;
    }
    .purchase-detail4{
        width: 92px;
        height: 85px;
        display: flex;
        flex-direction: column;
        margin-top: 36px;
        font-size: 10px;
        line-height: 15px;
    }
    .DeliveryStatus{
        font-size: 0.8rem;
        text-transform: capitalize;
        color: green;
        font-size: 10px;
        line-height: 15px;

    }
}

@media screen and (max-width:480px) {
    .GC-purchase {
        width: 100%;
        height: 145px;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 1rem;
        /* width: 75%; */
        min-width: 330px;
        text-align: left;
        /* margin-left: -3%; */
        justify-content: none;
        margin: 0rem auto 1.5rem auto;
        gap: 0px;
    }
      .GC-Image{
        width: 104px;
    height: 65px;
    top: 32px;
    margin-left: 5px;
    gap: 0px;
    border-radius: 5px ;
    opacity: 1;
    
    
    }
    .all-purchase-detail{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        height: 32px;
    }
    .purchase-detail1 {
        width: 91px;
        height: 49px;
        display: flex;
        font-size: 10px;
        line-height: 15px;
        flex-direction: column;
        justify-content: center;
        /* margin-top: 6px; */
        margin-left: 11px;
    }
    .purchase-detail2{
        width: 93px;
        height: 20px;
        display: flex;
        flex-direction: column;
       
        margin-top: 0px;
        font-size: 10px;
        line-height: 15px;
    }
    .purchase-detail3{
        width: 31px;
        height: 20px;
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        font-size: 10px;
        line-height: 15px;
    }
    .purchase-detail4{
        width: 92px;
        height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0px;
        font-size: 10px;
        line-height: 15px;
    }
    .DeliveryStatus{
        font-size: 0.8rem;
        text-transform: capitalize;
        color: green;
        font-size: 10px;
        line-height: 15px;

    }
}

    