.home-page-container {
  display: flex;
  flex-direction: column;
 
}
.home-page-container-content{
margin:0 auto;
width:100%;
min-width: 978px;
    max-width: 1680px;
display: flex;
flex-direction: column;
}
.home-page-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 1rem;
}
.home-trending-head{
  align-self:center;
  font-size:2.5rem;
  font-weight:600;
  margin:2rem auto;
  position:relative;
}
.home-trending-head-text{
  margin-bottom:1rem;
}
.home-trending-head-underline{
  position:absolute;
  background: rgba(248, 152, 128, 1);
  height:0.4rem;
  width:100%;
  border-radius:80%;
  margin-bottom:1rem;
}
.product-image-cards{
  display:flex;
  align-items:center;
  justify-content:space-around;
  min-height:20rem;
  flex-wrap: wrap;
/* margin:2rem auto; */
}
.home-season-offers-head{
  align-self:center;
  font-size:2.5rem;
  font-weight:600;
  margin:5rem auto;
  position:relative;
}
.home-season-offers-head-text{
  margin-bottom:1rem;
}
.home-season-offers-head-underline{
  position:absolute;
  background: rgba(248, 152, 128, 1);
  height:0.4rem;
  width:100%;
  border-radius:80%;
}
.home-season-offers-image-layout{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  width:100%;
}
.home-season-offers{
  display:flex;
  min-height:40rem;
  align-items:center;
  justify-content:space-around;
  margin:0rem 0;
  max-width:100%;
}
.card-product-offer-image{
  width:20rem;
  height:20rem;
  border-radius:100%;
  margin-bottom:2rem;
}
.card-product-offer-image-middle{
  width:30rem;
  height:30rem;
  border-radius:100%;
  margin-bottom:2rem;

}


.card-product-brand-image{
    height:3rem;
    width:10rem;
    margin-bottom:2rem;
}
.card-product-offer{
  font-size:1.5rem;
  font-weight:600;
}

.home-season-facts-head{
  align-self:center;
  font-size:2.5rem;
  font-weight:600;
  margin:2rem auto;
  position:relative;
}
.home-season-facts-head-text{
  margin-bottom:1rem;
}
.home-season-facts-head-underline{
  position:absolute;
  background: rgba(248, 152, 128, 1);
  height:0.4rem;
  width:100%;
  border-radius:80%;
}
.product-facts-cards{
  display:flex;
  align-items:center;
  justify-content:space-around;
  min-height:40rem;
  /* background:url("../mandala.png"); */
  background-color: #08404D;
}
.home-benefits{
  display:flex;
  align-items:center;
  justify-content:space-around;
  margin:2rem auto;;
  width:100%;
  /* transform:scale(1,0.8); */
}
.benefit-image{
  margin-right:2rem;
  width: 15rem;
  height: 7rem;
}
.benefits-item{
  display:flex;
  flex-direction:row;
  width:20%;
}
.benefit-name{
  font-size:1.3rem;
  font-weight:600;
}
.benefit-desc{
  font-size:1.0rem;
}

@media screen and (max-width: 1200px) {
  .product-image-cards{
    flex-direction: column;
  }
  .home-season-offers{
    flex-direction: column;
    /* max-width:20rem; */
  }
  .product-facts-cards{
    flex-direction: column;
  }
  .home-benefits{
    flex-direction: column;
  }
  .home-benefits>*{
    margin:2rem auto;

  }
  .benefits-item{
    width:100%;
  }
  .card-product-offer-image-middle{
  width:250px;
  height:250px;
  }
  .home-page-container-content
  {min-width: 0px;
  }

}