.customer-cart-layout {
    width: 1280px;
    margin: 40px auto;
    margin-top:72px;
}
.add-address-cart{
    width: 130px;
    height: 24px;
    border: none;
    background-color: white;
    display: flex;
align-items: flex-start; 
gap: 16px;
margin: 1rem;
color: rgba(0, 0, 0, 0.66);
font-family: Barlow;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height:  22.4px ;
color: #000000A8;
cursor: pointer;
}
.customer-cart {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 48px;
    /* min-height:800px; */
    /* border: 1px solid black; */

}

.customer-cart-products {
    width: 771px;
    min-height: 300px;
    /* border: 1px solid black; */
    padding: 12px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
}

.customer-cart-pricing {
    max-width: 389px;
    min-height: 500px;
    /* border: 1px solid black; */
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
}

.customer-cart-products-head {
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

.customer-cart-card-layout {
    width: 723px;
    display: flex;
    margin: 10px;
    padding-bottom: 16px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);
    
}

.customer-cart-card-image {
    width: 85px;
    margin-right: 16px;
    height: 82px;
    border-radius: 8px;
}

.customer-cart-card-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    flex: 1;
}

.customer-cart-card-size {
    flex: 0.9;
}

.customer-cart-card-prices {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ;
}

.customer-cart-card-quantity {
    padding: 2px 8px;
    width: 72px;
    border-radius: 176px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: rgba(217, 217, 217, 0.00);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.customer-cart-products-heading {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}
.cart-address
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #FE3C72;
}
.address-details-cart
{
    max-width: 351px;
flex-shrink: 0;
color: rgba(0, 0, 0, 0.66);
font-family: Barlow;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22.4px ;
}
.customer-cart-products-clear-cart {
    background: linear-gradient(90deg, #FD2A80 0%, #FE575C 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* text-decoration: underline; */
}

.customer-cart-products-clear-cart:hover {
    cursor: pointer;
    text-decoration: underline;
}

.customer-cart-pricing-coupons {
    border-radius: 10px;
    /* border: 1px solid rgba(0, 0, 0, 0.20); */
    background: #FFF;
    max-width: 358px;
    min-height: 145px;
    margin: 16px 16px;
}

.customer-cart-pricing-coupons-head {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px auto 16px 16px;
}
.customer-cart-pricing-coupons-check-field{
    width: 358px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
}
.apply-coupon-title{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.cart-coupon-applied-remove-field{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 326px;
}
.cart-checkout-amount-discount{
    color: #029F4A;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.discount-cart-fields{
    display: 'flex';
     width: '90%';
     justify-content: center;
     align-items: center;
      margin: '0 auto';
}
.discount-cart-fields .css-1bp1ao6 {
    height: 36px;
    font-size: 16px;
    width: 250px;
}

.customer-cart-pricing .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none !important;
    position: inherit;
}

.discount-card {
    padding: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.discount-sucess-field{
    margin-top: 1rem;
    background: #D5E9DE;
    color: #029F4A;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 392px;
    text-align: center;
    margin-left: -5%;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.total-price-saving{
    color: #000;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.discount-head{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}
.discount-desc{
    color: var(--neutral-black, #1A1A1A);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
opacity: 0.5;
max-width: 174px;
}
.coupon-applied-title{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
max-width: 174px;
display: flex;
flex-direction: column;
}
.coupon-applied-head{
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    min-width: 208px;
}
.coupon-applied-description{
    opacity: 0.5;
    color: var(--neutral-black, #1A1A1A);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.discount-desc
span{
    font-weight: 800;
    font-size: 13px;
}
.cart-checkout-field {
    width: 327px;
    padding: 16px;
    margin:0 auto;
    margin-top: 24px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.20);
}

.discount-button {
    color: rgba(253, 42, 128, 1);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.discount-button:hover {
    cursor: pointer;
}
.checkout-btn-secn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 359px;
    padding: 0px;
    margin: 10px auto;
}
.cart-address{
    display:flex;
    flex-direction: column;
    gap: 1rem;
}
.customer-cart-products-address-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.customer-address-card-layout{
min-width: 795px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid rgba(0, 0, 0, 0.20);
padding: 8px;
}
.customer-cart-pricing-address-tab{
    max-width: 389px;
    /* min-height: 500px; */
    /* border: 1px solid rgba(0, 0, 0, 0.20); */
    border-radius: 8px;
    background: #FFF;
    /* box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16); */
}
.cart-checkout-field-address-tab {
    width: 327px;
    padding: 16px;
    margin: 0 auto;
    margin-top: 17px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.20);
}
@media screen and (max-width: 480px) {
    .customer-cart {
        width: 340px;
        margin: 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 48px;
        /* min-height: 800px; */
        /* border: 1px solid black; */
        flex-direction: column;
        gap: 16px;
    }
    .customer-address-card-layout {
        width: 332px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        padding: 20px;
    }
    .customer-cart-card-layout {
        width: 315px;
        display: flex;
        margin: 12px;
        padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.20);
    }
    .customer-cart-products {
        width: 345px;
        min-height: 300px;
        /* border: 1px solid black; */
        padding: 16px;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
    }
    .customer-cart-pricing-coupons {
        border-radius: 10px;
        background: #FFF;
        margin: 8px 8px;
    }
    .customer-cart-pricing {
        width: 376px;
        min-height: 500px;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
    }
    .discount-sucess-field {
        margin-top: 1rem;
        background: #D5E9DE;
        color: #029F4A;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 361px;
        text-align: center;
        margin-left: 0%;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .address-details-cart {
        flex-shrink: 0;
        color: rgba(0, 0, 0, 0.66);
        font-family: Barlow;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.4px;
        width: 294px;
    }
    .customer-address-card-layout {
        min-width: 335px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        padding: 13px;
        margin-left: 6px;
    }
    .customer-cart-layout{
        /* margin-top: 32px;
        margin-left: 16px;
        margin-right: 16px; */
    }
    .customer-cart-layout {
        width: 330px;
        margin: 0;
    }
    .checkout-btn-secn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 350px;
        padding: 10px;
        margin: 0 auto;
    }
}
