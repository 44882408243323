.admin-categories-table {
    width:70%;
    margin: auto;
    min-width: 350px;
    max-width: 100%;
     border-collapse:separate; 
    border-spacing: 0 1em;
  }
  .error-message-facts-field{
    color: #d32f2f;
    font-size: 12px;  
  }
  .admin-categories-table tr:first-child {
    /* border-top: none; */
    text-align: center;
    background: white;
    color: black;
  }
  .seller-field-text-area-facts:focus {
    outline-color:  #FF5569;
  
}
  .admin-categories-table tr {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .admin-categories-table tr:nth-child(odd):not(:first-child) {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .admin-categories-table th {
    display: none;
  }
  
  .admin-categories-table td {
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-categories-table td:first-child {
    margin-top: .5em;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-categories-table td:last-child {
    margin-bottom: .5em;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-categories-table td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 120px;
    display: inline-block;
    color: black;
  }

  
  .admin-categories-table {
    color: #333;
    border-radius: .4em;
    overflow: hidden;
  }
  
  .admin-categories-table tr {
    background: #FFFFFF;
   }
  
  .admin-categories-table th,
  .admin-categories-table td {
    padding: .5em 1em;
  }
  
  .admin-categories-table tr:first-child , .admin-categories-table td:first-child { 
    /* border-top-left-radius: 10px;  */
  }
  .admin-categories-table tr:first-child , .admin-categories-table td:last-child { 
    /* border-top-right-radius: 10px;  */
  }
  
  .admin-categories-table tr:last-child ,.admin-categories-table td:first-child {
     /* border-bottom-left-radius: 10px;  */
    }
  .admin-categories-table tr:last-child,  .admin-categories-table td:last-child { 
    /* border-bottom-right-radius: 10px;  */
  }
  
  @media screen and (max-width: 601px) {
   .admin-categories-table tr:nth-child(2) {
      border-top: none;
    }
  
    .admin-categories-table tr {
      background: #fafafa;
      border: 1px solid rgba(0, 0, 0, 0.2);
     }
  
    .admin-categories-table td {
      display: block;
      border: none !important;
      display: flex;
      justify-content:space-between;
    }
    .admin-categories-table td:first-child {
      border: none !important;
    }
    
    .admin-categories-table td:last-child {
      border: none !important;
    }
    
    .admin-categories-table {
      width:95%;
      margin:0 auto;
      border-collapse: collapse;
      border-spacing: 0 1em;
    }
    .data-th {
      width: 50%;
      gap: 2rem;
    }
    .admin-category-addition-field{
        flex-direction: column;
        gap:2rem;
    }
    
  }
  @media screen and (min-width: 600px) {
  
    
  
    .admin-categories-table {
      width:95%;
      margin:0 auto;
    }
    .admin-categories-table tr:hover:not(:first-child) {
      /* background-color: #d8e7f3; */
      cursor:pointer;
    }
    .admin-categories-table td:before {
      display: none;
    }
    .admin-categories-table th,
   .admin-categories-table td {
      display: table-cell;
      padding: .25em .5em;
    }
    .admin-categories-table th:first-child,
  .admin-categories-table td:first-child {
      padding-left: 0;
    }
   .admin-categories-table th:last-child,
   .admin-categories-table td:last-child {
      padding-right: 0;
    }
    .admin-categories-table th {
      padding: 0.5em !important;
    }
    .admin-categories-table td {
      padding: 0.8em !important;
    }
  
  
  }
  .facts-upload-files{
    display: flex;
    flex-direction: column;
    width: 450px;
    max-width: 450px;
    min-width: 300px;
    margin: 0 auto;
  }
  .seller-products-list-table-product-item{
        /* text-align: center; */
        width: 25%;
        /* max-width: 50px; */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 18px;
    }
    .seller-products-card {
      padding: 0.8em;
    margin: 1.5rem auto;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 3.4rem;
    background: #ffffff;
    border: none;
    width: 90%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .layout-container{
max-width: 1190px;
/* height: 1376px; */
background: #FFFFFF;
border-radius: 10px;
margin: 0 auto;
  }
  .search-icon
  .css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    color: gray;
    position: relative;
    /* bottom: 3px; */
    /* left: 2px; */
}
.status-indicator-scheduled {
  border-radius: 100px;
    margin-left: 2rem;
    margin-right: 1rem;
    min-height: 0.8rem;
    min-width: 0.8rem;
    background: #01babf;
}
 
.status-indicator-active {
  border-radius: 100px;
  margin-left: 2rem;
  margin-right: 1rem;
  min-height: 0.8rem;
  min-width: 0.8rem;
  background: #00ed42; 
}
.status-indicator-expired {
  border-radius: 100px;
    margin-left: 2rem;
    margin-right: 1rem;
    min-height: 0.8rem;
    min-width: 0.8rem;  
background: #FF5E5E; 
}

.status-indicator-text {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem
}
.multiline-input {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Show only 2 lines */
  -webkit-box-orient: vertical;
  max-height: 3em; /* 2 lines with 1.5em line height */
  line-height: 1.5em;
}
.description {
  max-height: 2rem !important; /* Adjust the height as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;


}
.error-message-sellerfact{
  color: #d32f2f;
  font-size: 13px;  

}
.fact-modal-form-field-imageFIeld{
  width: 40%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fact-modal-form-field-textField .MuiInputBase-input {
  max-width: 100%; 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%; 
}