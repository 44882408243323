.logistic-orders{
    min-height:300px;
    min-width:100%;
    background: #FDF4ED;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 40px;
    padding-bottom: 16px;
    /* min-height: 100vh; */
    /* height: 100vh;  */
}
.logistic-order-details{
    display:flex;
    justify-content:space-around;
    color:red;
    padding:20px;
    width:90%;
    margin: 24px auto;
    background-color: white;
    border-radius:8px;
}
.logistic-order-details-tr{
    display:flex;
    justify-content:space-around;
    /* color:red; */
    padding:10px;
    width:90%;
    margin: 18px auto;
    background-color: white;
    border-radius:8px;
    cursor: pointer;

}
.logistic-order-details-tr:first-child{
    width:40%;
}
.logistic-order-details-tr:first-child{
    width:40%;
}

.logistic-order-details-tr:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.logistic-order-table-item{
    text-align: center;
    width: 20%;
}

.order-head{
    width:90%;
    margin:1rem auto;
    display:flex;
    justify-content: space-between;
    background:white;
    padding:16px;
    border-radius: 8px;;
    max-width:990px;
}
.order-column {
    /* min-height:112px; */
    display: flex;
    flex-direction: column;;
    gap:16px;
    align-items: center;
    justify-content: space-evenly;
}

.order-column-name{
    color: #FE3C72;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}

.order-column-value{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.14px;
}

.order-detail{
    width:90%;
    margin:1rem auto;
    background:white;
    padding:16px;
    border-radius: 8px;;
    max-width:990px;
    min-height:300px;
}

.order-product-status-detail{
    display: flex;
    justify-content:space-between;
}

.order-details-product-info{
    width:382px;
    display:flex;
    gap:16px;
}

.order-details-product-image{
    width:85px;
}
.order-details-product-details{
    display:flex;
    flex-direction: column;
    align-items: flex-start;;
    justify-content: space-evenly;
}
.order-details-product-name{
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    width: 200px;
    }
.order-details-product-size-color-qty{
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
.order-details-product-dimensions{
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
.order-product-address-deatil{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    gap: 4rem;
}
.order-product-address-buyers{
    width: 352px;
    height: 99px;
    font-size: 16px;
    color: #000000A8;

}
.order-product-address-heading{
    color:  #000000;
    font-weight:600;
    font-size: 16px;
}
.freightCalculator{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-direction: column;
    margin-top: 2rem;
}
.freightCalculator-content{
    display: flex;
    justify-content: space-between;

}
.Logistic-check-btn{
    width: 186px;
height: 34px;
background-color: white;
border-radius: 8px;
border: 1px solid #FE3C72;
color: #FE3C72;
margin-top: 1rem;

}
.freightCalculator-content{
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 1rem;
}
.freightCalculator-content-left{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 0.5rem;}
.freightCalculator-content-right{
    /* margin-top: 4rem; */
    width: 40%;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
}
.local-common-btn{
    Width:186px;
Height:34px;
background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 14px;
letter-spacing: -0.01em;
text-align: center;
padding:1rem auto;
margin-top: 1rem;
border-radius: 8px;
color: white;
border: none;
}
.logistic-assignment-head{
    font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 27px;
letter-spacing: 0em;
text-align: left;
color: #000000CC;

}
.QualityHeading{
    font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 27px;
letter-spacing: 0em;
text-align: left;
color: #000000CC;
margin-top: 2rem;

}
.QualityCheckButtons{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}
.textarea-QualityReject{
    width: 644px;
    border: 1px solid #00000033;
    height: 110px;
    resize: none;
    border-radius: 8px;
    padding: 1rem;
}
.logistic-assignment-container{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    flex-direction: column;
}
.logistic-assignment-dropdown
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 307px;
    border-radius: 8px;
    padding: 14px, 24px, 14px, 24px;
    margin-top: 1rem;
    height: 57px;
}
.status-button{
    background: #FE843F;
    text-align: center;
    color: white;
    width: 121px;
    height: 37px;
    padding: 8px 16px;
    border-radius: 8px;
}
.freightCalculator-head{
    font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 27px;
letter-spacing: 0em;
text-align: left;

}
.thirdPart-head{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 2rem;
    overflow:scroll;

}
.thirdPart-table-style {
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    overflow: scroll;
    margin-top: 1rem;
    border: 1px solid #dddddd; 
  }
  
  .thirdPart-table-style th{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: normal;
    align-items: center;
    padding: 20px; 
  }
  .thirdPart-table-style td {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    padding: 30px; /* Adjust padding for better spacing */
    /* border-bottom: 1px solid #dddddd;  */
    min-width:150px;
  }
  
  .thirdPart-table-style th {
    background-color: white;
    color: #000;
    font-weight: normal;
    text-align: left;
    font-size: 18px;
    /* border-right: 1px solid #dddddd;  */
  }
  
  .thirdPart-table-style tr:last-child td {
    border-bottom: none;
  }
  
  .thirdPart-table-style tr:first-child th {
    border-top: none; 
    border-bottom: none;
  }
  @media screen and (max-width: 480px){
    .logistic-orders {
        min-height: 300px;
        min-width: 100%;
        background: #FDF4ED;
        padding: 0;
        /* height: 100vh; */
        margin-top: 65px;
    }
    .logistic-order-table-item{
        text-align: center;
        /* width: 20%; */
        min-width:200px;
        font-size: 12px;
    }
    .status-button {
        background: #FE843F;
        text-align: center;
        color: white;
        width: 60px;
        height: 22px;
        padding: 5px 5px;
        border-radius: 8px;
    }
    .order-column {
        min-height: 85px;
        display: flex;
        flex-direction: column;
        gap: 1px;
        align-items: flex-start;
        justify-content: space-between;
    }
  .order-product-address-deatil {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    gap: 2rem;
}
.order-product-status-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.order-details-status-dropdown
.css-1nyb6wm-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
   width: 330px;
}
.order-details-product-name {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
  }
  @media (max-width: 768px) {
    .logistic-order-table-item {
      /* width: 100%; */
      box-sizing: border-box;
    }
  }