.buyers-otp-subtext{
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    margin-left: 8%;
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
}
.buyer-otp-Container {
    font-family: "Barlow"; 
    width: 100%;
    /* max-width:1300px; */
    /* min-height: 100vh; */
    display: flex;
    position: relative;
    /* background: conic-gradient(from 219.36deg at 50% 50%, rgba(186, 252, 255, 0) 0deg, rgba(56, 197, 204, 0.202739) 224.84deg, rgba(56, 197, 204, 0) 360deg); */
    overflow: hidden;
    z-index: 2;
    /* color: #008185; */
    /* align-items:center; */
    justify-content: center;
    margin: 0 auto;
}
.buyer-otp-login-title{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.8);

}
.buyer-otp-page-phone
#number-helper-text {
    position: absolute;
    bottom: -45%;
    left: 19%;
    font-size: 12px;
    letter-spacing: 0.05em;
}
.buyer-otp-right{
    width: 100%;
    max-height: 1300px;
    background: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    flex-direction: column;
}
.buyer-otp-right-content {
    width: 100%;
    /* max-width: 2000px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10% auto;
    flex-direction: column;
    /* max-height: 1800px; */
    gap: 2rem;
}
.buyer-otp-left-content{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.buyer-otp-content-desc{
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 15px;
    color: rgba(112, 112, 112, 0.72);
    margin: 0 auto;
    width: 54%;
    text-align: center;
    margin-top: 2%;
    
}
.buyer-left-img{
    width: 400px;
    height: 100%;
    display: flex;
    justify-content: center;
}
.buyer-otp-login-icon {
    position: absolute;
    left: 15%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.2rem;
    top: 30%;
}
.buyer-reset-login{
    font-weight: 600;
    font-size: 20px;
    text-decoration-line: underline;
    /* color: #FF5569; */
    display: flex;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    /* margin-bottom: 70%; */
    color: #FF6155;
    letter-spacing: 0.01em;
}
.buyer-otp-page-terms{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-top: 0%;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
    margin-top: 4rem;
}
.buyer-otp-card {
    /* margin: 2% 3%; */
    width: 35%;
    min-width: 400px;
    /* min-height: 85%; */
    max-width:700px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    padding-top:5%;
    transform: scale(0.8);
    /* transform-origin: 0 0; */
}
.buyer-otp-page-terms{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-top: 0%;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
    margin-top: 3rem;
}
.buyer-otp-head {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    margin: 5% auto;
    color: rgba(248, 152, 128, 1);
    margin-top:5rem;
    text-align: center;
}
.buyer-otpLogin-phone-icon {
    position: absolute;
    left: 21%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
    top: 40%;
}
.buyer-otp-auth-text {
    /* color: rgba(248, 152, 128, 1); */
    /* font-size:1.3vw; */
    font-size: 1.6rem;
    max-width: 65%;
    font-weight: 400;
    margin: 5% auto;
    text-align: center;

}
.buyer-otp-page-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 7%;
    gap: 2rem;
}
.buyer-otp-page-email-field{
    width:100%;
    position:relative;
    margin:3rem auto;
}
.buyer-otp-email .MuiOutlinedInput-root{
    align-self: center;
    width: 60%;
    /* border-radius: 100px; */
    min-height: 50px;
    height: 2.5rem; 
    background-color:white;
    padding-left: 150%;
    background-size: 8%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3% ;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
#number-helper-text{
    position:absolute;
    bottom:-15%;
    left:28%;
    font-size:1rem;
}

#number{
    padding:  1rem;
}

.buyer-otp-page-phone-field {
    width: 90%;
        display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    margin:0 auto;
    text-align: center;
    margin-bottom:2.5rem;
}
.buyer-otp-page-phone{
    width: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 2.5rem;
    margin-top: -8%;
}
.buyer-otp-phone-icon {
    position: absolute;
    left: 12%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
    top:40%;
}
.buyer-otp-phone-validation-text {
    position: absolute;
    bottom: -8%;
    color: red;
    opacity:0.7;
    font-size: 0.8rem;
    padding-top: 1rem;
    text-align: center;
}
.buyer-otp-number {
    width: 100%;
    /* border-radius: 100px; */
    /* min-height: 50px; */
    height: 7vh;
    padding-left: 15%;
    /* background: url("/image 6.png") no-repeat 2vw; */
    background-size: 8%;
    font-weight: 400;
    /* font-size: 1.2vw; */
    font-size: 1.2rem;
    line-height: 2vw;
    margin: 5% auto;
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

.buyer-otp-send-otp {
    margin: 5% 35%;;
    /* font-size: 1.3vw; */
    font-size: 1.3rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}

.buyer-password-signin {
    margin: 3% auto;
    font-weight: 600;
    /* font-size: 1.6vw; */
    font-size: 1.5rem;
    line-height: 2vw;
    color: #000000;
}

    .buyer-password-signin span {
        font-weight: 600;
        /* font-size: 1.4vw; */
        line-height: 2vw;
        text-decoration-line: underline;
        color: rgba(248, 152, 128, 1);
    }

    .buyer-password-signin span:hover {
        cursor:pointer;
    }
    .buyer-otp-terms {
        /* margin: 5% 5%; */
        font-style: normal;
        font-weight: 600;
        /* font-size: 1.4vw; */
        font-size: 1.2rem;
        text-align: center;
        color: rgba(112, 112, 112, 0.72);
    }

    .buyer-otp-terms span {
        color: #707070;
    }

.buyer-otp-logo {
   /* position: fixed; */
   position:absolute;
   /* margin: 1% 1%; */
   /* bottom:-50%; */
   top:-2.5rem;
   /* left:43%; */
   width:10rem;
   height:10rem;
   /* z-index: 100;; */
}

.buyer-otp-number:focus {
    outline: none;
}

.buyer-otp-number[type=number] {
    -moz-appearance: textfield;
}

.buyer-otp-number::-webkit-outer-spin-button,
.buyer-otp-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.buyer-otp-phone-icon {
    position: absolute;
    left: 20%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
    top: 40%;
}
@media only screen and (min-width: 1300px) {
    .buyer-otp-head {
        font-size: 2.0rem;
    }
}
.seller-otp-number .MuiOutlinedInput-root{
    align-self: center;
    width: 60%;
    /* border-radius: 100px; */
    min-height: 50px;
    height: 2.5rem; 
    background-color:#efefef;
    padding-left: 10%;
    background-size: 8%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3% ;
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}
@media only screen and (max-width: 480px) {
  
    .buyer-otp-left {
       display: none;
    
  
  }
  .buyers-subtext-otp {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin: 0 auto; */
    padding: 1rem;
    font-size: 18px;
    margin-left: 0%;
    letter-spacing: 0.03em;
    color: rgba(0, 0, 0, 0.8);
}
.buyer-otp-login-head {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: black;
    font-family: Barlow;
    font-size: 30px;
    font-weight: 600;
    /* line-height: 54px; */
    letter-spacing: 0.02em;
    margin: 0 auto;
    margin-left: 5%;
    padding: 1rem;
}
  .buyer-otp-login-title{
width: 60%;

}
  .buyer-otp-login-title {
   
    width: 67%;
}
.buyer-otp-content-desc {
   
    width: 73%;
}
  .buyer-otp-phone-icon {
    position: absolute;
    left: 5%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
    top: 40%;
}
  .buyer-otp-right {
 
    width: 100%;
    background-color: white;
    }
  
  }
  @media only screen and (max-width: 1200px) {

    .buyer-otp-phone-icon {
        position: absolute;
        left: 7%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.6rem;
        top: 37%;
    }
    .otp-phone-icon {
        position: absolute;
        left: 13%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.6rem;
        top: 37%;
    }
    .buyer-otp-login-title{
        width: 95%;
        font-size: 25px;
        max-width: 85%;
        margin: 0 auto;
        text-align: center;
    }
    

  }
  .buyer-left-img{
    width: 70%;
  }
  .buyer-otp-page-phone
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border-radius: 4px !important;
  }
  .BuyerOtpImg{
    width: 90%;
  }
  .buyers-subtext-otp {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 auto;
    padding: 1rem;
    font-size: 18px;
    margin-left: 10%;
    letter-spacing: 0.03em;
    color: rgba(0, 0, 0, 0.8);
}
.buyer-otp-login-head {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: black;
    font-family: Barlow;
    font-size: 30px;
    font-weight: 600;
    /* line-height: 54px; */
    letter-spacing: 0.02em;
    margin: 0 auto;
    margin-left: 10%;
    padding: 1rem;
}