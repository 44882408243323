/* Marquee styles */
.marquee {
    --gap: 0.5rem;
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    border-top:2px solid rgba(250, 0, 142, 1);
    
    border-bottom:2px solid rgba(250, 0, 142, 1);
    
  }
  
  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
 
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }
  
  
  
  .enable-animation .marquee__content {
    animation: scroll 10s linear infinite;
  }
  
  
  
  
  
  /* Attempt to size parent based on content. Keep in mind that the parent width is equal to both content containers that stretch to fill the parent. */
  .marquee--fit-content {
    max-width: fit-content;
  }
  
  
  
  
  @keyframes scroll-abs {
    from {
      transform: translateX(calc(100% + var(--gap)));
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Other page demo styles */
  .marquee__content > * {
    flex: 0 0 auto;
    /* color: white; */
    width:250px;
    /* background: dodgerblue; */
    margin: 2px;
    padding: 1rem 2rem;
    border-radius: 0.25rem;
    text-align: center;
  }
  

  
  
  
  
  
  
  
  
  
  section {
    margin-block: 3rem;
  }
  
  section > * + * {
    margin-block-start: 0.5rem;
  }
  