.address-container {
  display: flex;
  margin-bottom: 42px;
}
.address-page-form {
  padding: 1rem;
  /* min-height: 60rem; */
  display: flex;
  /* width: 100%; */
  /* height: 100%; */
  flex-direction: column;
  /* min-width: 80rem; */
  /* margin: 0 auto; */
  align-items: center;
}
.State-city-address-form-field{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-address-head-container{

}
.address-form-field {
  display: flex;
  width: 100%;
  /* flex-direction: column; */
  margin: 0.8rem 0.8rem;
  align-items: center;
  position: relative;
  text-align: center;
}
.field-name {
  width: 30%;
  font-size: 1.4rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  margin: 0 auto;
}
.address-button {
  width: 10rem;
  margin: 2rem;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background: rgba(248, 152, 128, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: none;
  color: white;
  align-self: flex-start;
}
.address-field .MuiOutlinedInput-root {
  /* align-self: center; */
  width: 443px;
  max-width: 500px;
  border-radius: 8px;
  /* min-height: 50px; */
  /* height: 3rem; */
  background-color: white;
  background: none;
  /* padding-left: 1%; */
  background-size: 3%;
  font-weight: 600;
  font-size: 14px;
  margin: 0.5rem auto;
  color: #000;
  /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
}

.addressCards {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: flex-start;
  /* margin-left:15px; */
  justify-content: space-between;
  gap: 18px;
  flex-direction: column;
}
.address-field-disabled .MuiOutlinedInput-root {
  align-self: center;
  width: 210px;
  height: 49px;
  border-radius: 8px;
  /* height: 2.5rem; */
  background: none;
  padding-left: 1%;
  font-weight: 600;
  font-size: 14px;
  /* margin: 1rem; */
  color: rgba(0, 0, 0, 1);
  /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
  padding: 0 auto;
  background-color: #F5F5F6;
  color: #000;
  align-items: center;
  padding: 14px 0px 14px 0px;  

}
.address-instructions {
  display: none;
  position: absolute;
  padding: 0.5rem;
  /* width:10rem; */
  border: 1px solid rgba(248, 152, 128, 1);
  right: 0%;
  top: -50%;
  border-radius: 100px;
  color: rgba(112, 112, 112, 0.72);

  background-color: #ffffff;
}

.address-i {
  position: absolute;
    font-size: 16px;
    right: 10%;
    line-height: 0px;
    padding: 0.5px 15px;
    /* background: rgba(248, 152, 128, 1); */
    border-radius: 50px;
    font-weight: 600;
    background: rgba(253, 42, 128, 1);
    color: white;
}
.address-i:hover {
  background:  rgba(253, 42, 128, 1);
}
.address-i:hover + .address-instructions {
  display: block;
}
.address-modal {
  padding:1rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  /* min-height:300px; */
  min-width:700px;
  width: 998px;
  margin:2rem auto;
  /* margin-left:0; */
  transform:scale(0.8,0.8)
}

.address-close-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: black;
  width: 26.0000057220459px;
height: 26.0000057220459px;
border-radius: 100%;
border: 1px solid rgba(0, 0, 0, 1);
display: flex;
justify-content: flex-end;

}
.address-close-button:hover {
cursor: pointer;
}

.left {
  background-color: rgba(248, 152, 128, 1) !important;
  color: black !important;
  align-self: flex-start !important;
}

.address-page-form-title{
  font-family: Poppins;
font-size: 30px;
font-weight: 400;
line-height: 48px;
letter-spacing: -0.01em;
text-align: left;
width: 201px;
height: 48px;
/* margin: 5% 0 0 0 ; */
margin: 0% 70% 0px 0;


}
.add-address-buyer{
  border: 1px solid rgba(253, 65, 109, 1);
  background-color: white;
  color: rgba(253, 65, 109, 1);
  width: 175px;
  height: 40px;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;

}
.add-address-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: left;
}

.address-contact-details{
  display: flex;

}
.address-details-fields{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  gap: 12px;
}
.address-contact-details-content{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
  width: 445px;
  padding: 1rem;
}
.address-contact-personal-details-content{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
  width: 445px;
  padding: 1rem;
}
.address-form-btn{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.address-modal-title{
  color: #2D0B16;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
  text-transform: uppercase;
  padding: 1rem;

}
.address-card-place-field{
  display: flex;
   gap: 1rem;
}
@media screen and (max-width: 1200px) {
  .addressCards {
    display: grid;
    grid-template-columns: auto;
  }
  .address-form-field {
    flex-direction: column;
    width: 65%;
  }
  .address-page-form {
    min-width: 18rem;
  }
  .field-name {
    width: 90%;
  }
  .address-field.MuiOutlinedInput-root {
    /* align-self: center; */
    width: 100%;
    border-radius: 100px;
    /* min-height: 50px; */
    height: 2.5rem;
    background-color: white;
    background: none;
    padding-left: 1%;
    background-size: 3%;
    font-weight: 600;
    font-size: 1.2rem;
    /* margin: 1rem auto; */
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  }
  .address-i {
    right: 16%;
    top: 65%;
  }
  .address-modal {
    width: 90%;
    align-items: flex-start;
    padding: 0;
    /* height: 100%; */
    overflow-y: scroll;
    transform: scale(0.6);
  }
  .address-modal > * {
    /* margin-left: 0.5rem; */
    /* width:100%; */
    align-items: center;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
  }
  .address-close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

@media screen and (max-width: 488px) {


.address-modal {
  padding:0.5rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  /* min-height:300px; */
  min-width:300px;
  width:100%;
  margin:0.5rem auto;
  /* margin-left:0; */
  transform:scale(0.8,0.8);
  overflow:scroll !important;
}
.address-details-fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
}
.address-field .MuiOutlinedInput-root {
  /* align-self: center; */
  width: 320px;
}
.State-city-address-form-field {
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 77%;
}
.address-field-disabled .MuiOutlinedInput-root {
  align-self: center;
  width: 320px;
}
.address-page-form-title {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 48px;
  text-align: center;
  height: 27px;
  margin: 0% 0% 0px 0;
}
.add-address-head {
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;
  gap: 0rem;
  width: 330px;
  padding: 12px;
  align-items: center;
  margin: 0 auto;
}
.add-address-buyer {
  border: 1px solid rgba(253, 65, 109, 1);
  background-color: white;
  color: rgba(253, 65, 109, 1);
  width: 225px;
  height: 40px;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;
}
.addressCards {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  /* margin-left: 15px; */
  justify-content: space-between;
  gap: 0px;
  padding: 0px;
  flex-direction: column;
  margin: 0 auto;
}
.address-contact-personal-details-content {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
  width: 300px;
  padding: 1rem;
}
.address-contact-details-content {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
  /* width: 300px; */
  padding: 1rem;
}
.address-card-place-field{
  display: flex;
   gap: 0rem;
   flex-direction: column;
   align-items: flex-start;
}
}
