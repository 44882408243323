.admin-layout {
  /* max-width: 1200px; */
  /* margin: 1rem auto 1rem auto; */
  /* background: #DAD6D6; */
  background: #F4F4F4;
  margin: 0 auto;

}
.MuiListItem-button {
  padding: 1rem auto !important;
}
.MuiButtonBase-root.MuiListItem-root.makeStyles-listItem-3.MuiListItem-gutters.MuiListItem-button {
  padding: 1.5rem;
  /* margin:0 0.2rem; */
  font-size: 1.8rem;
  background-color: rgba(212, 240, 240);
}
@media screen and (max-width: 1000px) {
  .admin-layout {
    width: 90%;
    max-width:1000px;
    margin: 5rem auto;
  }
  .MuiButtonBase-root.MuiListItem-root.makeStyles-listItem-3.MuiListItem-gutters.MuiListItem-button {
    padding: 1rem;
    /* margin:0 0.2rem; */
    font-size: 1.8rem;
  }
}

.admin-subs-plan-basic-info-layout {
  width: 60%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 3rem auto;
  padding: 2rem;
}

.admin-subs-plan-basic-info-layout-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0.5rem;
}

.admin-subs-plan-basic-info-layout-field-name {
  width: 30%;
  text-align: start;

  color: rgba(0, 0, 0, 0.8);
  font-size: 1.4rem;
}
.admin-subs-plan-basic-info-layout-field-textfield {
  width: 65%;
}

.btn {
  display: flex;
  align-items: center;
  margin: 2rem auto;
  justify-content: center;
}

.admin-card {
  margin: 1.5rem auto;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 4rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.admin-card:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.admin-card-list-table-product-item {
  text-align: center;
  width: 25%;
  /* max-width:50px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
}
.admin-approval-table th{
  font-weight: 600;
  font-size: 19px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.8);
} 
.admin-approval-table td{
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.7);

}
.admin-approval-details-list {
  width: 95%;
  margin: 0 auto;
  /* min-width:; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.admin-approval-details-category-card {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 18px;
  /* min-height: 4rem; */
  margin: 16px auto;
  max-width: 1280px;
}
.admin-approval-details-category-card-name {
  text-align: center;
  font-size: 20px;
  margin: 5px auto;
}
.admin-approval-details-card-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:4px;
  margin: 1rem auto 1rem auto;
}
.admin-approval-details-card-field-name {
  font-size: 16px;
  font-weight: 400;
}
.admin-approval-details-textfield {
  width: 70%;
  /* max-height: 1rem;   */
  min-width:330px !important;
  border-radius: 5px !important;
}

.admin-approval-cert-image {
  /* max-width:800px; */
  max-height: 300px;
}

.admin-approval-status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.admin-approval-pending {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.4rem;
  margin: 2rem auto;
  width: 100%;
  padding: 1.5rem;
  background: #ffffff;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 20px;
}

.admin-approval-updated-status {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.4rem;
  margin: 2rem auto;
  width: 80%;
  padding: 1.5rem;
  background: #ffffff;
}
.admin-page-header {
  text-align: center;
  font-size: 2.8rem;
  text-decoration: underline;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

@media screen and (max-width: 480px) {
  .admin-approval-cert-image {
    /* max-width:800px; */
    max-height: 100px;
    max-width: 100px;
  }

  .admin-approval-details-card-field {
    flex-direction: column;
  }

  .admin-approval-details-card-field-name {
    /* text-align: left; */
    width: 70%;
    min-width:370px;
    margin: 0 auto;
    align-self: flex-start;
  }
}

.responsive-table {
  width: 100%;
  overflow-x: auto;

}

.card {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 2px 2px 5px #ddd;
}

td {
  padding: 10px;
}

@media only screen and (max-width: 480px) {
  .seller-navbar-component
  .css-hyum1k-MuiToolbar-root {
    min-height: 64px;
    background: #ffffff;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  }
  .seller-navbar-component {
    background: white!important;
}
  .card {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-bottom: 20px;
  }
  
}

.admin-approval-table {
  width: 70%;
  margin: auto;
  min-width: 350px;
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.6em;

}

.admin-approval-table tr:first-child {
  /* border-top: none; */
  /* border-bottom: none; */
  text-align: center;
  background: white;
  color: black;
}

.admin-approval-table tr {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px #ddd;
}

.admin-approval-table tr:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.admin-approval-table tr:nth-child(odd):not(:first-child) {
  background: #ffffff;
  border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
}

.admin-approval-table th {
  display: none;
}

.admin-approval-table td {
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.admin-approval-table td:first-child {
  margin-top: 0.5em;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.admin-approval-table td:last-child {
  margin-bottom: 0.5em;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.admin-approval-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: black;
}

.admin-approval-table th,
.admin-approval-table td {
  /* text-align: left; */
}

.admin-approval-table {
  color: #333;
  border-radius: 0.4em;
  overflow: hidden;
}

.admin-approval-table tr {
  background: #ffffff;
}

.admin-approval-table th,
.admin-approval-table td {
  padding: 0.5em 1em;
}

.admin-approval-table tr:first-child,
.admin-approval-table td:first-child {
  border-top-left-radius: 10px;
}
.admin-approval-table tr:first-child,
.admin-approval-table td:last-child {
  border-top-right-radius: 10px;
}

.admin-approval-table tr:last-child,
.admin-approval-table td:first-child {
  border-bottom-left-radius: 10px;
}
.admin-approval-table tr:last-child,
.admin-approval-table td:last-child {
  border-bottom-right-radius: 10px;
}

@media screen and (max-width: 601px) {
  .admin-approval-table tr:nth-child(2) {
    border-top: none;
  }

  .admin-approval-table tr {
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .admin-approval-table td {
    display: block;
    border: none !important;
    display: flex;
    justify-content: space-between;
  }
  .admin-approval-table td:first-child {
    border: none !important;
  }

  .admin-approval-table td:last-child {
    border: none !important;
  }

  .admin-approval-table {
    width: 95%;
    margin: 0 auto;
    border-collapse: collapse;
    border-spacing: 0 1em;
  }
  .data-th {
    width: 50%;

    gap: 2rem;
  }
 
}

@media screen and (min-width: 600px) {
  .admin-approval-table {
    width: 95%;
    margin: 0 auto;
  }
  .admin-approval-table tr:hover:not(:first-child) {
    /* background-color: #d8e7f3; */
    cursor: pointer;
  }
  .admin-approval-table td:before {
    display: none;
  }
  .admin-approval-table th,
  .admin-approval-table td {
    display: table-cell;
    padding: 0.25em 0.5em;
  }
  .admin-approval-table th:first-child,
  .admin-approval-table td:first-child {
    padding-left: 0;
  }
  .admin-approval-table th:last-child,
  .admin-approval-table td:last-child {
    padding-right: 0;
  }
  .admin-approval-table th,
  .admin-approval-table td {
    padding: 1em !important;
  }
}
/* @media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
    min-height: 64px;
  background: #ffffff; 
 box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
} */

.admin-approval-details-text-area {
  width: 60%;
  min-height: 200px;
  padding: 1rem;
  margin: 0 auto;
  font-size: 1rem;
  border-radius: 5px;
  resize: none;
  border: 0.1px solid rgba(112, 112, 112, 0.5);
}
.admin-approval-details-text-area:focus {
  outline: 1px solid #ff2171;
  border: 1px solid #ff2171;

}

.admin-reject-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Seller-layout{
 background: white;
 /* background: rgba(244, 244, 244, 1); */
width: 100%;
/* height: 100vh; */
}
.admin-layout{
background: white;
 width: 100%;
 /* height: 100vh; */
 }