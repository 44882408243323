.seller-login-email-textfield{
    align-self: center;
    background-color: white;
    font-weight: 400 !important;
    font-size: 1.1rem;
    width: 375px;
    height: 49px;
    color: rgba(0, 0, 0, 0.15);
    padding: 2px 0px 18px 0px;
    border-color: rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}
.seller-login-modal-field-name {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
    margin-top: 8px;

}
.seller-login-form-textfields{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    flex-direction: column;
}
.textField-Seller-login
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 1);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 375px;
    height: 49px;
    position: relative;
    border-radius: 8px;
}
.seller-login-modal-name-field{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.textField-Seller-login
.css-1wc848c-MuiFormHelperText-root.Mui-error {
    color: #d32f2f;
    position: relative;
    left: 10px;
    top: 0px;
    font-size: 12px;
}
.seller-login-Forget-terms {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 80%;
    margin-top: 8px;
    color: #FF5569;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.14px;
text-decoration-line: underline;
}
.seller-login-modal-field-divider {
    text-align: center;
    margin: 2px auto;
}
.seller-login-modal-field-signup {
    color: #1A1A1A;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 1px auto;
    text-align: center;
}