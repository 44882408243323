.return-refund-policy{
    padding: 32px;
    margin-top: 7rem;
}
.return-refund-policy p{
    font-size: 18px;
}
@media only screen and (max-width: 880px) {
    .return-refund-policy{
        padding: 16px;
        margin-top: 7rem;
    }
    .return-refund-policy p{
        font-size: 16px;
    }
    .return-refund-policy h1{
        font-size: 22px;
    }
    .return-refund-policy h2{
        font-size: 18px;
    }
}
@media only screen and (max-width: 480px) {
    .return-refund-policy{
        padding: 16px;
        margin-top: 13rem;
    }
    .return-refund-policy p{
        font-size: 16px;
    }
    .return-refund-policy h1{
        font-size: 22px;
    }
    .return-refund-policy h2{
        font-size: 18px;
    }
}