.buyer-hero-image {
    width: 100%;
    height: 470px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-around;
}
.img-gap{
    height:25%;
}


.hero-img-button{
    background: white;
    color:black;
    display:flex;
    align-items:center;
    gap:1rem;
    padding:0.5rem 1rem;
    border:1px solid rgba(0,0,0,0.5);
    border-radius:5px;
}
.hero-img-head{
    font-size: 40px;

}

.hero-img-desc{
    /* width:25%; */
    max-width:350px;
    margin:0 auto;
    text-align:center;
    font-size: 20px;

}

.hero-img-button:hover{
    cursor:pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}