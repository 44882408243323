.admin-planlist-table {
    width:70%;
    margin: auto;
    min-width: 350px;
    max-width: 100%;
     border-collapse:separate; 
    border-spacing: 0 1em;
  }
  .common-btn {
    font-size: 18px;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 2%;
}
  .admin-planlist-table tr:first-child {
    /* border-top: none; */
    text-align: center;
    background: white;
    color: black;
  }
  
  .admin-planlist-table tr {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .admin-planlist-table tr:nth-child(odd):not(:first-child) {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .admin-planlist-table th {
    display: none;
  }
  
  .admin-planlist-table td {
    display: block;
    /* border-top: 1px solid rgba(0, 0, 0, 0.2); */
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
  
  }
  
  .admin-planlist-table th{
    font-size: 19px;
    color: rgba(0, 0, 0, 0.8);
  }
  .admin-planlist-table td:first-child {
    margin-top: .5em;
    /* border-left: 1px solid rgba(0, 0, 0, 0.2); */
  
  }
  
  .admin-planlist-table td:last-child {
    margin-bottom: .5em;
    /* border-right: 1px solid rgba(0, 0, 0, 0.2); */
  
  }
  
  .admin-planlist-table td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 120px;
    display: inline-block;
    color: black;
  }
  
  .admin-planlist-table th,
  .admin-planlist-table td {
    /* text-align: left; */
  }
  
  .admin-planlist-table {
    color: #333;
    border-radius: .4em;
    overflow: hidden;
  }
  
  .admin-planlist-table tr {
    background: #FFFFFF;
   }
  
  .admin-planlist-table th,
  .admin-planlist-table td {
    padding: .5em 1em;
  }
  
  .admin-planlist-table tr:first-child , .admin-planlist-table td:first-child { 
    /* border-top-left-radius: 10px;  */
  }
  .admin-planlist-table tr:first-child , .admin-planlist-table td:last-child { 
    /* border-top-right-radius: 10px;  */
  }
  
  .admin-planlist-table tr:last-child ,.admin-planlist-table td:first-child { 
    /* border-bottom-left-radius: 10px;  */
  }
  .admin-planlist-table tr:last-child,  .admin-planlist-table td:last-child {
     /* border-bottom-right-radius: 10px; */
     }
  
  @media screen and (max-width: 601px) {
   .admin-planlist-table tr:nth-child(2) {
      border-top: none;
      
    }
  
    .admin-planlist-table tr {
      background: #fafafa;
      border: 1px solid rgba(0, 0, 0, 0.2);
  
     }
  
    .admin-planlist-table td {
      display: block;
      border: none !important;
    
    }
    .admin-planlist-table td:first-child {
      border: none !important;
    
    }
    
    .admin-planlist-table td:last-child {
      border: none !important;
    
    }
    
    .admin-planlist-table {
      width:95%;
      margin:0 auto;
      border-collapse: collapse;
      border-spacing: 0 1em;
    }
    .admin-planlist-table td:before {
        content: attr(data-th) ": ";
        font-weight: bold;
        width: 200px;
        display: inline-block;
        color: black;
      }
  }
  @media screen and (min-width: 600px) {
  
    
  
    .admin-planlist-table {
      width:95%;
      margin:0 auto;
    }
    .admin-planlist-table tr:hover:not(:first-child) {
      /* background-color: #d8e7f3; */
      cursor:pointer;
    }
    .admin-planlist-table td:before {
      display: none;
    }
    .admin-planlist-table th,
   .admin-planlist-table td {
      display: table-cell;
      padding: .25em .5em;
    }
    .admin-planlist-table th:first-child,
  .admin-planlist-table td:first-child {
      padding-left: 0;
    }
   .admin-planlist-table th:last-child,
   .admin-planlist-table td:last-child {
      padding-right: 0;
    }
    .admin-planlist-table th,
    .admin-planlist-table td {
      padding: 1em !important;
    }
  
  
  }
