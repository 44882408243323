
.subnav {
    /* height: 40px; */
    width: 90%;
    /* background-color: #fff; */
    color: rgba(0, 0, 0, 0.66);
    position: relative;
    left:2.8%;
    transform: scale(1, 0.9 );
    transform-origin: 0 -50%;
    margin-bottom:0;
  }
  
  .subnav > .subnav-header {
    display: inline;
  }
  
  .subnav > .subnav-header > .subnav-title {
    display: inline-block;
    font-size: 1.5rem;
    color: #fff;
    padding: 10px 10px 10px 10px;
  }
  
  .subnav > .subnav-btn {
    display: none;
  }
  
  .subnav > .subnav-links {
    display: inline;
    font-size: 1rem;
  }
  
  .subnav > .subnav-links > a {
    display: inline-block;
    padding:0.5rem 1rem;
    text-decoration: none;
    color: #222;
    font-size:1.5rem;
    font-weight:600;
    margin:0 0.5rem;
  }
  
  .subnav > .subnav-links > a:hover {
    color: rgba(248, 152, 128, 1);
    /* color:rgba(248, 152, 128, 1); */
    cursor:pointer;
    text-decoration: underline;
    text-underline-offset: 1rem;
    /* background: rgba(0, 129, 133, 1); */
    /* color: #fff; */



    border-radius: 30px;
  }
  
  .subnav > #subnav-check {
    display: none;
  }
  
  @media (max-width:600px) {
    .subnav > .subnav-btn {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .subnav > .subnav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
  /*     padding: 13px; */
    }
    .subnav > .subnav-btn > label:hover,.subnav  #subnav-check:checked ~ .subnav-btn > label {
      background-color: rgba(0, 0, 0, 0.0);
      cursor:pointer;
    }
    .subnav > .subnav-btn > label > span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 2px solid #222;
    }
    .subnav > .subnav-links {
      position: absolute;
      display: block;
      width: 100%;
      background-color: #fff;
      height: 0px;
      transition: all 0.3s ease-in;
      overflow-y: hidden;
      top: 50px;
      left: 0px;
    }
    .subnav > .subnav-links > a {
      display: block;
      width: 90%;
    }
    .subnav > #subnav-check:not(:checked) ~ .subnav-links {
      height: 0px;
    }
    .subnav > #subnav-check:checked ~ .subnav-links {
  /*     height: calc(100vh - 50px); */
      height:50vh;
      z-index:10;
      max-width:100%;
      overflow-y: hidden;
    }
    .subnav > .subnav-links {
      background:#eee;

    }
  }