.seller-otp-Container{
    font-family: "Barlow";
   height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.seller-forgot-verify-right-content {
    width: 100%;
    max-height: 1000px;
    height: 100vh;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* gap: 3rem; */
}
.seller-otp-verify-left{
    width: 100%;
    /* height: 100vh; */
    /* max-width: 1400px; */
    background: white;
    display: flex;
    justify-content: space-between;align-items: center;
    flex-direction: column;
  
}
.OtpVerification-Myhraki-logo{
margin: 0% auto;
}
.sellerOtpVerification-container-left-img {
    display: flex;
    justify-content: flex-start;
    /* margin: 85px auto; */
}
.seller-forgot-password-title {
    font-size: 30px;
    /* margin-bottom: 10%; */
    color: white;
    /* margin-top: -15%; */
}
.shopping-img-forgot {
    width: 100%;
    /* height: 400px; */
    margin: 0 auto;
    
}

.seller-otp-verify-right{
    width: 50%;
    /* height: 100vh; */
    background: linear-gradient(359.79deg, #FF6155 -9.58%, rgba(252, 38, 120, 0.3875) 104.55%, rgba(255, 97, 85, 0) 126.21%);
    /* max-width: 2000px; */
    max-height: 1900px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}
.seller-forgot-verify-right {
    width: 50%;
    /* height: 100vh; */
    /* background: linear-gradient(359.79deg, #FF6155 -9.58%, rgba(252, 38, 120, 0.3875) 104.55%, rgba(255, 97, 85, 0) 126.21%); */
    /* max-width: 2000px; */
    max-height: 1900px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}
.SellerOtpVerification-container-right-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    /* gap: 1rem; */
    margin: 0 auto;
}
.seller-forgot-password-otp-title{
    font-size: 30px;
color: white;

}
.seller-forgot-password-otp-desc{
    font-size: 18px;
color: white;
width: 70%;
}
.admin-reset-phone-icon {
    position: absolute;
    left: 13%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
    top: 35%;
}
.SellerOtpVerification-container-right-content {
    max-width: 2000px;
    /* max-height: 1900px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    
    

}
.admin-reset-page-phone-field
.css-v7esy.Mui-error {
    color: rgb(211, 47, 47);
    left: 18%;
    bottom: -16%;
}
.SelllerOtpVerification-title {
    font-weight: 400;
    font-size: 28px;
    color: #707070;
    margin-bottom: 6%;
}
@media only screen and (max-width: 900px)  {
    
.seller-otp-verify-right{
    display: none;
}

.seller-otp-verify-left{
    width: 100%;
}
}
@media only screen and (max-width: 579px)  {
    
    .seller-otp-verify-right{
        display: none;
    }
    
    .seller-otp-verify-left{
        width: 100%;
    }
    .admin-reset-phone-icon {
        position: absolute;
        left: 19%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.6rem;
        top: 35%;
    }
    .login-email .MuiOutlinedInput-root {
        align-self: center;
        width: 75%;

        min-width: 224px;
        min-height: 50px;
        height: 2.5rem;
        background-color: white;
        padding-left: 8%;
        background-size: 3%;
        font-weight: 300 !important;
        font-size: 1.2rem;
        /* margin: 3%; */
        color: rgba(112, 112, 112, 0.72);
        /* border: 0.5px solid rgba(112, 112, 112, 0.11); */
    }
    .admin-otp-page-phone-field
    .login-email .MuiOutlinedInput-root {
        align-self: center;
        width: 85%;
        min-width: 189px;
        min-height: 50px;
        height: 2.5rem;
        background-color: white;
        padding-left: 8%;
        background-size: 3%;
        font-weight: 300 !important;
        font-size: 1.2rem;
        /* margin: 3%; */
        color: rgba(112, 112, 112, 0.72);
        /* border: 0.5px solid rgba(112, 112, 112, 0.11); */
    }
   
    .login-email .MuiOutlinedInput-root {
        align-self: center;
        width: 67%;
        min-width: 194px;
        min-height: 50px;
        height: 2.5rem;
        /* background-color: white; */
        /* padding-left: 8%; */
        /* background-size: 3%; */
        /* font-weight: 300 !important; */
        /* font-size: 1.2rem; */
        /* margin: 3%; */
        /* color: rgba(112, 112, 112, 0.72); */
        /* border: 0.5px solid rgba(112, 112, 112, 0.11); */
    }
    .usertype-switch {
        font-size: 15px;
    }
    .otp-verify-resend-otp {
        display: flex;
        /* flex-direction: column; */
    }
    }
    .seller-reset-login span {
        font-weight: 600;
        font-size: 14px;
        text-decoration-line: underline;
        color: #FF5569;
     display: flex;
     justify-content: center;
     margin: 0 auto;
     cursor: pointer;
    }

        .admin-reset-page-phone-field
        .css-1wc848c-MuiFormHelperText-root.Mui-error {
            position: absolute;
            color: #d32f2f;
            bottom: -8%;
            font-size: 12px;
            left: 18%;
        }