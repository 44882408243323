.product-details-pincode-field .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 39px;
}
.css-heg063-MuiTabs-flexContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 48px;
    border-bottom: 2px solid #D9D9D9;
}
.css-k008qs {
    display: flex;
    gap: 12px;
    border-bottom: 2px solid #D9D9D9;
}
.Product-details-container {
    max-width: 1280px;
    max-height: 4090px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    /* align-items: center; */
    padding: 0rem;
    margin: -10px auto 0px;
    margin-top:80px;
}
.category-single-product{
    margin-top: -16px;
    margin-bottom: 2px;
    font-size: 14px;
    color:rgba(134, 134, 134, 1);
}
.customer-image-gallery {
    width: 70%;
    /* max-width: 580px; */
    align-self: flex-start;
    /* margin: 2rem auto; */
}

.Product-details-right {
    display: flex;
    flex-direction: column;
    gap: 32px;
    /* align-items: flex-start; */

}
.product-details-pincode-field{
    display: flex;
}
.Product-details-container-right {
    max-width: 592px;
    /* max-height: 659px; */
    flex-shrink: 0;
    border-radius: 8px;
    background: rgba(255, 189, 112, 0.15);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    padding: 24px;
    

}

.Product-details-container-right-tabs {
    display: flex;
    width: 589px;
    margin-top: 1rem;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;
    cursor: pointer;
}

.Product-details-title {
    color: rgba(0, 0, 0, 1);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.24px;
}

.Product-details-title-field {

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.product-favourite-share-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.inclusive-tag-lines {
    color: #868686;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.14px;
    text-align: center;
    display: flex;
    align-items: center;
}

.Quantity-button-field {
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-Pincode-tracking-field {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 4px;
}

.icon-shipping-tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--neutral-black, #1A1A1A);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.product-Pincode-tracking-textfield
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    position: relative;
    border-radius: 100px;
    /* padding: 5px 16px; */
    padding-left: 12px;
    width: 260px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: var(--neutral-black, #1A1A1A);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;

}
.green-star{
    fill: green;
}

.Product-details-container-right-tabs-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 389px;
    /* min-height: 10rem; */
    margin: 0 auto;
}

.Product-details-container-right-tabs-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* width: 500px; */
}

.reviews-single-product{
    display: inline-flex;
padding: 8px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: #FFF;
color: rgba(0, 0, 0, 1);
}
.fabric-desc{
    text-overflow: ellipsis;
    overflow: hidden;
    flex-wrap: nowrap;
    width: 350px;
    text-align: left;
    font-size:16px;
    margin:6px 0;
}
.tab-active {
    color: #FE3C72;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: underline;
}

.tab-inactive {
    color: #FE3C72;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.Product-details-container-right-tabs-description {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: justify;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 14px;
    width: 500px;
}

.Product-details-container-right-tabs-Specifications {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 3px;
    align-items: flex-start;
    text-align: justify;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 14px;
}

.Product-details-media-images {
    display: inline-flex;
    align-items: flex-end;
    gap: 16px;
    justify-content: space-between;
    /* max-width: 592px; */

}

.Product-details-media-images-container {
    width: 187px;
    height: 116px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FD2A80;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-image {
    width: 157px;
    /* height: 76px; */
    flex-shrink: 0;
}

.media-image2 {
    width: 103px;
    /* height: 104px; */
    flex-shrink: 0;
}
.media-imag2{
    width: 72px;
    /* height: 104px; */
    flex-shrink: 0;
}

.single-product-color {
    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.16px;
    text-transform: uppercase;
    background: #FFF;

}

.customer-image-gallery {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;
}


.customer-thumbnail-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 600px;
    overflow: hidden;
    /* Hide the scrollbar */
    position: relative;
}

.customer-thumbnail-list-scrollable {
    overflow-y: scroll;
    /* Add a scrollbar when necessary */
    scrollbar-width: thin;
    scrollbar-color: #007bff transparent;
    /* Customize scrollbar color */
}

.customer-scroll-button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    /* Adjust button width as needed */
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.customer-scroll-button:hover {
    opacity: 1;
}

.customer-thumbnail {
    cursor: pointer;
    margin: 10px;
    border: 2px solid transparent;
    transition: border-color 0.2s;
}

.customer-thumbnail img {
    max-width: 100px;
    height: auto;
}

.customer-thumbnail.customer-selected {
    border-color: #007bff;
    /* Highlight the selected thumbnail */
}

.customer-selected-image {
    margin-left: 20px;
}

.customer-selected-image img {
    max-width: 400px;
    height: auto;
}

.customer-image-buttons {
    margin-top: 10px;
}

.customer-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
}

.customer-button:hover {
    background-color: #0056b3;
}

.sku-cards{
    display:flex;
    flex-wrap:wrap;
    gap:20px;
}

.sku-card {
    width: 160px;
    height: 60px;
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 15px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    cursor:pointer;
}
.selected-sku-card{
    border: 1px solid #FD2A80;
}

.sku-card-img {
    width: 56px;
    height: 48px;
    border-radius: 8px;
}

.sku-card-color {
    color: #000;
    font-size: 12px;
    letter-spacing: -.12px;
    line-height: 100%;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-wrap: nowrap;
    width: 95px;
}

.sku-card-size {
    color: #000;

    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.12px;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-wrap: nowrap;
    width: 95px;
}
.check-link{
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
color: #FE3C72;
text-decoration: underline;
align-items: flex-end;
}
.check-link1{
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #FE3C72;
    padding-bottom: 7px;
    text-decoration: underline;
    align-items: flex-end;
    }
.Product-details-tab-bar{
    display: flex;
    justify-content: space-between;
}
.product-Pincode-tracking-textfield input {
    border-radius: 38px;
}

@media screen and (max-width: 1250px) {
    .Product-details-container {
        /* max-width: 1280px; */
        /* max-height: 4090px; */
        display: flex;
        justify-content: space-between;
        gap: 20px;
        flex-direction: column;
        align-items: center;
        padding: 0rem;
        margin: 0 auto;
        margin-top:80px;
    }

    .Quantity-button-field {
        display: flex;
        justify-content: center;
        /* flex-direction: column; */
        align-items:center;
        /* width: 300px; */
    }

    .Product-details-media-images {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;
        /* flex-direction: column; */
        margin-top: 10px;
    }

    .Product-details-container-right-tabs {
        display: flex;
        width: 589px;
        margin-top: 1rem;
        flex-direction: column;
        align-items: flex-start;
        text-align: justify;
        cursor: pointer;
    }

    .Product-details-container-right {
        /* min-width: 325px; */
    /* min-height: 659px; */
    flex-shrink: 0;
    border-radius: 8px;
    /* background: rgba(255, 189, 112, 0.15); */
    background: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 25px; */
    padding: 0px;
    
     width: 90%;
     margin: 0 auto;
    }
.media-image{
width: 72px;

}
.media-image2 {
    width: 48px;
    /* height: 104px; */
    flex-shrink: 0;
}
.media-imag2{
    width: 48px;
    /* height: 104px; */
    flex-shrink: 0;
}
    .inclusive-tag-lines {
        font-size: 12px;
        text-align: left;
        display: none;

    }

    .Product-details-container-right-tabs-head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* min-width: 200px; */
    }

    .Product-details-media-images-container {
        width: 220px;
        height: 116px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #FD2A80;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Faq-head-nav{
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .product-details-pincode-field{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .icon-shipping-tag {
        font-size: 14px;

    }
    .Product-details-container-right-tabs-head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 330px;
    
    }
}

@media screen and (max-width: 480px) {

    .css-heg063-MuiTabs-flexContainer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        gap: 12px;
        border-bottom: 2px solid #D9D9D9;
    }
.customer-image-gallery{
    max-width: 80%;
    margin: 0 auto;
    /* margin: 2rem auto; */
/*  */
}
.Product-details-container{
    margin-top:0px;
}
.Quantity-button-field {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 95%;
}
.Product-details-media-images-container {
    width: 106px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FD2A80;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reviews-single-product {
    display: inline-flex;
    padding: 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #FFF;
    color: rgba(0, 0, 0, 1);
}
.sku-card {
    width: 148px;
    height: 87px;
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 15px;
    border-radius: 8px;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.20);
    cursor: pointer;
    text-align: center;
    flex-direction: column;
  }
  .sku-card {
    width: 148px;
    /* height: 60px; */
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 15px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.Product-details-container-right-tabs-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* min-height: 10rem; */
    margin: 0 auto;
    min-width: 310px;
    /* padding-left: 20px; */
}
.Product-details-container-right-tabs-head {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* min-width: 200px; */
    font-size: 14px;
    padding: 0px;
}
.Faq-head-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    justify-content: flex-start;
    margin: 0;
}
.Product-details-title {
    color: rgba(0, 0, 0, 1);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.24px;
}
.Product-details-container-right-tabs-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 318px;

}
.Product-details-container-right-tabs-description {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: justify;
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 14px;
    width: 320px;
}
.css-9kkkb7-MuiButtonBase-root-MuiTab-root{
  padding: 8px 6px;
}
.Product-details-right {
    display: flex;
    flex-direction: column;
    gap: 24px;
    /* align-items: flex-start; */
}
}




