.seller-subscription-selection-cards {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto; */
}
.seller-subscription-select-card-head{
  position:relative;
}
.subs-models-container{
    width:100%;
    max-width:1300px;
}
.price-with-rupee::before {
  content: '₹';
  background: var(--primary, linear-gradient(90deg, #FA068A 0%, #FE505F 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-right: 6px;
}
.seller-subscription-checkbox{
  max-width:1000px;
  margin: 0 auto;
  font-size:24px;
}
.price-with-rupee-annual{
  color: #767575;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.16px;
}
.title-subscription{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 54px;
  margin-top: 8px;
  margin-right: 8px;
  margin-left: 8px;
  width: 309px;
    height: 108px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--primary, linear-gradient(90deg, #FA068A 0%, #FE505F 100%));
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.seller-subscription-select-card {
  border: 1px solid #FA068A;
  border-radius: 10px;
  width:23%;
  min-width:325px;
  max-width: 325px;
  min-height:950px;
  background:#ffffff;
}

/* .seller-subscription-select-card:nth-child(2),
.seller-subscription-select-card:nth-child(3),
.seller-subscription-select-card:nth-child(4) {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 23%;
  min-width: 250px;
  max-width: 325px;
  min-height: 1000px;
  background: white;
} */
.seller-subscription-select-card:hover{
    cursor:pointer;

}

.selected-subscription-card{
 
  background: #FDF4ED;
}
.seller-subscription-select-card-plan-details {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  align-items: center;
  gap: 4px;
  /* margin-bottom: 8px; */
}
.seller-subscription-select-card-plan-price {
  line-height: 48px;
  color: #FF7891;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  background: var(--primary, linear-gradient(90deg, #FA068A 0%, #FE505F 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.seller-subscription-select-card-plan-frequency {
  color: #767575;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
}

.seller-subscription-select-card-plan-annualPrice {
  font-size: 15px;
  line-height: 18px;

  color: rgba(0, 0, 0, 0.7);
}
.subs-card-divider{
    width:90%;
    margin:0 auto;
}
.seller-subscription-select-card-feature{
  width: 90%;
  margin: 18px auto;
  display: flex;
  color: #363636;
  gap: 12px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.subs-models-header {
  letter-spacing: 0.02em;
  font-weight: 900;
  color: #FB1581;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  }
  .seller-subs-cards {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 2rem;
    /* width:1400px; */
   
  }
  .subs-list-note{
    text-align: center;
    margin-bottom: 40px;
    color: rgba(0, 0, 0, 0.50);
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }

  @media screen and (max-width: 480px){
    
    .seller-subscription-selection-cards {
     flex-direction:column;
      
    }
    .seller-subscription-select-card {
      /* border: 1px solid rgba(0, 0, 0, 0.2); */
      /* border-radius: 10px; */
      width:90%;
      min-width:250px;
      max-width: 325px;
      /* min-height:1000px; */
      /* background:#ffffff; */
    }
    .seller-subscription-select-card:nth-child(2),
.seller-subscription-select-card:nth-child(3),
.seller-subscription-select-card:nth-child(4) {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 90%;
  min-width: 250px;
  max-width: 325px;
  min-height: 1000px;
  /* background: #e2e2e2; */
}
.title-subscription {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 54px;
  margin-top: 8px;
  margin-right: 8px;
  margin-left: 8px;
  width: 310px;
  height: 108px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--primary, linear-gradient(90deg, #FA068A 0%, #FE505F 100%));
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.subs-models-header {
  letter-spacing: 0.02em;
  font-weight: 900;
  color: #FB1581;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 5rem;
}
.subs-list-note {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 16px;
  margin: 8px auto;
  /* padding-left: 0; */
}
  }