.admin-forgot-otp-Container{
    width: 100%;
    height: 100vh;
    margin: 0 auto;
}
.admin-forgot-password-left{
    /* width: 50%;
    height: 100vh; */
    background-color: white;
}
.admin-forgot-password-right{
    width: 50%;
    height: 100vh;
    margin: 0 auto;
background: #F2F2F2;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.admin-forgot-password-left-content{
    width: 100%;
    max-width: 2000px;
    height: 100vh;
    max-height: 1900px;
    margin: 0 auto;
}.admin-forgot-password-right-content{
    width: 100%;
    max-width: 2000px;
    height: 100vh;
    max-height: 1900px;
    margin: 0 auto;
}
.admin-page-terms{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-top: 0%;
    width: 90%;
    margin: 5% 5%;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
     /* margin-bottom: 8%;  */
}
.admin-span{
    color: "#707070";
}
.SellerOtpVerification-container-right-content {
    max-width: 2000px;
    /* max-height: 1900px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
width: 100%;
}
.Byr-OtpVerification-container-right-content {
    /* max-width: 2000px; */
    /* max-height: 1900px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
}
.OtpVerification-title-byr {
    font-weight: 400;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.8);
}
.otp-verify-resend-otp-byr span {
    /* display: none; */
    font-weight: 600;
    /* text-decoration-line: underline; */
    color: #707070;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
}
.login-email-byr-forgot .MuiOutlinedInput-root {
    align-self: center;
    width: 95%;
    max-width: 100%;
    min-width: 224px;
    min-height: 50px;
    height: 2.5rem;
    background-color: white;
    padding-left: 8%;
    background-size: 3%;
    font-weight: 300 !important;
    font-size: 1.2rem;
    border-radius: 4px;
    /* margin: 3%; */
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.11); */
}
.byr-reset-phone-icon {
    position: absolute;
    left: 5%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
    top: 26%;
}
.byr-reset-page-phone-field {
    width: 75%;
    min-width: 360px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin: 0 auto; */
    text-align: center;
    margin-bottom: 0.5rem;
}

.byr-reset-page-phone-field #password-helper-text {
    position: absolute;
    bottom: -38%;
    left: 11%;
    font-size: 12px;
}

.byr-reset-page-phone-field #rePassword-helper-text {
    position: absolute;
    bottom: -38%;
    left: 11%;
    font-size: 12px;
}