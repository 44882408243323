.Payment-success-title{
    font-family: Poppins;
font-size: 28px;
font-weight: 400;
line-height: 42px;
letter-spacing: 0em;
text-align: right;
width: 282px;

}
.Payment-display-container {
    display: flex;
    justify-content: center;
    /* width: 920px; */
    align-items: center;
    margin: 75px auto 0px;
    flex-direction: column;
}

