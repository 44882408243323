#password-helper-text{
    position:absolute;
    bottom:-15%;
    left:14%;
    font-size:12px;
}
#rePassword-helper-text{
    position:absolute;
    bottom:-15%;
    left:14%;
    font-size:12px;
}
#resetPassword-helper-text {
    position: absolute;
    bottom: -25%;
    left: 3%;
    font-size: 0.9rem;
}
#enter-password-helper-text {
    position: absolute;
    bottom: -25%;
    left: 3%;
    font-size: 0.9rem;
    /* margin-top: 1rem; */
}
.admin-reset-head{
    display: flex;
    justify-content: center;
    margin: 0 auto;
color: #707070;

}


.ResetPassword_Container {
    font-family: "Barlow";
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    /* background: conic-gradient(from 0deg at 50% 50%, rgba(212, 240, 240, 0.52) 0deg, #FFFFFF 154.87deg, rgba(212, 240, 240, 0.52) 360deg); */
}
.ResetPassword_LeftScreen{
    width: 50%;
    height: 100vh;
background: #F2F2F2;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.ResetPassword-right-content{
    max-width: 1900px;
    max-height: 1200px;
display: flex;
flex-direction: column;
justify-content: space-around;
margin: 0 auto;
gap: 3rem;

}
.ResetPassword_LeftScreen_Content{
    max-width: 1900px;
    max-height: 1200px;
display: flex;
flex-direction: column;
justify-content: space-around;
margin: 0 auto;
gap: 3rem;
}
.ResetPassword_Content_Description{
    font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
text-align: justify;
color: white;
width: 90%;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
  }
.admin-reset-card {
    /* margin: 2% 3%; */
    width: 35%;
    min-width: 400px;
    /* min-height: 85%; */
    max-width:700px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    padding-top:5%;
    transform: scale(0.8);
    /* transform-origin: 0 0; */
}

.admin-reset-head {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    margin: 5% auto;
    color: #707070;
    /* margin-top: 5rem; */
    text-align: center;
}
.admin-reset-auth-text {
    /* color: rgba(248, 152, 128, 1); */
    /* font-size:1.3vw; */
    font-size: 1.6rem;
    max-width: 65%;
    font-weight: 400;
    /* margin: 5% auto; */
    text-align: center;

}
.admin-reset-page-form{
    width:100%;
    display:flex;
    flex-direction:column;
}
.admin-reset-page-email-field{
    width:80%;
    max-width: 65%;
    min-width: 300px;
    position:relative;
    margin:0.5rem auto;
}
.admin-reset-email .MuiOutlinedInput-root{
    align-self: center;
    width: 90%;
    min-width:360px;
    /* border-radius: 100px; */
    min-height: 50px;
    height: 2.5rem; 
    background-color:white;
    padding-left: 150%;
    background-size: 8%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3% ;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
#number-helper-text{
    position:absolute;
    bottom:-15%;
    left:28%;
    font-size:1rem;
}

#number{
    padding:  1rem;
}

/*  */
.admin-reset-page-phone-field {
    width: 75%;
    min-width: 360px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin: 0 auto; */
    text-align: center;
    margin-bottom: 0.5rem;
}
.admin-reset-phone-icon {
    position: absolute;
    left: 20%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
    top: 35%;
}
.admin-reset-phone-validation-text {
    position: absolute;
    bottom: -8%;
    color: red;
    opacity:0.7;
    font-size: 0.8rem;
    padding-top: 1rem;
    text-align: center;
}
.admin-reset-number {
    width: 100%;
    /* border-radius: 100px; */
    /* min-height: 50px; */
    height: 7vh;
    padding-left: 15%;
    /* background: url("/image 6.png") no-repeat 2vw; */
    background-size: 8%;
    font-weight: 400;
    /* font-size: 1.2vw; */
    font-size: 1.2rem;
    line-height: 2vw;
    margin: 5% auto;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

.admin-reset-send-otp {
    margin: 5% 35%;;
    /* font-size: 1.3vw; */
    font-size: 1.3rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}

.admin-password-signin {
    margin: 3% auto;
    font-weight: 600;
    font-size: 1.6vw;
    font-size: 1.2rem;
    line-height: 2vw;
    color: #707070;
    width: 35%;
    text-align: center;

}
.admin-otp-phone-icon {
    position: absolute;
    /* left: 15%; */
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.3rem;
    top: 37%;
}
    .admin-password-signin span {
        font-weight: 600;
        font-size: 1.4vw;
        line-height: 2vw;
        text-decoration-line: underline;
        color: rgba(248, 152, 128, 1);
    }

    .admin-password-signin span:hover {
        cursor:pointer;
    }
        .admin-reset-terms {
    margin: 5% 5%;
    font-style: normal;
    font-weight: 600;
    /* font-size: 1.4vw; */
    font-size: 1.4rem;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
}

    .admin-reset-terms span {
        color: #707070;
    }

.admin-reset-logo {
   /* position: fixed; */
   position:absolute;
   /* margin: 1% 1%; */
   /* bottom:-50%; */
   top:-2.5rem;
   /* left:43%; */
   width:10rem;
   height:10rem;
   /* z-index: 100;; */
}

.admin-reset-number:focus {
    outline: none;
}

.admin-reset-number[type=number] {
    -moz-appearance: textfield;
}

.admin-reset-number::-webkit-outer-spin-button,
.admin-reset-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.admin-otp-login-title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #707070;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-bottom: 9%; */
}
@media only screen and (min-width: 1300px) {
    .admin-reset-head {
        font-size: 2.0rem;
    }
    .admin-otp-phone-icon {
        position: absolute;
        left: 20%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.6rem;
        top: 37%;
    }
    .otp-phone-icon {
        position: absolute;
        left: 13%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.6rem;
        top: 37%;
    }
}

@media only screen and (max-width: 480px) {

    .admin-reset-phone-icon {
        position: absolute;
        left: 12%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.6rem;
        top: 35%;
    }
    .otp-phone-icon {
        position: absolute;
        left: 13%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.6rem;
        top: 37%;
    }

}

.seller-otp-number .MuiOutlinedInput-root{
    align-self: center;
    width: 60%;
    /* border-radius: 100px; */
    min-height: 50px;
    height: 2.5rem; 
    background-color:#efefef;
    padding-left: 10%;
    background-size: 8%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3% ;
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

