.CustomerProductDetails1-mainsection {
  background: white;
  display: flex;
  gap: 10px;
}
.CustomerProductDetails1-mainsection1 {
  width: 50%;
  padding: 8px;
  height: 100%;
  position: relative;
}
.CustomerProductDetails1-mainsection2 {
  width: 50%;
}
.CustomerProductDetails1-mainsection-wrapper {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.CustomerProductDetails1-mainsubsection2-1 {
  display: flex;
  gap: 10px;
  align-items: center;
}
.CustomerProductDetails1-mainsection-title {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
}
.CustomerProductDetails1-mainsection-Main-title {
  font-family: Mulish;
  font-size: 32px;
  font-weight: 400;
  line-height: 40.16px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerProductDetails1-price {
  font-family: Mulish;
  font-size: 24px;
  font-weight: 800;
  line-height: 30.12px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerProductDetails1-dis-price {
  font-family: Mulish;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.12px;
  letter-spacing: 0.04em;
  text-align: left;
  text-decoration-line: line-through;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 4%, 72%, 1);
}
.CustomerProductDetails1-dis-price-details {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 4%, 72%, 1);
}
.CustomerProductDetails1-horiline {
  border: 1px solid hsla(0, 0%, 85%, 1);
}
.CustomerProductDetails1-Sub-mainsection {
  width: 80%;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerProductDetails1-types-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 80%;
}

.CustomerProductDetails1-types {
  display: flex;
  gap: 10px;
  border: 1px solid black;
  padding: 8px 24px 8px 24px;
  border-radius: 35px;
}

.CustomerProductDetails1-types-col {
  display: flex;
  flex-direction: column;
}

.CustomerProductDetails1-types-img {
  width: 20%;
}
.CustomerProductDetails1-types:hover {
  border-color: hsla(336, 98%, 58%, 1);
  cursor: pointer;
}
.CustomerProductDetails1-types-col-text1 {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;

  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 25%, 1);
}
.CustomerProductDetails1-types-col-text1-span {
  color: hsla(0, 0%, 0%, 1);
  padding-left: 6px;
}

.CustomerProductDetails1-buttons-section {
  width: 80%;
  display: flex;
  gap: 20px;
  align-items: center;
}
.CustomerProductDetails1-border-button1 {
  cursor: pointer;
  width: 30%;
  border: 2px solid #fe585b;
  padding: 5px;
  color: #fe585b;
  text-align: center;
  border-radius: 20px;
  display: flex;
  gap: 30px;
  justify-content: center;
}
.CustomerProductDetails1-border-button1 div {
  display: flex;
  align-items: center; /* Ensures icons are vertically centered */
  justify-content: center; /* Ensures icons are horizontally centered */
}
.CustomerProductDetails1-border-button2 {
  width: 70%;
  color: hsla(0, 0%, 100%, 1);
  padding: 8px;
  text-align: center;
  border-radius: 20px;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
}
.GiftcardSection-customerProducts-line {
  width: 80%;
}
.GiftcardSection-customerProducts {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.GiftcardSection-customerProducts-sub1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.GiftcardSection-customerProducts-title {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.GiftcardSection-customerProducts-learnmore {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
}
.customer-product-pincode {
  width: 78%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 25px;
  border: 1px solid hsla(0, 0%, 80%, 1);
}

.customer-product-pincode-entry {
  flex: 1;
  padding: 8px;
  margin: 0 8px;
  border-radius: 8px;
  outline: none;
  min-height: 24px;
  cursor: text;
}

.customer-product-pincode-entry:empty::before {
  content: attr(data-placeholder);
  color: #aaa;
}
.customer-product-check-pc {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe575c;
  padding-right: 10px;
}
.customer-product-pincode-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.customer-product-pincode-section-text {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 10%, 1);
}
.customer-product-logos {
  display: flex;
  gap: 20px;
}
.customer-product-logo-img {
  width: 15%;
}
.customerproduct-accordian {
  width: 80%;
}

.customerproduct-accordian .customerproduct-custom-accordion {
  position: relative;
  box-shadow: none;
}
.customer-product-text-title {
  font-family: Mulish !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1) !important;
}

.CustomerProductDetails1-mainsection1-slider {
  position: relative;
  width: 100%;

  object-fit: cover;
}

.CustomerProductDetails {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
}

.customerproduct-next-arrow {
  right: 10px;
}

.customerproduct-prev-arrow {
  left: 10px;
}

.slick-dots {
  position: absolute;
  bottom: 10px;

  display: flex !important;
  justify-content: center;
  z-index: 5;
}

.slick-dots li button:before {
  color: white;
  font-size: 10px;
}
.fav-icons-product-details {
  position: absolute;
  display: flex;
  align-items: center;
  top: 24px;
  right: 24px;
  background: hsla(0, 0%, 100%, 1);
  z-index: 10;
  border: none;
  padding: 4px;
  border-radius: 30px;
  cursor: pointer;
}
.fav-icons-product-details2 {
  position: absolute;
  display: flex;
  align-items: center;
  top: 24px;
  right: 80px;
  background: hsla(0, 0%, 100%, 1);
  z-index: 10;
  border: none;
  padding: 4px;
  border-radius: 30px;
  cursor: pointer;
  color: #fd2583;
}
.fav-icons-product-details2:hover {
  background: #fd2583;
  color: hsla(0, 0%, 100%, 1);
}
.CustomerProductDetails1-mainsection1-slider-images {
  width: 100%;
}
@media screen and (max-width: 598px) {
  .CustomerProductDetails1-mainsection {
    display: flex;
    flex-direction: column;
  }
  .CustomerProductDetails1-mainsection1 {
    width: 100%;
    padding: 0px;
  }
  .CustomerProductDetails1-mainsection2 {
    width: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .CustomerProductDetails1-types-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
  }
  .CustomerProductDetails1-buttons-section {
    width: 100%;
  }
  .CustomerProductDetails1-Sub-mainsection {
    width: 100%;
  }
  .GiftcardSection-customerProducts-line {
    width: 100%;
  }
  .customer-product-pincode {
    width: auto;
  }
  .customer-product-logos {
    justify-content: space-around;
  }
  .customerproduct-accordian {
    width: 100%;
  }
  .CustomerProductDetails1-mainsection-wrapper {
    padding: 10px;
  }
}
@media (min-width: 600px) and (max-width: 1200px) {
  .CustomerProductDetails1-types-section {
    grid-template-columns: repeat(2, 1fr);
    width: 98%;
  }
  .CustomerProductDetails1-Sub-mainsection {
    width: 90%;
  }
  .CustomerProductDetails1-buttons-section {
    width: 98%;
  }
  .GiftcardSection-customerProducts-line {
    width: 98%;
  }
  .customer-product-pincode {
    padding: 0px;
    width: 90%;
  }
  .customer-product-logos {
    justify-content: space-around;
  }
  .customerproduct-accordian {
    width: 100%;
  }
}
