.product-listing {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-top: 24px;
}
 
.product-listing-filter>* {
    margin-bottom: 1rem;
}
.product-listing-filter-section-clear-all{
    text-decoration: underline;
    color: #FD2A8A;
    font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1.2px;
text-transform: uppercase;
cursor: pointer;
}
.product-listing-products {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 1rem;
    padding-top: 2.5rem;
    justify-content: space-evenly;
}
 
.product-listing-filter-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
}
.bottom-sticky-button{
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: rgba(26, 26, 26, 1);
display: flex;
justify-content: space-between;
cursor: pointer;
 
}
 
.product-listing-filter-section-heading {
    color: #1A1A1A;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
}
 
 
.product-listing-products .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root .Mui-selected {
    background-color: #FD2A8A;
    color: white;
}
.customAccordionDetails {
 
    padding: 8px !important;
    margin-top: -20px;
  }
  /* .product-listing-filter-section-clear-all{
 
  } */
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    padding: 5px !important;
  }
.css-1bxu5hb-MuiPaper-root-MuiAccordion-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    position: relative;
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
    width: 288px;
    background: #FFF2F6;
    margin-bottom: 1rem;
    padding: 4px;
}
/* .bottom-navigation {
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  } */
.product-listing-filter-section-mobile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 330px;
    gap: 8px;
 
}
.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFF2F6;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    z-index: 1000;
  }
@media screen and (max-width: 900px) {
    .product-listing-filter{
        display: none;
    }
    .bottom-nav {
        display: flex;
      }
      .css-1bxu5hb-MuiPaper-root-MuiAccordion-root {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        position: relative;
        transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        overflow-anchor: none;
        border-radius: 0;
        width: 325px;
        background: #FFF2F6;
        margin-bottom: 1rem;
        padding: 16px;
        width: 330px;
        /* height: 272px; */
        /* margin: 2rem auto; */
        flex-shrink: 0;
    }
    .css-1bxu5hb-MuiPaper-root-MuiAccordion-root.Mui-expanded {
        margin: 8px 5px 0px 8px;
    }
    .css-1nrlq1o-MuiFormControl-root{
        padding: 8px;
    }
    .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
        margin: 0px 16px !important;
    }
    .customAccordionDetails {
        padding: 8px !important;
    }
    .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
        color: rgba(0, 0, 0, 0.7);
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.03rem;
    }
    .product-listing-products {
        flex: 1 1;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 0rem;
        padding-top: 18px;
        justify-content: space-evenly;
    }
    .product-listing {
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-wrap:wrap;
        margin-top: 24px;
        width: 95%;
        margin: 0 auto;
        margin-top: 24px;
    }
}
 
@media screen and (max-width: 480px) {
.product-listing {
    align-items: flex-start;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 330px;
    flex-direction: column;
}
.product-listing-filter>* {
display: none;
}
.css-1bxu5hb-MuiPaper-root-MuiAccordion-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    position: relative;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
    width: 325px;
    background: #FFF2F6;
    margin-bottom: 1rem;
    padding: 16px;
    width: 330px;
    /* height: 272px; */
    /* margin: 2rem auto; */
    flex-shrink: 0;
}
.css-1bxu5hb-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 8px 5px 0px 8px;
}
.css-1nrlq1o-MuiFormControl-root{
    padding: 8px;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0px 16px !important;
}
.customAccordionDetails {
    padding: 8px !important;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    color: rgba(0, 0, 0, 0.7);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.03rem;
}
/* .bottom-navigation {
    display: block;
  } */
  .css-1bxu5hb-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 8px 5px 0px 8px;
}
.css-1nrlq1o-MuiFormControl-root{
    padding: 8px;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0px 16px !important;
}
.customAccordionDetails {
    padding: 8px !important;
}
.product-listing-products {
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 0rem;
    /* padding-top: 18px; */
    justify-content: space-evenly;
}
.product-listing {
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-top: 24px;
    width: 330px;
    margin: 0 auto;
    margin-top: 24px;
}
}
.accordian
.css-1jaw3da {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-left: 0px;
    margin-right: 16px;
}