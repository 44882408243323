.myhraki-description {
    padding: 32px;
    margin-top: 7rem;
}
.myhraki-description-cancelPolicy{
    padding: 32px;
    margin-top: 7rem;
}
.myhraki-description-cancelPolicy p{
    font-size: 18px;  
}
.myhraki-description p{
    font-size: 18px;
}
@media screen and (max-width: 880px) {
    .myhraki-description {
        padding: 32px;
        margin-top: 10rem;
    }
    .myhraki-description h1{
font-size: 26px;
    }
    .myhraki-description-cancelPolicy{
        padding: 32px;
        margin-top: 10rem;
    }
    .myhraki-description-cancelPolicy h1{
        font-size: 26px;
    }
}
@media screen and (max-width: 480px) {
    .myhraki-description {
        padding: 32px;
        margin-top: 12rem;
    }
    .myhraki-description-cancelPolicy{
        padding: 32px;
        margin-top: 12rem;
    }
    .myhraki-description h1{
        font-size: 18px;
            }
            .myhraki-description-cancelPolicy h1{
                font-size: 18px;
            }
            .myhraki-description-cancelPolicy p{
                font-size: 14px;
            }
            .myhraki-description p{
                font-size: 14px;
            }
}