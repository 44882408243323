.card-container-screen {
    width: 100%;
    height: 100vh;
    overflow: hidden; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* background: linear-gradient(to right, rgba(259, 221, 227, 0.9) 9%, rgba(259,221, 4, 0.1) 100%); */
     /* background: #fedde3ba; */
     background: linear-gradient(359.65deg,#f8bbbb3d 2.24%,rgba(252,38,120,.388) 157.4%,rgb(251 162 155 / 99%) 157.41%);
     /* dev background: linear-gradient(359.65deg,#f2eded 2.24%,rgba(252,38,120,.388) 157.4%,rgba(255,97,85,0) 157.41%); */
  }
  .Card_layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Myhraki_Title{
    width: 100%;
    margin: 0 auto;
    display: flex;
    font-size: 20px;
    margin-top: 2rem;
    justify-content: center;
  }
  
  .card-container-content {
    width: 80%;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 5% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1000px;
    max-height: 1000px;
  }

  .card-container-left,
  .card-container-right {
    flex: 1 1;
    overflow: hidden;
    /* background: white; */
    border-radius: 20px;
    margin: 0px 2%;
    height: 510px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4rem;
    max-width: 1000px;
    max-height: 1000px;
  }
  
  .card-content-img-first {
    flex: 1;
    /* border-radius: 0px 35px 35px 0px; */
    border-radius: 20px 0px 0px 20px;
background: linear-gradient(358.67deg, #FF6155 -0.13%, rgba(252, 38, 120, 0.3875) 102.79%, rgba(255, 97, 85, 0) 130.4%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 510px; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 
  }
  
  .card-content-img {
    flex: 1;
    background: linear-gradient(358.67deg, #FF6155 -0.13%, rgba(252, 38, 120, 0.3875) 102.79%, rgba(255, 97, 85, 0) 130.4%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 510px; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 20px 20px 0px;
    flex-direction: column;
  }
  @media only screen and (max-width: 990px) {
    
    .card-container-right {
      width: 100%;
    }.card-content-img-first {
      display: none;
    }
    
  .card-content-img {
    display: none;
  }

  }
  
  @media only screen and (max-width: 590px) {
    .card-container-content {
      margin: 4% auto;
    }
    .card-container-right {
      width: 100%;
    }
  .seller-accept-buttons{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-content-img-first {
      display: none;
    }
    
    .card-content-img {
      display: none;
  }}
  @media screen and (max-width:480px) {
    
  .cardContentImgStyle{
    display: none;
  }
  .card-container-left{
    width: 100%;
  }
  .card-content-img-first{
    display: none;
  }
  .card-container-content {
    width: 95%;
  
  }
  .Myhraki_Title{
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 16px;
    justify-content: center;
  }
}
/* @keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.imageFirst .card-content-img-first,
.imageFirst .card-container-right {
  animation: slideInFromLeft 1s ease-in-out;
}

.card-container-right,
.card-content-img {
  animation: fadeIn 1s ease-in-out;
}
.animated {
  animation-fill-mode: both;
} */
/* background: linear-gradient(to right, rgba(259, 221, 227, 0.9) 9%, rgba(0,0, 0, 0.2) 100%);
box-shadow: 6px 0 10px -5px rgba(0, 0, 0, 0.3); */
/* background: linear-gradient(to right, rgba(259, 221, 227, 0.9) 9%, rgba(259,221, 4, 0.1) 100%); yellowish
    box-shadow: 6px 0 10px -5px rgba(159, 221, 259); p+g*/ 
    /* background: linear-gradient(to right, rgba(259, 210, 222, 0.9) 9%, rgba(5,65, 151, 0.1) 100%); */
    /* linear-gradient(359.65deg, #f2eded 2.24%, rgba(110, 68, 129, 0.3875) 147.4%, rgba(280, 105, 90, 0) 157.48%) */