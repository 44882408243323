.order-details-container {
  background: #ffffff;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 5px;
  margin: 2rem auto 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 2rem; */
  font-size: 1.5rem;
  font-family: "Barlow";
  /* transform: scale(0.8, 0.8); */
  transform-origin: 0 0;
}
.view-order-align{
  width:862px;
  margin:0 auto;
}

.order-id-and-buttons {
  display: flex;
  justify-content: space-between;
  /* margin: 1rem; */
  font-size: 14px;
    letter-spacing: 0.02em;
}
.order-details-buttons {
  display: flex;
  justify-content: space-between;
  width: 50%;
  gap: 1rem;
  margin: 0 auto;
  align-items: center;
  text-align: center;
}
/* .order-details-buttons>*:hover {
    cursor:pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

} */
.shipping-info-field-value{
  color: rgba(0, 0, 0, 0.66);
  font-size: 16px;
  width: 70%;
}
.order-product-name{
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
}
.order-product-color{
  font-size: 18px;
  color: rgba(0, 0, 0, 0.66);

}
.order-product-size{
  font-size: 18px;
  color: rgba(0, 0, 0, 0.66);

}
.order-price{
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);

}
.order-quantity{
  font-size: 18px;
  color: rgba(0, 0, 0, 0.66);
}
.order-get-invoice:hover {
  cursor: pointer;
  text-decoration: underline;
}

.order-date {
  /* margin: 1rem; */
  font-size: 14px;
  letter-spacing: 0.02em;
}
.order-details-button {
  font-size: 15px;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  color: white;
  align-self: center;
  background:#FF6155;
  border-radius: 10px;
  min-width: 120px;
}
.orders-heading {
  margin: 0.5rem auto;
  font-size: 25px;
  letter-spacing: 0.02em;
  margin-bottom: 3rem;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 800;
}
.order-product {
  width: 801px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin: 1rem auto;
}

.order-product-desc > * {
  margin: 1rem;
}

.order-price-and-quantity > * {
  margin: 1rem;
}
.order-shipping-details {
  display: flex;
  width: 833px;
  margin: 2rem auto;
  justify-content: space-around;
  font-size: 16px;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 189, 112, 0.6);
}
.order-shipping-products{
  display: flex;
  width: 833px;
  height: 381px;
  border-radius: 8px;
  margin: 0 auto;
  padding: 1rem;
  justify-content: space-around;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 39px;
  border: 1px solid rgba(255, 189, 112, 0.6);
}
.shipping-info {
  width: 50%;
}
.shipping-info-header {
  font-size: 16px;
  /* margin: 1.5rem 0; */
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 900;
}
.shipping-info-field {
  display: flex;
  margin: 0.8rem 0;
  font-size: 1.4rem;
}
.shipping-info-field-name {
  min-width: 40%;
  letter-spacing: 0.02em;
  font-size: 22px;
}
.payment-info {
  width: 40%;
}
.payment-info-field > * {
  /* display:flex; */
  margin: 0.8rem 0;
  font-size: 21px;
  color: rgba(0, 0, 0, 0.66);
  letter-spacing: 0.02em;
}
.disabled {
  border-radius: 10px;
  background: rgba(248, 152, 128, 1);
  color:white;
}
.black{
  color: rgba(255, 97, 85, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;

}
@media screen and (max-width: 1200px) {
  .order-details-buttons {
  flex-direction: column;
  }
  .order-details-buttons>* {
    margin-top:1rem;

  }
  .order-shipping-details {
  flex-direction: column;
  }
  .delivery-details {
  flex-direction:column;
  }
  .delivery-details>* {
    margin-top:1rem;
  }
  .order-product {
    flex-direction: column;
  }
  .order-product >*{
    align-self:center;
    width:100%;
    /* margin:0 auto */
  }
  .order-shipping-details {
  flex-direction: column;
  }
  .shipping-info {
width:100%;
  }
  .payment-info {
    width:100%;
      }
}
@media screen and (max-width: 480px) {
  .order-details-container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    margin: 0rem auto 0 auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
    font-size: 1.5rem;
    font-family: "Barlow";
    transform: scale(1, 1);
    transform-origin: 0 0;
    /* transform: scale(0.8, 0.8); */
    /* transform-origin: 0 0; */
}
.view-order-align {
  width: 80%;
  margin: 0 auto;
}
.payment-info {
  width: 90%;
}
.track-order-details-page{
  margin: 0 auto;
}
}
.order-details-status{
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}