.review-text-area {
    max-width: 554px;
    min-width: 370px;
    height: 200px;
    /* border: 1px solid gray; */
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  
  .start-field {
    width: 307.8px;
height:88px;
gap: 4px;
  }
  
  .add-image-field {
    max-width: 554px;
    min-width: 370px;
    height: 271px;
    /* border: 1px solid gray; */
  }
  .start-rating-text-field{
    display: flex;
    justify-content: space-between;
    align-items: justify;
  }
  .add-image-arear{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;}
    .rating-fields{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 2.5rem;
    }
    .card-container-reviews {
        font-family: "Barlow";
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1200px;
        min-width: 340px;
        /* min-height: 200px; */
        /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
        /* border: 1px solid #00000033; */
        /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); */
        border-radius: 15px;
        /* margin: 0 7.5%; */
        /* margin: 38px 12.5%; */
        margin-bottom: 1.5rem;
        position: relative;
    }
    .rating-fields-set{
        display: flex;
        flex-direction: column;
        align-items:flex-start;
    }
    .upload-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 154px;
      height: 162px;
      min-width: 154px;
      border: 0;
      margin: 2rem auto;
      }
      
      .file-input {
        display: none;
      }
      .review-image-area{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        
      }
      .button-field-reviews{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 0.5%;
      }
      .card-product-details-review {
        gap: 4rem;
        /* margin: 1rem auto; */
        display: flex;
        /* flex-direction: column; */
        justify-content: flex-start;
        align-items: center;
        min-width: 40%;
        padding: 2rem;
    }
    .card-product-properties{
        padding: 2%;
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    }
    
@media only screen and (max-width: 480px) {
    .card-container-reviews{
    }
        .card-product-details-review {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            min-width: 40%;
            margin-top: 12%;
        }
        .start-rating-text-field {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin: 0 auto;
            width: 100%;
        }
        .review-text-area {
            width: 554px;
            height: 200px;
            /* border: 1px solid gray; */
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            margin-top: 5%;
          }
          .rating-fields-set {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;
            margin: 0 auto;
        }
        .card-container-reviews {
            font-family: "Barlow";
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1200px;
            min-width: 340px;
            border: 1px solid #00000033;
            border-radius: 15px;
            margin-left: -4%;
            position: relative;
        }
        .rating-product-name img {
          margin-left: 0;
          width: 90%;
          height: 400px;
      
      }
     
    }
    .start-rating-text-field{
        display: flex;
        flex-direction: column;
        /* justify-content: space-evenly; */
        align-items: center;
        /* margin: 0 auto; */
        /* gap: 22rem; */
        /* width: 100%; */
    }
    .rating-product-name img {
      /* margin-left: 1rem; */
      /* margin-bottom: 1rem; */
      width: 85px;
      /* height: 400px; */
  }