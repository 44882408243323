.ResetPassword_Container{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: white;
    overflow:hidden;
}
.seller-otp-confirmation-num-field
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: #000;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  max-width: 390px;
  height: 49px;
  min-width: 340px;
  padding: 14px 1px;
}
.seller-otp-confirmation-container{
  display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.seller-otp-confirmation-leftScreen {
  background: linear-gradient(359.79deg, #FF6155 -9.58%, rgba(252, 38, 120, 0.3875) 104.55%, rgba(255, 97, 85, 0) 126.21%);
  box-shadow: 0 4px 4px rgba(0,0,0,.25);
  height: 100vh;
  width: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.ResetPassword_RightScreen {
  width: 100%;
  height: 100vh;
  background: white;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ResetPassword_Content_Title{
  font-weight: 600;
  font-size: 28px;
  color: #707070;
  margin: 0 auto;
  margin-bottom: -31px;
  text-align: center;
}
.seller-otp-confirmation-page-form{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  /* margin-bottom: 38px; */
}

.ResetPassword_Content_TextField .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 98%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 12.5px 28px;
    background-color: rgba(0, 0, 0, 0.1);
}

.ResetPassword_Content_TextField
.css-wb57ya-MuiFormControl-root-MuiTextField-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0 auto;
    border: 0;
    vertical-align: top;
    width: 100%;

}
.ResetPassword_Button_Field{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}
.otp-submit-button {
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 10px;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    min-width: 100px;
    font-family: 'Barlow';
    display: flex;
    justify-content: center;
    font-size: 18px;
padding: 0.5rem 1rem;
border-radius: 20px;
border: none;
color: white;
background: linear-gradient(90deg, rgb(253, 37, 131) 0%, rgb(254, 88, 91) 100%);
border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: 10px;
margin-right: 1rem;
margin-left:1rem;
}
.ResetPassword-Terms-Conditions-1{
    font-family: 'Barlow';
font-weight: 600;
font-size: 16px;
width: 75%;
text-align: center;
color: rgba(112, 112, 112, 0.72);
}
.ResetPassword-Terms-Conditions-2{
    font-family: 'Barlow';
font-weight: 600;
font-size: 13px;
width: 100%;
text-align: center;
color: rgba(112, 112, 112, 0.72);
}
.ResetPassword-Terms-Conditions_Request{
    color: #F54565;
    font-family: 'Barlow';
font-weight: 600;
font-size: 16px;
line-height: 24px;
text-decoration: underline;
}
.textfield-container {
  display: flex;
  align-items: center;
}
  .ResetPassword_LeftScreen {
    display: none;
  }
  .signup-firstName-icon{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.5rem;
    color: gray;
  }
  @media (min-width: 700px) {
    .ResetPassword_Container {
      flex-direction: row;
    }
    .ResetPassword_LeftScreen {
      display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #FFEDF1;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    gap: 3rem;
}
    .ResetPassword_RightScreen {
      flex: 1;

    }
   
    .seller-otp-email-icon {
      position: absolute;
      top: 63%;
      left: 25%;
      color: rgba(112, 112, 112, 0.72);
      font-size: 1.4rem;
  }

  .otp-page-email-field
  .seller-otp-email-icon {
    position: absolute;
    top: 45%;
    left: 25%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.4rem;
}
.otp-page-email-field .seller-otp-confirmation-email-icon{
  color: hsla(0,0%,44%,.72);
  font-size: 1.4rem;
  left: 24%;
  position: absolute;
  top: 52%;
}
.ResetPassword_Content_Title {
  font-weight: 600;
  font-size: 25px;
  color: #707070;
  margin: 0 auto;
  text-align: center;
}
  }

  @media (max-width: 480px) {
    .seller-otp-confirmation-leftScreen
  {
    display: none;
  }
  .ResetPassword_RightScreen{
    width: 100%;
  }
  .seller-otp-email-icon {
    position: absolute;
    top: 50%;
    left: 4%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.4rem;
}
.seller-otp-confirmation-email-icon{
  position: absolute;
  left: 16%;
  top: 53%;
  color: rgba(112, 112, 112, 0.72);
  font-size: 1.4rem;
}
.ResetPassword_Content_Title {
  font-weight: 600;
  font-size: 20px;
  color: #707070;
  margin: 0 auto;
  text-align: center;
}
  }
  .ResetPassword_Content {
    color: white;
    font-size: 30px;
    font-weight: 600;
}
  .ResetPassword_Content_Title img {
    width: 100%;
    max-width: 200px;
   
  }
.MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .phone-icon .css-i4bv87-MuiSvgIcon-root{
  color: gray;
  position: relative;
  top: -58px;
  left: 3px;

}
.ResetPassword_Content_TextField {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
}
  
  .ResetPassword_Button_Field {
    margin-bottom: 24px;
  }
  
  .ResetPassword-Terms-Conditions_Request,
  .ResetPassword-Terms-Conditions {
    font-size: 15px;
    margin-top: 24px;
    text-align: center;
    width: 35%;
    cursor: pointer;
  }
  .seller-otp-confirmation-page-terms {
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    width: 100%;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
}
.seller-otp-confirmation-page-terms span{
  font-weight: bold;
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
color: #707070;
}
  .ResetPassword-Terms-Conditions span {
    font-weight: bold;
  }
  .ResetPassword_Description {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 18px;
    text-align: center;
    color: white;
    width: 85%;
}
  .otp-PhoneIcon{
    display: block;
    justify-content: center;
    color: gray;
    font-size: 1.3rem !important;
    position: relative;
    top: -34px;
    left: 4px;
  }

.ResetPasswod-Left-Img{
    width: 500px;
    height: 275px;
    display: flex;
    justify-content: center;
    opacity: 0;
    animation: fadeInAnimation 2s ease-in-out forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.otp-submit-button:hover {
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
