.customer-product-image-layout {
    /* width: 500px; */
    /* height: 500px; */
    display: flex;
    /* margin: 5rem; */
    gap: 18px;
    /* align-items: flex-start; */
    /* justify-content: space-between; */
}

.customer-product-image-layout-thumbnails-layout {
    display: flex;
    flex-direction: column;
    max-width: 100px;
    gap: 10px;
    align-items: center;
    /* max-height:400px; */
}

.customer-product-image-layout-thumbnails {
    display: flex;
    flex-direction: column;
    max-width: 100px;
    gap: 16px;
    align-items: center;
    max-height: 362px;
    overflow: hidden;

}

.customer-product-image-layout-thumbnail-img {
    width: 85px;
    /* margin:5px; */
    height: 85px;
    min-height: 85px;
    border-radius: 10px;
    /* object-fit: cover; */

}

.customer-product-image-layout-thumbnail-img-selected {
    border: 2px solid black;
    border-radius: 10px;
    object-fit: cover;

    /* width:200px; */
}
.customer-product-image-layout-selected-image-layout{
    position:relative;
    width: 491px;
    height: 362px;
    object-fit: cover;
    border-radius: 10px;
}
.customer-product-image-layout-selected-image {
    width: 490px;
    height: 525px;
    object-fit: fill;
    border-radius: 10px;
}
.customer-product-image-layout-thumbnails-layout-left-button{
    display:none;
}
.customer-product-image-layout-thumbnails-layout-right-button{
    display:none;
}
.customer-product-image-layout-thumbnails-layout-previous-button{
    display:block;
    position:absolute;
    left:27px;
    top:255px;
}
.customer-product-image-layout-thumbnails-layout-next-button{
    display:block;
    position:absolute;
    right:27px;
    top:255px;}

@media screen and (max-width: 1000px) {
    .customer-product-image-layout {
        width: 70%;
        min-width:300px;
        /* height: 500px; */
        display: flex;
        flex-direction: column-reverse;
        /* margin: 5rem; */
        /* gap: 5rem; */
        gap:1rem;
        align-items: flex-start;
        justify-content: space-between;
    }
    .customer-product-image-layout-selected-image-layout{
        width: 100%;
        border-radius: 10px;
    }

    .customer-product-image-layout-thumbnails-layout {
        display: flex;
        flex-direction: row;
        max-width: 370px;
        gap: 10px;
        align-items: center;
        /* max-height:400px; */

    }
    .customer-product-image-layout-selected-image {
        max-width: 490px;
        object-fit: fill;
        border-radius: 10px;
        height:400px;
        width:70%;
        margin: 0 auto;

    }
    .customer-product-image-layout-thumbnails {

        flex-direction: row;
        max-width: 400px;
        gap: 10px;
        align-items: center;
        max-height: 100px;
        overflow:hidden;
    }

    .customer-product-image-layout-thumbnails-layout-left-button{
        display:block;
    position:absolute;
    left:27px;
    top:175px;
    }
    .customer-product-image-layout-thumbnails-layout-right-button{
        display:block;
        position:absolute;
        right:27px;
        top:175px;
        }
    .customer-product-image-layout-thumbnails-layout-previous-button{
        display:none;
    }
    .customer-product-image-layout-thumbnails-layout-next-button{
        display:none;
    }
    .customer-product-image-layout-selected-image{
        max-width:370px;
    }
}
@media screen and (max-width: 480px){

.customer-product-image-layout-selected-image {
    max-width: 370px;
}
.customer-product-image-layout-selected-image {
    width: 90%;
    margin:0 auto;
    /* height: 362px; */
    object-fit: fill;
    border-radius: 10px;
}
.customer-product-image-layout-selected-image {
    width: 90%;
    margin: 0 auto;
    height: auto;
    object-fit: fill;
    border-radius: 10px;
}

}
@media screen and (max-width:768px) {
    .customer-product-image-layout-selected-image {
        width: 90%;
        margin:0 auto;
        height: auto;
        object-fit: fill;
        border-radius: 10px;
        min-width: 300px;
        min-height: 350px;
        max-height:350px;
    }
    
}