.Ad-GiftCardLayout{
    display: flex;
    justify-content: left;
    
    /* margin-left: auto;
    margin-right: auto; */
    margin-bottom: 300px;
    margin-top: -40px;
}

.Ad-gc-heading{
    margin-top: 32px;
    width: 180px;
    height: 32px;
    font-size: 32px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -1%;
    margin-left: 40px;
}
.Ad-gc-main-container{
    display: flex;
    flex-direction: row;
    height: 800px;
}
.Ad-gc-box-container{
    margin-top: 42px;
    margin-left: 40px;
    height: 400px;
    width: 340px;
    border-top-left-radius: 20px;
    background-color: rgba(255, 244, 235, 1);
    position: relative;
}
.Ad-Box-image{
    height: 228px;
    width: 340px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    position: absolute;
    top: 0%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
}
.Ad-gc-tr1{
       height: 70px;
    width: 340px;
    display: flex;
    flex-direction: row;
    /* padding-top: -10px; */
    /* margin-top: -1px; */
    position: absolute;
    top: 57%;
   
}
.Ad-gc-td1{
    width: 248.5px;
    padding-right: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap; */
    /* position: absolute;
    top: 0%; */
    border-left: 0px;
    border-top: 0px;
    height: 48px;
    background-color:rgba(255, 244, 235, 1) ;
    padding-top: 0px;
    padding-bottom: 0px;
    flex-direction: row;

}
.Ad-gc-td2{
    width: 111.5px;
    height: 48px;
    display: flex;
    justify-content: start;
    align-items: center;
    /* padding-right:70px ;
    position: absolute;
    top: 0%;
    left: 75%; */
    border-right: 0px;
    border-top: 0px;
    background-color:rgba(255, 244, 235, 1) ;
    overflow: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
}
.Ad-Box-message{
    width: 340px;
    height: 90px;
    margin-top: 26px;
    /* padding-left: 0px;
    padding-right: 10px; */
    position: absolute;
    top: 68%;
    padding-left: 6px;
    padding-bottom: 0px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow-y: scroll;
    box-sizing: border-box; 

}
.Ad-Box-message::-webkit-scrollbar {
    width: 2px; 
    height: 5px; 
  }
  
.Ad-gc-pay{
    height: 20px;
    margin-bottom: 9px;

}
.Ad-imgPay{
    height: 20px;
    width: 80px;
}

.Ad-eGift-card{
   max-width: 1000px;
   height: 100px;
   margin-top: 47px;
   margin-left: 16px;
}
.Ad-My-text1{
  width: 400px;
  height: 32px;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -1%;
  font-weight: 400;
}
.Ad-My-text2{
    width: 100px;
    height: 16px;
    margin-top: 15px;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -1%;
    margin-bottom: 15px;
}
.Ad-Cardrating{
    width: 149px;
    height: 24px;
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.Ad-gc-divider{
    margin-top: 16px;
}
.Ad-My-text3{
    margin-top: 16px;
    width: 335px;
    height: 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -1%;
}
.Ad-My-text4{
    margin-top: 8px;
    width: 189px;
    height: 16px;
    font-weight: 500;
    line-height: 16px;
    font-size: 16px;
    letter-spacing: -1%;
    display: flex;
    flex-direction: row;
    color: #888C8C;
}
.Ad-gc-span{
font-size: 16px;
font-weight: 500;
line-height: 16px;
letter-spacing: -0.01em;
text-align: left;
color: #030303;

}
.Ad-cardImage{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
}
.Ad-Gc-Image{
    margin-top: 32px;
    width: 120px;
    height: 80px;
    border-radius: 8px;
    padding-left: 5px;
}

.Ad-table-detail{
    width: 470px;
    margin-top: 9px;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    justify-content: space-between;
    /* margin-left: -13px; */
}
::placeholder{
    color: rgba(89, 89, 89, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    padding: 12px 10px 12px 10px;
    }
 #Ad-gc-error{
        padding-left: 115px;
        font-size: 10px;
    }
.Ad-gc-e-input:focus{
        outline: 2px solid #FE575C;
        border: none;
        border-radius: 8px;
        padding-left: 10px;
    }
    .Ad-gc-e-inputnum:focus{
        outline: 2px solid #FE575C;
        border: none;
        border-radius: 8px;
        padding-left: 5px;
    }
.Ad-gc-withimage-input:focus{
        height: 34px;
        outline: 2px solid #FE575C;
        border: none;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-left: 10px;
    }
    
.Ad-gc-label{
    margin-top: 4px;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -1%;
    width: 80px;
    /* padding-right: 40px; */
    text-align: right;
}
.Ad-gc-date-label{
    margin-top: 4px;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -1%;
    width: 60px;
    /* padding-right: 40px; */
    text-align: right;
    display: flex;
    flex-direction: row;
    width: 68px;
}
.Ad-gc-icon{
    height: 40px;
    width: 40px;
}
.Ad-gc-withimage-input{
    height: 40px;
    width: 304px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border:1px solid #888C8C;
    /* border-left: 0px; */
    padding-right: 10px;
}
.Ad-gc-img-input{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 10px; */
    height: 44px;
}
.Ad-gc-div-input{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: start;
    margin-top: 16px;
   
}
.Ad-gc-to-grid{
    margin-right: 10px;
    height: 180px;
}
.Ad-gc-toinput{
    height: 46px;
    width: 350px;
    margin-bottom: 20px;
    padding-left: 10px;
}
.Ad-gc-toinput #Ad-gc-error{
    padding-left: 0px;
    height: 1px;
}
.Ad-gc-e-input{
    height: 40px;
    width:347px ;
    padding-left: 10px;
    border-radius: 8px;
    border:1px solid #888C8C;
}
.Ad-gc-e-inputfrom{
    height: 40px;
    width:344px ;
    /* margin-left: 19px; */
    padding-left: 10px;
    border-radius: 8px;
    border:1px solid #888C8C;
}
.Ad-e-messagelabel{
    font-weight: 600;
    margin-top: 4px;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: -1%;
    width: 80px;
    text-align: right;

}
.Ad-gc-msginput-field{
    height: 100px;
  width: 342px;
  padding-bottom: 50px;
  border-radius: 8px;
  font-size: 12px;
  border: 1px solid #888C8C;
  resize: vertical; 
  box-sizing: border-box; 
  overflow-y: auto; 
  word-wrap: break-word; 
  white-space: pre-wrap; 
  padding-top:12px;
    
   
}


.Ad-custom-calendar {
    transform: translateY(-500%);
    right: 0px;
    left: 200px;
    height:50px;
    font-size: 12px; 
  }
 

.Ad-gc-msginput-field::-webkit-scrollbar {
    width: 5px; 
    height: 5px; 
  }
  .Ad-gc-e-inputfrom:focus{
    outline: 2px solid #FE575C;
    border: none;
    border-radius: 8px;
  }
.Ad-gc-msginput-field:focus{
    outline: 2px solid #FE575C;
    border: none;
    border-radius: 8px;
}
.Ad-gc-msginput-field::placeholder{
    color: rgba(89, 89, 89, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    padding: 0px 10px 0px 10px;
    
    }
.Ad-cardBuy{
    width: 360px;
    height: 34px;
    border-radius: 8px;
    margin-right: auto;
    margin-left: auto;
    margin-left: 95px;
    padding: 10px 0px;
    border-radius: 8px;
    background: linear-gradient(90deg, #FD2A80 0%, #FE575C 106.43%);
    border: none;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor:pointer;
}
.Ad-gc-withimage-dateinput{
    height: 40px;
    width: 304px;
    max-width: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border:1px solid #888C8C;
    border-left: 0px;
    /* padding-left: 10px; */
    padding-right: 10px;
    overflow: hidden;
    box-sizing: border-box;
}
.Ad-gc-withimage-dateinput:focus{
    height: 34px;
    margin-top: 2px;
    outline: 2px solid #FE575C;
        border: none;
}


.Ad-gc-msginput-field {
    resize: none; 
  }
  .Ad-gc-withimage-dateinput[type="date"]::-webkit-calendar-picker-popup {

    width: 300px;
    background-color: #fff;
  }
  
 
  