.seller-orders-table {
    width:70%;
    margin: auto;
    min-width: 350px;
    max-width: 100%;
     border-collapse:separate; 
    border-spacing: 0 1em;
  }
  
  .seller-orders-table tr:first-child {
    /* border-top: none; */
    text-align: center;
    background: white;
    color: black;
  }
  .seller-orders-table th {
    padding: 0.3em !important;
    font-size: 25px;
color: rgba(0, 0, 0, 0.8);
}
.seller-orders-table td {
  padding: 0.8em !important;
  font-size: 23px;
color: rgba(0, 0, 0, 0.7);
}
  .seller-orders-table tr {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .seller-orders-table tr:nth-child(odd):not(:first-child) {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .seller-orders-table th {
    display: none;
  }
  
  .seller-orders-table td {
    display: block;
    /* border-top: 1px solid rgba(0, 0, 0, 0.2); */
    border-bottom: 1px solid #E3E9EF;
  
  }
  
  .seller-orders-table td:first-child {
    margin-top: .5em;
    /* border-left: 1px solid rgba(0, 0, 0, 0.2); */
  
  }
  
  .seller-orders-table td:last-child {
    margin-bottom: .5em;
    /* border-right: 1px solid rgba(0, 0, 0, 0.2); */
  
  }
  
  .seller-orders-table td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 120px;
    display: inline-block;
    color: black;
    font-size: 18px;
  }

  
  .seller-orders-table {
    color: #333;
    border-radius: .4em;
    overflow: hidden;
  }
  
  .seller-orders-table tr {
    background: #FFFFFF;
   }
  
  .seller-orders-table th,
  .seller-orders-table td {
    padding: .5em 1em;
  }
  
  .seller-live-order-details-acceptance-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .seller-orders-table tr:first-child , .seller-orders-table td:first-child { border-top-left-radius: 0px; }
  .seller-orders-table tr:first-child , .seller-orders-table td:last-child { border-top-right-radius: 0px; }
  
  .seller-orders-table tr:last-child ,.seller-orders-table td:first-child { border-bottom-left-radius: 0px; }
  .seller-orders-table tr:last-child,  .seller-orders-table td:last-child { border-bottom-right-radius: 0px; }
  
  @media screen and (max-width: 601px) {
   .seller-orders-table tr:nth-child(2) {
      border-top: none;
    }
  
    .seller-orders-table tr {
      background: #fafafa;
      border: 1px solid rgba(0, 0, 0, 0.2);
     }
  
    .seller-orders-table td {
      display: block;
      border: none !important;
      display: flex;
      justify-content:space-between;
      font-size: 18px;
    }
    .seller-orders-table td:first-child {
      border: none !important;
    }
    
    .seller-orders-table td:last-child {
      border: none !important;
    }
    
    .seller-orders-table {
      width:95%;
      margin:0 auto;
      border-collapse: collapse;
      border-spacing: 0 1em;
    }
    .data-th {
      width: 50%;
      gap: 2rem;
    }
  }
  @media screen and (min-width: 600px) {
  
    
  
    .seller-orders-table {
      width:95%;
      margin:0 auto;
    }
    .seller-orders-table tr:hover:not(:first-child) {
      /* background-color: #d8e7f3; */
      cursor:pointer;
    }
    .seller-orders-table td:before {
      display: none;
    }
    .seller-orders-table th,
   .seller-orders-table td {
      display: table-cell;
      padding: .25em .5em;
    }
    .seller-orders-table th:first-child,
  .seller-orders-table td:first-child {
      padding-left: 0;
    }
   .seller-orders-table th:last-child,
   .seller-orders-table td:last-child {
      padding-right: 0;
    }
    .seller-orders-table th {
      padding: 0.5em !important;
    font-size: 20px;
    letter-spacing: 0.04em;
    color: rgba(0, 0, 0, 0.8);
  }
  .seller-orders-table td {
    padding: 0.5em !important;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
  }
    
  
  }