.seller-accept-invite-page {
  background:white;
  max-width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 2rem;
}

.seller-accept-head {
  margin: 0 auto;
  width: 100%;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 800;
  font-size: 2.4rem;
  letter-spacing: 0.01em;
  color: #FF2171;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.seller-accept-text {
  font-family: 'Barlow';
  /* font-style: normal; */
  font-weight: 600;
  font-size: 1.2rem;
  /* line-height: 1.2; */
  text-align: center;
  color: #fe468a;
  /* min-width: 500px; */
  opacity: 0;
  width: 100%;
  animation: fadeInAnimation 1s ease-in-out forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inviteAcceptImg {
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes rotateAnimation {
  0% {
    transform: rotate(-7deg);
  }
  50% {
    transform: rotate(7deg);
  }
   100% {
    transform: rotate(-7deg);
  }
}

.seller-logo {
  width: 280px;
  height: 100px;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInAnimation 4s forwards;
}

@media (max-width: 768px) {
  .inviteAcceptImg {
    width: 90%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .seller-accept-head {
    font-size: 2rem;
  }

  .seller-accept-text {
    font-size: 1.2rem;
}

  .inviteAcceptImg {
    width: 90%;
    max-width: 250px;
  }
}

@media (min-width: 2560px) {
  .inviteAcceptImg {
    width: 50%;
    max-width: 800px;
  }
}
