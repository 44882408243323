*{
  font-family:"Poppins" !important;
}


body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color:rgb(255, 240, 240,0.05); */
}


code {
  font-family: 'Poppins';
}
::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(144, 222, 224, 1); */
	/* background-color: #F5F5F5; */
}


::-webkit-scrollbar
{
	width: 0.6rem;
	/* background-color: #F5F5F5; */
}

::-webkit-scrollbar-thumb {

  background: linear-gradient(90deg, rgb(253, 37, 131) 0%, rgb(254, 88, 91) 100%);  }

/* ::-webkit-scrollbar-thumb
{
	background-color: rgba(255, 187, 170, 1)  ;
} */

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1rem !important;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 16.5px 14px;
}

input:-webkit-autofill::first-line,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: 'Poppins';
  color: rgba(112, 112, 112, 0.72) !important;

}

input[type="checkbox"] {
  accent-color: #FA068A !important;
  }

  input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

  .MuiFormHelperText-root {
    margin: 0 !important;
  }