/* .buyer-login-right{
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    width: 525px;
    height: 610px;
    box-shadow: 0px 2px 2px 2px rgba(0,0,0,0.25);
  }
  .buyer-login-container{
    width: 533px;
height: 684px;
margin: 5% auto;

  }
  .buyer-login-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    text-align: justify;
    width: 100%;
  }
  .buyer-login-container-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
.buyer-login-head{
    display: flex;
    justify-content: left;
    align-items: flex-start;
    color: black;
    font-family: Barlow;
    font-size: 30px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0em;
    margin: 0 auto;
    margin-left: 10%;
}
.buyer-login-email
.MuiOutlinedInput-root{
    align-self: center;
    width: 100%;
    min-width: 364px;
    min-height: 50px;
    height: 2rem;
}
.buyer-login-password 
.MuiOutlinedInput-root{
    align-self: center;
    width: 75%;
    min-width: 230px;
    min-height: 45px;
    height: 2rem;
}
.buyer-login-email-icon
.css-i4bv87-MuiSvgIcon-root
{
    position: absolute;
    top: 30%;
    left: 15%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.2rem;
}
.buyer-login-email-icon{
    position: absolute;
    top: 35%;
    left: 15%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.2rem;
}

.buyer-login-password-icon{
    position: absolute;
    top: 30%;
    left: 15%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.2rem;
}
.buyer-login-page-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
}
.logo-field{
    display: flex;
    justify-content: left;
    align-items: flex-start;
}
.Buyer-logo{
    width: 40%;
    max-width: 200px;

}
.buyer-login-page-email-field {
    width: 80%;
    min-width: 210px;
    position: relative;
    display: flex;
    justify-content: left;
    align-items: flex-start;
  }
  .buyer-login-page-password-field{
    width: 80%;
    min-width: 210px;
    margin: 0 auto;
    position: relative;
  }
  .buyer-login-page-email-field
  #login-page-email-helper-text {
    position: absolute;
    bottom: -45%;
    left: 20%;
    font-size: 12px;
    letter-spacing: 0.05em;
}
 .buyer-login-page-password-field
 #login-page-password-helper-text
 {
    position: absolute;
    bottom: -45%;
    left: 20%;
    font-size: 12px;
    letter-spacing: 0.05em;
 }

.buyer-login-page-checkbox{
    width: 60%;
    font-style: normal;
    display: inline-block;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    align-self: flex-start;
    margin-left: 19%;
    color: #FF6155;
    margin-bottom: 1rem;
    margin-top: 3%;
}
.buyer-login-page-email-field
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
    border:0.5px solid #70707080;
    font-size: 0.5rem;

}
.buyer-login-page-password-field
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
    border:0.5px solid #70707080;
}

  @media only screen and (max-width: 480px) {
  
    .buyer-login-right {
      width: 100%;
      background-color: white;
  
  } 
  } */
  .buyers-subtext{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin: 0 auto; */
    padding: 1rem;
    font-size: 18px;
    margin-left: 11%;
    letter-spacing: 0.03em;
    color: rgba(0, 0, 0, 0.8);
  }
.buyer-login-right{
  width: 480px;
  height: 656px;
  margin: 0 auto;
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.buyer-login-head{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: black;
  font-family: Barlow;
  font-size: 30px;
  font-weight: 600;
  /* line-height: 54px; */
  letter-spacing: 0.02em;
  margin: 0 auto;
  margin-left: 11%;
  padding: 1rem;
}
.buyer-login-email
.MuiOutlinedInput-root{
  align-self: center;
  width: 75%;
  min-width: 330px;
  min-height: 45px;
  height: 2rem;
}
.buyer-login-password 
.MuiOutlinedInput-root{
  align-self: center;
  width: 75%;
  min-width: 230px;
  min-height: 45px;
  height: 2rem;
}
.buyer-login-email-icon
.css-i4bv87-MuiSvgIcon-root
{
  position: absolute;
  top: 30%;
  left: 15%;
  color: rgba(112, 112, 112, 0.72);
  font-size: 1.5rem;
}
.buyer-login-email-icon{
  position: absolute;
  top: 28%;
  left: 4%;
  font-size: 1.5rem;
  color: rgba(255, 97, 85, 1);
}

.buyer-login-password-icon{
  position: absolute;
  top: 35%;
  left: 4%;
  font-size: 1.5rem;
  color: rgba(255, 97, 85, 1);
}
.buyer-login-page-form{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
}
.logo-field{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.Buyer-logo{
  width: 300px;
  max-width: 300px;

}
.buyer-login-page-email-field {
  /* width: 65%;
  min-width: 340px; */
  margin: 0 auto;
  position: relative;
}
.buyer-login-page-password-field{
  /* width: 65%;
  min-width: 340px; */
  margin: 0 auto;
  position: relative;
}
.buyer-login-page-email-field
#login-page-email-helper-text {
  position: absolute;
  bottom: -45%;
  left: 0;
  font-size: 12px;
  letter-spacing: 0.05em;
}
.buyer-login-page-password-field
#login-page-password-helper-text
{
  position: absolute;
    bottom: -8%;
    left: 0%;
    font-size: 12px;
    letter-spacing: 0.05em;
}

.buyer-login-page-checkbox{
  width: 60%;
  font-style: normal;
  display: inline-block;
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  align-self: flex-start;
  margin-left: 19%;
  color: #FF6155;
  margin-bottom: 1rem;
  margin-top: 3%;
}
.buyer-login-page-email-field
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 4px !important;
  border:0.5px solid #70707080;
  font-size: 0.5rem;

}
.buyer-login-page-password-field
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 4px !important;
  border:0.5px solid #70707080;
  font-size: 0.5rem;
}

@media only screen and (max-width: 480px) {

  .buyer-login-right {
    width: 100%;
    background-color: white;

} 

}
.buyer-login-page-password-field .MuiOutlinedInput-root {
  align-self: center;
  background-color: #fff;
  color: hsla(0,0%,44%,.72);
  font-size: 1.1rem;
  height: 2rem;
  margin: 3%;
  min-height: 55px;
  padding-left: 8%;
  min-width: 335px;
  max-width: 375px;
}
.buyer-login-page-email-field .MuiOutlinedInput-root {
  align-self: center;
  background-color: #fff;
  color: hsla(0,0%,44%,.72);
  font-size: 1.1rem;
  height: 2rem;
  /* margin: 3%; */
  min-height: 55px;
  /* padding-left: 8%; */
  min-width: 335px;
  max-width: 375px;
}
.byr-login-page-forgot {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-decoration-line: underline;
  align-self: flex-end;
  margin: 0rem auto;
  color: #FF5569;
  cursor: pointer;
  display: flex;
  justify-content: center;
  letter-spacing: 0.02em;
}