.css-k008qs {
    display: flex;
    overflow: scroll;
    scrollbar-width: none;
}
.css-heg063-MuiTabs-flexContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    scroll-behavior: smooth;
    overflow: scroll;
    scrollbar-width: none;
}
.css-1q2h7u5 .Mui-selected {
    color: #FE3C72 !important;
    font-size: 18px;
    font-weight: 600;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root .Mui-selected {
    color: #FE3C72 !important;
}

.MuiTabs-flexContainer .css-heg063-MuiTabs-flexContainer {
    font-size: 1.5rem;
}

/* .css-1aquho2-MuiTabs-indicator{
      color: #17305e;
  
  } */
.orders-container {
    display: flex;
    flex-direction: column;
    /* transform: scale(1, 0.8); */
    /* transform-origin: 0 0 ; */
}

.my-orders-heading {
    font-weight: 600;
    font-size: 35px;
    letter-spacing: 0.02em;
    margin: 1rem 0%;
    position: relative;
    /* right:2.5rem; */
}

.orders-filter {
    display: flex;
    align-items: center;
    margin: 1rem 1rem;
}


.css-63vwl1-MuiTypography-root-MuiBreadcrumbs-root {
    margin: 0.5rem 2rem;
}

.breadcrumbs {
    margin: 0.5rem 4rem;
    margin-right: 0;
    font-size: 1.2rem;
}

.order-tabs>.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    /* color: rgba(248, 152, 128, 1); */
    font-weight: 600;
    font-size: 20px;
    /* position:relative; */
    /* right:3rem; */
}

#basic-button {
    position: absolute;
    top: 5%;
    right: 5%;
    padding: 0.3rem 1rem;
    background-color: white;
    color: rgba(112, 112, 112, 1);
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 15px;
    letter-spacing: 0.02em;
}

.right {
    position: relative !important;
    /* left:0% !important; */
}

.css-13xfq8m-MuiTabPanel-root {
    margin: 0 !important;
    align-self: flex-start;
    /* color:red !important; */
}

.tab-panel {
    margin: 1rem 0 !important;
}

@media screen and (max-width:480px) {
    .my-orders-heading {
        font-weight: 600;
        font-size: 1.2rem;
        margin: 3rem auto;
    }

    .orders-screen-container {
        padding: 2px;
        margin: 0 auto;
    }

    #basic-button {
        position: absolute;
        top: -36px;
        right: 11%;
        padding: 0.2rem 0.5rem;
        background-color: white;
        color: black;
        border-radius: 30px;
        font-size: 11px;
        border: 1px solid black;
        display: none;
    }

    .page-content {
        width: 350px;
        min-width: 0px;
        transform: scale(0.9, 1);

    }

    .tab-panel {
        padding: 0 !important;
        /* margin: 0 !important; */
    }

    .my-orders-cards .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
        color: rgba(254, 60, 114, 1) !important;
        font-size: 14px;
        font-weight: 800;
        /* width: 14%; */
    }


    .order-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0;
        border: 0;
        margin: 0;
        border-radius: 0;
        padding: 0;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: middle;
        -moz-appearance: none;
        -webkit-appearance: none;
        -webkit-text-decoration: none;
        text-decoration: none;
        /* color: inherit; */
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        max-width: 100px;
        min-width: 30px;
        position: relative;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding: 10px 6px;
        overflow: hidden;
        white-space: normal;
        text-align: center;
        -webkit-flex-direction: column;
        color: #FE3C72 !important;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        white-space: normal;
        text-align: center;
    }

}

.card-links>* :visited {
    color: black;
}

.order-tabs .css-1aquho2-MuiTabs-indicator {
    background-color: #FE3C72 !important;
}

.order-tabs .css-ttwr4n {
    position: absolute;
    height: 1.5px;
    bottom: 0px;
    width: 100%;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #FE3C72 !important;
}

.order-details-info {
    display: inline-flex;
    padding: 16px;
    margin-top: 2rem;
    align-items: flex-start;
    flex-wrap:wrap;
    gap: 90px;
    gap: 5rem;
    border-radius: 8px;
    border: 1px solid rgba(255, 189, 112, 0.60);
    width: 100%;
    margin: 2rem auto;
    max-width: 833px;

}

.buyer-order-details-containe {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.order-details-info-title {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.16px;
    text-transform: uppercase;
    /* margin-bottom: 1rem; */
}

.order-details-info-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 13px;
}

.order-details-info-desc {
    width: 215px;
    /* height: 109px; */
    color: rgba(0, 0, 0, 0.66);
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px
}

.order-detail-info-item {
    display: inline-flex;
    padding: 16px;
    margin-top: 2rem;
    align-items: flex-start;
    flex-direction: column;
    gap: 90px;
    gap: 2.5rem;
    border-radius: 8px;
    border: 1px solid rgba(255, 189, 112, 0.60);
    width: 833px;

}

.product-order-detail-info {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.order-detail-info-button-fields {
    display: flex;
    justify-content: space-between;
    gap: 264px;
    align-items: flex-end;

}

.review-link-order {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    color: #FD2A80;
}

.order-detail-info-buttons {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
}

.delivered-status-field {
    color: #029F4A;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.16px;
}

.order-detail-info-image {
    min-width: 85px;
    max-height: 85px;
    border-radius: 8px;
    width: 85px;
    height: 85px;
}



.cart-checkout-row-buyers {
    display: flex;
    justify-content: space-between;
}

.buyer-cart-checkout-row-dark {
    display: flex;
    justify-content: space-between;

}

.order-tabs .css-1q2h7u5 .Mui-selected {
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    -webkit-appearance: none;
    background-color: initial;
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 12px;
    justify-content: center;
    letter-spacing: .02857em;
    margin: 0;
    max-width: 292px;
    min-width: 65px;
    outline: 0;
    overflow: hidden;
    padding: 9px 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
    color: #FF6155 !important;
    white-space: normal;
}

.css-1q2h7u5.Mui-selected {
    color: #fe3c72 !important
}

.order-tabs .css-1q2h7u5 {
    appearance: none;
    color: #fe3c72;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    max-width: 350px;
    min-height: 48px;
    min-width: 80px;
    padding: 12px;
    scroll-behavior: smooth;
}

.order-tabs .MuiTabs-indicator .css-ttwr4n {
    position: absolute;
    height: 2px;
    bottom: 0px;
    width: 100%;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #FE3C72 !important;
}

/* .MuiButtonBase-root .MuiTab-root .MuiTab-textColorPrimary .css-1q2h7u5{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    border-radius: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    max-width: 360px;
    min-width: 90px;
    position: relative;
    min-height: 48px;
    flex-shrink: 0;
    padding: 12px 16px;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    flex-direction: column;
    font-size: 18px;
    color: rgba(254, 60, 114, 1);
} */
.order-detilas-invoice {
    display: inline-flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.14px;
    background: linear-gradient(90deg, #FD2A80 0%, #FE575C 111.22%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid #FD2A80;
    cursor: pointer;
}

.order-details-heading {
    display: flex;
    flex-direction: column;

}

.order-detail-date {
    color: #777;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.14px;
    text-transform: uppercase;
}

.order-details-head-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:95%;
    margin:0 auto;
    max-width: 864px;
}



@media screen and (max-width: 900px) {
    .order-details-info {
        display: inline-flex;
        padding: 16px;
        margin-top: 1rem;
        align-items: flex-start;
        flex-direction: column;
        gap: 0.5rem;
        border-radius: 8px;
        border: 1px solid rgba(255, 189, 112, 0.60);
        min-width: 330px;
        margin:2rem auto;
    }

    .buyer-order-details-container {
        width: 100%;
        margin: 0 auto;
    }

    .product-order-detail-info {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }

    .order-detail-info-item {
        display: inline-flex;
        padding: 16px;
        margin-top: 2rem;
        align-items: flex-start;
        flex-direction: column;
        gap: 90px;
        gap: 0.5rem;
        border-radius: 8px;
        border: 1px solid rgba(255, 189, 112, 0.60);
        width:90%;
        margin:2rem auto;
        min-width: 330px;
    }

    .order-detail-info-button-fields {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        align-items: flex-end;
        flex-direction: column;
    }

    .order-details-head-container {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        width: 330px;
        gap: 8px;
        flex-direction: column;
        margin-left: 18px;
    }

    .order-details-heading {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .order-back-link {
        color: #FD2A80;
        text-decoration: underline;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        letter-spacing: -0.14px;
        margin-bottom: 8px;
        margin-left: 18px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .order-details-info-desc {
        width: 320px;
        /* height: 109px; */
        color: rgba(0, 0, 0, 0.66);
        font-family: Barlow;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.4px;
    }

    .order-details-info-title {
        margin-bottom: 8px;
    }

    .order-details-info-fields {
        gap: 4px;
    }
}

.order-tabs .MuiTabs-indicator .css-ttwr4n {
    position: absolute;
    height: 2px;
    bottom: 0px;
    width: 100%;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #FF6155 !important;
}

.my-orders-cards .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: rgba(254, 60, 114, 1) !important;
    font-size: 13px;
    font-weight: 800;
    /* width: 14%; */
}

.tab-panel {
    padding: 0 !important;
}

.css-heg063-MuiTabs-flexContainer {
    justify-content: flex-start;
    gap: 8px;
}