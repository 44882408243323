.admin-category-addition-field{
  display:flex;
  max-width:1100px;
  margin:2rem auto;
  align-items:center;
  justify-content:space-between;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius:10px;
  padding:2rem;
}
.seller-dashboard-add-btn{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 9%;
}
.seller-discount-search-sort-field{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 33rem;
  margin: 0 auto;
}
.SellerDiscount-table {
  width:50%;
  margin: auto;
  min-width: 350px;
  max-width: 100%;
   border-collapse:separate; 
  border-spacing: 0 0.3em;
}

.SellerDiscount-table tr:first-child {
  /* border-top: none; */
  text-align: center;
  background: white;
  color: black;
}

.SellerDiscount-table tr {
  background: #FFFFFF;
  /* border: 1px solid 1px solid rgba(0, 0, 0, 0.2); */
}

.SellerDiscount-table tr:nth-child(odd):not(:first-child) {
  background: #FFFFFF;
  /* border: 1px solid 1px solid rgba(0, 0, 0, 0.2); */

}

.SellerDiscount-table th {
  display: none;
}

.SellerDiscount-table td {
  display: block;
  /* border-top: 1px solid rgba(0, 0, 0, 0.2); */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
  border-bottom: 1px solid #E3E9EF;

}

.SellerDiscount-table td:first-child {
  margin-top: .5em;
  /* border-left: 1px solid rgba(0, 0, 0, 0.2); */

}

.SellerDiscount-table td:last-child {
  margin-bottom: .5em;
  /* border-right: 1px solid rgba(0, 0, 0, 0.2); */

}

.SellerDiscount-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  min-width:150px;
  display: inline-block;
color: rgba(0, 0, 0, 0.8);
text-align: left;
}


.SellerDiscount-table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.SellerDiscount-table tr {
  background: #FFFFFF;
 }

.SellerDiscount-table th,
.SellerDiscount-table td {
  padding: .5em 1em;
}

.SellerDiscount-table tr:first-child , .SellerDiscount-table td:first-child { 
  /* border-top-left-radius: 10px; */
 }
.SellerDiscount-table tr:first-child , .SellerDiscount-table td:last-child {
   /* border-top-right-radius: 10px;  */
  }

.SellerDiscount-table tr:last-child ,.SellerDiscount-table td:first-child { 
  /* border-bottom-left-radius: 10px;  */
}
.SellerDiscount-table tr:last-child,  .SellerDiscount-table td:last-child { 
  /* border-bottom-right-radius: 10px; */
 }

@media screen and (max-width: 601px) {
 .SellerDiscount-table tr:nth-child(2) {
    border-top: none;
  }

  .SellerDiscount-table tr {
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.2);
   }

  .SellerDiscount-table td {
    display: block;
    border: none !important;
    display: flex;
    justify-content:flex-start;
  }
  .SellerDiscount-table td:first-child {
    border: none !important;
  }
  
  .SellerDiscount-table td:last-child {
    border: none !important;
  }
  
  .SellerDiscount-table {
    width:55%;
    margin:0 auto;
    border-collapse: collapse;
    border-spacing: 0 1em;
  }
  .data-th {
    width: 50%;
    gap: 2rem;
  }
  .admin-category-addition-field{
      flex-direction: column;
      gap:2rem;
  }
  .seller-discount-search-sort-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 gap: 2rem;
    margin: 0 auto;
}
.seller-dashboard-add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
}
.search-bar-field {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3% auto;
}
.SellerDiscount-table td {
  padding: 0.8em !important;
  text-align: center;
  width: 95%;
  font-size: 23px;
  color: rgba(0, 0, 0, 0.7);
}
}
@media screen and (min-width: 600px) {
  .SellerDiscount-table {
    width: 95%;
    margin: 0;
  }
  .SellerDiscount-table tr:hover:not(:first-child) {
    /* background-color: #d8e7f3; */
    cursor:pointer;
  }

  .SellerDiscount-table td:before {
    display: none;
  }
  .SellerDiscount-table th,
 .SellerDiscount-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .SellerDiscount-table th:first-child,
.SellerDiscount-table td:first-child {
    padding-left: 0;
  }
 .SellerDiscount-table th:last-child,
 .SellerDiscount-table td:last-child {
    padding-right: 0;
    /* width: 5%; */
  }
  .SellerDiscount-table th,
  .SellerDiscount-table td {
    padding: 0.8em !important;
    text-align: center;
    width: 20%;
    font-size: 20px;
color: rgba(0, 0, 0, 0.7);
  }
  .seller-discount-search-sort-field {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto;
}
.search-bar-field {
  width: 28%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 3% auto;
}
.SellerDiscount-table td {
  padding: 0.8em !important;
  text-align: center;
  width: 95%;
  font-size: 23px;
  color: rgba(0, 0, 0, 0.7);
}
}
.SellerDiscount-table th{
  padding: 0.3em !important;
  text-align: center;
  width: 20%;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0.02em;
}
.SellerDiscount-table td {
  padding: 0.8em !important;
  text-align: center;
  width: 30%;
  min-width: 95%;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  width: 40%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px
}
.admin-category-list-name{
  display:flex;
}


.choose-file-btn input[type="file"] {
  display: none;
}

.seller-discount-status{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
}
.address-field-offer-type
.css-1869usk-MuiFormControl-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  margin: 8px;
  min-width: 300px;
  width: 75%;
  max-width: 500px;
}
.address-field-offer-type{
width: 100%;
}
.address-field-offer-type
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 32px;
  text-align: left;
color: rgba(0, 0, 0, 0.5);
}

.modal-admin-discount{
  max-width: 847px;
max-height: 1132px;
min-width: 300px;
min-height: 1000px;
width: 80%;
height: 100%;
background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.2);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
display: flex;justify-content: center;
align-items: center;
margin: 0 auto;
}
.address-form-field-date{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  width: 90%;
}
.seller-products-list-category
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 10.5px 14px;
}