.bc-buyer-navbar {
    max-width: 1280px;
    margin: 0 auto;
    background-color: rgb(255, 255, 255);
}

.bc-buyer-layout-container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    /* padding:0 10px  */

}

.bc-buyer-bg-container {
    width: 100%;
    margin: 0 auto;
    background-color: #faf8f3;
    /* margin-bottom: 60px; */
    min-height: 300px;
}


.bc-buyer-navbar .css-hip9hq-MuiPaper-root-MuiAppBar-root {
    box-shadow: none;
}

.bc-buyer-navbar .css-hyum1k-MuiToolbar-root {
    box-shadow: none;

}

.css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
    color: rgb(241, 71, 99) !important;
}