

.admin-dashboard-stats-cards{
    display:flex;
    align-items:center;
    justify-content:space-between;

}

.admin-dashboard-stats-card{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items: center;
    gap: 0.5rem;  
}
.admin-dashboard-stats-card-image{
    width:80px;
    height:80px;
}

.admin-dashboard-stats-card-image>img{
    border-radius:50px;
    height:100%;
    width:100%;
}

.admin-dashboard-stats-card-count{
    font-size:1.2rem;
    color: #000000CC;

}
.admin-dashboard-stats-card-text{
    font-size:1.6rem;
    color: #000000CC;

}

.admin-dashboard-status{
    margin-top:3rem;
}
.admin-dashboard-status-head{
    font-size: 1.6rem;
}
.admin-dashboard-status-date{
    margin-top:1rem;
    font-size: 1.3rem;
}

.admin-dashboard-card-layout {
  width: 88%;
  /* margin: 2.5rem auto; */
  display: flex;
  justify-content: space-evenly;
  gap: 2.6rem;
  /* margin-bottom: -1%; */
  margin-top: 1.5rem;
  margin-left: 3%;
}
.admin-dashboard-bar-graph{
    width:50%;
    min-height:100px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
    border-radius: 20px;
}
.admin-dashboard-doughnut-graph{
    border: 1px solid rgba(0, 0, 0, 0.2);
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
    border-radius: 20px; 
    width:45%;
    height: 372px;
}
.admin-dashboard-top-sellers {
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  background-color: #FFFFFF;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  min-height: 350px;
  width: 65%;
  margin: 0 auto;
  margin-left: -9%;
  min-width: 330px;

}
.dashboard-top-sellers {
  /* border: 1px solid rgba(0, 0, 0, 0.2);  */
  background-color: #FFFFFF;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  min-height: 350px;
  width: 45%;
  margin: 0 auto;
  margin-left: 2%;
  min-width: 395px;

}
.dashboard-section-head {
  margin: 0 auto;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: left;
  margin-left: 3%;
}
.dashboard-section-para{
  margin: 0 auto;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: left;
  margin-left: 3%;
}
.admin-dashboard-status {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 1rem;
    margin-left: 2%;
}
.dashboard-section-head-top-seller{
    margin: 0 auto;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: left;
    margin-left: 5%;
}
.dashboard-section-top-seller-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dashboard-top-seller-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
width: 95%;
margin-top: 2.5rem;
}
.chart{
  display: flex;
  justify-content: center;
  margin-left: -2%;
  gap: 1.5rem;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: -1rem;

}
.admin-dashboard-reviews{
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    background-color: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 15px; 
       min-height:300px;
    width:60%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    margin-right: 5%;
    min-width: 395px;
}
   .admin-dashboard-top-sellers-table table {
        font-family: arial, sans-serif;
       display: flex;
       justify-content: space-between;
       flex-direction: column;
        width: 100%;
  
      }
      .table-data{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;
      }
      .admin-dashboard-top-sellers-table tr{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1.5px;
        background: #F2F2F2;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }
      
     .admin-dashboard-top-sellers-table td, th {
        display: flex;
        text-align: center;
      justify-content: center;
        margin:5px auto;
        /* color: rgb(99, 98, 98); */
      }
      .invite-table-header td, th {
        display: flex;
        text-align: center;
      justify-content: center;
        margin:5px auto;
        color: rgba(0, 0, 0, 0.8);      }
      .user-logo{
        width: 35px;
        height: 35px;
      }
      .admin-dashboard-top-sellers-title{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .admin-dashboard-doughnut-graph-title{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
      }
      .admin-dashboard-reviews-title{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .row-excelent {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 10px 10px;
        gap: 1.5rem;
    }
  

  .side {
    float: left;
    width: 15%;
    margin-top:10px;
    margin: 5px auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;  

  }
  
  .middle {
    margin-top:10px;
    float: left;
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 5rem;  
  }
  
  .right {
    text-align: right;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;  

  }
  

  .bar-container {
    width: 100%;
    background-color: #f1f1f1;
    text-align: center;
    color: white;
    border-radius:25px;
  }

  .bar-5 {    border-radius:25px;
width: 60%; height: 18px; background-color: #04AA6D;}
  .bar-4 {    border-radius:25px;
width: 30%; height: 18px; background-color: #2196F3;}
  .bar-3 {    border-radius:25px;
width: 10%; height: 18px; background-color: #00bcd4;}
  .bar-2 {    border-radius:25px;
width: 4%; height: 18px; background-color: #ff9800;}
  .bar-1 {    border-radius:25px;
width: 15%; height: 18px; background-color: #f44336;}

.admin-dashboard-doughnut-graph-title{
    font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 30px;
display: flex;
align-items: center;
justify-content: center;
}

.doughnut{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 450px;
}
.review-star-conten{
  display: flex;
  justify-content: space-around;
  align-items: center;

}
.profile-product{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

}

@media screen and (max-width:700px){
    .admin-dashboard-stats-cards{
        flex-direction:column;
        gap:2rem;
    }
    .side, .middle {
        width: 100%;
      }
      .right {
        width: 100%;
        display: none;
      }
      /* .chart{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 2rem;
        width: 100%;
      } */
      .admin-dashboard-status-help{
        height: 230px;
        width: 30%;
        min-width: 425px;
        padding: 24px;
        border-radius: 15px;
        /* filter: 0px, 4px rgba(0, 0, 0, 0.25); */
        /* filter: drop-shadow(0px 4px 4px rgba(252, 38, 120, 0.3875)); */
        background: linear-gradient(359.65deg, #FF6155 2.24%, rgba(252, 38, 120, 0.3875) 157.4%, rgba(255, 97, 85, 0) 157.41%);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        /* margin-bottom: -49.5px; */
      }
}
.admin-dashboard-status{
  width: 65%;
  height: 230px;
  padding: 24px 16px;
  font-size: 14px;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  background-color: #FFFFFF;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-width: 450px;

}
.seller-dashboard-status {
  width: 61%;
  height: 230px;
  padding: 24px 16px;
  font-size: 14px;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  background-color: #FFFFFF;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-width: 330px;
}
.welcome-img{
  height: 173.92578125px;
  width: 200px;
  left: 191px;
  top: 210px;
  border-radius: 0px;
  max-width: 300px;
  margin: 0 auto;
  min-width: 200px
}
.welcome-desc{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.admin-dashboard-status-help-title{
font-family: "barlow";
font-style: SemiBold;
font-size: 25px;
line-height: 30px;
line-height: 100%;
align-items: Left;
Vertical-align: Top;
}
.admin-dashboard-welcome{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  /* width: 93%; */
  max-width: 90%;
  /* margin-right: -2%; */
}
.admin-dashboard-status-help-title{
color: #FFFFFF;
height: 45px;
width: 214px;
left: 982px;
top: 268px;
border-radius: nullpx;

}
.admin-dashboard-status-help-desc
{
font-family: Barlow;
font-style: SemiBold;
font-style: 20px;
line-height: 24px;
line-height: 100%;
color: #FFFFFF;
Vertical-align: Top;


}
.ellipse-img{
background: rgba(255, 181, 181, 1);
    /* width: 55%; */
    padding: 8px;
    height: 45px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;}
    .seller-activities-cards{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;
      width: 88%;
      height: 300px;
      max-width:88%;
      min-width: 400px;
      margin-top: 1.5rem;
      margin-left: 1%;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    .admin-activities-cards {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
      width: 88%;
      max-height:  300px;
      max-width: 88%;
      min-width: 400px;
      margin-top: 1.5rem;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      background-color: white;
      border-radius: 15px;
      padding: 2rem auto;
      /* margin: 4% auto; */
      margin-left: 2%;
  }
    .admin-dashboard-activities-icon {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      /* gap: 1rem; */
      width: 100%;
  }
    .review-star-content{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 768px) {
      .admin-dashboard-welcome{
        display: flex;
        flex-direction: column;
      }
      .welcome-desc {
display: flex;
      }
      .dashboard-chart-layout{
        display: flex;
        flex-direction: column;
      }
      .chart{
        display: flex;
        flex-direction: column;
      }
      .dashboard-section-top-seller-container{
        display: flex;
        flex-direction: column;
      }
      .dashboard-top-seller-container{
        display: flex;
        flex-direction: column;
      }
    
    }
    @media screen and (max-width:480px) {
      .dashboard-chart-layout{
        display: flex;
        flex-direction: column;
      }
      .admin-dashboard-card-layout{
        display: flex;
        flex-direction: column;
      }
      .pieChart{
        display: none;
      }
      .admin-dashboard-activities-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* flex-direction: column; */
    }
    .dashboard-section-top-seller-container{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .seller-activities-cards{
      display: none;
    }
    .dashboard-chart-layout {
      display: none;
    }
    .admin-dashboard-status {
      min-width: 360px;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 0rem;
      margin: 25% auto;
      /* margin-top: 25%; */
      margin-left: 2%;
    }
    .admin-dashboard-status-help {
      height: 230px;
      width: 30%;
      min-width: 360px;
      padding: 24px;
      border-radius: 15px;
      background: linear-gradient(359.65deg, #FF6155 2.24%, rgba(252, 38, 120, 0.3875) 157.4%, rgba(255, 97, 85, 0) 157.41%);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 12%;
      margin-top: -26%;
  }
  .seller-barChart {
    width: 75%;
    height: 300px;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    min-width: 315px;
    margin-left: -7%;
    margin-top: 15%;
}
.seller-pieChart {
  width: 45%;
  height: 300px;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  min-width: 326px;
  margin-left: -5%;
}
.seller-dashboard-card-layout {
  width: 80%;
  /* margin: 2.5rem auto; */
  display: flex;
  justify-content: space-evenly;
  gap: 2.6rem;
  margin-bottom: 0%;
  margin-top: 1.5rem;
  margin-left: 2%;
  background-color: #F2F2F2;
  border-radius: 15px;
  height: 400px;
  min-width: 350px;
  min-height: 300px;
}
.seller-dashboard-table-field {
  min-width: 240px;
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
}
.seller-dashboard-table-body {
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.1rem;
  text-align: center;
  align-items: center;
}
.seller-dashboard-table-tr:first-child {
  display: flex;
  align-items: center;
  gap: 0rem;
  width: 75%;
}
.seller-dashboard-table-tr {
  display: flex;
  width: 80%;
  align-items: center;
  margin: 0 auto;
}
    .welcome-desc {
      display: flex;
      flex-direction: column;
  }
  .dashboard-section-head {
    width: 100%;
  }
  .welcome-img {
display: flex;
  }
  
.admin-dashboard-reviews {
  margin-left: 12%;
}
.dashboard-top-seller-container{
  display: flex;
  flex-direction: column;
}
.seller-activities-card {
  /* border: 0.1rem solid rgba(0, 0, 0, 0.2); */
  min-height: 4rem;
  min-width: 5rem;
  max-width: 15rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  display: flex;
  padding: 0.2rem;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  border-radius: 4px;
}
.admin-activities-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  width: 88%;
  max-height: 300px;
  max-width: 88%;
  min-width: 400px;
  margin-top: 5.5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: white;
  border-radius: 15px;
  padding: 2rem auto;
  margin-left: 2%;
  /* margin: 23% auto; */
}
    }
    .admin-dashboard-card-layout-chart{
      display: flex;
      gap: 2.6rem;
      justify-content: space-between;
      margin-bottom: -1%;
      margin-left: 3%;
      margin-top: 2.6rem;
      width: 90%;
    }
    .barChart-admin{
      background-color: #fff;
      border-radius: 15px;
      /* -webkit-filter: drop-shadow(0 4px 4px rgba(0,0,0,.25)); */
      filter: drop-shadow(0 4px 4px rgba(0,0,0,.25));
      margin-left: -7%;
    padding: 2rem;
    width: 70%!important;
    }
    .pieChart-admin{
      
    align-self: center;
    height: 320px!important;
    max-width: 81%!important;
    width: 350px!important;
    background-color: #fff;
    border-radius: 15px;
    margin-right: -2%;
      /* -webkit-filter: drop-shadow(0 4px 4px rgba(0,0,0,.25)); */
      filter: drop-shadow(0 4px 4px rgba(0,0,0,.25));
  }
  .chart-admin {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    width: 100%;
}
.seller-dashboard-table-tr {
  display: flex;
  /* gap: 5rem; */
  width: 80%;
  align-items: center;
  margin: 0 auto;
}

