.logistics-layout {
    /* max-width: 1200px; */
    /* margin: 1rem auto 1rem auto; */
    /* background: #DAD6D6; */
    /* background: #F4F4F4; */
    margin: 0 auto;

}

@media screen and (max-width: 900px) {

    .logistics-layout {
        width: 95%;
        max-width: 1000px;
        margin: 5rem auto;
    }
}

.tabColor {
    width: 90%;
    margin: 0 auto;
}

.tabColor .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #FF4B6B;
    ;
}

.tabColor .css-1aquho2-MuiTabs-indicator {
    background-color: #FF4B6B !important;
}

.logistics-layout .css-heg063-MuiTabs-flexContainer {
    overflow: hidden;
    min-height: 48px;
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    background-color: #F2F2F2;
    min-width: 100%;
}

.logistics-layout .css-1ujnqem-MuiTabs-root {
    width: 100%;
}

.logistics-layout .css-8atqhb {
    width: 90%;
    margin: 0 auto;
}

.localVendor-tab-table {
    width: 100%;
    margin: auto;
    min-width: 350px;
    max-width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.3em;
    color: #333;
    /* border-radius: 0.4em; */
    overflow: hidden;
}

@media screen and (min-width: 600px) {

    .localVendor-tabs-table {
        width: 100%;
        margin: auto;
        min-width: 350px;
        max-width: 100%;
        /* border-collapse: separate; */
        border-collapse: collapse;

        border-spacing: 0 0.6em;
    }
}

.localVendor-tab-table-head {
    background-color: #fff;
    font-size: 1rem;
}
.localVendor-tabs-table-head-row>*{
    font-size:1.2rem;
}

.localVendor-table-row td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size:1rem;
}

.localVendor-select-option{
    padding:0.3rem 0.5rem !important;
    border:1px solid rgba(0,0,0,0.3) !important;

}

.localVendor-select-option:focus{
    outline:none !important;
}