/* privacy-policy.css */

.privacy-policy-content {
    margin: 20px;
  }
  
  .privacy-policy-content h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .privacy-policy-content h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-content ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .privacy-policy-content p {
    margin-bottom: 20px;
  }
  
  .privacy-policy-content a {
    color: blue;
  }
  