.AdminLogin-Container{
  display: flex;
  max-width: 2000px;
  background: white;
  overflow: hidden;
  max-height: 1300px;
  /* margin: 0 auto; */
}
.admin-login-page-left{
    height: 100vh;
    background: #F2F2F2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    flex-direction: column;
    width: 50%;
    max-width: 2000px;
    max-height: 1300px;
}
.admin-login-page-right-img{
  width: 500px;
  height: 300px;
  /* margin: 0 auto; */
}
.admin-login-page-right-title{
  font-family: Barlow !important;
  font-size: 35px;
  font-weight: 600px;
  color: #FF5569;
  margin: -9% auto;
}
.adminSignIn-input-form-fields{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 52px;
}
.adminLogin-Layout-head {
  font-size: 2rem;
  font-weight: 600;
  color: #FF5569;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.admin-login-right-img{
  display: flex;
  justify-content: center;
  width: 50%;
  height: 100vh;
}
.admin-login-page-form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .admin-login-page-email-field {
    width: 80%;
    min-width: 210px;
    margin: 0 auto;
    position: relative;
  }
  .admin-login-page-password-field {
    width: 80%;
    min-width: 210px;
    margin: 0 auto;
    position: relative;
    color: #FF5569;
  }
  .admin-login-email .MuiOutlinedInput-root {
    align-self: center;
    width: 80%;
    /* border-radius: 100px; */
    min-height: 60px;
    height: 2.5rem;
    background-color: white;
    /* background: none; */
    padding-left: 8%;
    background-size: 3%;
    font-weight: 300 !important;
    font-size: 1.1rem;
    margin: 3%;
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  }
  .admin-login-password .MuiOutlinedInput-root {
    align-self: center;
    width: 80%;
    /* border-radius: 100px; */
    min-height: 60px;
    height: 2.5rem;
    background-color: white;
    background: none;
    padding-left: 8%;
    background-size: 3%;
    font-weight: 600;
    font-size: 1.1rem;
    margin: 3%;
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  }


  /* .admin-login-password .MuiOutlinedInput-root:active{
      border:5px solid red;
  } */
  /* .Mui-focused .Mui-focused{
      border-color:red;
  } */
  #login-page-password {
    padding: 0px 1rem;
  }
  #login-page-email {
    padding: 0px 1rem;
  }
  
  #login-page-password-helper-text {
    position: absolute;
    bottom: -14%;
    left: 15%;
    font-size: 12px;
  }
  #login-page-email-helper-text {
    position: absolute;
    bottom: -14%;
    left: 15%;
    font-size: 12px;
  }
  .admin-login-page-email-field
  #login-page-email-helper-text {
    position: absolute;
    bottom: -8%;
    left: 18%;
    font-size: 12px;
}
.admin-login-page-password-field
#login-page-password-helper-text {
  position: absolute;
  bottom: -8%;
  left: 18%;
  font-size: 12px;
}
  .admin-login-page-container {
    font-family: "Barlow", sans-serif;
    width: 100%;
    /* max-width:1300px; */
    min-height: 100vh;
    display: flex;
    position: relative;
    /* background: conic-gradient(from 219.36deg at 50% 50%, rgba(186, 252, 255, 0) 0deg, rgba(56, 197, 204, 0.202739) 224.84deg, rgba(56, 197, 204, 0) 360deg); */
    overflow: hidden;
    z-index: 2;
    /* color: #008185; */
    /* align-items:center; */
    justify-content: space-between;
  }
  .admin-login-page-left{
    width: 50%;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
  }
  .admin-login-page-right{
    width: 50%;
    height: 100vh;
    max-width: 2000px;
    max-height: 1300px;
    background: #F2F2F2;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* margin: 0 auto; */
    flex-direction: column;
  }
  .admin-login-page-logo {
margin: 0 auto;
    height: 90%;
    width: 100%;
  }
  .admin-login-right-img{
    height: 80%;
    width: 100%;
  }
  .admin-login-page-right-content{
    /* margin-top: -19%;  */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 3rem; */
    flex-direction: column;
    margin-bottom: -10%;

  }
  .admin-login-page-offer {
    font-weight: 600;
    font-size: 2.5rem;
    /* color: #008185; */
    width: 30%;
    margin-top: 3%;
    margin-bottom: 6%;
    font-style: normal;
    min-width: 150px;
    background: radial-gradient(199.32% 199.32% at 50% 0%, rgba(255, 135, 48, 0.91) 0%, rgba(226, 48, 255, 0.7644) 100%);
    background-clip: text;
  
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .admin-login-page-large {
    font-size: 3.5rem;
  }
  
  
  .admin-login-logo-container{
    background: linear-gradient(161.76deg, #FFFFFF 60.25%, rgba(1, 186, 191, 0) 94.72%);
  mix-blend-mode: normal;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  border: 1px solid white;
  padding:3rem;
  border-radius:50%;;
  }
  
  .admin-login-page-coupon {
    width: 70%;
    color: #008185;
    font-size: 2rem;
    font-weight: 600;
    margin-left: 40%;
  }
  
  .admin-login-page-code {
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    color: rgba(0, 129, 133, 1);
    font-size: 2rem;
    background-color: #4fe7eb;
    border-right: 5px solid #38c5cc;
    border-left: 5px solid #38c5cc;
    border-radius: 10px;
    padding: 0 0.5rem;
  }
  
  .admin-login-page-error {
    display: block;
    position: absolute;
    background-color: red;
    color: white;
  }
  
  .admin-login-page-card-login {
    font-weight: 600;
    font-size: 3rem;
    margin: 3% auto;
    color: rgba(248, 152, 128, 1);
  }
  .seller-login-page-card-login{
    color: #ff5569;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .admin-login-password-field {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .admin-login-password-icon {
    position: absolute;
    top: 35%;
    left: 15%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
  }
  
   .admin-login-email-icon {
    color: hsla(0,0%,44%,.72);
    font-size: 1.6rem;
    left: 14%;
    position: absolute;
    top: 35%;
  } 
  .admin-login-email-icon, .admin-login-password-icon {
    color: hsla(0,0%,44%,.72);
    font-size: 1.6rem;
    left: 14%;
    position: absolute;
    top: 35%;
}
  .sign-in-button {
    font-size: 1.5rem !important;
    /* padding: 0.75rem 5rem !important; */
    margin: 1.5rem auto !important;
  }
  
  .admin-login-page-checkbox {
    width: 50%;
    font-style: normal;
    display: inline-block;
    font-weight: 600;
    font-size: 1rem;
    text-decoration-line: underline;
    align-self: flex-start;
  color: #FF5569;
    margin-left: 22%;
    margin-bottom: 1rem;
    margin-top: 4%;
  }
  .sign-in-button-field {
    display: flex;
    justify-content: center;
    margin-left: -52px;
}
.admininSignIn-Forget-terms{
  color: #FF5569;
  display: flex;
  justify-content: center;
  text-decoration-line: underline;
  cursor: pointer;
}
.admin-signin-submit-button{
  padding: 0.75rem 1rem;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 1rem;
  align-self: center;
  margin: 1rem;
  cursor: pointer;
  border-radius: 5px;
  float: right;
  text-align: center;
  background: #FF7891;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 4rem;
}
.sign-in-with-otp-accept-terms-field{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
  .admin-login-page-sign-in {
    width: 30%;
    margin-top: 2%;
    /* margin-left:25%; */
    font-size: 1.2rem;
    padding: 2% 5%;
    background: rgba(52, 108, 116, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
    align-self: center;
  }
  
  .admin-login-page-forgot {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-decoration-line: underline;
    align-self: flex-end;
    margin: 0rem auto;
    color: #FF5569;
    cursor: pointer;
    display: flex;
    justify-content: center;
    letter-spacing: 0.02em;
  
}
  
  .admin-login-page-social {
    display: flex;
    margin: 1rem auto;
  }
  
  .admin-login-page-social > * {
    width: 3rem;
    height: 3rem;
    align-items: center;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    margin-right: 2rem;
  }
  .admin-login-page-social :hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .admin-login-page-signUp {
    font-size: 1.4rem;
    margin: 1rem auto;
  } 
  .admin-login-page-signUp span {
    font-weight: 600;
    font-size: 1.4rem;
    text-decoration-line: underline;
    color: #019dbf;
  }
  
  .admin-login-page-terms {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 0%;
    width: 90%;
    /* margin: 5% 5%; */
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
    /* margin-bottom: 8%; */
}
  .admin-login-page-terms span {
    color: #707070;
  }

  .admin-login-right-Img{
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
  }

  
  .admin-login-page-email[type="number"] {
    -moz-appearance: textfield;
  }
  
  .admin-login-page-email::-webkit-outer-spin-button,
  .admin-login-page-email::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  
  .admin-login-page-password:focus {
    outline: 1px solid #ff5569;
  }
  
  .admin-login-page-password[type="number"] {
    -moz-appearance: textfield;
  }
  
  .admin-login-page-password::-webkit-outer-spin-button,
  .admin-login-page-password::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  .checked input:checked {
    color: #ff5569;
  }
  .admin-login-page-card {
    width: 100%;
    max-width: 1000px;
    max-height: 2000px;
}
  @media only screen and (max-width: 900px) {
 .admin-login-page-left{
    width:100%;
 }
 .admin-login-page-left {
    /* display:none; */
 }
    .admin-login-page-right {
      /* width: 100%;
      align-items: center; */
      display:none;
    }
  
  }
  
  @media only screen and (max-width: 480px) {
    .admin-login-page-container {
      /* overflow-y: hidden !important; */
      /* max-width:400px; */
      width:100%;
      /* max-width:400px;   */
    }
    .admin-login-page-head {
      margin: 0 auto;
      font-size: 2rem;
      /* margin: 2% 2%; */
    }
 
  
    .admin-login-page-card {
      width: 100%;
      min-width:350px;
      max-width: 350px;
      margin: 0 auto;
      /* text-align:center; */
      /* margin-left:5rem; */
      /* transform: scale(0.8, 0.7); */
      /* transform-origin: 0% -50%; */
    }
  
    .admin-login-page-email {
      font-size: 0.8rem;
    }
  
    .admin-login-page-password {
      font-size: 0.8rem;
    }
 

  }

  .submit-button{
    padding: 0.75rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 1rem;
    align-self: center;
    margin: 1rem;
    border-radius: 5px;
    float: right;
    text-align: center;
    background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}
  .MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-fullWidth .MuiInputBase-formControl{
    background-color: white !important;
  }
  .admin-login-head{
    display: flex;
    justify-content: center;
    align-items: center;
   color: #FF5569;
    font-family: Barlow;
    font-size: 30px;
    
  }
