.submit-button {
  padding: 0.75rem 1rem;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 1.1rem;
  align-self: center;
  margin: 1rem;
  border-radius: 5px;
  float: right;
  text-align: center;
  background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.full-width-button {
  width: 70%;
  color: black;
  border-radius: 5px;
  font-weight: 600;
  background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
  /* align-self: center; */
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  font-size: 16px;
  /* margin-left:20rem; */
}
.admin-login-page-terms span{
  cursor: pointer;
}
.full-width-button:hover {
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.CustomerPlain-common-btn:hover{
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
}
.common-button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  align-self: center;
  margin: 0.8rem;
  background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
  color: white;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
  /* display:flex; */
  float: right;
  text-align: center;
}
.buyer-save-button {
  font-size: 14px;
  padding: 10px 8px;
  border-radius: 20px;
  border: none;
  align-self: center;
  margin: 0.8rem;
  background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
  color: white;
  border-radius: 10px;
  float: right;
  text-align: center;
  width: 186px;
}
.byr-common-button {
  font-size: 20px;
  padding: 0.4rem 0.8rem;
  border-radius: 15px;
  border: none;
  align-self: center;
  margin: 0.5rem;
  /* background: #FF6155; */
  background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  float: right;
  text-align: center;
  max-width: 424px;
  width: 70%;
  height: 45px;
  min-width: 340px;
}
.contact-button-byr{
  width: 187px;
  min-width: 100px;
height: 34px;
font-size: 14px;
letter-spacing: 0.03em;
font-weight: 500;
line-height: 14px;
letter-spacing: -0.01em;
text-align: left;
padding: 10px, 8px, 10px, 8px;
border-radius:8px;
background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
color: white;
border: none;
text-align: center;



}
.full-width-button-byr {
  width: 350px;
  min-width: 300px;
  color: white;
  border-radius: 15px;
  font-weight: 600;
  background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
  /* align-self: center; */
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  font-size: 1.2rem;
  /* margin-left:20rem; */
}
.disabled-common-button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  align-self: center;
  margin: 1rem;
  color: white;
  background: #ecb9c2;
    border: 1px solid rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  /* display:flex; */
  float: right;
  text-align: center;
}
.back-to-button {
  display: flex;
  align-items: center;
  font-size: 18PX;
color: rgba(0, 0, 0, 0.8);
}
.back-to-button:hover {
  text-decoration: underline;
  cursor: pointer;
}
.Customer-common-btn{
  display: flex;
width: 148px;
padding: 10px 8px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
border: none;
margin-top: 1rem;
color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 14px ;
letter-spacing: -0.14px;
cursor: pointer;

}
.CustomerPlain-common-btn{
  display: flex;
  width: 148px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #FD2A80;
  color: #FD2A80;
  background: white;
  margin-top: 1rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px ;
  cursor: pointer;
  letter-spacing: -0.14px;

}
.common-btn-secondary{
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  align-self: center;
  margin: 0.8rem;
  color: #FA068A;
  background: white;
  border: 1px solid #FA068A;
  border-radius: 10px;
  /* display:flex; */
  float: right;
  text-align: center;
  margin-top: 2%;
}
.common-button-primary{
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  align-self: center;
  margin: 0.8rem;
  color: white;
  background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
  border: none;
  border-radius: 10px;
  /* display:flex; */
  float: right;
  text-align: center;
  margin-top: 2%;

}
.BuyAgainButton{
width: 148px;
padding: 10px 8px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: none;
background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
color: white;
}
.Return-PlainButton{
  width: 148px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none;
  display: flex;
width: 148px;
padding: 10px 8px;
justify-content: center;
align-items: center;
gap: 8px;
border: 1px solid #FD2A80;
  background: white;
  color: #FD2A80;
  }

.common-btn {
  font-size: 18px;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  color: white;
background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-right: 1rem;
  margin-left:1rem;
}
.byr-submit-btn {
  font-size: 19px;
  letter-spacing: 0.02em;
  width: 148px;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: none;
  color: white;
  background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
  padding: 10px, 8px, 10px, 8px;
  width: 148px;
  font-size: 14px;
  height: 34px;
  font-weight: 500;
  margin-right: 1rem;
  margin-left:1rem;
}
.Reject-button-facts{
  font-size: 18px;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  color: #FFFFFF;
  background: #C6C6C6;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-right: 1rem;
  margin-left:1rem;
}
.cancel-button{
  background-color:#eeeeee;
  color:#444;
  width:70%;
  margin:0.5rem auto;
  border-radius:5px;
  border:0.2px solid grey;
  padding:5px;
}

.common-btn-large {
  font-size: 1.5rem;
  padding: 0.75rem 1.25rem;
  border-radius: 20px;
  border: none;
  background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* margin-right:1rem; */
}
.operation-button {
  background: none;
  font-size: 25px;
  border: none;
  margin: 0 0.5rem;
  width: 20px;
  height: 20px;
  color: #FE3C72;
display: flex;
  align-items:center;
}
.reject-button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  align-self: center;
  margin: 1.1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  float: right;
  text-align: center;
  color: #444;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background:#dcdcdc;
}

.accept-button {
  font-size: 1rem;
  padding: 0.6rem 1rem;
  border-radius: 20px;
  border: none;
color: #FFF;
  align-self: center;
  margin: 1rem; 
  background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
  border: NONE;
  border-radius: 10px;
}
.reject-button:hover
{
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.accept-button:hover
{
  cursor: pointer;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.add-address-buyer:hover,
.address-edit-icon:hover,
.address-delete-icon:hover,
.contact-button-byr:hover
{
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.choose-file-btn {
  font-size: 14px;
  padding: 0.5rem 1rem;
  background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
  border: 1px solid white;
  border-radius: 8px;
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  color: white;
}
.addCategory-btn{
  border: 1px solid #FF5569;
  background-color: white;
  color: #FF5569;
  width: 196px;
  height: 50px;
  border-radius: 8px;
  font-size: 21px;
  text-align: left;
  padding: 0.3rem;

}
.common-btn-faq {
  font-size: 18px;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: none;
  color: white;
  background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.addCategory-btn:hover,
.common-btn-faq :hover,
.common-button:hover,
.common-btn-secondary:hover,
.submit-button:hover,
.buyer-save-button:hover,
.contact-button-byr:hover,
.byr-common-button:hover ,
.byr-submit-btn:hover,
.reject-button:hover,
.common-btn:hover,
.Reject-button-facts:hover,
.common-btn-large:hover,
.cancel-button:hover,
.byr-submit-btn:hover,
.otp-submit-button:hover,
.byr-common-button:hover,
.OtpVerify-button-seller:hover,
.common-button-seller:hover,
.Plain-common-button-seller:hover,
.customer-login-btn:hover
  {
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.heading-styling {
  align-self: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem auto;
  position: relative;
}
.heading-styling-text {
  margin-bottom: 0.4rem;
}
.heading-styling-underline {
  position: absolute;
  background-color: #F54565;
  height: 0.2rem;
  width: 100%;
  margin:0 auto;
  
  border-radius: 80%;
  /* margin-bottom:1rem; */
}
.show-more-button {
  /* width: 20%; */
  margin-left: 70%;
  padding: 1rem;
  /* display:flex; */
  align-self: right;
   color: white;
  background: rgba(255, 97, 85, 1);
  border-radius: 10px;
  border: none;
}
.show-more-button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.page-content {
  max-width: 1200px;
  min-width: 900px;
  width: 95%;
  /* height: 100%; */
  padding: 2rem 0%;
  /* align-self:center; */
  justify-content: center;
  margin: 1rem auto !important;
  /* padding-left:1rem; */
  background: #FFFFFF;
  border-radius: 10px;
  /* box-shadow: 0px 1px 3px rgba(3, 0, 71, 0.09); */

}
.center-text {
  text-align: center;
}

.page-content-admin {
  /* max-width:1500px; */
  /* min-width:900px; */
  min-width: 60%;
  width: 80%;
  /* align-self:center; */
  justify-content: center;
  margin: 0 auto !important;
  text-align: center;
  margin: 3rem auto;
  /* padding: 0 5%; */
  min-height: 50rem;
  /* background:red; */

}

.pagination-button {
  float: right;
  margin: 2rem 1rem;
  font-size: 1.2rem;
}

@media only screen and (max-width: 1300px) {
  .page-content {
    max-width: 1250px;
    min-width: 650px;
    /* width: 100%; */
    /* height: 100%; */
    /* padding: 0; */
    /* padding: 0 5%; */
    /* align-self: center; */
    justify-content: center;
    margin: 0 auto !important;
    /* padding-left: 1rem; */
    background: white;
    border-radius: 10px;

  }
 
  /* .css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    color: #d32f2f;
    bottom: -25%;
    font-size: 0.9rem;
    left: 20%;
} */
.seller-otp-phone-icon {
  position: absolute;
  left: 11%;
  color: rgba(112, 112, 112, 0.72);
  font-size: 1.4rem;
  top: 37%;
}
}

.subs-creator-form-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subs-creator-form-mandatory {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 1300px;
  width: 90%;
}
.admin-page-content {
  /* margin-top:20rem; */
  max-width: 1200px;
  /* min-width: 900px; */
  width: 80%;
  /* align-self:center; */
  justify-content: center;
  margin: 0 auto;
  /* margin-top:5rem; */
}

.seller-color {
color: #707070 !important;
}
.heading-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 0 0% auto;
  gap: 8px;
  margin-left: 10px;
  margin:1rem auto;
  max-width:1280px;
}
.customer-heading-container{
  display: flex; 
  justify-content: flex-start; 
   align-items: center; 
   text-align: center; 
   margin: 0% 0 0 10%;
  gap: 0rem;
   /* width:100%;
  /* margin:2rem auto; */
  font-size: 32px;
  max-Width: 1245px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0rem auto;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 20px;
}


.heading-image{
  width: 26px;
  /* height: 26.29px; */
  margin-bottom: 0%;
}
.heading-text{
  text-transform: uppercase;

}
.customer-heading-text{
  font-family: 'Barlow';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 36px;
letter-spacing: 0.02em;
color: rgba(0, 0, 0, 0.8);
position: relative;
text-transform: uppercase;

}
.customer-heading-view-all{
  color: #000;
text-align: right;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: 100%; /* 20px */
letter-spacing: -0.2px;
text-decoration-line: underline;
}
.Myhraki-logo {
  /* height: 90px; */
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color:rgba(255, 120, 145, 1) !important;
}
/* .css-hyum1k-MuiToolbar-root {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 56px;
  background:  #F2F2F2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
} */

.common_textfield{
  width:75%;
  margin:1.5rem auto;
}

#custom{
  position: absolute !important;
  left: 17% !important;
  bottom: -5% !important;
}

/* .css-1wc848c-MuiFormHelperText-root.Mui-error {
  position: absolute;
  color: #d32f2f;
  bottom: -20%;
  font-size: 12px;
  left: 19%;
} */
.address-field 
.css-1wc848c-MuiFormHelperText-root.Mui-error {
  position: absolute;
  color: #d32f2f;
  bottom: -19%;
  font-size: 12px;
  left: 3%;
}
.fact-modal-form-field-textField
.css-1wc848c-MuiFormHelperText-root.Mui-error {
  position: absolute;
  color: #d32f2f;
  bottom: -43%;
  font-size: 13px;
  letter-spacing: 0.03em;
  left: 4%;
}
.seller-navbar-sidebar
.css-h4y409-MuiList-root .css-1qfpb3d-MuiListItem-root  {
  background-color: rgba(244, 244, 244, 1) !important;
}
.seller-navbar-sidebar
.css-1h3225h-MuiDrawer-docked .MuiDrawer-paper{
  background-color: rgba(244, 244, 244, 1) !important;
  border: none;
}
.seller-navbar-sidebar
.css-9mgopn-MuiDivider-root {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color:  rgba(0, 0, 0, 0.66);
  /* border-bottom-width: thin; */
}
.seller-navbar-sidebar
.css-18im8wm-MuiDrawer-docked .MuiDrawer-paper
{
  background-color: rgba(244, 244, 244, 1) !important;
  border: none;
}



.seller-navbar-sidebar .css-hyum1k-MuiToolbar-root {
  background-color: rgba(244, 244, 244, 1) !important;
  box-shadow: none;
}
.seller-navbar-sidebar .css-cc01sb {
  background: rgba(244, 244, 244, 1)!important;
}
.seller-navbar-sidebar .css-koxw2w {
  background: rgba(244, 244, 244, 1);

}
.seller-navbar-sidebar .css-i6s8oy{
  background: rgba(244, 244, 244, 1);

}

.seller-navbar-sidebar
.css-h4y409-MuiList-root{
  background: rgba(244, 244, 244, 1)!important;
}
.seller-navbar-sidebar
.css-ddutzj .MuiDrawer-paper{
  background: rgb(255, 255, 255)!important;
}

.css-h4y409-MuiList-root
.css-1qfpb3d-MuiListItem-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  display: block;
  background: #F2F2F2;
  border-radius: 0px;
  min-height: 3rem;
}
.Buyer-navbar-sidebar 
.css-h4y409-MuiList-root .css-1qfpb3d-MuiListItem-root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 250px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  left: 24px;
  /* background: #F2F2F2; */
  border-radius: 8px;
  justify-content: space-around;
  min-height: 40px;
  gap: 2rem;
  background: white;
}
.Buyer-navbar-sidebar 
.css-ml9xc6-MuiListItem-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  /* width: 100%; */
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 0px;
  min-height: 3rem;
  background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
  width: 250px;
  height: 40px;
  left: 24px;
  color: white;
  border-radius: 8px;
}
.Buyer-navbar-sidebar 
.css-i4bv87-MuiSvgIcon-root 
.css-wa5fuu-MuiListItemIcon-root {
  -webkit-user-select: none;
  user-select: none;
  width: 22.75px;
  height: 22.75px;
  display: inline-block;
  -webkit-flex-shrink: 0;
  /* color: black; */
  color: white;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* font-size: 58px; */
  /* line-height: 25px; */
}
.Buyer-navbar-sidebar 
.css-y9bczb-MuiDrawer-docked .MuiDrawer-paper {
  width: 330px;
  height: 774px;
  background: rgba(255, 242, 246, 1)!important;
  -webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow-x: hidden;
  border:none
}
.Buyer-navbar-sidebar 
.css-10hburv-MuiTypography-root {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
}
.Buyer-navbar-sidebar 
.css-hyum1k-MuiToolbar-root {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  min-height: 65px;
  /* background: #F2F2F2; */
  box-shadow:none;
  background: linear-gradient(90deg, #FD2583 0%, #FE585B 100%) !important;

  border: none;
}

.Buyer-navbar-sidebar 
.css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 22.75px;
  height: 22.75px;
  display: inline-block;
  -webkit-flex-shrink: 0;
  /* color: black; */
  color: rgba(45, 11, 22, 1);
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* font-size: 58px; */
  /* line-height: 25px; */
}
.Buyer-navbar-sidebar 
.css-h4y409-MuiList-root {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 25px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 1rem;
}
.Buyer-navbar-sidebar 
.css-uhu9wd-MuiDrawer-docked .MuiDrawer-paper {
  width: 405px;
  -webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow-x: hidden;
  background: rgba(255, 242, 246, 1);
  height: 744px;
  margin-left: 30px;
  top: 157px;
  bottom: 0;
  max-height: calc(100vh - 120px); /* Adjust as needed */
  overflow-y: auto;
  border: none;
}
.search-icon{
  position: relative;
  left: 3rem;
  top: 0rem;
}
.logistic-navbar-sidebar .css-h4y409-MuiList-root .css-1qfpb3d-MuiListItem-root {
  background-color: white !important;
}



.logistic-navbar-sidebar
.css-h4y409-MuiList-root .css-1qfpb3d-MuiListItem-root  {
  background-color: white !important;
}
.logistic-navbar-sidebar
.css-1h3225h-MuiDrawer-docked .MuiDrawer-paper{
  background-color: white !important;
  border: none;
}
.logistic-navbar-sidebar
.css-9mgopn-MuiDivider-root {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color:  rgba(0, 0, 0, 0.66);
  /* border-bottom-width: thin; */
}
.logistic-navbar-sidebar
.css-18im8wm-MuiDrawer-docked .MuiDrawer-paper
{
  background-color: white !important;
  border: none;
}



.logistic-navbar-sidebar .css-hyum1k-MuiToolbar-root {
  background-color: white !important;
  box-shadow: none;
}
.logistic-navbar-sidebar .css-cc01sb {
  background: white!important;
}
.logistic-navbar-sidebar .css-koxw2w {
  background:  white!important;

}
.logistic-navbar-sidebar .css-i6s8oy{
  background: white!important;

}

.logistic-navbar-sidebar
.css-h4y409-MuiList-root{
  background: white!important;
}
.logistic-navbar-sidebar
.css-ddutzj .MuiDrawer-paper{
  background: white!important;
}
@media screen and (max-width: 480px) {
  .heading-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin: 17% 0px 15px 0%;
    /* gap: 3rem; */
    padding:12px;
    width: 300px;
    font-size: 13px;
    /* margin-left: -2rem; */
}
.Seller-layout {
  background: white;
  /* background: rgba(244, 244, 244, 1); */
  width: 100%;
  /* height: 100vh; */
  margin-bottom:240px !important;
}
.page-content {
  max-width: 1250px;
  min-width: 650px;
  width: 100%;
  /* height: 100%; */
  padding:0;
  /* padding: 0 5%; */
  /* align-self:center; */
  justify-content: center;
  margin: 0 auto !important;
  /* padding-left:1rem; */
  background: white;
  border-radius: 10px;

}
.customer-heading-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 18px;
  Width: 330px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.customer-heading-text {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.8);
  position: relative;
  text-transform: uppercase;
}
.customer-heading-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 0% 0 0 10%;
  gap: 0rem;
  font-size: 20px;
  max-Width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.customer-heading-view-all {
  color: #000;
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  letter-spacing: -0.2px;
  text-decoration-line: underline;
  cursor:pointer;
}
.logistic-navbar-component
.css-hyum1k-MuiToolbar-root {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 56px;
  background: white;
}
}
.admin-layout{
  background: white;
  margin-top: 5rem;
   width: 100%;
   /* height: 100vh; */
   }
   .byr-terms-condition {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-top: 0%;
    width: 90%;
    margin: 0% 5%;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
    margin-bottom: 5%;
}
.green-tick {
  border-radius: 100%;
  color: white;
  height: 2rem;
  margin: 0 auto;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #00800063;
}
.approve-tick{
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  align-items: center;
}
.customer-review-date
.css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.3rem;
  color: #FF6155;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.address-edit-icon{
  width: 140px;
  height: 34px;
  padding: 10px 8px 10px 8px;
  border-radius: 8px;
  border: 1px solid rgba(217, 217, 217, 1);
  color: rgba(80, 80, 80, 1);
  background-color: white;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center

  
}
.address-delete-icon{
  width: 140px;
  height: 34px;
  padding: 10px 8px 10px 8px;
  border-radius: 8px;
  border:1px solid rgba(253, 65, 109, 1);
  color:rgba(253, 65, 109, 1);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.logistic-navbar-sidebar
.css-ml9xc6-MuiListItem-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  display: block;
  background-color: #FD2A80;
  color: white;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 40px;
  width: 240px;
  min-height: 3rem;
}
.logistic-navbar-sidebar
.css-i4bv87-MuiSvgIcon-root
.css-ml9xc6-MuiListItem-root{
color: white;
}

.logistic-navbar-component
.css-hyum1k-MuiToolbar-root {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 56px;
  background: white;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}
.logistic-navbar-component
.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12); */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;
  color: black;
}
@media (min-width: 600px){
  .seller-navbar-sidebar
  .css-1p2j380-MuiDrawer-docked .MuiDrawer-paper {
      width: calc(64px + 1px);
      border: none;
  }
  .logistic-navbar-component
.css-hyum1k-MuiToolbar-root {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 56px;
  background: white;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}
  }
  .seller-navbar-sidebar
  .css-ml9xc6-MuiListItem-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    display: block;
    border-radius:  0px 8px 8px 0px;
    background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
    min-height: 40px;
    color: white;
  }


.custom-input {
  padding: 12px 24px;
  width: 340px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  outline:none;
  border-radius :8px;
  font-size:16px;
  display: flex;
width: 340px;
padding: 14px 19px;
align-items: flex-start;
gap: 10px;
  /* margin-bottom: 20px; */
}
.custom-input:focus {
  border:none;
  outline: 2px solid #FD416D;
}

.custom-input-error{
  /* position:absolute; */
  font-size:12px;
  font-weight:400;
  color:#d32f2f;
  margin-left:5px;
  /* margin-bottom:10px; */
  /* margin-top:4px; */
}
.myhraki-textfield{
  margin-bottom:10px;
}
.textfield-head{
  color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
opacity: 0.7;
}
.common-button-seller{
  display: flex;
width: 375px;
padding: 10px 8px;
justify-content: center;
align-items: center;
gap: 8px;
color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 14px;
letter-spacing: -0.14px;
text-transform: uppercase;
border: none;
background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
margin-top: 10px;
margin-bottom: 10px;
border-radius: 8px;
}
.OtpVerify-button-seller{
  display: flex;
  width: 300px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  border: none;
  background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}
.Plain-common-button-seller{
  display: flex;
  width: 375px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #FE2E7E;
  color: #FE426E;;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.14px;
  text-transform: uppercase;
    background: none;
  margin-top: 10px;
  margin-bottom: 10px;
}
.custom-background {
  background-color: #F4F4F4;;
}
@media (max-width: 480px){
  .custom-input{
   padding: 12px 24px;
   width: 290px;
   border: 1px solid rgba(0, 0, 0, 0.15);
   outline:none;
   border-radius :8px;
   font-size:16px;
   display: flex;
   width: 280px;
 padding: 14px 19px;
 align-items: flex-start;
 gap: 10px;
  }
  .common-button-seller{
    display: flex;
  width: 330px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
  margin-top: 10px;
  margin-bottom: 10px;
  }
  .Plain-common-button-seller{
    width: 330px;
  }
}

.customer-layout-children-cont{
  position:relative;
  top:72px;
}
@media screen and (max-width: 700px) {
  .customer-layout-children-cont{
    top:220px;
  }
}
.shopping-img-reject{
  width: 880px;
}