.CustomerNewMyAccountAdresss {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.CustomerNewMyAccountAdresssectionMain {
  width: 70%;
  border: 1px solid hsla(0, 0%, 74%, 1);
  padding: 15px;
}
.CustomerNewMyAccountAdress {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CustomerNewMyAccountAdress-sec1 {
  display: flex;
  gap: 20px;
  align-items: center;
}
.CustomerNewMyAccountAdress-sec2 {
  display: flex;
  gap: 20px;
}
.CustomerNewMyAccountAdress-sec1-tit {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewMyAccountAdress-sec1-subtit {
  padding: 2px 8px 2px 8px;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.06px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 100%, 1);
}
.CustomerNewMyAccountAdress-details-section {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 50%;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;

  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 34%, 1);
}
.CustomerNewMyAccountAdresssectionMain-head {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
  padding-bottom: 5px;
}
.CustomerNewMyAccountAdress-setasdefaultsec {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;

  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
  justify-content: center;
  display: flex;

  border-radius: 25px;
  padding: 5px;
  width: 20%;
  border: 1px solid #fe585b;
}
.CustomerNewMyAccountAdress-sec-gap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.CustomerNewMyAccountAdresss-add-adress {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;

  align-items: center;
  display: flex;
  gap: 5px;
}
.CustomerNewMyAccountAdress-saveadress {
  display: flex;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  justify-content: center;
  padding: 5px;
  color: hsla(0, 0%, 100%, 1);
  border-radius: 20px;
}
.deletdailog-myaccadressnew {
  font-family: Mulish;
  font-size: 20px;
  font-weight: 500;
  line-height: 25.1px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.deletdailog-myaccadressnew-dtl {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 31%, 1);
}
.cus-myscc-new-del-dai {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cus-myscc-new-del-cancel {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
  cursor: pointer;
}
.cus-myscc-new-del-delet {
  padding: 0px 20px 0px 20px;
  display: flex;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  color: hsla(0, 0%, 100%, 1);
  border-radius: 20px;
  cursor: pointer;
}
/*WISH LIST*/
.cust-my-acc-wishlist-main {
  display: flex;
  flex-direction: column;
  width: 70%;
  border: 1px solid hsla(0, 0%, 74%, 1);
}
.cust-my-acc-wishlist-iconssec {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cust-my-acc-wishlist-main-btn-sec {
  display: flex;

  padding: 10px;
  border-radius: 25px;
  align-items: center;
  gap: 15px;
  width: 80%;
}
.cust-my-acc-wishlist-main-sec {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
/*HELP SECTION*/
.cust-myacc-help-sec-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cust-myacc-help-sec1 {
  display: flex;
  flex-direction: column;
}
.cust-myacc-help-sec2 {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: -0.01em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
}
.cust-myacc-help-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cust-myacc-help-sec1-title {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.08px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.cust-myacc-help-sec1-subtitle {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 47%, 1);
}
.cust-myacclinesection {
  border-bottom: 1px solid #ccc; /* Customize the color and thickness */
}
.cust-myacc-help {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cust-myacc-help-or-sec {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
  display: flex;
  justify-content: center;
}
.cust-query-help-mail-new {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.submit-btn-faq-new-myacc-help {
  display: flex;
  justify-content: center;
  padding: 5px;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  color: hsla(0, 0%, 100%, 1);
  border-radius: 20px;
  cursor: pointer;
}
.submit-btn-faq-new-myacc {
  padding-top: 20px;
}
.cus-myacc-new-faq-main-sec-width {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.cus-myacc-new-faq-main-sec1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cus-myacc-new-faq-main-sec1-para {
  padding-top: 10px;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 28%, 0.8);
}
.myacc-newwall-mainsec {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.myacc-newwall-main {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.06px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 47%, 1);
}
.cus-myacc-new-faq-main-title-head {
  font-size: 16px;
}
.warning-circle-myacc-newwall {
  font-size: 18px;
}
@media screen and (max-width: 598px) {
  .CustomerNewMyAccountAdresssectionMain {
    width: 85%;
  }
  .warning-circle-myacc-newwall {
    font-size: 35px;
  }
  .CustomerNewMyAccount-tabs-below {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .CustomerNewMyAccountAdresssectionMain-head {
    font-size: 10px;
  }
  .CustomerNewMyAccountAdress-details-section {
    padding-top: 2px;
    padding-bottom: 10px;
    width: 100%;
    font-size: 10px;
  }
  .CustomerNewMyAccountAdress-sec1-tit {
    font-size: 12px;
  }
  .CustomerNewMyAccountAdress-sec1-subtit {
    font-size: 10px;
  }
  .CustomerNewMyAccountAdress-setasdefaultsec {
    font-size: 10px;
    width: 40%;
    padding: 2px;
  }
  .Add-shipping-adress-modal {
    padding-top: 15px;
    gap: 2px;
  }
  .CustomerNewMyAccountAdress-saveadress {
    margin: 10px;
  }
  .cust-my-acc-wishlist-main {
    width: 100%;
  }

  .CustomerNewCart-subheading-itemlist-img {
    width: 20%;
  }

  .cust-my-acc-wishlist-main-btn-sec {
    padding: 0px;
    gap: 5px;
    width: 100%;
  }
  .cust-myacc-help {
    width: 100%;
  }
  .cust-myacc-help-sec-main {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }
  .cust-myacc-help-sec1-title {
    font-size: 10px;
  }
  .cust-myacc-help-sec1-subtitle {
    font-size: 10px;
  }
  .cust-myacc-help-sec2 {
    font-size: 10px;
  }
  .cust-myacc-help-col {
    gap: 10px;
  }
  .cus-myacc-new-faq-main-sec-width {
    width: 100%;
  }
  .myacc-newwall-mainsec {
    width: 100%;
  }
  .cus-myacc-new-faq-main-title-head {
    font-size: 10px;
  }
  .cus-myacc-new-faq-main-sec1-para {
    padding-top: 0px;
    font-size: 10px;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .myacc-newwall-mainsec {
    width: 100%;
  }
  .CustomerNewMyAccountAdresssectionMain {
    width: 80%;
  }
  .CustomerNewMyAccountAdress-details-section {
    width: 80%;
  }
  .CustomerNewMyAccountAdress-setasdefaultsec {
    width: 40%;
  }
  .CustomerNewMyAccountAdress-saveadress {
    margin: 10px;
  }
  .cust-my-acc-wishlist-main {
    width: 90%;
  }
  .cust-my-acc-wishlist-main-btn-sec {
    padding: 0px;
    gap: 5px;
    width: 100%;
  }
  .cust-myacc-help {
    width: 100%;
  }
  .cus-myacc-new-faq-main-sec-width {
    width: 90%;
  }
}
