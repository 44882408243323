


.product-facts-card{
    /* height:30rem; */
    /* width:30%; */
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-around;
    position:relative;
}
.product-facts-image{
    /* height:30rem; */
    width:25rem;
    opacity:0.5;
    position:relative;

}
.product-facts-name{
    position:absolute;
    top:85%;
    background:rgba(144, 222, 224, 0.1);
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 10px;
    border: none;
    font-size:2.2rem;
    padding: 1rem 2rem;
    color: #fff;
    font-weight: 600;
}
.product-facts-name:hover{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.product-facts-desc{
    color:#fff;
    font-size:1rem;
    margin: 0 auto;
    width:60%;
    text-align: center;
    margin-top:4rem ;

}

@media screen and (max-width: 1200px) {
    .product-facts-image{
        width:20rem;
    }
    .product-facts-desc{
        margin-bottom:5rem;
    }
}