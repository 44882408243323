.seller-otp-vrify-container {
    max-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    overflow: hidden;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
}
.seller-otp-vrify-leftScreen{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    /* height: 100vh; */
    /* max-height: 2000px; */
    margin: 0 auto;
    background: linear-gradient(358.67deg, #FF6155 -0.13%, rgba(252, 38, 120, 0.3875) 102.79%, rgba(255, 97, 85, 0) 130.4%);
}
.seller-otp-vrify-rightScreen{
    width: 100%; 
         margin: 0 auto; 
background: white;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
text-align: center;

}
.seller-otp-verify-right-content{
    width: 100%;
    /* max-height: 1000px; */
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    gap: 1.4rem;
}
.seller-otp-verify-left-content{
    width: 100%;
    max-height: 1800px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: center;
}
.OtpVerification-container-left{
width: 50%;
background: #FFEDF1;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.OtpVerification-container-right{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    flex-direction: column;
    width: 60%;
}
.OtpVerification-title{
font-family: 'Barlow';
font-weight: 400;
font-size: 28px;
color: #707070;
}
.left-image{
    width: 60%;
    animation: fadeIn 1s ease-in-out;
}
.OtpVerification-container-left-title{
display: flex;
justify-content: space-around;
flex-direction: column;
}
.Otp-Verify-Recheck{
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.OtpVerification-acknowledgement-desc{
    font-family: 'Barlow';
font-weight: 600;
font-size: 16px;
margin: 0 auto;
width: 60%;
color: rgba(112, 112, 112, 0.72);

}
.OtpVerification-termsAndCondition {
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
}
.verify-submit-button {
    background: #FF7891;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    font-size: 1.3rem;
    padding: 0.5rem 2rem;
    color: white;
    border: none;
   
}
.OtpVerification-acknowledgement{
    font-family: 'Barlow';
    color: white;
    font-weight: 400;
font-size: 18px;
}
.seller-otp-verify-left-desc {
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
color: white;
    width: 60%;
    margin: 0 auto;
}
.did-you-know{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
}
.OtpVerification-container-left-title{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.otp-resend-action{
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    gap: 2rem;
}
@media screen and (max-width: 580px){
   
.seller-otp-vrify-leftScreen{
display: none;
    }
    
.seller-otp-vrify-rightScreen{
    width: 100%;
}
    .OtpVerification-termsAndCondition {
        display: flex;
        width: 100%;
        font-family: 'Barlow';
        font-weight: 400;
        font-size: 15px;
        text-align: center;
        color: rgba(112, 112, 112, 0.72);
    }
    .Otp-Verify-Recheck {
        display: flex;
        justify-content: center;
        gap: 1rem;
       flex-direction: column;
    }
}
