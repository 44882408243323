.invoice {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    max-width: 660px;
    padding: 20px;
   
  }
 p{
    color: black;
    font-family: barlow;
    font-weight: 300;
    letter-spacing: 0.03em;
    font-size: 12px;
 }
  .invoice h2 {
    font-size: 12px;
  }
  .invoice-section{
    margin-block: 0rem;
  }
  .invoice p {
    margin: 0px 0;
  }
 
  .invoice table {
    width: 100%;
    border-collapse: collapse;
  }
  .invoice th,
  .invoice td  {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: left;font-size: 12px;
    background: none;
  }
  /* Styles for the entire custom invoice table */
.custom-invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px; /* Adjust as needed */
}

/* Styles for the header row of the invoice table */
.invoice-header-row th {
  border: 1px solid #dddddd; /* Gray border */
  padding: 8px;
  text-align: left;
}

/* Styles for the data row of the invoice table */
.invoice-data-row {
  border: 1px solid #dddddd; /* Gray border */
}

/* Styles for each cell in the invoice table */
.invoice-data-cell {
  border: 1px solid #dddddd; /* Gray border */
  padding: 8px;
}

/* Optional: Hover effect on invoice table rows */
.invoice-data-row:hover {
  background-color: #f5f5f5; /* Lighter gray background on hover */
}

 
  .invoice .grand-total {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
 
  .invoice .returns-policy {
    margin-top: 20px;
  }
 
  .invoice .contact-info {
    margin-top: 20px;
  }
 
  .invoice .contact-info a {
    color: blue;
  }
 
  .invoice footer {
    text-align: center;
  }
  .separator {
    border: 1px solid black;
    border-top: 1px solid #ccc;
  }
  @media print {
    .invoice {
      box-shadow: none;
      margin: 0;
      padding: 0;
    }
  }
 
  @media screen and (max-width: 800px) {
    .invoice {
      max-width: 100%;
      padding: 10px;
    }
  }
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
  }
 
  .invoice-table th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
 
  .invoice-table-thead {
    background-color: #f2f2f2;
  }
 
  .invoice-table .separator {
    border: none;
    border-top: 1px solid #ccc;
    margin: 20px 0;
  }
 
  .heading-invoice{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -7px auto;
    font-size: 20px;
  }
  .Oder-id-filed-set{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;
    gap: 4rem
  }
  .Oder-id-filed{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    font-size: 12px;
    flex-direction: column;
}
  .footer-invoice{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-top:-3%;
  }
  .logo-field{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .invoice-span{
    color: black;
    font-weight: 800px;
  }
.para{
    color: rgba(0, 0, 0,0.8);
    font-size: 8px;
    text-align: justify;
}
.Grand-total-field{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
 
}
.additional-info{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.receipt-modal-download-button{
  display: flex;
  width: 220px;
  padding: 4px 4px;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
  border: none;
}
.receipt-modal-download-button span{
  display: flex;
width: 220px;
padding: 8px 8px;
justify-content: center;
align-items: center;
color: white;
font-size: 12px;
gap: 8px;
border-radius: 8px;
cursor: pointer;
background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);

border: none;
}
.actions-right{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.grand-total{
    display: flex;
    justify-content: flex-end;
}
.invoice-table{
    border-collapse: collapse;
    width: 100%;
  }
  .invoice-head th,
  .invoice-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-family: barlow;
    font-size: 14px;
  }
  .Main-Logo-invoice{
    width: 70%;
  }
  .order-id-value {
    display: inline-block;
    font-family: 'barlow';
  }
  .invoice > span{
    color: rgba(0,0,0,0.7);
    font-family: barlow;
    font-weight: 400;
    font-size: 12px;
  }
  .invoice > h2{
    color: black;
    font-family: barlow;
    font-weight: 800;
  }