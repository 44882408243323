.seller-register-container { 
    display: flex;
    max-width: 2000px;
    background: conic-gradient(from 131.32deg at 49.93% 49.93%, rgba(254, 0, 122, 0) 0deg, rgba(255, 134, 134, 0.202739) 224.84deg, rgba(239, 29, 117, 0) 360deg);
  width: 100%;
  height: 100vh;
    overflow: hidden;
    max-height: 1300px;
    margin: 0 auto;
}


    .seller-register-welcome-layout-content {
        max-width: 1200px;
        width: 100%;
        max-height: 1000px;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        justify-content: space-evenly;
        /* background: conic-gradient(from 243.5deg at 50.07% 73.47%, #FFEDF1 0deg, rgba(255, 216, 216, 0) 360deg); */
        align-items: center;
        gap: 2rem;
}
.register-img-shopping{
    width: 100%;
    /* height: 280px; */
}

.seller-register-form-field{
    width:55%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-around;

}

.seller-register-form-field-head{
    color: #FF2171;
    font-size:2rem;
    font-weight: 600;
}

.seller-register-input-form-fields{
    width:100%;

}

.seller-register-form-field-row{
    display:flex;
    align-items:center;
    justify-content:space-around;
    width:100%;
    margin-bottom:2rem;
}
.seller-register-form-field-textfield{
    width:40%;
}
.seller-register-form-gender{
   width: 90%;
   display: flex;
   flex-direction: column;
   margin-left: 32px;
}
.gender-head{
    color:#FF2171;
    font-size:1.2rem;
}
.radio-buttons{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    gap:3rem;
}
.seller-register-accept-terms{
    display:flex;
    justify-content: space-around;
    width:80%;
    gap:1rem;
    font-size:1.2rem;
    color: #707070B8;

    
}
.seller-button-field{
    display: flex;
    justify-content: center;
    margin: 0 auto;
}
.seller-button{
    background: #FF7891;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
font-size:1.2rem;
padding: 0.5rem 1rem;
color:white;
border:none;
}
.seller-register-btn-field{
display: flex;
justify-content: center;
align-items: center;

}
.css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    fill: currentColor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.3rem;
    color: gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    vertical-align: top;
    width: 100%;
    padding-left: 1px;

} */

.css-1wc848c-MuiFormHelperText-root.icon-style {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: -24.34;
    text-align: left;
    margin-top: 3px;
    margin-bottom: 0;
    
}

.seller-reset-phone-icon {
    position: absolute;
    left: 11%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.5rem;
    top: 35%;
}
/* .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: barlow;
    color: gray;
    font-weight: 400;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    display: flex;
    border-radius: 4px;
 
} */
@media only screen and (max-width: 620px) {
    .seller-register-welcome-layout {
      display: none;
    }
    .seller-register-form-field{
        
        width: 100%;
    }
    
.seller-register-right-screen{
    overflow: scroll;
}
.registration-gender {
    font-weight: 600;
    font-size: 2rem;
    /* margin: 2rem 2rem; */
    color: #FF2171;
    align-self: flex-start;
    margin-left: 16%;
}
.registration-terms-text {
    /* margin: 3rem 1rem; */
    font-size: 1.2rem;
    color: rgba(112, 112, 112, 0.72);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* gap: 1rem; */
    margin-top: 2%;
    margin-left: 10%;
}
.seller-register-button-field{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 17%;
    width: 330px;
  }
  }