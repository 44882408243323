.navbar-container {
  max-width: 100vw;
  font-family: "Barlow";
  /* min-width: 90%; */
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  align-items: center;
  transform: scale(1, 0.9);
}

.navbar-left {
  display: flex;
  width: 70%;
  align-items: center;
}

.navbar-right {
  display: flex;
  align-self: flex-end;
}

.navbar-logo {
  /* width: 5rem;
  height: 5rem;
  margin: 0.3rem 2rem;
  margin-left: 0.5rem; */
  /* width: 3%; */
  /* height: 3%; */
  width: 26%;
    /* margin: 0 auto; */
    margin-left: -23px;
}

.navbar-logo-name {
  color: rgba(248, 152, 128, 1);
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  font-size: 2.4rem;
  margin-right: 5%;
  /* font-weight: 300 !important; */
}

.navbar-search-bar {
  position: relative;
  min-width: 60%;
  margin-right: 5%;
  margin-bottom: 4%;
}

.navbar-search {
  background: #ffffff;
  border: 1px solid #707070;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  min-height: 2rem;
  padding-left: 3rem;
}

.navbar-search-icon {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  left: 1rem;
  top: 2rem;
}

.navbar-icons {
  display:flex;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
  margin-bottom: 15%;
}

.navbar-wishlist {
  width: 3rem;
  height: 2.3rem;
  margin-right: 2rem;
  align-self: center;
}

.navbar-cart {
  width: 2.5rem;
  height: 2rem;
  margin-right: 2rem;
  display: inline;
  align-self: center;
}

.navbar-profile {
  width: 3rem;
  height: 2.8rem;
  margin-right: 2rem;
  align-self: center;
}

@media screen and (max-width: 480px) {
  .navbar-container {
    width: 90%;
  }
  .navbar-logo-name {
    display: none;
  }
  .navbar-logo {
    display: none;
    /* margin: 0 0.5rem 0 0; */
  }

  .navbar-right {
    width: 60%;
    /* margin-left: 1.2rem; */
    margin-right: 0;
    padding-right: 0;
    position: relative;
    left: 2rem;
    overflow-x: hidden;
  }
  .navbar-left {
    width: 60%;
  }
  .navbar-search-bar {
    width: 80%;
    margin-right: 0;
  }

  .navbar-right > * {
    font-size: 0.6rem;
    margin-right: 0;
  }
  .navbar-icons {
    margin-right: 0;
    margin-left: 2rem;
    margin-bottom: 4%;
}
  .navbar-cart {
    font-size: 0.6rem;
    margin-right: 0;
    /* margin-left: 2rem; */
  }
  .navbar-icons > * {
    /* align-items: flex-end; */
    width: 20%;
    margin-right: 10%;
  }
}
.MuiPaper-root
  .MuiMenu-paper
  .MuiPopover-paper
  .MuiPaper-elevation0
  .MuiPaper-rounded {
  top: 5rem;
}

.menu-item {
  width: 20rem;
  margin-bottom: 1rem;
  display: flex;
  /* justify-content:space-between; */
}
.menu-item > * {
  margin: 0 2rem;
}
