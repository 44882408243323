

.reset-screen-container {
    font-family: "Barlow";
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: conic-gradient(from 0deg at 50% 50%, rgba(212, 240, 240, 0.52) 0deg, #FFFFFF 154.87deg, rgba(212, 240, 240, 0.52) 360deg);
}

.reset-top-left-rect {
    position: fixed;
    min-width: 450px;
    /* width: 45vw; */
    height: 30%;
    left: -378px;
    top: -9.16px;
    z-index: -1;
    background: linear-gradient(119.13deg, #4FE7EB 62.17%, rgba(79, 231, 235, 0) 90.1%);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    /* transform: matrix(0.98, -0.16, 0.19, 0.99, 0, 0); */
}

.reset-btm-left {
    position: fixed;
    width: 220.4px;
    height: 79px;
    left: -5vw;
    /* top: 700px; */
    bottom: 0vh;
    background: linear-gradient( 269.41deg, #60fbff 10.17%, rgba(79, 231, 235, 0) 94.57% );
    z-index: -1;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 40px;
    transform: rotate(-60deg);
}

.reset-btm-left-top {
    position: fixed;
    width: 177.19px;
    height: 79px;
    left: -8vw;
    /* top: 762.45px; */
    bottom: 0vh;
    z-index: -1;
    background: linear-gradient( 337.31deg, #008185 11.67%, rgba(198, 250, 252, 0) 93.51% );
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 40px;
    transform: rotate(-60deg);
}

.reset-btm-left-bottom {
    position: fixed;
    width: 177.19px;
    height: 79px;
    /* left: 18px; */
    /* top: 822.45px; */
    bottom: -5vh;
    z-index: -1;
    left: 0vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: linear-gradient( 192.25deg, #00e9ef 8.92%, rgba(11, 230, 236, 0) 80.44% );
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 40px;
    transform: rotate(-60deg);
}

.reset-circle {
    position: fixed;
    width: 209px;
    height: 214px;
    right: 20vw;
    bottom: 1vh;
    border-radius: 50%;
    background: linear-gradient(207.66deg, #67DCDF 37.17%, rgba(79, 231, 235, 0) 81.8%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: -1;
}

.reset-top-right-rect {
    position: fixed;
    width: 30%;
    height: 30%;
    right: -50px;
    top: -50px;
    opacity: 0.2;
    background: linear-gradient(252.56deg, #00e9ef 8.92%, 82.37%, rgba(79, 231, 235, 0) 43.95%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transform: matrix(0.98, -0.16, 0.19, 0.99, 0, 0);
}

.reset-card {
    /* margin: 2% 3%; */
    width: 35%;
    min-width: 400px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    padding-top:3%;
    transform: scale(0.8);
    /* transform-origin: 0 0; */

}

.reset-head {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 2.0rem;
    margin: 5% auto;
    margin-top:5rem;
    color: rgba(248, 152, 128, 1);
}
.reset-page-form{
    width:100%;
    display:flex;
    flex-direction:column;
}
.reset-page-email-field{
    width:100%;
    position:relative;
    margin:3rem auto;
}
.reset-email .MuiOutlinedInput-root{
    align-self: center;
    width: 60%;
    /* border-radius: 100px; */
    min-height: 50px;
    height: 2.5rem; 
    background-color:white;
    padding-left: 10%;
    background-size: 8%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3% ;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}



.reset-email-icon {
    position: absolute;
    top:35%;
    left: 22%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.8rem;

}

.reset-auth-text {
    color: rgba(248, 152, 128, 1);
    font-size: 1.2rem;
    max-width: 85%;
    font-weight: 600;
    margin: 4% auto;
}
.reset-email-field {
    width: 75%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    margin:0 auto;
}
.reset-email-validation-text {
    position:absolute;
    bottom:-8%;
    color: red;
    opacity:0.7;
    font-size: 0.8rem;
    padding-top:1rem;
}
.reset-email {
    width: 100%;
    /* border-radius: 100px; */
    padding-left: 15%;
    background-size: 8%;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2vw;
    margin: 5% auto;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

    .reset-email:focus {
        outline: none;
        border: 0.5px solid rgba(112, 112, 112, 0.5);
    }

.reset-send-otp {
    margin: 8% 25%;
    font-size: 1.4rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}
.reset-page-send-instructions {
    margin: 2rem auto;
    width:45%;
    font-size: 1.4rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}
.reset-signUp {
    margin: 3% auto;
    font-weight: 600;
    font-size: 1.4rem;
    color: #000000;
}

    .reset-signUp span {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2vw;
        text-decoration-line: underline;
        color: #019dbf;
    }

.reset-login {
    margin: 2rem auto;
    font-weight: 600;
    /* font-size: 1.4vw; */
    font-size: 1.4rem;
    /* line-height: 2vw; */
    /* margin-bottom: 5rem; */
    color: #000000;
}

    .reset-login span {
        font-weight: 600;
        /* font-size: 1.4vw; */
        font-size: 1.4rem;
        line-height: 2vw;
        text-decoration-line: underline;
        color: #019dbf;
    }

    .reset-page-terms {
        font-style: normal;
        font-weight: 600;
        font-size: 1.3rem;
        margin: 3% 5%;
        text-align: center;
        color: rgba(112, 112, 112, 0.72);
    }
    
        .reset-page-terms span {
            color: #707070;
        }

.reset-logo {
    /* position: fixed; */
    position:absolute;
    /* margin: 1% 1%; */
    top:1rem;
    left:43%;
}
.reset-card span:hover{
    cursor:pointer;
}
::placeholder{
    opacity:1;
    /* font-size:2vw; */
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}