.track-order {
    width: 80%;
    max-width:1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.track-order-searchbar {
    min-width: 80%;
}

.track-order-searchbar>* {
    width: 90%;
}

.track-order-search-button {
    width: 20%;
}

.order-track-details {
    width: 80%;
    margin: 2rem auto;
    /* border:1px solid black; */
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-height: 100px;
}

.order-details-id {
    display: flex;
    width: 90%;
    margin: 1rem auto;
    justify-content: flex-end;
color: rgba(0, 0, 0, 0.8);
font-size: 20px
}

.order-details-personal-details {
    width: 90%;
    margin: 1rem auto;
font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 26px;
color: rgba(0, 0, 0, 0.6);
}
.order-details-details{
    margin:1rem auto;
    width:90%;
}

.order-details-personal-details-field {
    margin: 0 auto;
}

.order-details-details-card{
    width: 90%;
    padding: 1rem;
    margin: 1rem auto;
    /* border:1px solid black; */
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    /* flex-direction: column; */
    align-items:center;
    justify-content:flex-start;
    gap:5rem;
    /* min-height: 100px; */
font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 16px;
color: rgba(0, 0, 0, 0.6);
}