.horizontal-scroller {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
}

.product-display-root {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  overflow-x: auto;
  width: 95%;
  padding: 24px;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  flex-wrap: wrap;
  background: #fff;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-icon {
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .product-display-root {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    overflow-x: auto;
    width: 85%;
    /* padding:24px; */
    /* max-width:1280px; */
    margin: 0 auto;
    position: relative;
    flex-wrap: wrap;
    background: #fff;
  }
  .customer-tale-of-crafts-card {
    width: 274px;
    height: 160px;
    flex-shrink: 0;
    border-radius: 8px;
    cursor: pointer;
    background: linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0) 100%),
      lightgray 50% / cover no-repeat;
  }
}

.LinearProgress-buyers
  .MuiLinearProgress-bar
  .MuiLinearProgress-barColorPrimary
  .MuiLinearProgress-bar1Determinate
  .css-5xe99f-MuiLinearProgress-bar1 {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  -webkit-transition: -webkit-transform 0.4s linear;
  transition: transform 0.4s linear;
  transform-origin: left;
  background-color: #eb4d80 !important;
}
.LinearProgress-buyers
  .MuiLinearProgress-root
  .MuiLinearProgress-colorPrimary
  .MuiLinearProgress-determinate
  .css-eglki6-MuiLinearProgress-root
  .css-eglki6-MuiLinearProgress-root {
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  z-index: 0;
  background-color: #ededed !important;
  width: 142px;
  height: 4px;
}
.landingpage-category-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  /* margin-top: 24px;
  margin-bottom: 40px; */
}
.category-scroll-card {
  width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.landingpage-category-tail {
  border-radius: 0px 0px 10px 10px;
  background: rgba(158, 83, 76, 0.7);
  opacity: 0.8;
  text-align: "center";
  display: flex;
  width: 85%;
  padding: 5px 18px;
  justify-content: center;
  align-items: center;
  color: "white";
  color: #fff;
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .landingpage-category-tail {
    /* width: 60%; */
    padding: 6px 6px;
  }
}
@media screen and (max-width: 800px) {
  .landingpage-category-tail {
    /* width: 85%; */
    padding: 6px 14px;
  }
}
@media screen and (max-width: 480px) {
  .landingpage-category-tail {
    /* width: 80%; */
    padding: 6px 14px;
  }
}
