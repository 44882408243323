.filter-container{
    width:20%;
    min-width:250px;
    border-right: 0.5px solid rgba(0, 0, 0, 0.2);
    font-family:'Barlow';
    padding-bottom:3rem;
    position:static;
    max-width:100%; 
    overflow:hidden;
    max-height:100%;
}
.filter-radio{
    width:80%;
    margin:0 auto;
    /* max-height:10rem;
    overflow-y: scroll;
    margin-bottom:2rem; */
}
.filter-checkbox{
    width:80%;
    margin:0 auto;
    max-height:10rem;
    overflow-y: scroll;
    margin-bottom:2rem;
    margin-top:2rem;

}

.items-list{
    /* max-height:10rem; */
}
.filter-container .MuiFormControlLabel-root{
    min-height:2rem;
    /* font-size:0.3rem; */
}


.filter-heading-styling {
    align-self: center;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem auto;
    position: relative;
    width:100%;
    text-align: center;
  }
  .filter-heading-styling-text {
    margin-bottom: 0.4rem;
    margin:0 auto;
  }
  .filter-heading-styling-underline {
    position: absolute;
    background: rgba(248, 152, 128, 1);
    height: 0.2rem;
    width: 50%;
    /* margin:0 auto; */
    border-radius: 80%;
    left:25%;
    /* margin-bottom:1rem; */
  }