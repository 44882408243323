.address-card-container {
  max-width: 866px;
  background: #ffffff;
  border-radius: 5px;
  /* max-height: 260px; */
  padding: 1rem;
  /* margin: 1rem auto; */
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 8px;
  
}
.address-card-content-fields{
  width: 850px;
 /* height: 1007px; */
}
.address-card-container:hover {
  /* background: #FFE9CF; */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  /* cursor: pointer; */

}
.isDefault {  
  margin-bottom: 12px;
  color: rgba(112, 112, 112, 0.72);
  font-size: 14px;
  min-height: 1.5rem;
  max-width: 866px;
  cursor: pointer;
  text-transform: uppercase;
}
.name-and-icons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.name {
  font-weight: 600;
  font-size: 20px;
 
  color: rgba(0, 0, 0, 0.8);
}
.address {
  font-size: 18px;
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.66);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.contact {
  font-size: 18px;
  margin-bottom: 1rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.66);
}
.address-place-field{
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.66);
}
.address-name-type-field{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}
.delivery {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  text-decoration: underline;
  color: rgba(248, 152, 128, 1);
  margin-left: 2rem;
}
.delivery:hover{
  cursor: pointer;
}

.icon {
  font-size: 1rem;
  margin-right: 1rem;
}
.icon:hover{
  cursor: pointer;
}
.location-icon {
  font-size: 1.2rem;
  color: red;
}
.add-address {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-symbol {
  font-size: 5rem;
  margin: auto auto;
}
.add-text {
  font-size: 1.2rem;
}
.add-address:hover{
  cursor:pointer;
}
.add-addres-button-fields{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
@media screen and (max-width: 1200px) {
  .name {
    font-size: 16px;
    /* width: 170px; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
  }
  .contact,
  .delivery {
    font-size: 16px;
  
  }
  .address{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.01em;
    text-align: left;
    max-width: 567px;

  }
  .address-card-container {
  /* margin: 1rem 0; */
 min-width: 308px;
 /* max-width: 500px; */
  }
  .cart-address-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.cart-email-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.login-email-cart .MuiOutlinedInput-root {
  align-self: center;
  min-width: 300px;
  min-height: 50px;
  height: 2.5rem;
  background-color: white;
  background-size: 3%;
  font-weight: 300 !important;
  font-size: 1.2rem;
  border-radius: 10px;
  color: rgba(112, 112, 112, 0.72);
  border: 0.5px solid rgba(112, 112, 112, 0.11);
  margin: 0 auto;
}
.add-address-head {
  align-items: center;
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  text-align: left;
  width: 90%;
}
.address-card-content-fields{
width: 100%;
}
.add-address-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: left;
  width: 100%;
}
.address-container {
  display: flex;
  margin-bottom: 42px;
  width: 100%;
  margin: 0 auto;
}
.add-address-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: left;
}
}

@media screen and (max-width: 480px) {
  .address-card-container {
    font-family: "Barlow";
    /* width: 330px; */
    /* max-width: 520px; */
    /* min-width: 320px; */
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 8px;
    padding: 1rem;
    padding-top: 5px;
    /* margin: 1rem; */
    min-height: 17rem;
    /* margin: 1rem; */
    /* min-height: 15rem; */
    /* transform: scale(0.6); */
    /* transform-origin: 0 0; */
    background: #fff;
    border: 1px solid #0003;
    border-radius: 8px;
    min-height: 15rem;
    padding: 24px 16px 16px 16px;
}
.address, .contact, .delivery {
  font-size: 14px;
}
.textfield-add-address-shipping
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  /* width: 65%; */
 width: 300px;
  min-width: 300px;
  border-color: rgba(0, 0, 0, 0.23);
}
.form-row{
  display: flex;
  justify-content:space-evenly;
  align-items: flex-start;
  text-align: center;
  flex-direction: column;
}
.modal-address-content {
  background-color: #fff;
  padding: 0;
  max-width: 400px;
  width: 240px;
}
.add-addres-button-fields{
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
}
.address-card-content-fields {
  width: 330px;
  margin-left: 16px;
  /* height: 1007px; */
}
}
.cart-email-field{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
flex-direction: column;
}
.payment-success
.css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: #f89880;
}
/* .highlighted {
  background-color: #FFE9CF;
} */
.modal-address {
  background-color: #fff;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.modal-address-content {
  background-color: #fff;
  padding: 20px;
  max-width: 400px;
}

.modal-address h2 {
  margin-top: 0;
}

.modal-address input,
.modal-address button {
  display: block;
  margin-bottom: 10px;
}
.form-row{
  display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.add-address-shipping{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity to control the blur effect */
  display: flex;
  align-items:flex-start;
  justify-content: flex-start;
  z-index: 100;
}
.textfield-add-address-shipping
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  overflow: hidden;
  /* width: 65%; */
 width: 350px;
  min-width: 300px;
  border-color: rgba(0, 0, 0, 0.23);
}

.selected-address {
  background-color: #FFE9CF;
}
.address-edit-delete-icons{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}
.address-type-icon{
  background: rgba(217, 217, 217, 0.6);
  width: 66px;
  height: 24px;
  border-radius: 8px;
  border: none;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0.1rem;
  justify-content: center;
  text-transform: uppercase;
  
}
.addressType{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 0.2rem;
  max-width:100px;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}