.admin-facts-table {
    width:70%;
    margin: auto;
    min-width: 350px;
    max-width: 100%;
     border-collapse:separate; 
    border-spacing: 0 1em;
  }
  .fact-modal-form-field-name{
    width:40%;
    font-size: 20px;
  }
  .admin-facts-table tr:first-child {
    /* border-top: none; */
    text-align: center;
    background: white;
    color: black;
  }
  
  .admin-facts-table tr {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .admin-facts-table tr:nth-child(odd):not(:first-child) {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .admin-facts-table th {
    display: none;
  }
  
  .admin-facts-table td {
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-facts-table td:first-child {
    margin-top: .5em;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-facts-table td:last-child {
    margin-bottom: .5em;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-facts-table td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 120px;
    display: inline-block;
    color: black;
  }

  
  .admin-facts-table {
    color: #333;
    border-radius: .4em;
    overflow: hidden;
  }
  
  .admin-facts-table tr {
    background: #FFFFFF;
   }
  
  .admin-facts-table th,
  .admin-facts-table td {
    padding: .5em 1em;
  }
  
  .admin-facts-table tr:first-child , .admin-facts-table td:first-child { border-top-left-radius: 10px; }
  .admin-facts-table tr:first-child , .admin-facts-table td:last-child { border-top-right-radius: 10px; }
  
  .admin-facts-table tr:last-child ,.admin-facts-table td:first-child { border-bottom-left-radius: 10px; }
  .admin-facts-table tr:last-child,  .admin-facts-table td:last-child { border-bottom-right-radius: 10px; }
  .fact-approve-buttons{
    display:flex;
    flex-direction:column;
    gap:1rem;
    align-self:center;
  }
  
  @media screen and (max-width: 601px) {
   .admin-facts-table tr:nth-child(2) {
      border-top: none;
    }
  
    .admin-facts-table tr {
      background: #fafafa;
      border: 1px solid rgba(0, 0, 0, 0.2);
     }
  
    .admin-facts-table td {
      display: block;
      border: none !important;
      display: flex;
      justify-content:space-between;
    }
    .admin-facts-table td:first-child {
      border: none !important;
    }
    
    .admin-facts-table td:last-child {
      border: none !important;
    }
    
    .admin-facts-table {
      width:95%;
      margin:0 auto;
      border-collapse: collapse;
      border-spacing: 0 1em;
    }
    .data-th {
      width: 50%;
      gap: 2rem;
    }
    .admin-category-addition-field{
        flex-direction: column;
        gap:2rem;
    }
    .fact-approve-buttons {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      align-self: center;
  }
  .fact-modal-form-field-name {
    width: 30%;
    font-size: 20px;
}
  }
  @media screen and (min-width: 600px) {
  
    
  
    .admin-facts-table {
      width:95%;
      margin:0 auto;
    }
    .admin-facts-table tr:hover:not(:first-child) {
      /* background-color: #d8e7f3; */
      cursor:pointer;
    }
    .admin-facts-table td:before {
      display: none;
    }
    .admin-facts-table th,
   .admin-facts-table td {
      display: table-cell;
      padding: .25em .5em;
    }
    .admin-facts-table th:first-child,
  .admin-facts-table td:first-child {
      padding-left: 0;
    }
   .admin-facts-table th:last-child,
   .admin-facts-table td:last-child {
      padding-right: 0;
    }
    .admin-facts-table th,
    .admin-facts-table td {
      padding: 0.8em !important;
    }
    .admin-facts-table th{
      padding: 0.5em !important;
    }
    .fact-approve-buttons {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      align-self: center;
  }.fact-modal-form-field-name {
    width: 30%;
    font-size: 20px;
}

  }

  .fact-modal-form{
    min-width:60%;
    max-width:850px;
  }
  .fact-modal-form-field{
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
    margin: 8px auto;
  }

  .fact-modal-form-field-textField{
    /* width:50%; */
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%; 
  }
  .facts-modal-button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .css-1j6nv9s-JoyTextarea-root.Joy-focused{
    --Textarea-focusedHighlight: rgba(255, 120, 145, 1);
  }

  .fact-modal-form-field-textfield>*:focused{
    border-color:  rgba(255, 120, 145, 1);
  }

  .single-fact{
    display:flex;
    align-items:flex-start;
    justify-content:space-around;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius:5px;
    margin:1rem auto;
    padding:2rem;
  }

  .single-fact-image{
    width:100px;
    height:100px;
  }
  .single-fact-field{
    max-width:20%;
  }

 
  .seller-single-fact-head{
    margin-bottom:1.5rem;
    font-size:20px;
  }

  @media screen and (max-width: 1000px) {
    .single-fact{
        flex-direction:column;
    }
    .single-fact-field{
        max-width:80%;
        margin:1rem auto;
        display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    }
  
  }
  .Facts-Button-field{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
  }
  .Facts-Button-field
  .common-btn {
    font-size: 18px;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 3%;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 2rem 1rem;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
  min-width: 700px;
  width: 50%;
  max-height: 700px;
  overflow: scroll;
}
.error-message-query-admin{
  color: #d32f2f;
  font-size: 13px;
  position: relative;
  left: 15px;
}
.fact-modal-form-field-imageFIeld{
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 55%;
  justify-content: flex-start;
}
.image-area.error {
  border: 1px solid #d32f2f;
  border-radius: 8px;
}
@media screen and (max-width: 480px) {
  .fact-modal-form-field {

    flex-direction: column;
}
.fact-modal-form-field-name {
  width: 100%;
  font-size: 16px;
}

}
.Admin-facts-description-field{
  overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    width: 200px;
}