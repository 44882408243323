.add-fact {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0; 
    padding: 0; 
    background: #ffffff;
    width: 100%;
    overflow: scroll;
}

.fact-modal-form{
    margin: 0 auto; 
} 

.add-the-fact-form{
    height: 100vh;
    margin: 0;
}

u.heading {
    text-decoration: none;
    position: relative;
    color: black; 
    display: inline-block;
  }

  u.heading::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px; 
    height: 2px; 
    width: 100%; 
    background-color: #FF7891;
  }

.coupon-box {
    margin: none;
    padding: 1rem 5rem;
    background: #ffffff;
    width: 100%;
    max-height: 100vh;
    overflow: scroll;
}

.fact-modal-form-field-name{
    margin-right: 40px;
}

@media screen and (min-width: 600px) {
    .add-fact {
        padding: 0.5rem;
        overflow: auto;
    }

    .fact-modal-form {
        padding: 0.5rem;
    }

    .coupon-box {
        padding: 1rem;
    }

    .fact-modal-form-field-name {
        margin-right: 20px; 
    }
}

@media screen and (min-width: 300px) {
    .add-fact {
        padding: 0.5rem;
        overflow: auto;
    }

    .fact-modal-form {
        padding: 0.5rem;
    }

    .coupon-box {
        padding: 1rem;
    }

    .fact-modal-form-field-name {
        margin-right: 20px;
    }
}
.BlogArticle-Seller:focus {
    outline: 2px solid #FF5569;
  }
/* .textarea:focus {
    border-color: rgba(248, 152, 128, 1) !important;
  } */