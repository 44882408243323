.add-product-by-seller-form {
  width: 60%;
  min-width: 850px;
   /* border: 1px solid rgba(0, 0, 0, 0.2); */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  margin: 1rem auto;
}
.add-product-key-value-field .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled::placeholder {
  color: black;
}
.MuiInputBase-input .MuiOutlinedInput-input .Mui-disabled .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  color: black !important;

}

@media screen and (max-width: 900px){
  .add-product-by-seller-form {
    width: 90%;
    min-width: 350px;
   
  }
}

.add-product-by-seller-field {
  width: 90%;
  margin: 15px auto;
  position:relative;
}


.add-product-by-seller-field-button {
  width: 80%;
  margin: 1rem auto;
    display:flex;
    justify-content:flex-end;
}

.add-product-by-seller-field-name {
  font-size: 15px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0.5rem;
}

.add-product-by-seller-field-with-unit {
  width: 90%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
}

.add-product-by-seller-field-with-unit-value {
  width: 85%;
}

.add-product-by-seller-field-with-unit-unit {
  width: 10%;
}

.add-product-by-seller-field-text-area {
  width: 100%;
  min-height: 200px;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  resize: none;
  border: 0.1px solid rgba(112, 112, 112, 0.5);
}

.add-product-by-seller-field-text-area:focus {
    outline-color:  #FF5569;
  
}

.add-product-by-seller-field-desc{
    color:rgba(0, 0, 0, 0.5);

}

.add-product-by-seller-field-error {
    outline: 0.3px solid red;
  }

.add-product-by-seller-field-error-text {
    /* outline: 2px solid red;
     */
     position:absolute;
     top: 6rem;
     left: 1rem;
     color:#d32f2f;
  }

.add-product-by-seller-field-error-text-desc {
  font-size: 12px;
  position: absolute;
  bottom: -16px;
  left: 0;
  color: #d32f2f;
  }

.add-product-by-seller-field-no-error {
    outline: none;
  }

  .add-product-by-seller-form.MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained.css-1wc848c-MuiFormHelperText-root{
    position: absolute;
    top: 3rem;
    left: 0rem;
  }

  .add-product-by-seller-field-textfield p{
    position: absolute;
    top: 3.5rem;
    left: 0rem; 
  }
.dropzone{
padding:30rem;
}
  .add-product-by-seller-common-img-field{
      width:80%;
      margin:3rem auto 1rem auto;;
      /* display:flex; */
      justify-content:space-between;
      align-items:center;
    }

    .add-product-by-seller-common-img-field>*{
        width:80%;
        min-height:300px;
    }
    
    .add-product-by-seller-common-img-rules{
      width:80%;
      margin: 1rem;
      align-items:center;
    }

    .add-product-by-seller-image-field{
      width:100%;
      min-height:300px;
      border: 0.3px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    }
    .add-product-by-seller-image-field>*{
      min-height:150px;
    }

    .seller-products-mediaList{
      display:flex;
      align-items:center;
      justify-content:flex-start;
      flex-wrap:wrap;
      gap:5rem;
    }

  .seller-products-mediaList>*{
    list-style-type: none;
    width:135px;
    height:180px;
    /* border: 1px solid red; */
    padding:8px 8px;
    border: 0.3px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    border-radius:5px;
  }
  .seller-products-mediaList > * img {
    max-width: 100%;
    height: 80%;
  }


    .add-product-image-add-text{
      width: 100%;
      margin: 0% auto;
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction: column;
      text-align: center;
      background-color: rgba(243, 243, 243, 1);
      height:100%;
      min-height:300px;
    }
    .seller-drag-image-field{
      background-color:red !important;
    }

    .add-product-image-field{
      width:80%;
      margin:2rem auto 0 auto;
      display:flex;
      flex-direction:column;
    }

    .added-images-layout{
      display:flex;
      flex-wrap:wrap;
      margin-bottom:1rem;
    }

    .add-product-by-seller-field-add-img-checkbox{
        width:80%;
      margin:1rem auto;
      display:flex;
      align-items:center;
      font-size:1.5rem;
      /* justify-content: center; */
    }

    .add-product-by-seller-field-single-line-inputs{
      width:90%;
      display:flex;
      flex-direction:row;
      align-items:center;
      justify-content:space-between;
      margin: 2rem auto;
    }
 

    .add-product-by-seller-field-single-line-input-field{
      display:flex;
      flex-direction: column;
    }

    .add-product-by-seller-field-single-line-input-field-name{
      
    }

    .add-product-by-seller-field-single-line-input-field-textfield{
      width:80%;

    }

    @media screen and (max-width: 480px){
      .add-product-by-seller-field-single-line-inputs{
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:space-between;
        margin: 1rem auto;
      }
      .add-product-by-seller-field-single-line-input-field-textfield{
        width:80%;
  
      }

      .add-product-by-seller-field-single-line-input-field{
        display:flex;
        flex-direction: column;
        width:80%;
      }
      
    .add-product-by-seller-field-single-line-input-field-textfield{
      width:100%;

    }
  }
    .products-page-button-container{
      display:flex;
      justify-content:flex-end;
      width:80%;
      margin:3rem auto 0 auto;;
    }
    .add-product-key-value-field{
      display:flex;
      width:80%;
      margin: 2rem auto;
      justify-content:space-between;
    }
@media screen and (max-width: 600px) {
  .add-product-by-seller-form {
    width: 100%;
    box-shadow: none;
  }

  .add-product-by-seller-field-with-unit-value {
    width: 75%;
  }

  .add-product-by-seller-field-with-unit-unit {
    width: 20%;
  }

}

.add-product-by-seller-field .css-1wc848c-MuiFormHelperText-root.Mui-error {
  position: absolute;
  color: #d32f2f;
  /* bottom: 37%; */
  font-size: 12px;
  left: 0%;
  top: 57px;
}

.seller-product-sku-delete-icon-container{
  width:90%;
  margin:0 auto;
  display:flex; 
  align-items:center;
  justify-content:flex-end;
}

.seller-product-sku-delete-icon:hover{
cursor:pointer;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

}


