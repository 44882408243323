

.admin-otp-verify-container {
    font-family: "Barlow";
    min-height: 100vh;
    max-width: 2000px;
    max-height: 1300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: conic-gradient(from 0deg at 50% 50%, rgba(212, 240, 240, 0.52) 0deg, #FFFFFF 154.87deg, rgba(212, 240, 240, 0.52) 360deg);
}
.admin-otp-verify-left{
    width: 50%;
    height: 100vh;
    background: white;
    /* max-width: 2000px; */
    max-height: 1300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.admin-otp-verify-right{
    width: 50%;
    height: 100vh;
    background: #F2F2F2;
    /* max-width: 2000px; */
    max-height: 1300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.OtpVerification-container-left-content{
    max-width: 2000px;
    max-height: 1900px;
    display: flex;
    justify-content: center;
    align-items: center;
  
}

.admin-otp-verify-note{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1rem;
    width: 54%;
}
.admin-otp-top-left-rect {
    position: fixed;
    min-width: 450px;
    /* width: 45vw; */
    height: 30%;
    left: -378px;
    top: -9.16px;
    z-index: -1;
    background: linear-gradient(119.9deg, rgba(255, 134, 82, 0.35) 63.99%, rgba(255, 140, 91, 1 ) 93.94%);
/* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
transform: matrix(0.98, -0.16, 0.19, 0.99, 0, 0);
}
.OtpVerification-container-right-content-admin{
    max-width: 2000px;
    max-height: 1900px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
    margin: 0 auto;
}
.admin-otp-btm-left {
    position: fixed;
    width: 220.4px;
    height: 79px;
    left: -3vw;
    /* top: 700px; */
    bottom: 3vh;
    background: linear-gradient(269.41deg, rgba(255, 77, 0, 0.43) 10.17%, rgba(255, 175, 140, 0.3) 94.57%);
/* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    border-radius: 40px;
    transform: rotate(-60deg);
}
.admin-otp-verify-note {
    width: 51%;
    text-align: center;
    justify-content: center;
    display: flex;
    margin-top: 10%;
    min-width: 300px
}
.admin-otp-btm-left-top {
    position: fixed;
    width: 177.19px;
    height: 79px;
    left: -5vw;
    /* top: 762.45px; */
    bottom: 3vh;
    /* z-index: -1; */
    background: linear-gradient(337.31deg, rgba(65, 72, 243, 0.58) 11.67%, rgba(175, 178, 255, 0.8) 93.51%);
/* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    border-radius: 40px;
    transform: rotate(-60deg);
}

.admin-otp-btm-left-bottom {
    position: fixed;
    width: 177.19px;
    height: 79px;
    /* left: 18px; */
    /* top: 822.45px; */
    bottom: -3vh;
    /* z-index: -1; */
    left: 0vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: linear-gradient(192.25deg, #B3FDFF 8.92%, rgba(11, 230, 236, 0.3) 80.44%);
/* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    border-radius: 40px;
    transform: rotate(-60deg);
}

.admin-otp-circle {
    position: fixed;
    width: 209px;
    height: 214px;
    right: 20vw;
    bottom: 1vh;
    border-radius: 50%;
    background: linear-gradient(207.66deg, #67DCDF 37.17%, rgba(79, 231, 235, 0) 81.8%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: -1;
}
.Admin-OtpVerification-container-left{
    background: #F2F2F2;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
width: 80%;
height: 100vh;
margin: 0 auto;
}
.OtpVerification-container-left-img{
    display: flex;
    justify-content: center;
    margin: 85px auto;
}
.admin-otp-top-right-rect {
    position: fixed;
    width: 30%;
    height: 30%;
    right: -50px;
    top: -50px;
    opacity: 0.2;
    background: linear-gradient(252.56deg, rgba(254, 103, 130, 0.82) 82.37%, #FF006B 93.95%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: matrix(0.98, -0.16, 0.19, 0.99, 0, 0);   
}

.admin-otp-verify-card {
    /* margin: 2% 3%; */
    width: 35%;
    min-width: 400px;
    /* min-height: 85%; */
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    transform: scale(0.8);
    /* transform-origin: 0 0; */
}

.admin-otp-verify-head {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2% 5%;
    /* font-size: 2.0vw; */
    font-size: 1.8rem;
    margin: 5% auto;
    color: rgba(248, 152, 128, 1);
    margin-top:10rem;


}

.admin-otp-verify-resend-otp{
    width:80%;
    display:flex;
    justify-content:space-evenly;
    align-items:center;
}

.admin-otp-verify-auth-text {
    color: rgba(248, 152, 128, 1);
    /* font-size:1.3vw; */
    font-size: 1.3rem;
    max-width: 85%;
    font-weight: 600;
    margin: 5% auto;
}
.admin-otp-numbers{
    /* max-width:400px; */
}
.admin-otp-verify-verify-otp {
    /* max-width:400px; */
    margin: 5% auto;
    /* font-size: 1.3vw; */
    font-size: 1.3rem;
    padding: 0.5rem 0.5rem;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
        padding:0.5rem 1.5rem;

}
    .admin-otp-verify-verify-otp:hover{
        cursor:pointer;
    }
    .admin-otp-verify-signUp {
        margin: 3% auto;
        font-weight: 600;
        /* font-size: 1.6vw; */
        font-size: 1.6rem;
        color: #000000;
    }

    .admin-otp-verify-signUp span {
        font-weight: 600;
        /* font-size: 1.4vw; */
        font-size: 1.4rem;
        text-decoration-line: underline;
        color: #019dbf;
    }
        .admin-otp-verify-signUp span:hover{
            cursor:pointer;
        }

        .admin-otp-verify-resend-otp {
            min-height: 13%;
            margin: 2% auto;
            font-weight: 600;
            /* font-size: 1.2vw; */
            font-size: 1.2rem;
            color: red;        }


            .admin-otp-verify-resend-otp-button:hover{
                cursor:pointer;
            }
    .admin-otp-verify-resend-otp span {
        /* display: none; */
        font-weight: 600;
        /* text-decoration-line: underline; */
        color: black;
    }
.admin-otp-verify-error{
    color:red;
    font-size:1rem;
}
.admin-otp-verify-terms {
    min-height: 13%;
    margin: 2% 5%;
    font-style: normal;
    font-weight: 600;
    /* font-size: 1.4vw; */
    font-size: 1.4rem;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
}

    .admin-otp-verify-terms span {
        color: #707070;
    }

.admin-otp-verify-logo {
    /* position: fixed; */
    position:absolute;
    /* margin: 1% 1%; */
    /* bottom:-50%; */
    top:-2.5rem;
    /* left:43%; */
    width:10rem;
    height:10rem;
    /* z-index: 100;; */
}

.admin-otp-verify-otp {
    width: 80%;
    /* align-self: center; */
    margin: 1% 5%;
    /* display:none; */
        padding:0.5rem 1rem;
/*  */
}

.admin-otp-verify-otp-verify {
    color: #000000;
    /* font-size:2vw; */
    font-size: 1rem;
    display: inline-block;
    margin: 2rem 1.2rem;
    width: 2rem;
    /* padding:0.5rem 1rem; */
    height: 12%;
    /* width:2rem; */
    /* height:1rem; */
    text-align: center;
    border-right: none;
    border-left: none;
    border-top: none;
}

    .admin-otp-verify-otp-verify:focus {
        outline: none;
    }

    .admin-otp-verify-otp-verify[type=number] {
        -moz-appearance: textfield;
    }

    .admin-otp-verify-otp-verify::-webkit-outer-spin-button,
    .admin-otp-verify-otp-verify::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .seller-color{
        color:#01b1bf;
    }
    .admin-reset-phone-icon {
        position: absolute;
        left: 20%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.6rem;
        top: 35%;
    }
    @media only screen and (max-width: 900px)  {
        .admin-otp-verify-right{
       display: none;
        }
        .admin-otp-verify-left{
            width: 100%;
        }
}
@media only screen and (max-width: 578px)  {

.admin-reset-phone-icon {
    position: absolute;
    left: 15%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
    top: 35%;
}}
.OtpVerification-container-right-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.otp-verify-resend{
    /* margin-top: 7%; */
    /* margin-bottom: 5%; */
    font-weight: 600;
    /* font-size: 1.2vw; */
    font-size: 14px;
    color: #FF5569;
    display: flex;
    gap: 1rem;
    justify-content: center;
    font-weight: 600;
    width: 100%;
}