.review-head{
    display: flex;
    justify-content: flex-start;
    font-family: Poppins;
font-size: 32px;
font-weight: 500;
line-height: 48px;
letter-spacing: 0em;
text-align: left;

}
.rating-fields-title{
    font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 27px;
letter-spacing: 0em;
text-align: left;
color: rgba(254, 60, 114, 1);

}
.start-field
.css-dqr9h-MuiRating-label {
    cursor: inherit;
    font-size: 50px;
}
.textarea-style {
    border: 1px solid #00000026;
    border-radius: 8px;
    gap: 10px;
    height: 70px;
    padding: 8px 8px;
    resize: none;
    width: 330px;
  }
  .start-field
  .css-34he1w-MuiRating-decimal {
    position: relative;
    width: 100%;
    margin: 0 auto;
}
.rating-fields-set{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 3rem;
}
.rating-title
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    width: 550px;
    height: 43px;
    min-width: 200px;
}
.custom-rating-star-field
.css-1ipqyij{
    font-size: 3.5rem;
    color: rgba(2, 159, 74, 1) !important;

}
.custom-rating-star-field
.css-1vooibu-MuiSvgIcon-root {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 57px;
    height: 57px;
    flex-shrink: 0;
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: inherit;
}
.custom-rating-star-field
.css-iljtu2-MuiRating-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    font-size: 1.5rem;
    color: #029F4A;
    cursor: pointer;
    text-align: left;
    -webkit-tap-highlight-color: transparent;
    width: 57px;
    height: 57px;
}
.order-details-product-info-buyer {
    width: 90%;
    display: flex;
    gap: 8px;
    /* margin-top: 16px; */
}
@media screen and (max-width: 480px){

.textarea-style {
    width: 280px;
    height: 100px;
    padding: 9px 10px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    gap: 10px;
    resize: none;
}
.order-details-product-info-buyer {
    width: 300px;
    display: flex;
    gap: 8px;
}
}
