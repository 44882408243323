.manage-form-field-seller{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* gap: 20px; */
    width: 74%;
    margin: 14px auto;
}
.seller-account-textfields-style
.css-1v4ccyo {
    font-weight: 400;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    width: 380px;
    font-size: 14px;
}
@media screen and (max-width: 480px) {
    .manage-form-field-seller {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 8px;
        width: 330px;
        margin: 18px auto;
        flex-direction: column;
    }
    .seller-account-textfields-style 
    .css-1v4ccyo {
        font-weight: 400;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
        color: rgba(0, 0, 0, 0.87);
        box-sizing: border-box;
        position: relative;
        cursor: text;
        display: inline-flex;
        align-items: center;
        position: relative;
        border-radius: 8px;
        width: 320px;
        font-size: 14px;
    }
}
