.add-seller-user-form{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.seller-added-list{
    display: flex;
    width: 85%;
align-items: center;
margin: 0 auto;
}
.add-seller-user-form-field{
    width:80%;
    margin:1rem auto;
    display:flex;
    align-items:center;
    justify-content:space-between;
    position:relative;
    letter-spacing: 0.03em;
    font-size: 20px;
}

@media screen and (max-width:480px){
    .add-seller-user-form{
        width:95%;
        margin:1rem auto;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius:5px;
        min-height:100px;
        display:flex;
        flex-direction:column;
    }
    .add-seller-user-form-field{
        flex-direction:column;
        width:100%;
    margin:1rem auto;
    }
}
.add-seller-user-form-field-name{
    width:30%;
    min-width:340px;
    font-size:1.2rem;
}
.add-seller-user-form-field-textfield{
    width:60%;
    min-width:340px;
}


.add-seller-user-form-buttons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    width: 94%;
}

.add-seller-user-form-field .css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    color: #d32f2f;
    bottom: -40%;
    font-size: 0.9rem;
    left: 0%;
}