.seller-dashboard-activities-field{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.visitors-card {
    /* width:100%; */
    background: rgba(255, 253, 216, 1);
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  
  }
  .seller-activities-icon {
    width: 45%;
    align-self: center;
    justify-content: center;
    font-size: 2.5rem !important;
    margin-left: 0.1rem;
  }
  .seller-activities-card {
    /* border: 0.1rem solid rgba(0, 0, 0, 0.2); */
    min-height: 6rem;
    min-width: 8rem;
    max-width: 15rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
    display: flex;
    padding: 0.2rem;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    border-radius: 4px;
}
  .seller-dashboard-button{
    width: 95%;
    height: 2rem;
    background: rgba(112, 112, 112, 0.2);
    border-radius: 5px;
    border: none;
    min-width: 100px;
  }
  .admin-dashboard-activities-icon {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    /* gap: 1rem; */
    width: 100%;
}.admin-dashboard-card-layout {
    background-color: white;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    padding: 20px;
    border-radius: 15px;
  }

  .seller-dashboard-table {
    width: 100%;
    border-collapse: collapse;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 0.5rem;
  }

  .seller-dashboard-table-th {
    /* background-color: #f2f2f2; */
    font-weight: bold;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    width: 79%;
    font-size: 12px;
  }

  .seller-dashboard-table-tr {
    display: flex;
    gap: 5rem;
    width: 80%;
    align-items: center;
    margin: 0 auto;
  }
  
  .seller-dashboard-table-tr:not(:first-child) {
    /* border-bottom: 1px solid #ddd; */
  }
  
  .seller-dashboard-table-tr:first-child {
    display: flex;
    gap: 5rem;
    align-items: center;
    width: 79%;
  }
  .seller-dashboard-table th,
  .seller-dashboard-table td {
    padding: 8px;
    text-align: left;
    display: flex;
    gap: 1.5rem;
    /* width: 60%; */
    align-items: center;
    font-size: 18px;
  }

  .seller-dashboard-table img {
    max-width: 100px;
    height: auto;
    margin-right: 5px;
    vertical-align: middle;
  }

  .seller-dashboard-table span {
    vertical-align: middle;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 0, 0, 0.7)
  }

  .seller-dashboard-table button {
    padding: 5px 10px;
    width: 169px;
    height: 36px;
    right: 161px;
    top: 1483px;
    background: rgba(112, 112, 112, 0.2);
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  .seller-dashboard-table-title{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-left: 5%;
    margin-top: 2%;

  }
  .admin-dashboard-card-layout-chart{
    width: 90%;
    /* margin: 2.5rem auto; */
    display: flex;
    justify-content: space-between;
    gap: 2.6rem;
    margin-bottom: -1%;
    margin-top: 2.6rem;
    margin-left: 3%;
  }
  .seller-dashboard-table-body{
    color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0.5rem;
    text-align: center;
    align-items: center;
}

@media (max-width: 480px) {
    .seller-dashboard-table th,
    .seller-dashboard-table td {
      display: block;
      text-align: left;
      width: 55%;
    }
  
    .seller-dashboard-table td {
      padding: 8px;
      line-height: 1.5;
    }
  
    .seller-dashboard-table td:first-child {
      padding-top: 16px;
    }
  
    .product-details {
      display: flex;
      align-items: center;
    }
  
    .product-details img {
      max-width: 80px;
      margin-right: 8px;
    }
  
    .seller-dashboard-button {
      margin-top: 8px;
    }
    .seller-dashboard-table-tr {
        /* border-bottom: 1px solid #ddd; */
        
        display: flex;
        gap: 0.1rem;
        width: 100%;
        align-items: center;
        margin: 0 auto;
    }
    .seller-dashboard-table-tr:last-child {
        border-bottom: none;
        display: flex;
        gap: 0.1rem;
        width: 80%;
        align-items: center;
    }
    .seller-dashboard-table button {
        padding: 5px 10px;
        width: 140px;
        height: 36px;
        right: 161px;
        top: 1483px;
        background: rgba(112, 112, 112, 0.2);
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }
    .admin-dashboard-card-layout {
        width: 95%;
        /* margin: 2.5rem auto; */
        display: flex;
        justify-content: space-between;
        gap: 2.6rem;
        margin-bottom: -1%;
        margin-top: 3.5rem;
        margin-left: 3%;
        height: 400px;
    }
    .seller-dashboard-table img {
      display: none;
    }
    .admin-dashboard-card-layout {
        width: 100%;
        /* margin: 2.5rem auto; */
        display: flex;
        justify-content: space-between;
        gap: 2.6rem;
        margin-bottom: -1%;
        margin-top: 3.5rem;
        margin-left: -6%;
    }
    .seller-dashboard-status-help {
      height: 230px;
      width: 30%;
      min-width: 400px;
      padding: 24px;
      border-radius: 15px;
      /* filter: drop-shadow(0px 4px 4px rgba(252, 38, 120, 0.3875)); */
      background: linear-gradient(359.65deg, #FF6155 2.24%, rgba(252, 38, 120, 0.3875) 157.4%, rgba(255, 97, 85, 0) 157.41%);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      /* margin-bottom: -49.5px; */
  }
  }
  .admin-dashboard-card-layout{
  width: 84%;
  margin: 2.5rem auto;
  display: flex;
  justify-content: space-between;
  gap: 2.6rem;
  margin-bottom: -5%;
  margin-top: 3.5rem;
  margin-left: 2%;
  }
.List
  .css-1qfpb3d-MuiListItem-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    display: block;
    background-color: white;
    border-radius: 0px;
    min-height: 3rem;
  }
  .seller-dashboard-card-layout-chart{
    min-width: 87%;
    max-width: 88%;
    display: flex;
    justify-content: space-between;
    gap:1.5rem;
 margin-top: 1.5rem;
 margin-left: 1%;

  }
  .seller-barChart{
    width: 75%;
    height: 300px;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    min-width: 450px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .seller-pieChart{
    width: 45%;
    height: 300px;
    min-width: 450px;
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .seller-dashboard-card-layout {
    width: 88%;
    /* margin: 2.5rem auto; */
    display: flex;
    justify-content: space-evenly;
    gap: 2.6rem;
    margin-bottom: 0%;
    margin-top: 1.5rem;
    margin-left: 2%;
    background-color: white;
    border-radius: 15px;
    min-height: 350px;
    max-width: 88%;
    margin-left: 1%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.seller-dashboard-welcome{
  display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    width: 80%;
}
.seller-dashboard-status{
  width: 65%;
    height: 230px;
    padding: 24px 16px;
    font-size: 14px;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    background-color: #FFFFFF;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    min-width: 450px;
    margin: 0 auto;
}
.seller-dashboard-status-help {
  height: 230px;
    width: 30%;
    min-width: 300px;
    padding: 24px;
    border-radius: 15px;
    /* filter: drop-shadow(0px 4px 4px rgba(252, 38, 120, 0.3875)); */
    background: linear-gradient(359.65deg, #FF6155 2.24%, rgba(252, 38, 120, 0.3875) 157.4%, rgba(255, 97, 85, 0) 157.41%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* margin-bottom: -49.5px; */
}
/* <div className="admin-dashboard-card-layout">

      <div className='admin-dashboard-top-sellers'>
        <div className='admin-dashboard-top-sellers-title'><h2> Top Sellers</h2></div>
        <div className='admin-dashboard-top-sellers-table'>
        <table>
          <thead>
            <tr>
              <th className='profile'>Profile</th>
              <th className='product'>Product</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td className='table-data'>
                  {item.profile}
                  <div>{item.profilePic}</div>
                  <div>{item.name}</div>
                </td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      </div>
      <div className='admin-dashboard-reviews'>
        <div className='admin-dashboard-reviews-title'><h2>Coustomer Reviews</h2></div>
        <div className='review-star-content'>
<span>⭐⭐⭐⭐</span>
<span>4 out of 5</span>
</div>
<div className="row" style={{padding:"1rem 1rem 1rem 1rem"}}>
{ratings.map((rating, index) => (
  <div key={index} className="row-excelent">
    <div className="side">
      <div>{rating.name}</div>
    </div>
    <div className="middle">
      <div className="bar-container">
        <div className={rating.barClass}></div>
      </div>
    </div>
    <div className="side right">
      <div>{rating.count}</div>
    </div>
  </div>
))}
</div>
      </div>

    </div> */
    @media (max-width: 767px) {
  .admin-dashboard-welcome {
    max-width: 82%;
    display: flex;
    flex-direction: column;
  }
  .seller-activities-cards {
display: none;
  }
  .seller-dashboard-card-layout-chart {
    display: flex;
    flex-direction: column;
    margin-left: 8%;
  }
  .seller-dashboard-table-field {
min-width: 350px;
  }
  .admin-dashboard-activities-icon {
   display: none;
}
.seller-dashboard-status-help  {
  height: 230px;
  width: 30%;
  min-width: 433px;
  padding: 24px;
  border-radius: 15px;
  /* filter: drop-shadow(0px 4px 4px rgba(252, 38, 120, 0.3875)); */
  background: linear-gradient(359.65deg, #FF6155 2.24%, rgba(252, 38, 120, 0.3875) 157.4%, rgba(255, 97, 85, 0) 157.41%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* margin-bottom: -49.5px; */
}
}
.admin-dashboard-status-help{
  height: 230px;
  width: 30%;
  min-width: 400px;
  padding: 24px;
  border-radius: 15px;
  /* filter: drop-shadow(0px 4px 4px rgba(252, 38, 120, 0.3875)); */
  background: linear-gradient(359.65deg, #FF6155 2.24%, rgba(252, 38, 120, 0.3875) 157.4%, rgba(255, 97, 85, 0) 157.41%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: -49.5px;
}
.seller-dashboard-status-help {
  height: 230px;
  width: 30%;
  min-width: 310px;
  padding: 24px;
  border-radius: 15px;
  /* filter: drop-shadow(0px 4px 4px rgba(252, 38, 120, 0.3875)); */
  background: linear-gradient(359.65deg, #FF6155 2.24%, rgba(252, 38, 120, 0.3875) 157.4%, rgba(255, 97, 85, 0) 157.41%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* margin-bottom: -49.5px; */
  margin: 0 auto;
  margin-left: 3%;
}