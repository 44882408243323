.buyer-otp-verify-right{
    width: 100%;
    background: white;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
}
.buyer-main-logo-field{
display: flex;
justify-content: center;
align-items: center;
margin: 0 auto;
}
.Myhraki-logo-buyer{
    width: 65%;
}
.otp-verify-resend-buyer{
    /* margin-top: 7%; */
    /* margin-bottom: 5%; */
    font-weight: 600;
    /* font-size: 1.2vw; */
    font-size: 18px;
    color:  #FF6155;
    display: flex;
    gap: 1rem;
    justify-content: center;
    font-weight: 600;
    width: 100%;
}
.OtpVerification-title-buyer{
    font-weight: 400;
    font-size: 30px;
    color: rgba(0,0,0,1);
}
.buyer-otp-verify-note{
    width: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
    /* margin-bottom: 3%; */
    /* min-width: 300px; */
    color: rgba(112, 112, 112, 0.72);
    font-size: 18px;
}
