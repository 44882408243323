.customer-new-all-order-detail {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.customer-new-order-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.customer-new-all-order-details-table {
  padding: 40px;
  background: hsla(27, 100%, 96%, 1);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.customer-new-all-order-table-row {
  display: flex;
  justify-content: space-between;
}
.customer-new-all-order-table-row2 {
  display: flex;
}

.customer-new-all-order-table-cell {
  flex: 1;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.customer-new-all-order-table-cell1 {
  flex: 1;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.customer-new-all-order-table-cell2 {
  flex: 1;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: hsla(0, 0%, 43%, 1);
}
.customer-new-all-order-table-cell-ord-det-btn {
  flex: 1;
  padding: 5px;
  display: flex;
  justify-content: center;
  border: 1px solid #fe585b;
  border-radius: 25px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
}
.customer-new-all-order-detail-date {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.customer-new-all-order-detail-day {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(148, 98%, 32%, 1);
}

.customer-new-all-order-img-details-section {
  width: 70%;
  display: flex;
}
.customer-new-all-order-img-details-section-review {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.CustomerNewCart-all-ord-imag-details-btn-sec {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.CustomerNewCart-all-ord-imag-details-btn-track {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
  border: 1px solid #fe585b;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  color: hsla(0, 0%, 100%, 1);
  border-radius: 25px;

  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  letter-spacing: 0.04em;
  cursor: pointer;

  color: hsla(0, 0%, 100%, 1);
}
.CustomerNewCart-all-ord-imag-details-btn-cancel {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  border: 1px solid #fe585b;
  color: #fd2583;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  padding: 5px;

  border-radius: 25px;
}
.cust-new-myacc-img-sec {
  width: 100%;
  display: flex;

  gap: 16px;
}
.customer-new-all-order-details-back-button {
  padding: 10px 0px 40px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.customer-new-all-orderdetails-table {
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.write-a-review-column-cust-new {
  display: flex;
  flex-direction: column-reverse;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
  padding-bottom: 22px;
}
