.Seller-ResetPassword_Container {
    width: 100%;
    margin: 0 auto;
   height: 100vh;overflow: hidden;
   display:flex;
   justify-content: center;
}
.seller-reset-left{
    display: flex;
    width: 50%;
    /* height: 100vh; */
}
.Seller-ResetPassword_RightScreen{
width: 50%;
background-color:white;
width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    text-align: center;

}
.Seller-ResetPassword_LeftScreen{
    width: 50%;
    background-color: #FFEDF1;
}
.seller-reset-password-icon {
    position: absolute;
    left: 60%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
    top: 40%;
}

.seller-reenter-password-icon{
position: absolute;
    left: 60%;
    color: rgba(112, 112, 112, 0.72);
    top: 55%;
    font-size: 1.6rem;
}
.seller-resetpassword-lock-icon{
    position: absolute;
    left: 60%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.5rem;
    top: 60%;
}
.admin-reset-screen-container {
    font-family: "Barlow";
    /* min-height: 100vh; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: conic-gradient(from 0deg at 50% 50%, rgba(212, 240, 240, 0.52) 0deg, #FFFFFF 154.87deg, rgba(212, 240, 240, 0.52) 360deg);
}





.admin-reset-card {
    /* margin: 2% 3%; */
    width: 35%;
    min-width: 400px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    padding-top:3%;
    transform: scale(0.8);
    /* transform-origin: 0 0; */

}


.toast-message {
    color: gray;
    font-size: 14px;
    min-width: 358px;
    width: 33%;
    background: rgba(202, 97, 44, 0.1);
    left: 0.3rem;
    margin: 0 auto;
}
.ResetPassword_RightScreen {
    width: 100%;
    max-height: 1600px;
    /* height: 100vh; */
    background: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    max-width: 2000px;
    margin: 0 auto;
}.ResetPassword_LeftScreen {
    background: #FFEDF1;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    width: 50%;
    /* height: 100vh; */
    max-height:1600px;
}
.admin-reset-page-form{
    width:100%;
    display:flex;
    flex-direction:column;
}
.admin-reset-page-email-field{
    width:100%;
    position:relative;
    margin:3rem auto;
}
.admin-reset-email .MuiOutlinedInput-root{
    align-self: center;
    width: 60%;
    /* border-radius: 100px; */
    min-height: 50px;
    height: 2.5rem; 
    background-color:white;
    padding-left: 15%;
    background-size: 8%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3% ;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
#email-helper-text{
    position:absolute;
    bottom:-15%;
    left:28%;
    font-size:1rem;
}


.admin-reset-email-icon {
    position: absolute;
    top:35%;
    left: 12%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.8rem;

}

.admin-reset-auth-text {
    color: rgba(248, 152, 128, 1);
    font-size: 1.2rem;
    max-width: 71%;
    font-weight: 600;
    margin: 4% auto;
}
.admin-reset-email-field {
    width: 75%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    margin:0 auto;
}
.admin-reset-email-validation-text {
    position:absolute;
    bottom:-8%;
    color: red;
    opacity:0.7;
    font-size: 0.8rem;
    padding-top:1rem;
}
.admin-reset-email {
    width: 100%;
    /* border-radius: 100px; */
    padding-left: 15%;
    background-size: 8%;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2vw;
    margin: 5% auto;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

    .admin-reset-email:focus {
        outline: none;
        border: 0.5px solid rgba(112, 112, 112, 0.5);
    }

.admin-reset-send-otp {
    margin: 8% 25%;
    font-size: 1.4rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}
.admin-reset-page-send-instructions {
    margin: 2rem auto;
    width:45%;
    font-size: 1.4rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}
.admin-reset-signUp {
    margin: 3% auto;
    font-weight: 600;
    font-size: 1.4rem;
    color: #000000;
}

    .admin-reset-signUp span {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2vw;
        text-decoration-line: underline;
        color: #019dbf;
    }

.admin-reset-login {
    margin: 2rem auto;
    font-weight: 600;
    /* font-size: 1.4vw; */
    font-size: 1.4rem;
    /* line-height: 2vw; */
    /* margin-bottom: 5rem; */
    color: #000000;
}

    .admin-reset-login span {
        font-weight: 600;
        /* font-size: 1.4vw; */
        font-size: 1.4rem;
        line-height: 2vw;
        text-decoration-line: underline;
        color: #019dbf;
    }

    .admin-reset-page-terms {
        font-style: normal;
        font-weight: 600;
        font-size: 1.3rem;
        margin: 3% 5%;
        text-align: center;
        color: rgba(112, 112, 112, 0.72);
    }
    
        .admin-reset-page-terms span {
            color: #707070;
        }

.admin-reset-logo {
    /* position: fixed; */
    position:absolute;
    /* margin: 1% 1%; */
    top:1rem;
    left:43%;
}
.admin-reset-card span:hover{
    cursor:pointer;
}
::placeholder{
    opacity:1;
    /* font-size:2vw; */
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media screen and (max-width:480px){
        .admin-reset-logo{
            top:-3.5rem;
            left:35%;
        }
        .Seller-ResetPassword_RightScreen{
            width: 100%;
        }
        .Seller-ResetPassword_LeftScreen{
            display: none;
        }
        .seller-reenter-password-icon{
            position: absolute;
            left: 18%;
            color: rgba(112, 112, 112, 0.72);
             top: 56%; 
             font-size: 1.6rem;
            }
            .seller-reset-password-icon {
                position: absolute;
                left: 19%;
                color: rgba(112, 112, 112, 0.72);
                font-size: 1.6rem;
                top: 44%;
            }
}


.toast-message
.Toastify__toast.Toastify__toast-theme--coloured.Toastify__toast--default.Toastify__toast--close-on-click{
    position: relative;
    min-height: var(--toastify-toast-min-height);
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-family: barlow;
    font-size: 18px;
    padding: 8px;
    border-radius: 15px;
    /* box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05); */
    display: flex;
    justify-content: space-between;
    max-height: var(--toastify-toast-max-height);
    overflow: hidden;
    font-family: var(--toastify-font-family);
    cursor: default;
    direction: ltr;
    z-index: 0; 
background-color:rgb(217, 214, 214); 
color: rgb(68, 67, 67);
}
.Toastify__progress-bar {
     position: absolute; 
     bottom: 0; 
    left: 0; 
  width: 100%; 
     height: 5px; 
    z-index: var(--toastify-z-index); 
     opacity: 0.7; 
     transform-origin: left; 
}
    .toast-message {
        background:none;
        color:rgb(247, 236, 236);
        font-size: 20px;
        padding: 30px 20px;
        min-width:400px;
        max-width: 400px;
        width:50%;
        margin-left:2rem;
        left:0.3rem;
        /* top:-5rem; */
        margin: 0 auto;
        margin-left: 55%;
    }
    .Toastify__close-button > svg {
        color:white;
        /* background-color: white; */
        border-radius: 100%;
    }

