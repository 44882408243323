.Blog-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
}

.Category-buttons-blog-field{
    display: flex;
    justify-content: center;
    gap: 160px;
}
.side-bar-components-title{
    font-family: Poppins;
font-size: 20px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: #FD2A80;
padding-left: 24px;
margin-top: 24px;

}
.Category-buttons-blog{
    border-radius: 8px;
background: linear-gradient(90deg, #FD2A80 0%, #FE575C 106.43%);
display: flex;
padding: 8px 16px;
align-items: flex-end;
gap: 8px;
color: #FFF;
border: none;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
cursor: pointer;
}
.Category-buttons-blog,
.Category-buttons-blog-plain {
    border-radius: 8px;
    background: white;
    color: #FD2A80;
    display: flex;
    padding: 8px 16px;
    align-items: flex-end;
    gap: 8px;
    border: none;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #FD2A80;
    text-transform: uppercase;
    cursor: pointer;
}

.Category-buttons-blog.active,
.Category-buttons-blog-plain.active {
    border-radius: 8px;
    background: linear-gradient(90deg, #FD2A80 0%, #FE575C 106.43%);
    display: flex;
    padding: 8px 16px;
    align-items: flex-end;
    gap: 8px;
    color: #FFF;
    border: none;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
}

.Category-buttons-blog-plain.active {
    background: linear-gradient(90deg, #FD2A80 0%, #FE575C 106.43%);
    color: white;
}

.Category-buttons-blog-field{
    margin-top: 48px;
    margin-bottom: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
}
.bloag-card-container{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;
    flex-direction: column;
    /* height: 434px; */
}
.blog-facts-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin: 0 auto;
    width: 1202px;
    margin-bottom: 32px;
}
.blog-card-img{
    width: 389px;
height: 283px;
flex-shrink: 0;
border-radius: 8px;
}
.blog-banner-img{
    width: 1280px;
    height: 380px;
    margin: 0 auto;
}
.blog-card-description{
    color: #3E3E3E;
    width: 330px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    max-height: 3em;
    overflow: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;
}
.detailed-blog-title{
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    text-transform: capitalize;
    max-width: 630px;
}
.detailed-blog-page-container{
    display: flex;
    justify-content: space-around;
    gap: 0px;
    /* flex-direction: column; */
    align-items: flex-start;
    width: 1280px;
    margin: 0 auto;
}
.Deatiled-page-image{
    width: 896px;
height: 604px;
flex-shrink: 0;
border-radius: 8px;
}
.detailed-blog-page-contenet{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 896px;
    /* margin-left: 40px; */
    margin-top: 24px;
}
.detailed-page-back-link{
    margin-top: 40px;
    /* margin-left: 40px; */
    /* margin-bottom: -20px; */
    width: 1280px;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
    cursor: pointer;
}

.para-detailed-blog p{
    width: "896px";
    color:"#000" ;
    font-size:" 18px"
}
.Detailed-blog-side-card{
    border-radius: 8px;
background: #FDF4ED;
width: 288px;
height:auto;
flex-shrink: 0;
margin-top:89px;
}
.blog-side-card-img{
    width: 240px;
height: 125px;
flex-shrink: 0;
border-radius: 8px;
}
.blog-side-card-description{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
   width: 240px;
   color: #3E3E3E;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.blog-side-card-Title{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
width: 220px;
}
.blog-side-card-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 auto;
cursor: pointer;
    gap: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.30);
}
.bloag-side-card-container{
    padding: 24px;
}
.para-side-bar-blog{
    color:rgba(0, 0, 0, 0.53);
    font-size:12px;
    margin-top: 8px;
    margin-bottom: 16px;
}
.blog-card-Title{
    width: 171px;
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
    font-weight: 500;
    text-transform: capitalize;
}

.blog-showmore-button{
    font-size: 21px;
    width: auto;
    line-height: 18px;
    background-color: transparent;
    border: none;
    color: #fe3c72;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -1%;
    margin-top: 30px;
}
.blog-pagination{
    display: flex;
    justify-content: center;
    margin-left: 0px;
}
.blog-pagination-count{
    display: flex;
    justify-content: center;
    align-items: center;
}

  
  .pagination-blog .MuiPaginationItem-root {
    color: #fe3c72;
    border-color: #fe3c72;
    background-color: white; /* Change the color of pagination items */
  }

   .pagination-blog .MuiPaginationItem-page.Mui-selected {
    background-color: #fe3c72; 
    color: white;
  }

@media screen and (min-width:1020px) and (max-width:1280px){
    .blog-banner-img{
        width: 1020px;
        height: 200px;
        flex-shrink: 0;
    }
    .blog-facts-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
        margin: 0 auto;
        width: fit-content;
        margin-bottom: 32px;
    }
    .pagination-blog .MuiPaginationItem-root {
        color: #fe3c72;
        border-color: #fe3c72;
        background-color: white; /* Change the color of pagination items */
      }
    
       .pagination-blog .MuiPaginationItem-page.Mui-selected {
        background-color: #fe3c72; 
        color: white;
      }
}

@media screen and (max-width: 1024px){
    .Category-buttons-blog-field {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 0px;
    }
    .blog-banner-img{
        width: 750px;
height: 200px;
flex-shrink: 0;
    }
    .blog-card-img {
        width: 171px;
        height: 125px;
    }
    .blog-card-description{
        width: 171px;
        color: #3E3E3E;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;

    }
    .blog-card-Title{
        width: 171px;
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .blog-facts-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 28px;
        margin: 0 auto;
        width: 75%;
        margin-bottom: 32px;
    }
    .Category-buttons-blog.active, .Category-buttons-blog-plain.active {
        border-radius: 8px;
        background: linear-gradient(90deg, #FD2A80 0%, #FE575C 106.43%);
        display: flex;
        padding: 8px 8px;
        align-items: flex-end;
        gap: 8px;
        color: #FFF;
        border: none;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        cursor: pointer;
    }
    .Category-buttons-blog, .Category-buttons-blog-plain {
        border-radius: 8px;
        background: white;
        color: #FD2A80;
        display: flex;
        padding: 8px 8px;
        align-items: flex-end;
        gap: 8px;
        border: none;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: 1px solid #FD2A80;
        text-transform: uppercase;
        cursor: pointer;
    }
    
    .bloag-card-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;
        cursor: pointer;
        flex-direction: column;
    }
    .Deatiled-page-image {
            width: 600px;
    height: 400px;
    flex-shrink: 0;
    }
    .detailed-blog-page-contenet {
        display: flex;
        flex-direction: column;
        gap: 16px;
width: 100%;
      margin: 0 auto;
      padding: 0px;
      margin-top: 1rem;
    }
    .para-detailed-blog{
        width: 650px;
        color: rgb(0, 0, 0);
        font-size: 14px;
    }
    .detailed-blog-title {
        color: #000;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.03em;
    }
    .detailed-page-back-link {
        margin-top: 1rem;
        margin-left: 18px;
        margin-bottom: 0px;
        cursor: pointer;
        width: 330px;
    }
    .detailed-blog-page-container {
        display: flex;
        justify-content: space-around;
        gap: 16px;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto;
        width: 90%;
    }
    .Detailed-blog-side-card {
        width: 373px;
        height: 255px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    .blog-side-card-content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 auto;
        gap: 0;
        cursor: pointer;
        border-bottom: none;
    }
    .bloag-side-card-container{
        display: none;

    }
    .Detailed-blog-side-card{
        display: none;
    }
    .pagination-blog .MuiPaginationItem-root {
        color: #fe3c72;
        border-color: #fe3c72;
        background-color: white; /* Change the color of pagination items */
      }
    
       .pagination-blog .MuiPaginationItem-page.Mui-selected {
        background-color: #fe3c72; 
        color: white;
      }
}
@media screen and (min-width: 481px) and (max-width: 750px){
    .blog-banner-img{
        width: max-content;
        height: 200px;
        flex-shrink: 0;
    }
    .blog-facts-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
        margin: 0 auto;
        width: fit-content;
        margin-bottom: 32px;
    }
    .pagination-blog .MuiPaginationItem-page {
        transition: none; /* Disable transitions for immediate color change */
      }
    .pagination-blog .MuiPaginationItem-root {
        color: #fe3c72;
        border-color: #fe3c72;
        background-color: white; /* Change the color of pagination items */
      }
    
       .pagination-blog .MuiPaginationItem-page.Mui-selected {
        background-color: #fe3c72; 
        color: white;
      }
      
      .pagination-blog .MuiPaginationItem-page:hover,
      .pagination-blog .MuiPaginationItem-page:focus {
        background-color: #fe3c72;
        color: white;
      }
      .pagination-blog .MuiPaginationItem-root.Mui-selected:active{
        background-color: #fe3c72;
        color: white;
      }
}
@media screen and (max-width: 480px){
    .Category-buttons-blog-field {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 0px;
    }
    .blog-banner-img{
        width: 360px;
height: 162px;
flex-shrink: 0;
    }
    .blog-card-img {
        width: 171px;
        height: 125px;
    }
    .blog-card-description{
        width: 171px;
        color: #3E3E3E;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;

    }
    .blog-card-Title{
        width: 171px;
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .blog-facts-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
        margin: 0 auto;
        width: 358px;
        margin-bottom: 32px;
    }
    .Category-buttons-blog.active, .Category-buttons-blog-plain.active {
        border-radius: 8px;
        background: linear-gradient(90deg, #FD2A80 0%, #FE575C 106.43%);
        display: flex;
        padding: 8px 8px;
        align-items: flex-end;
        gap: 8px;
        color: #FFF;
        border: none;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        cursor: pointer;
    }
    .Category-buttons-blog, .Category-buttons-blog-plain {
        border-radius: 8px;
        background: white;
        color: #FD2A80;
        display: flex;
        padding: 8px 8px;
        align-items: flex-end;
        gap: 8px;
        border: none;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: 1px solid #FD2A80;
        text-transform: uppercase;
        cursor: pointer;
    }
    
    .bloag-card-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;
        cursor: pointer;
        flex-direction: column;
    }
    .Deatiled-page-image {
        width: 340px;
        height: 232px;
        flex-shrink: 0;
    }
    .detailed-blog-page-contenet {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 330px;
      margin: 0 auto;
      padding: 0px;
      margin-top: 1rem;
    }
    .para-detailed-blog{
        width: 330px;
        color: rgb(0, 0, 0);
        font-size: 14px;
    }
    .detailed-blog-title {
        color: #000;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.03em;
    }
    .detailed-page-back-link {
        margin-top: 1rem;
        margin-left: 18px;
        margin-bottom: 0px;
        cursor: pointer;
        width: 330px;
    }
    .detailed-blog-page-container {
        display: flex;
        justify-content: space-around;
        gap: 16px;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto;
        width: 330px;
    }
    .Detailed-blog-side-card {
        width: 373px;
        height: 255px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    .blog-side-card-content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 auto;
        gap: 0;
        cursor: pointer;
        border-bottom: none;
    }
    .bloag-side-card-container{
        display: none;

    }
    .Detailed-blog-side-card{
        display: none;
    }
    .pagination-blog .MuiPaginationItem-root {
        color: #fe3c72;
        border-color: #fe3c72;
        background-color: white; /* Change the color of pagination items */
      }
    
       .pagination-blog .MuiPaginationItem-page.Mui-selected {
        background-color: #fe3c72; 
        color: white;
      }
      .pagination-blog .MuiPaginationItem-page {
        transition: none; /* Disable transitions for immediate color change */
      }
}
.detailed-blog-page-contenet p {
    font-size: 18px;
    margin: 10px auto;

}