.signup-container {
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  font-family: "Barlow";
  background: conic-gradient(
    from 115.11deg at 50% 50%,
    rgba(1, 186, 191, 0) 0deg,
    rgba(201, 246, 248, 0.46) 0.04deg,
    rgba(0, 233, 239, 0) 201.75deg,
    rgba(1, 186, 191, 0) 360deg
  );
  z-index: 2;
  /* max-height:100vh; */
}
.seller-signup-container{
  background: #FFFFFF;
}
.signup-page-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#signup-firstName-helper-text,
#signup-lastName-helper-text,
#signup-email-helper-text {
  position: absolute;
  bottom: -25%;
  left: 10%;
  font-size: 1rem;
}
#signup-password-helper-text {
  position: absolute;
  bottom: -25%;
  left: 10%;
  font-size: 1rem;
  /* margin-top:1rem; */
}
#signup-number-helper-text {
  position: absolute;
  bottom: -25%;
  left: 10%;
  font-size: 1rem;
}

.signup-email .MuiOutlinedInput-root {
  align-self: center;
  width: 90%;
  border-radius: 4px;
  min-height: 50px;
  height: 2.5rem;
  background-color: white !important;
  background: none;
  padding-left: 15%;
  background-size: 3%;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 3%;
  color: rgba(112, 112, 112, 0.72);
  /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
}
.signup-number .MuiOutlinedInput-root {
  align-self: center;
  background-color: #fff!important;
  background: none;
  background-size: 3%;
  border-radius: 4px;
  color: hsla(0,0%,44%,.72);
  font-size: 1.2rem;
  font-weight: 400;
  height: 2.5rem;
  margin: 2%;
  min-height: 50px;
  padding-left: 15%;
  width: 90%;
  /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
.signup-firstName .MuiOutlinedInput-root {
  align-self: center;
  width: 90%;
  border-radius: 4px;
  min-height: 50px;
  height: 2.5rem;
  background-color: white !important;
  background: none;
  padding-left: 15%;
  background-size: 3%;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 3%;
  color: rgba(112, 112, 112, 0.72);
  /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
.Mui-focused .MuiOutlinedInput-notchedOutline:hover {
  border: 1px solid red;
  /* border-radius: 5px 5px 0 0; */
}

.signup-lastName .MuiOutlinedInput-root {
  align-self: center;
  width: 90%;
  border-radius: 10px;
  min-height: 50px;
  height: 2.5rem;
  background-color: white !important;
  background: none;
  padding-left: 15%;
  background-size: 3%;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 3%;
  color: rgba(112, 112, 112, 0.72);
  /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
.signup-password .MuiOutlinedInput-root {
  align-self: center;
  width: 90%;
  border-radius: 10px;
  min-height: 50px;
  height: 2.5rem;
  background-color: white !important;
  background: none;
  padding-left: 15%;
  background-size: 3%;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 3%;
  color: rgba(112, 112, 112, 0.72);
  /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

.signup-top-left-circle {
  position: fixed;
  left: -1%;
  top: -1%;
  width: 18%;
  height: 30%;
  border-radius: 50% 50%;
  background: linear-gradient(
    113.99deg,
    rgba(255, 239, 209, 0.73) 37.29%,
    rgba(248, 152, 128, 0) 101.11%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.signup-bottom-center-circle {
  position: fixed;
  width: 15%;
  height: 30%;
  right: 37%;
  bottom: -1%;
  border-radius: 50% 50%;
  background: linear-gradient(
    231.1deg,
    rgba(252, 200, 163, 0.88) 21.05%,
    rgba(255, 136, 200, 0) 77.67%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.signup-top-right-rect {
  position: fixed;
  width: 30%;
  height: 30%;
  left: 65%;
  top: -15%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(
    78.74deg,
    rgba(255, 134, 82, 0.2) 16.19%,
    rgba(255, 107, 0, 0) 61.89%
  );
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transform: matrix(0.98, -0.16, 0.19, 0.99, 0, 0);
}

.signup-bottom-right-shape {
  position: fixed;
  width: 25%;
  height: 10%;
  right: -12%;
  bottom: 0%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: conic-gradient(
    from 265.63deg at 42.76% 39.83%,
    rgba(186, 0, 251, 0) 0deg,
    #fde4ff 175.5deg,
    #e1a2ff 201.75deg,
    rgba(186, 0, 251, 0) 360deg
  );
  border-radius: 40px 40px;
  transform: rotate(-155deg);
}

.signup-container {
  display: flex;
  height: 100%;
}
.signup-card-container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
  transform-origin: 0 0;
  height: 100%;
  align-self: center;
}

.signup-card {
  width: 45vw;
  margin-left: 18%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  z-index: 1;
  min-width: 400px;
  justify-content: center;
  padding-top: 2rem;
}
.seller-signup-card{
  background: rgba(201, 246, 248, 0.46);

}

.signup-head {
  font-weight: 600;
  font-size: 2.4rem;
  margin: 3% auto;
  color: rgba(248, 152, 128, 1);
  background: -webkit-linear-gradient(rgba(255, 144, 63, 1) rgba(189, 0, 255, 0));
  background-clip: text;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.seller-signup-head {
  /* font-weight: 600;
  font-size: 2.4rem;
  margin: 3% auto;
  color: #FF2171; */
  font-weight: 600;
    font-size: 2rem;
    color: #FF2171;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 24%;
    margin-top: 5%;
}

.signup-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3% 1%;
  gap: 20px;

}
.signup-first {
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup-firstName-icon {
  position: absolute;
  left: 12%;
  color: rgba(112, 112, 112, 0.72);
  font-size: 1.3rem;
}
.signup-firstname-validation-text {
  position: absolute;
  bottom: -30%;
  color: red;
  font-size: 0.8rem;
  padding-top: 1rem;
  opacity: 0.7;
}

.signup-last {
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-last-name-icon {
  position: absolute;
  left: 12%;
  color: rgba(112, 112, 112, 0.72);
  font-size: 1.3rem;
}
.signup-lastname-validation-text {
  position: absolute;
  bottom: -30%;
  color: red;
  font-size: 0.8rem;
  padding-top: 1rem;
  opacity: 0.7;
}
.signup-last-name {
  width: 80%;
  /* border-radius: 100px; */
  /* min-height: 20px; */
  /* height:80%; */
  min-height: 45px;
  /* padding-left: 15%; */
  background-size: 8%;
  font-weight: 400;
  font-size: 1.2rem;
  margin: 2% auto;
  color: rgba(112, 112, 112, 0.72);
  border: 0.5px solid rgba(112, 112, 112, 0.5);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.signup-number-field {
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-number-icon {
  position: absolute;
  left: 12%;
  color: rgba(112, 112, 112, 0.72);
  font-size: 1.3rem;
}

.signup-email-field {
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-email-icon {
  position: absolute;
  left: 12%;
  color: rgba(112, 112, 112, 0.72);
  font-size: 1.3rem;
}
.signup-email-validation-text {
  position: absolute;
  bottom: -30%;
  color: red;
  font-size: 0.8rem;
  padding-top: 1rem;
  opacity: 0.7;
}
/* .signup-email {
    width: 80%;
    border-radius: 100px;
    height: 80%;
    min-height: 40px;
    padding-left: 15%;
    background-size: 8%;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2vw;
    margin: 2% auto;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
} */
.signup-password-field {
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #FFFFFF; */
}

.signup-password-icon {
  position: absolute;
  left: 12%;
  color: rgba(112, 112, 112, 0.72);
  font-size: 1.3rem;
}

.signup-calendar-field {
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;}

.signup-date-icon {
  position: absolute;
  left: 12%;
  color: rgba(112, 112, 112, 0.72);
  font-size: 1.3rem;
}
.signup-calendar-seller {
  width: 95%;
  height: 54px;
  border-radius: 4px;
  height: 80%;
  min-height: 45px;
  padding-left: 5%;
  background-size: 8%;
  /* font-weight: 400; */
  font-size: 16px;
  line-height: 2vw;
  margin: 2% auto;
  color: rgb(112 112 112 / 31%);
  border: 0.5px solid rgba(112, 112, 112, 0.5);
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
.signup-calendar:focus {
  border-color: rgba(248, 152, 128, 1) !important;
}

.signup-gender {
  font-weight: 600;
  font-size: 2rem;
  margin: 2rem 2rem;
  color: #FF2171;
  align-self: flex-start;
  margin-top: 2%;
}
/* .MuiFormHelperText-root {
  bottom: -8%;
  font-size: 0.8rem;
  left: 1%;
  position: absolute;
} */

.seller-reset-page-email-field
.MuiFormHelperText-root {
  bottom: -19%;
  font-size: .8rem;
  left: 18%;
  position: absolute;
}
.admin-reset-page-email-field
.MuiFormHelperText-root {
  bottom: -8%;
  font-size: .8rem;
  left: 18%;
  position: absolute;
}
.add-seller-user-form-field-textfield
.MuiFormHelperText-root {
  bottom: -37%;
  font-size: .8rem;
  left: 2%;
  position: absolute;
}
.admin-invite-form-field-textfield
.MuiFormHelperText-root {
  bottom: -37%;
  font-size: 12px;
  left: 8%;
  position: absolute;
}
.seller-personal-data-form-field .css-1wc848c-MuiFormHelperText-root.Mui-error {
  position: absolute;
  color: #d32f2f;
  bottom: -13%;
  font-size: 0.8rem;
  left: 0%;
}
/* .MuiFormGroup-root {
  margin-left: 2rem;
} */

.signup-gender-radio {
  width: 100%;
  margin: 0 2%;
  font-size: 2rem;
color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-self: flex-start;
  /* min-height: 5vh; */
  margin-left: 3rem;
}
.signup-male {
  margin-right: 3rem;
  font-size: 3rem;
  /* padding:200px; */
}

.signup-terms {
  margin: 3rem 1rem;
  font-size: 1.2rem;
  color: rgba(112, 112, 112, 0.72);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.signup-logo {
  height: 5rem;
  width: 5rem;
}
#signup-terms-helper-text {
  position: absolute;
  /* height:1rem; */
  bottom: -20%;
}

.signup-terms span {
  color: #707070;
}

.signup-button {
  align-self: center;
}
.signup-signup-btn {
  margin-top: 2%;
  /* margin-left:35%; */
  font-size: 1.2rem;
  padding: 1% 5%;
  color: rgba(52, 108, 116, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: none;
  color: white;
  align-self: center;
  /* min-height: 5vh; */
}
.signup-signup-btn:hover {
  cursor: pointer;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
}

.signup-signin {
  /* margin: 3% auto; */
  /* font-weight: 600;
  font-size: 1rem;
  color: rgba(112, 112, 112, 0.72); */
}
.signup-signin {
  margin: 20px auto;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01rem;
  color: rgba(112, 112, 112, 0.72);
  margin-left: 16%;
  /* width: 100%; */
  letter-spacing: 0.1px;
}
.signup-signin span {
  font-weight: 600;
  text-decoration-line: underline;
  color: #019dbf;
}
.signup-signin span:hover {
  cursor: pointer;
}

.signup-welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 15%;
  /* margin-top: 5%; */
  z-index: 1;
  /* transform: scale(0.9); */
  /* transform-origin: 0 0; */
  position:relative;
bottom:5rem;
}

.signup-welcome-head {
  color: rgba(52, 108, 116, 1);
  margin-top: 18%;
  /* margin-bottom: 8%; */
  font-size: 3rem;
  font-weight: 600;
}

.signup-welcome-name {
  color: rgba(52, 108, 116, 1);
  /* margin-top: 18%; */
  margin-bottom: 8%;
  font-size: 4rem;
  font-weight: 600;
}
.seller-signup-welcome-card{
  min-height:15rem;
  width:100%;
  /* min-width:30rem; */
  background: linear-gradient(54.45deg, #FFFFFF 51.91%, rgba(8, 234, 238, 0.3) 100.09%);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
transform: matrix(1, 0, 0.02, 1, 0, 0);
display:flex;
flex-direction:column;
align-items:center;
justify-content:space-around;
/* margin:0 auto; */

}


.signup-explore {
  font-weight: 600;
  font-size: 1.4rem;
  /* line-height: 2vw; */
  margin: 3%;
  color: rgba(52, 108, 116, 1);
}

.signup-shop {
  width: 40%;
  height: 30%;
  margin-left: 20%;
}

.signup-male {
  margin-right: 15%;
  font-size: 3rem;
}

.signup-female {
  margin-right: 15%;
}
::placeholder {
  font-size: 1rem;
}

/* Radio Button Font Sizes */
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 1.4rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  width: 100%;
  /* padding: 0px; */
  /* margin: 0px; */
  position: absolute;
  left: 10px;
  background: none;
  font-size: 0.6rem;
  font-family: "Barlow";
  font-weight: "200";
  opacity: 0.5;
  color: rgba(112, 112, 112, 0.72);
}



@media only screen and (max-width: 912px) {
  .signup-container {
    flex-direction: column-reverse;
  }

  .signup-card {
    width: 70%;
    margin-left: 0;
  }

  .signup-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2% 0%;
    gap: 1rem;
    min-width: 264px;
    margin-left: 15%;
    flex-direction: column;
  }
  .signup-row > * {
    width: 100%;
    min-height: 40px;
  }

  .signup-firstname-validation-text {
    bottom: -15%;
  }

  .signup-lastname-validation-text {
    bottom: -15%;
  }

  .signup-password-validation-text {
    bottom: -15%;
  }

  .signup-phone-validation-text {
    bottom: -15%;
  }

  .signup-email-validation-text {
    bottom: -15%;
  }
  .signup-signin {
    margin: 11px auto;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01rem;
    color: rgba(112, 112, 112, 0.72);
    margin-left: 16%;
    width: 94%;
    letter-spacing: 0.1px;
}
}

@media only screen and (max-width: 480px) {

.signup-container{
  max-height:100%;
  overflow: scroll;
}

  .signup-welcome-head {
    /* margin-top:3rem; */
    font-size: 1.6rem;
   
  }
  .signup-welcome {
    bottom:0;
    margin-left:0;

  }

  .signup-welcome-name {
    color: rgba(248, 152, 128, 1);
    /* margin-top: 18%; */
    margin-bottom: 5%;
    font-size: 2rem;
    font-weight: 600;
  }
  .seller-signup-welcome-card{
margin:0 auto;

}
.signup-signin {
  margin: 11px auto;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01rem;
  color: rgba(112, 112, 112, 0.72);
  margin-left: 16%;
  width: 94%;
  letter-spacing: 0.1px;
}
}
