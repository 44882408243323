.lv-common-btn{
    padding: 0.75rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 1.1rem;
    align-self: center;
    margin: 1rem;
    border-radius: 5px;
    float: right;
    text-align: center;
   background: rgba(255, 85, 105, 1) ;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}
.lv-approve-btn{
    padding: 0.75rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 1.1rem;
    align-self: center;
    margin: 1rem;
    border-radius: 5px;
    float: right;
    text-align: center;
    background: linear-gradient(0deg, #18BF5B, #18BF5B),
    linear-gradient(0deg, rgba(112, 112, 112, 0.5), rgba(112, 112, 112, 0.5));    
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}
.lv-reject-btn{
    padding: 0.75rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 1.1rem;
    align-self: center;
    margin: 1rem;
    border-radius: 5px;
    float: right;
    text-align: center;
    background: rgba(255, 85, 105, 1);      
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}