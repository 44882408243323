.manage-profile-form {
    max-width: 1000px;
    margin: 0rem auto;
    min-height: 30rem;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 0;
    width:100%;
}
.manage-profile-heading{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 152px;
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
}
.manage-form-name-fields {
    display: flex;
    justify-content: flex-start;
    /* gap: 1rem; */
    /* flex-direction: column; */
    width: 80%;
    align-items: center;
}
.manage-profile-head
 h3{
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: left;
    
}
.manage-form-field {
    align-items: center;
    display: flex;
    justify-content: space-between;
    /* margin: auto; */
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.manage-form-field-name {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(45, 11, 22, 1);
    width: 30%;
    
}

.manage-form-field-textfield {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.manage-form-field-textfield-name>*{
    width: 75%;
}
.manage-form-field-textfield>* {
    width: 90%;
    max-width: 90%;
}
.manage-form-field-textfield-name{
    width: 41%;
}
.manage-form-field .css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    color: #d32f2f;
    bottom: -35%;
    font-size: 12px;
    left: 0%;
}
.manage-form-field-textfield
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    /* color: rgba(176, 176, 176, 1); */
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    height: 48px;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border: 0px solid rgba(176, 176, 176, 1);
    border-radius: 4px;
    width: 561px;


}
.details-verify-link{
    display: flex;
    justify-content: flex-end;
}
.details-verify-link{
    cursor: pointer;
}
.manage-profile-container{
    padding: 3rem;
}
.verify-button{
    cursor: pointer;
    margin-left: 10px; 
}
.Eamil-verifed-link{
    position: absolute;
     width: 215px; 
     right: 0px;
      color:#FD2A80;
      cursor:pointer ;
      font-size:16px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      /* text-decoration:underline; */
}
.Eamil-verify-link{
    position: absolute;
    width: 215px; 
    right: 0px;
     color:#FD2A80;
     cursor:pointer ;
     font-size:16px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration:underline;
}
@media screen and (max-width:950px) {
    .manage-form-field {
        min-width: 50%;
        flex-direction: column;
    }
    .Eamil-verifed-link{
        position: absolute;
        width:160px!important;
        right: -20px;
        color: #FD2A80;
        cursor: pointer;
        font-size: 16px;
        /* text-decoration: underline; */
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        top:-20px;
    }
    .Eamil-verify-link{
        position: absolute;
        width:160px!important;
        right: 0px;
         color:#FD2A80;
         cursor:pointer ;
         font-size:16px;
         display: flex;
         justify-content: center;
         align-items: center;
         text-decoration:underline;
    }
    .manage-form-field-textfield {
        min-width: 340px;
        /* margin: 0 auto; */
    }

    .manage-form-field-name {
        width: 95%;
    }
    .manage-form-field-textfield>* {
        width: 350px;
    }
    .manage-form-field {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width:400px;
        margin: 0.5rem auto;
    }
    .manage-form-name-fields {
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        flex-direction: column;
        width: 95%;
    }
    .manage-form-field-textfield
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    min-width: 345px;
}
.manage-form-field-textfield .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    width: 345px;
}
.manage-profile-form {
    max-width: 1000px;
    margin: 0rem auto;
    min-height: 30rem;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 90%;
}
}
