.admin-sidebar .css-hyum1k-MuiToolbar-root {
    
    background: white;
}
.selected-item {
  background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
}
.css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper{
    background-color: green;
}
.css-hyum1k-MuiToolbar-root {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    min-height: 56px;
    background: white;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}
.admin-navbar-sidebar
.css-ofpr6p-MuiDrawer-docked .MuiDrawer-paper {
    width: 240px;
    background-color: #F2F2F2!important;
    -webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    overflow-x: hidden;
    color: rgba(0, 0, 0, 0.6);
}
.admin-navbar-sidebar
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
 
    color: rgba(0, 0, 0, 0.6);
    background: #F2F2F2;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    z-index: 1200;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    outline: 0;
    left: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.sideBarIcon{
    height: 22px;
}
.admin-navbar-sidebar
.css-1n4k1is {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-shrink: 0;
    position: fixed;
    top: 0px;
    left: auto;
    right: 0px;
    color: rgb(255, 255, 255);
    z-index: 1201;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-left: 240px;
    width: calc(100% - 0px);
    background: #f2f2f2f2;
}

.admin-navbar-sidebar .css-hyum1k-MuiToolbar-root {
    background-color: #F2F2F2 !important;
  }
  .admin-navbar-sidebar .css-cc01sb {
    background: #F2F2F2 !important;
  }
  .admin-navbar-sidebar .css-koxw2w {
        background: #F2F2F2;
  
  }
  .admin-navbar-sidebar 
.css-1ontqvh {
  list-style: none;
  margin: 0;
  padding: 0px 0px;
  position: relative;
}
.admin-navbar-sidebar 
.css-1qoqi26-MuiDrawer-docked .MuiDrawer-paper {
  width: 240px;
  background-color:  #F2F2F2!important;
  -webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow-x: hidden;
}
.admin-navbar-sidebar 
.css-1mky8oy-MuiDrawer-docked .MuiDrawer-paper {
  background-color: #F2F2F2!important;
  -webkit-transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow-x: hidden;
  width: calc(56px + 1px);
}
.admin-navbar-sidebar .MuiBox-root .css-k008qs
.css-i9gxme {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background: #F4F4F4;
}
.admin-navbar-sidebar 
.css-1qoqi26-MuiDrawer-docked .MuiDrawer-paper {
  width: 240px;
  background-color:  #F2F2F2!important;
  -webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow-x: hidden;
}
.admin-navbar-sidebar 
.css-1mky8oy-MuiDrawer-docked .MuiDrawer-paper {
    background-color: #F2F2F2!important;
    -webkit-transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    overflow-x: hidden;
    width: calc(56px + 1px);
    border: none;
  }