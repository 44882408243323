.buyer-card-container-screen {
    width: 100%;
    max-height: 100%;
    height: 100vh;
    overflow: hidden; 
    /* margin: 1% auto; */
    /* box-shadow:2px 2px 2px 2px rgba(0.12, 0.12, 0.25, 0.12); */
    /* background: linear-gradient(to right, rgba(259, 221, 227, 0.9) 9%, rgba(259,221, 4, 0.1) 100%); */
    /* background-image: linear-gradient(to bottom right, rgba(254, 237, 246, 1), rgba(252, 240, 226, 1)), url(../Images/gif2.webp); */
    /* background-image: linear-gradient(to bottom right, rgba(259, 221, 227, 0.9), rgba(252, 240, 226, 1)), url(http://localhost:3000/static/media/gif2.b7d5168….webp); */
    /* background-image: linear-gradient(to bottom right, rgba(254, 237, 246, 1), rgba(252, 240, 226, 1)), url(../Images/gif2.webp); */
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .buyer-card-container-content {
    width: 90%;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 8% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    max-height: 1200px;
  }

  .card-container-left,
  .buyer-card-container-right {
    flex: 1 1;
    overflow: hidden;
    /* background: white; */
    border-radius: 20px;
    margin: 0px 2%;
    height: 660px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4rem;
    max-width: 1200px;
    max-height: 1200px;
  }
  
 .buyer-card-content-img-first {
    flex: 1;
    /* border-radius: 0px 35px 35px 0px; */
    border-radius: 20px 0px 0px 20px;
    background: #FFC5AD;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 660px; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-x: scroll;
    width: 100%;
 
  }
  
  .buyer-card-content-img{
    flex: 1;
    /* background: linear-gradient(358.67deg, #FF6155 -0.13%, rgba(252, 38, 120, 0.3875) 102.79%, rgba(255, 97, 85, 0) 130.4%); */
    background-size: cover;
    background: #FFC5AD;
    background-position: center;
    background-repeat: no-repeat;
    height: 660px; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 20px 20px 0px;
    flex-direction: column;
    gap: 2rem;
  }
  @media only screen and (max-width: 990px) {
    
    .buyer-card-container-right {
      width: 100%;
    }.card-content-img-first {
      display: none;
    }
    
  .buyer-card-content-img {
    display: none;
  }

  }
  
  @media only screen and (max-width: 590px) {
    .buyer-card-container-content {
      margin: 4% auto;
    }
    .buyer-card-container-right {
      width: 100%;
    }
  .seller-accept-buttons{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 .buyer-card-content-img-first {
      display: none;
    }
    
    .buyer-card-content-img {
      display: none;
  }}
  @media screen and (max-width:480px) {
    
  .cardContentImgStyle{
    display: none;
  }
  .card-container-left{
    width: 100%;
  }
 .buyer-card-content-img-first{
    display: none;
  }
  .buyer-card-container-content {
    width: 95%;
  
  }
}
/* @keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.imageFirst .card-content-img-first,
.imageFirst .buyer-card-container-right {
  animation: slideInFromLeft 1s ease-in-out;
}

.card-container-right,
.card-content-img {
  animation: fadeIn 1s ease-in-out;
}
.animated {
  animation-fill-mode: both;
} */
/* background: linear-gradient(to right, rgba(259, 221, 227, 0.9) 9%, rgba(0,0, 0, 0.2) 100%);
box-shadow: 6px 0 10px -5px rgba(0, 0, 0, 0.3); */
/* background: linear-gradient(to right, rgba(259, 221, 227, 0.9) 9%, rgba(259,221, 4, 0.1) 100%); yellowish
    box-shadow: 6px 0 10px -5px rgba(159, 221, 259); p+g*/ 
    /* background: linear-gradient(to right, rgba(259, 210, 222, 0.9) 9%, rgba(5,65, 151, 0.1) 100%); */
    /* linear-gradient(359.65deg, #f2eded 2.24%, rgba(110, 68, 129, 0.3875) 147.4%, rgba(280, 105, 90, 0) 157.48%) */
    @media only screen and (max-width: 1000px){
      .buyer-card-content-img-first{
          display: none;
      }
  
  }