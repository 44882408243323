.manage-user-filter{
    display:flex;
    /* align-items: center; */
    justify-content:space-between;
}
.manage-users-dropdown{
    min-width:60%;
    margin: 0 auto;
}
.manage-users-filter-searchbar{
    min-width:30%;
    position:relative;
    margin: 0 auto;
}

.manage-user-search-icon {
  color: grey;
  left: 0.8rem;
  position: absolute;
  top: 1rem;
}
.manage-users-filter-searchbar
.manage-user-search-icon {
  color: grey;
  left: 1rem;
  position: absolute;
  top: 1rem;
}
.manage-users-filter-searchbar
.css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  left: 4%;
  /* top: -46%; */
}
.manage-users-textfield>*{
    padding-left:2rem;
}
.manage-users-name-section{
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}

.admin-manage-users-table {
    width:70%;
    margin: auto;
    min-width: 350px;
    max-width: 100%;
     border-collapse:separate; 
    border-spacing: 0 1em;
  }
  
  .admin-manage-users-table tr:first-child {
    /* border-top: none; */
    text-align: center;
    background: white;
    color: black;
  }
  
  .admin-manage-users-table tr {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .admin-manage-users-table tr:nth-child(odd):not(:first-child) {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .admin-manage-users-table th {
    display: none;
  }
  
  .admin-manage-users-table td {
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-manage-users-table td:first-child {
    margin-top: .5em;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-manage-users-table td:last-child {
    margin-bottom: .5em;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-manage-users-table td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 120px;
    display: inline-block;
    color: black;
  }

  
  .admin-manage-users-table {
    color: #333;
    border-radius: .4em;
    overflow: hidden;
  }
  
  .admin-manage-users-table tr {
    background: #FFFFFF;
   }
  
  .admin-manage-users-table th,
  .admin-manage-users-table td {
    padding: .5em 1em;
  }
  
 
  .admin-manage-users-table tr:first-child , .admin-manage-users-table td:first-child { border-top-left-radius: 10px; }
  .admin-manage-users-table tr:first-child , .admin-manage-users-table td:last-child { border-top-right-radius: 10px; }
  
  .admin-manage-users-table tr:last-child ,.admin-manage-users-table td:first-child { border-bottom-left-radius: 10px; }
  .admin-manage-users-table tr:last-child,  .admin-manage-users-table td:last-child { border-bottom-right-radius: 10px; }
  
  @media screen and (max-width: 601px) {
   .admin-manage-users-table tr:nth-child(2) {
      border-top: none;
    }
  
    .admin-manage-users-table tr {
      background: #fafafa;
      border: 1px solid rgba(0, 0, 0, 0.2);
     }
  
    .admin-manage-users-table td {
      display: block;
      border: none !important;
      display: flex;
      justify-content:space-between;
    }
    .admin-manage-users-table td:first-child {
      border: none !important;
    }
    
    .admin-manage-users-table td:last-child {
      border: none !important;
    }
    
    .admin-manage-users-table {
      width:95%;
      margin:0 auto;
      border-collapse: collapse;
      border-spacing: 0 1em;
    }
    .data-th {
      width: 50%;
      gap: 2rem;
    }
    .admin-category-addition-field{
        flex-direction: column;
        gap:2rem;
    }
  }
  @media screen and (min-width: 600px) {
  
    
  
    .admin-manage-users-table {
      width:80%;
      margin:0 auto;
    }
    .admin-manage-users-table tr:hover:not(:first-child) {
      /* background-color: #d8e7f3; */
      cursor:pointer;
    }
    .admin-manage-users-table td:before {
      display: none;
    }
    .admin-manage-users-table th,
   .admin-manage-users-table td {
      display: table-cell;
      padding: .25em .5em;
    }
    .admin-manage-users-table th:first-child,
  .admin-manage-users-table td:first-child {
      padding-left: 0;
    }
   .admin-manage-users-table th:last-child,
   .admin-manage-users-table td:last-child {
      padding-right: 0;
    }
    .admin-manage-users-table th,
    .admin-manage-users-table td {
      padding: 1em !important;
    }
    
  
  }
  .admin-manage-users-table th{
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.8);
  } .admin-manage-users-table td {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.7);
  }