.AdminGF{
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: center;
}.admin-giftcard{
    margin-left: 29px;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    display: flex;
    justify-content: flex-start;
}
.admin-giftcard-detailcontainer{
    width: 992px;
    /* height: 309px; */
    margin-top:32px;
    margin-left:29px; 
}
.admin-giftcard-datacontainer{
    width: 952.86px;
    /* height: 48px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* flex-wrap: wrap; */
    max-width:1200px;
}
.admin-giftcard-name{
    width: 133px;
    /* height: 48px; */
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
}
.admin-add-giftcard-btn{
    width: 205px;
    /* height: 34px; */
    padding: 20px 8px 20px 8px;
    margin-left: 623px;
    gap: 120px;
    border-radius:8px; 
    border: none;
    background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
    color: white;
    font-weight: 600px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -1%;
}
.admin-added-allgiftcard{
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    height: auto;
     gap: 20px;
}
.admin-added-giftcard{
    width: 304px;
    height: 245px;
    border-radius: 8px;   
}
.admin-add-new-giftcard{
    width: 67px;
    /* height: 86.11px; */
    margin-top: 79px
}