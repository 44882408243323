.order-tracker {
  min-height: 30rem;
  /* width: 80%; */
  margin: 0 auto;
  padding: 2rem;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  /* align-items:center; */
  justify-content: space-around;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius:  8px;
  font-size: 1.5rem;
  /* font-family: "Barlow"; */
  /* transform: scale(0.8, 0.8); */
  /* transform-origin: 0 0; */
  max-width: 988px;
  max-height: 396px;
}
.track-details-heads{
  color: rgba(0, 0, 0, 0.80);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  margin: 0;

}
.delivery-details {
  display: flex;
  justify-content: space-between;
  margin: 0;
  letter-spacing: 0.01em;
  font-size: 16px;
  align-items: center;
  
}
.blue {
  color: rgba(255, 97, 85, 1);
  display: flex;
  align-items: center;
  justify-content: space-around;
    gap: 1rem;
}
.order-status-highlight {
  color: #FC2975 !important;
  font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.16px;
}
.order-tracking-status
.css-1tujue3-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: #FC2975 !important;
}
.order-percent-completed {
  display: flex;
  align-items: center;
  justify-content:center;
  text-align: center;
  gap: 2rem;
  width: 100%;
  margin: 0 auto;
  font-size: 1rem;
}
.order-percent-completed-text {
  position: relative;
  right: 27rem;
  color: rgba(255, 97, 85, 1);
}
@media screen and (max-width: 1200px) {
  .stepper-wrapper {
    width: 100% !important;
  }
  .step-name {
    font-size: 0.8rem !important;
  }
  .order-percent-completed-text {
    right: 7rem;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 480px) {
.order-tracker {
  min-height: 30rem;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 1.5rem;
  font-family: "Barlow";
  /* transform: scale(0.8, 0.8); */
  /* transform-origin: 0 0; */
}}
.order-tracking-title{
  color: rgba(255, 97, 85, 1);
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  
}
.order-tracking-status{
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}