.product-page-filter{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 1rem;
}
.product-filter-layout{
    font-family:'Barlow';
    display:flex;
    /* width:40rem; */
}
.product-card-layout{
    display:flex;
    /* grid-template-columns: auto auto auto auto; */
    flex-wrap: wrap;
    margin-left:2rem;
    margin-bottom:5rem;
    /* margin-left:300px; */
    justify-content:space-around;
    width: 100%;
    
}