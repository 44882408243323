.productImageCard{
    min-height:20rem;
    display:flex;
    align-items:center;
    justify-content:space-around;
    position:relative;
    width:340px;
    margin:2rem auto;
}
.product-image{
    height:20rem;
    width:80%;
    opacity:0.2;

}
.product-button{
    position:absolute;
    top:95%;
    background:rgba(248, 152, 128, 1);
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 10px;
    border: none;
    font-size:1.5rem;
    padding: 0.5rem 1rem;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
}
.product-button:hover{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}