.Buyer_Privacy_policy{
    padding: 32px;
    margin-top: 0rem;
}
.Buyer_Privacy_policy p{
    font-size: 18px;
}
@media screen and (max-width: 880px) {
    .Buyer_Privacy_policy p{
        font-size: 16px;
    }
    
    .Buyer_Privacy_policy {
        padding: 32px;
        margin-top: 7rem;
    }
}
@media screen and (max-width: 480px) {
    .Buyer_Privacy_policy p{
        font-size: 16px;
    }
    
    .Buyer_Privacy_policy {
        padding: 32px;
        margin-top: 12rem;
    }
}
