.seller-category-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px;
    align-content: center;
    width: 100%;
    margin-left: 15px;
    margin-top: 16px;
    margin-bottom: 15px;
}

.seller-category-list-back-nav-field{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.seller-category-card{
    align-items: center;
    background: hsla(0,0%,44%,.03);
    border: 0.5px solid rgba(0,0,0,.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 270px;
    justify-content: space-around;
    min-width: 200px;
    width: 315px;

}
.seller-category-card-img{
    width: 70%;
    height: 70%;
    border-radius: 10px;
}

@media screen and (max-width: 900px){
    .seller-category-card{
        width:90%;
        margin:1rem auto;
    }
}
.seller-category-card:hover{
    cursor:pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.seller-product-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 14px;
    align-content: center;
    width: 100%;
    margin-top: 15px;
    margin-left: 15px;
    }
    .seller-product-card{
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        justify-content: space-around;
        height: 270px;
        width: 320px;
        border: 0.5px solid rgba(0, 0, 0, 0.2);
        min-width: 200px;
        background: rgba(112, 112, 112, 0.03);
        border-radius: 10px;
    }

    @media screen and (max-width: 480px){
        .seller-product-card{
            width:90%;
            margin:1rem auto;
        }
    }
    .seller-product-name{
        font-size:1.5rem;
        color: rgba(0, 0, 0, 0.7);
        display: inline-block;
        width: 200px; 
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align:center;
    }
    .seller-category-card-name{
        font-size:28px;
        /* color: rgba(0, 0, 0, 0.7); */
        color: rgba(0, 0, 0, 0.7);
        display: inline-block;
        width: 200px; 
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align:center;


    }
    .seller-product-card:hover{
        cursor:pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }