.admin-category-addition-field{
    display:flex;
    
    max-width:1100px;
    margin:2rem auto;
    align-items:center;
    justify-content:space-between;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius:10px;
    padding:2rem;
}

.admin-categories-table {
    width:50%;
    margin: auto;
    min-width: 350px;
    max-width: 100%;
     border-collapse:separate; 
    border-spacing: 0 1em;
  }
  
  .admin-categories-table tr:first-child {
    /* border-top: none; */
    text-align: center;
    background: white;
    color: black;
  }
  
  .admin-categories-table tr {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
 
  }
  

  .admin-categories-table tr:nth-child(odd):not(:first-child) {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .admin-categories-table th {
    display: none;
  }
  
  .admin-categories-table td {
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-categories-table td:first-child {
    margin-top: .5em;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-categories-table td:last-child {
    margin-bottom: .5em;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .admin-categories-table td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 120px;
    display: inline-block;
    color: black;
  }

  
  .admin-categories-table {
    color: #333;
    border-radius: .4em;
    overflow: hidden;
  }
  
  .admin-categories-table tr {
    background: #FFFFFF;
   }
  
  .admin-categories-table th,
  .admin-categories-table td {
    padding: .5em 1em;
  }
  
  .admin-categories-table tr:first-child , .admin-categories-table td:first-child { border-top-left-radius: 10px; }
  .admin-categories-table tr:first-child , .admin-categories-table td:last-child { border-top-right-radius: 10px; }
  
  .admin-categories-table tr:last-child ,.admin-categories-table td:first-child { border-bottom-left-radius: 10px; }
  .admin-categories-table tr:last-child,  .admin-categories-table td:last-child { border-bottom-right-radius: 10px; }
  
  @media screen and (max-width: 601px) {
   .admin-categories-table tr:nth-child(2) {
      border-top: none;
    }
  
    .admin-categories-table tr {
      background: #fafafa;
      border: 1px solid rgba(0, 0, 0, 0.2);
     }
  
    .admin-categories-table td {
      display: block;
      border: none !important;
      display: flex;
      justify-content:space-between;
    }
    .admin-categories-table td:first-child {
      border: none !important;
    }
    
    .admin-categories-table td:last-child {
      border: none !important;
    }
    
    .admin-categories-table {
      width:55%;
      margin:0 auto;
      border-collapse: collapse;
      border-spacing: 0 1em;
    }
    .data-th {
      width: 50%;
      gap: 2rem;
    }
    .admin-category-addition-field{
        flex-direction: column;
        gap:2rem;
    }
  }
  @media screen and (min-width: 600px) {
  
    
  
    .admin-categories-table {
      width:80%;
      margin:0 auto;
    }
    .admin-categories-table tr:hover:not(:first-child) {
      /* background-color: #d8e7f3; */
      cursor:pointer;
    }
    .admin-categories-table td:before {
      display: none;
    }
    .admin-categories-table th,
   .admin-categories-table td {
      display: table-cell;
      padding: .25em .5em;
    }
    .admin-categories-table th:first-child,
  .admin-categories-table td:first-child {
      padding-left: 0;
    }
   .admin-categories-table th:last-child,
   .admin-categories-table td:last-child {
      padding-right: 0;
    }
    .admin-categories-table td {
      padding: 0.8em !important;
      font-size: 20px;
    }
    .admin-categories-table th{
padding: 0.3em;
font-size: 23px;
    }
  
  }

  .admin-category-list-name{
    display:flex;
  }


  .choose-file-btn input[type="file"] {
    display: none;
  }
  .admin-categories-table th {
    font-size: 19px;
    color: rgba(0, 0, 0, 0.8);
    padding: 0.4rem;
    letter-spacing: 0.02em;
  }
  .admin-categories-table td {
    font-size:18px;
    color: rgba(0, 0, 0, 0.7);
    padding: 0.7rem;
  }