.product-filter-card-container {
  font-family:"Barlow";
  position: relative;
  /* margin: 3rem 1rem; */
  width: 15rem;
  height: 20rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding-bottom:1rem;
  
}
.product-filter-card-container:hover{
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.product-filter-card-container:hover img {
  height: 15rem;
  opacity:0.9;

}
.product-filter-card-container:hover .product-filter-add-to-cart {
  opacity: 1;
  border: 0.5px solid black;

}
.product-filter-card-container:hover .product-filter-wishlist {
  opacity: 1;
}

.product-filter-card-image {
  position: absolute;
  width: 100%;
  height: 15rem;
  left: 0;
}

.product-filter-add-to-cart {
  position: absolute;
  width: 60%;
  left: 10%;
  top: 50%;
  font-size: 1rem;
  text-align: center;
  opacity: 0;
  /* background:1px solid white; */
  /* width: 200px; */
  padding: 0.5rem;
  text-align: center;
  color: black;
  z-index: 1;
  background: 1px solid white;
}
.product-filter-wishlist {
  position: absolute;
  width: 20%;
  left: 80%;
  top: 50%;
  text-align: center;
  opacity: 0;
}
.product-filter-product-rating {
  font-size: 14px;
  display: flex;
  padding: 0.1rem;
  justify-content: space-around;
  align-items: center;
  background: rgba(255, 189, 112, 0.4);
  border-radius: 5px;
  /* width: 5rem; */
  /* margin-bottom: 1rem; */
  position: absolute;
  width: 20%;
  left: 10%;
  top: 90%;
  flex-direction:row;
}
.product-filter-review-count{
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.66);
  /* width:5rem; */
  /* margin-bottom: 1rem; */
  position: absolute;
  /* width: 20%; */
  left: 40%;
  top: 92%;
}
.product-filter-add-to-cart:hover {
  cursor: pointer;
  /* border: solid 2px black; */
  color: rgba(248, 152, 128, 1);
}

.product-filter-card-prices {
  position: absolute;
  top: 82%;
  left: 10%;
  display: flex;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.product-filter-card-price {
  margin-right: 1rem;
}
.product-filter-card-actual-price {
  margin-right: 1rem;
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.66);
}
.product-filter-card-discount {
  color: rgba(248, 152, 128, 1);
}
.product-filter-card-name {
  position: absolute;
  top: 75%;
  left: 10%;
  font-size: 1rem;
  margin-bottom: 1rem;
  margin: 0 auto;
}
.product-filter-card-name:hover {
  color: rgba(248, 152, 128, 1);
  cursor: pointer;
}
.product-filter-quantity {
  /* width: 90%; */
  display: flex;
  align-items: center;
  position: absolute;
  top: 3%;
  left: 80%;
  display: flex;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  height: 60%;
  max-height: 1rem;
  align-items: center;
  justify-content: space-between;
}
.product-filter-quantity-text{
  font-size:1rem;
  /* margin-top:1rem; */
  
}
.product-filter-quantity-field{
  font-size:1rem;
  margin-top:1rem;
}

