.contact-email-container{
    width: 88%;
    /* margin: 0 auto; */

}
.contact-email-form-field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    flex-direction: column;
    gap: 8px;
}
.contact-email-form-field
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    border-color:  #B0B0B0;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    align-items: center;
    position: relative;
    border-radius: 8px;
    width: 810px;
    /* max-width: 810px; */
    height: 43px;
    /* min-width: 328px; */
}
.email-field-textarea{
    width: 640px;
    height: 114px;
    padding: 12px 19px 11px 7px;
    border-radius: 8px;
    border: 1px solid #B0B0B0;
    resize: none;
    /* min-width: 300px; */
}
.contact-email-form-field
.css-1wc848c-MuiFormHelperText-root.Mui-error {
    color: #d32f2f;
    position: relative;
    bottom: -3px;
    left: 14px;
}
.contact-email-head{
    font-family: Poppins;
font-size: 32px;
font-weight: 500;
line-height: 37px;
letter-spacing: 0.13em;
text-align: center;
color: rgba(26, 26, 26, 1);
display: flex;
justify-content: flex-start;
margin: 24px auto;

}
.contact-email-form-field .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    border-color: #B0B0B0;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    align-items: center;
    position: relative;
    border-radius: 8px;
    width: 665px;
    height: 43px;
}
.contact-email-form-field
.css-1v4ccyo {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 665px;
    position: relative;
    border-radius: 8px;
}

@media screen and (max-width:480px) {
    .contact-email-form-field
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
        color: rgba(0, 0, 0, 0.87);
        box-sizing: border-box;
        position: relative;
        cursor: text;
        display: inline-flex;
        align-items: center;
        position: relative;
        border-radius: 8px;
        /* width: 810px; */
        max-width: 810px;
        height: 43px;
        width: 300px;
    }
    .email-field-textarea {
       
    height: 114px;
    padding: 7px 0px 6px 5px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    resize: none;
    width: 295px;

    }
    .contact-email-container {
        width: 100%;
        margin: 0 auto;
    }
    .contact-email-form-field .css-1v4ccyo, .contact-email-form-field .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
        align-items: center;
        border-radius: 8px;
        box-sizing: border-box;
        color: #000000de;
        cursor: text;
        display: inline-flex;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: .00938em;
        line-height: 1.4375em;
        position: relative;
        width: 338px;
    }
    .email-field-textarea {
        border: 1px solid #00000026;
        border-radius: 8px;
        height: 114px;
        padding: 7px 0 6px 5px;
        resize: none;
        width: 330px;
    }
    .contact-email-head {
        font-family: Poppins;
        font-size: 23px;
        font-weight: 500;
        line-height: 37px;
        letter-spacing: 0.13em;
        text-align: center;
        color: rgba(26, 26, 26, 1);
        display: flex;
        justify-content: flex-start;
        margin: 19px auto;
        padding: 10px;
      }

}
.error-message-query{
    color: #d32f2f;
    font-size: 13px;
    position: relative;
    left: 10px;
}
.error-message-query-field{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.email-field-textarea.error {
    border: 1px solid #d32f2f;
    border-radius: 8px;
  }
  .email-field-textarea {
    border: 1px solid rgba(0, 0, 0, 0.15); /* Change #ccc to the default border color */
  }
  .email-field-textarea:focus{
    border-color:#FF7891 !important;
    outline: none;
    border: 2px solid #FF7891;

  }