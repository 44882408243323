.buyer-category-layout-anim{
  animation: slideInFromLeft 1.5s ease;

}
.buyer-category-layout{
    display:flex;
    width:90%;
    margin: 0 auto;
    align-items:center;
    flex-wrap:wrap;
    justify-content:space-between;
    gap:2rem;

}
@keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
.buyer-category-card{
    height:440px;
    width:330px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
    align-items:flex-end;
    justify-content:center;
    border:rgba(0, 0, 0, 0.2);
    border-radius:5px;
    /* animation: slideInFromLeft 1.5s ease; */


}

.buyer-category-card:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: transform 0.3s ease;
    /* box-shadow:  */
  }
.buyer-category-name{
    color:white;
    font-size:30px;
    margin-bottom:2rem;
    font-weight:bold;
}


