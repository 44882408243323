.admin-plan-buttons{
    display:flex;
    align-items:center;
    justify-content: flex-end;
}
.admin-plan-basic-details {
    /* border:1px solid black; */
    width: 70%;
    max-width: 800px;
    padding: 2rem;
    margin: 0 auto;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }.admin-plan-basic-details {
    /* border:1px solid black; */
    width: 70%;
    max-width: 800px;
    padding: 2rem;
    margin: 0 auto;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .admin-plan-field {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem auto;
    width: 80%;
  }
  .admin-plan-field-name {
    width: 30%;
    font-size: 1.2rem;
    /* text-align:center; */
    margin-right: 2rem;
  }
  .admin-radio-field {
    display: flex;
    width: 70%;
    justify-content: space-evenly;
  }
  .admin-radio-field-label {
    font-size: 1.2rem;
  }
  
  .admin-features-table {
    width: 70%;
    max-width: 800px;
    padding: 2rem;
    margin: 0 auto;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .admin-features-table-head {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .admin-plan-feature-card {
    /* width:90%; */
    min-height: 0.5rem;
    max-width: 800px;
    padding: 1rem;
    margin: 0.5rem auto;
    background: #ffffff;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  
  .admin-action-icons {
    /* width:100%; */
    /* margin:0 auto; */
    /* margin-left:2rem; */
    position: relative;
    left: 3rem;
  }
  
  .admin-feature-field {
    width: 70%;
    max-width: 800px;
    padding: 2rem;
    margin: 0 auto;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .admin-feature-row {
    display: flex;
    min-height: 0.5rem;
    max-width: 800px;
    /* padding:1rem; */
    margin: 1rem auto;
    background: #ffffff;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  
  .admin-feature-cell {
    display: flex;
    min-height: 0.5rem;
    max-width: 800px;
    /* padding:1rem; */
    margin: 1rem auto;
    background: #ffffff;
    /* border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  .admin-add-feature-table {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .admin-add-feature-head {
    min-height: 2rem;
    font-size: 1.4rem;
    /* background-color: aqua; */
  }
  
  .admin-add-feature-head > .admin-feature-row {
    background-color: #d4f0f0;
  }
  .admin-add-feature-head > .admin-feature-row > .admin-feature-cell {
    background-color: #d4f0f0;
  }
  
  .add-feature-form {
    margin: 2rem auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 70%;
  }
  
  .admin-button {
    background: #ff5569;
    padding: 0.5rem 1rem;
    /* float:right; */
    align-self: flex-end;
    /* margin-bottom:20rem;   */
    border: none;
  }
  