.seller-layout-logo {
  width: 15rem;
  float: left;
}


@media screen and (max-width: 488px) {
  .Myhraki-logo-nav-bar {
    display: none;
  }
  .Myhraki-logo-nav-bar-buyer {
    display: none;
  }
  
}



.seller-navbar-link {
  font-size: 1.2rem;
  color: black;
  text-decoration: none;
}

.seller-navbar-link:hover {
  color: #fa008e;
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: #fa008e;
  text-decoration-thickness: 3px;
}

.seller-navbar-link-selected{
  color: #fa008e;
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: #fa008e;
  text-decoration-thickness: 3px; 
}
.Myhraki-logo-nav-bar{
width: 180px;
}
.Myhraki-logo-nav-bar-buyer{
  
  }
.seller-navbar-link-active {
  color: #fa008e;
  font-weight: bold;
}

.seller-layout{
  margin-top: 3rem;
  }
  .seller-navbar-sidebar 
.css-1mky8oy-MuiDrawer-docked .MuiDrawer-paper {
    background-color: white!important;
    -webkit-transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    overflow-x: hidden;
    width: calc(56px + 1px);
  }
  .seller-navbar-sidebar .MuiBox-root .css-k008qs
.css-i9gxme {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background: white!important;
}
.seller-navbar-sidebar
.css-1mky8oy-MuiDrawer-docked .MuiDrawer-paper {
  background-color: white !important;
  -webkit-transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow-x: hidden;
  width: calc(56px + 1px);
}
.seller-navbar-sidebar
.css-1ontqvh {
  list-style: none;
  margin: 0;
  padding: 0px 0px;
  position: relative;
}
.seller-navbar-component{
  background:rgba(244, 244, 244, 1) !important;
}
.seller-navbar-sidebar .css-h4y409-MuiList-root {
  background:rgba(244, 244, 244, 1) !important;
}
.seller-navbar-sidebar
.css-9avsy9 {
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  display: block;
  background-color:rgba(244, 244, 244, 1) !important;
  border-radius: 0px;
  min-height: 3rem;
}
@media screen and (min-width: 480px) {
  .Seller-layout {
    /* background: #F6F9FC; */
    background: white;
    width: 100%;
    /* height: 100vh; */
  }
  }