.faq-container {
    width: 100%;
}
.faq-question{
font-size: 16px;
}
.faq-category-list {
    margin-bottom: 24px;
    margin-top: 24px;
    /* margin-left: 32px; */
    display: flex;
    max-width: 862px;
    width:100%;
    align-items: flex-end;
    align-content: flex-end;
    padding: 16px 0px;
    gap: 8px;
    flex-wrap: wrap;
}

.faq-category-item {
    padding: 0 16px;
    color: #fe3c72;
    font-size: 18px;
    background: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: Poppins;
    text-transform: uppercase;
    max-width: 285px;
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */

}

.selected {
    padding: 0 16px;
    color: white;
    font-size: 18px;
    background: #fe3c72;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: Poppins;
    text-transform: uppercase;
}

/* .faq-question {
    width: 763px;
}

.faq-answer {
    width: 763px;
} */
/* .faq-question-ans-container
.css-1utut3r-MuiPaper-root-MuiAccordion-root:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 32px 16px 32px 24px;


} */
.faq-question-ans-container .css-vxuyxe-MuiPaper-root-MuiAccordion-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    margin-top: 20px;
    box-shadow: none;
    width: calc(100% - 32px);
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    padding: 8px 16px;
}

.faq-question-ans-container .css-v6rsyj-MuiPaper-root-MuiAccordion-root:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.faq-question-ans-container .css-v6rsyj-MuiPaper-root-MuiAccordion-root:last-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.css-vqxaom-MuiAccordionDetails-root {
    padding: 6px 2px 5px;
    width: 100%;
}
.css-v6rsyj-MuiPaper-root-MuiAccordion-root:before {
    position: absolute;
    left: 0;
    top: 0px;
    right: 0;
    height: 1px;
    content: "";
    opacity: 1;
    background-color: none;
    -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.faq-question-ans-container
.css-v6rsyj-MuiPaper-root-MuiAccordion-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    position: relative;
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
    margin-top: 24px;
    box-shadow: none;
    width: 85%;
    border-radius: 8px;
    border: 0.5px solid rgba(0, 0, 0, 0.20);
    padding: 8px 8px;
}

@media screen and (max-width: 480px) {

    .faq-heading {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 900;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: left;
        width: 300px;
        height: 48px;
        margin-bottom: 1rem;
        padding: 1rem;
    }

    .faq-question {
        width: 285px;
    }

    .faq-question {
        width: 245px;
        font-size: 16px;
    }

    .faq-category-list {
        width: 330px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-bottom: 40px;
        margin-top: 40px;
        padding: 0;
        margin-left: 25px;
    }
    .faq-question-ans-container
    .css-vqxaom-MuiAccordionDetails-root {
        padding: 5px 9px 2px;
        width: 100%;
        font-size: 16px;
    }
    .faq-question-ans-container {
        width: 320px;
        margin-left: 15px;
    }

    /* .faq-container {
        width: 98%;
        margin-left: 1%;
        } */
        .faq-question-ans-container
        .css-v6rsyj-MuiPaper-root-MuiAccordion-root {
            background-color: #fff;
            color: rgba(0, 0, 0, 0.87);
            -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-radius: 4px;
            box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
            position: relative;
            -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            overflow-anchor: none;
            border-radius: 0;
            margin-top: 24px;
            box-shadow: none;
            width: 300px;
            border-radius: 8px;
            border: 0.5px solid rgba(0, 0, 0, 0.20);
            padding: 8px 8px;
        }
        .css-vqxaom-MuiAccordionDetails-root {
            padding: 6px 2px 5px;
            width: 300px;
        }
        
    /* .selected {
        padding: 0 14px;
        color: white;
        font-size: 14px;
        background: #fe3c72;
        border-radius: 8px;
        border: none;
        cursor: pointer;
        font-family: Poppins;
        text-transform: uppercase;
    }
    .faq-category-item {
        padding: 0px 8px;
        color: #fe3c72;
        font-size: 14px;
        background: white;
        border-radius: 8px;
        border: none;
        cursor: pointer;
        font-family: Poppins;
        text-transform: uppercase;
        max-width: 260px;
        align-items: center;
        justify-content: flex-start;
    } */
}
.css-v6rsyj-MuiPaper-root-MuiAccordion-root:before{
    background-color: none;
}

