.Admin-faq-container{
    display: flex;
    justify-content: flex-start;
    max-width: 1280px;
    flex-direction: column;
    padding: 3rem;
}
.question-ans-textarea{
    resize: none;
    width: 850px;
height: 120px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid #CCC;
background: #FFF;
padding: 1rem;
}
.Admin-faq-buttons{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

  .question-ans-fields{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
  }
  .question-ans-button-fields{
    display: flex;
    justify-content: flex-end;
    width: 77%;
  }
  .faq-add-category{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
  }
  .faq-add-category-name{
    display: flex;
    justify-content: space-around;
    align-items: center ;
    gap:1rem;
  }
  .faq-add-question{
    width: 400px;
    padding: 1rem;
height: 120px;
resize: none;
border-radius: 8px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid #CCC;
background: #FFF;
  }
 .question-ans-textarea-fields{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    width: 79%;
    /* align-items: flex-start; */
  }
  .submit-button-add-category{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  @media screen and (max-width: 480px){
    .Admin-faq-buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
  }
  .question-ans-textarea {
    resize: none;
    width: 320px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #CCC;
    background: #FFF;
    padding: 1rem;
}
.question-ans-textarea-fields {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1rem;
  width: 320px;
  /* align-items: flex-start; */
}
  }
  /* .added-question-ans-fields
  .css-o4b71y-MuiAccordionSummary-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 12px 0;
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */