.landing-page-story-layout {
    width: 90%;
    margin: 2rem auto;
  }
  
  .story-card-layout {
    display: flex;
    max-height: 250px;
    width: calc(100%);
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.5);
    
    
    
  }

  .story-card-img{
    width:25%;
    /* height:10%; */
    min-height: 250px;

    /* min-width:300px; */
    /* clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); Create a hexagonal shape */

    height:auto;
    /* padding:25px; */
  }

  .story-card-desc{
    width:80%;
    text-align:center;
    margin:0 auto;
    font-size:30px;
  }
  
  .text-left {
    flex-direction: row;
    border-right:none;
    margin:4rem 2rem 0 0;
    align-self:right;


  }
  
  .text-right {
    flex-direction: row-reverse;
    border-left:none;
    margin:4rem 0 0 2rem;
    align-self:left;

   
  }
  
  .story-card-img {
    max-width: 50%;
    height: auto;
  }
  
  .story-card-text {
    flex: 1;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:space-evenly;
    

  }