.customerListingPage-Main-heading {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.customerListingPage-Main-sortsection {
  display: flex;
  padding-right: 50px;
  gap: 10px;
  align-items: center;
  flex-direction: row-reverse;
}
.customerListingPage-Main-heading-title {
  font-family: Mulish;
  font-size: 32px;
  font-weight: 700;
  line-height: 40.16px;
  letter-spacing: 0.04em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.customerListingPage-Main-heading-subtitle {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  letter-spacing: 0.04em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 33%, 1);
}
.customerListingPage-Main-sortby {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 500;

  letter-spacing: -0.01em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
}
.customerListingPage-cards {
  padding: 20px 90px 20px 90px;
  display: flex;
  flex-wrap: wrap;

  column-gap: 10px; /* Gap between columns */
  row-gap: 90px;
  justify-content: space-between;
}

.customerListingPage-cards > div {
  flex: 1 1 calc(25% - 10px);
  max-width: calc(25% - 10px);
  box-sizing: border-box;
  position: relative;
}
.customerlistingPage-loadmore {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fd2583;
  cursor: pointer;
}
.customernewListingCard1-best-seller-label {
  position: absolute;

  background-color: #fd2583;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;

  text-transform: uppercase;
  z-index: 10;
}
.CustomernewlistingCard1-imageRating {
  border-radius: 21px;
  background: #ffffff99;
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  border: none;
  padding: 0px 12px 0px 10px;
  gap: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 10;
  bottom: 60px;
  left: 8px;
}
.Customernewlistingheart-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: none;
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 10;
  width: 24px;
  height: 24px;
  bottom: 60px;
  right: 8px;
}
.customerNewlistingCard-titlesection {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 2px;
}
.customerNewlistingCard1-title {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.59px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 33%, 1);
}
.customerNewlistingCard1-subtitle {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fd2583;
}
@media screen and (max-width: 598px) {
  .customerListingPage-Main-sortsection {
    padding-right: 5px;
  }
  .customerListingPage-cards {
    padding: 10px;
  }
  .customerListingPage-cards > div {
    max-width: fit-content;
    flex-basis: auto;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .customerListingPage-Main-sortsection {
    padding-right: 5px;
  }
  .customerListingPage-cards {
    padding: 10px;
  }
}
