.CustomerNewMyAccount-heading {
  padding: 30px;
  display: flex;
  justify-content: center;
  font-family: Mulish;
  font-size: 32px;
  font-weight: 700;
  line-height: 40.16px;
  letter-spacing: 0.04em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewMyAccount-main-section {
  padding: 0px 90px 0px 90px;
}
.CustomerNewMyAccount-division-sec {
  display: flex;
  gap: 20px;
}
.CustomerNewMyAccount-menu-section {
  width: 15%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  cursor: pointer;
}
.CustomerNewMyAccount-icons-details {
  align-items: center;
  display: flex;
  gap: 5px;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 600;

  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.CustomerNewMyAccount-icons {
  font-size: 20px;
}
.CustomerNewMyAccount-details-section {
  flex-grow: 1;
  width: 85%;
}
.CustomerNewMyAccount-top-section {
  padding-top: 40px;
}

.vertical-line {
  width: 1px;
  background-color: #ccc;
  height: auto;
  margin: 0 10px;
}
.CustomerNewMyAccount-details-tabs {
  width: 70%;
  display: flex;
  justify-content: space-between;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 3%, 69%, 1);
}
.CustomerNewMyAccount-active-tab {
  cursor: pointer;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;

  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration: underline;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewMyAccount-active-tab2 {
  font-family: Mulish;
  font-size: 20px;
  font-weight: 500;
  line-height: 25.1px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewMyAccount-inactive-tab {
  cursor: pointer;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 3%, 69%, 1);
}

.CustomerNewMyAccount-details-tabs div:hover {
  text-decoration: underline;
}
.CustomerNewMyAccount-active-menu {
  color: #fe585b;
}

.CustomerNewMyAccount-active-menu .CustomerNewMyAccount-icons {
  color: #fe585b;
}
.CustomerNewMyAccount-tabs-below {
  padding-top: 30px;
  padding-bottom: 30px;
}
.CustomerNewMyAccount-Personal-info {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.CustomerNewMyAccount-Personal-info-edit-btn {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 100%, 1);
  display: flex;
  justify-content: center;
  padding: 5px;
  border-radius: 20px;
  width: 10%;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);

  cursor: pointer;
}
.CustomerNewMyAccount-Personal-passwordsec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative; /* Make container relative for absolute positioning */
}

.CustomerNewMyAccount-Personal-passwordsec::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: hsla(0, 0%, 56%, 1); /* Line color */
}

.CustomerNewMyAccount-changePasswordText {
  font-size: 14px;
  cursor: pointer;
  font-family: Mulish, sans-serif;
  font-weight: 400;
  text-decoration: underline solid;
  color: #fe585b;
  z-index: 1; /* Keep text above the line */
}
.CustomerNewMyAccount-edit-save-cancel-section {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}
.CustomerNewMyAccount-edit-save-cancel {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
  cursor: pointer;
}
.Custnewacc-change-pass-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Custnewacc-change-pass-title {
  font-family: Mulish;
  font-size: 28px;
  font-weight: 400;
  line-height: 35.14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(341, 61%, 11%, 1);
}
.Custnewacc-change-pass-close {
  font-size: 20px;
}
.Custnewacc-change-pass {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
}
.CustomerNewMyAccount-savepass {
  display: flex;
  justify-content: center;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  border-radius: 20px;
  padding: 5px;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 100%, 1);
  cursor: pointer;
}
.CustomerNewMyAccount-savepass-sec {
  padding-top: 20px;
}
/*help*/
.CustomerNewMyAccounthelp-details-tabs {
  width: 70%;
  display: flex;
  gap: 30px;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 3%, 69%, 1);
}
.sort-section-CustomerNewMyAccount {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  color: #fe585b;
}
.CustomerNewMyAccounthelp-details-tabs-for-myord {
  width: 70%;
  display: flex;
  justify-content: space-between;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 3%, 69%, 1);
}
.CustomerNewMyAccounthelp-details-tabs-for-myorders-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 598px) {
  .CustomerNewMyAccount-heading {
    padding: 10px;
  }
  .CustomerNewMyAccount-main-section {
    padding: 5px;
  }
  .CustomerNewMyAccount-menu-section {
    width: 30%;
  }
  .CustomerNewMyAccount-icons-details {
    font-size: 9px;
  }
  .CustomerNewMyAccount-division-sec {
    gap: 5px;
  }
  .CustomerNewMyAccount-details-section {
    width: 100%;
  }
  .CustomerNewMyAccount-details-tabs {
    width: 100%;
  }
  .CustomerNewMyAccount-Personal-info {
    width: 100%;
  }
  .CustomerNewMyAccount-icons-details {
    gap: 1px;
  }
  .CustomerNewMyAccount-active-tab {
    font-size: 10px;
  }
  .CustomerNewMyAccount-inactive-tab {
    font-size: 10px;
  }
  .CustomerNewMyAccount-changePasswordText {
    font-size: 6px;
  }
  .vertical-line {
    margin: 0 2px;
  }
  .CustomerNewMyAccount-Personal-info-edit-btn {
    width: 20%;
    font-size: 10px;
    padding: 0px;
  }
  .CustomerNewMyAccount-edit-save-cancel {
    font-size: 10px;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .CustomerNewMyAccount-heading {
    padding: 10px;
  }
  .CustomerNewMyAccount-main-section {
    padding: 30px;
  }
  .CustomerNewMyAccount-menu-section {
    width: 30%;
  }
  .CustomerNewMyAccount-division-sec {
    gap: 10px;
  }
  .CustomerNewMyAccount-details-tabs {
    width: 85%;
  }
  .CustomerNewMyAccount-Personal-info {
    width: 85%;
  }
  .CustomerNewMyAccount-Personal-info-edit-btn {
    width: 16%;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  .CustomerNewMyAccount-heading {
    padding: 10px;
  }
  .CustomerNewMyAccount-main-section {
    padding: 50px;
  }
  .CustomerNewMyAccount-menu-section {
    width: 30%;
  }
  .CustomerNewMyAccount-division-sec {
    gap: 10px;
  }
  .CustomerNewMyAccount-details-tabs {
    width: 85%;
  }
  .CustomerNewMyAccount-Personal-info {
    width: 85%;
  }
  .CustomerNewMyAccount-Personal-info-edit-btn {
    width: 16%;
  }
}
