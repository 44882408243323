.seller-navbar {
  font-size: 1.4rem;
  /* background-image: linear-gradient(260deg, #2376ae 0%, #c16ecf 100%); */
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  /* color:black;
     */
  background: rgba(212, 240, 240, 0.57);

  color: rgba(0, 0, 0, 0.66);
  padding: 2rem auto;
  /* padding-bottom: 10px; */
}

.seller-main-nav {
  list-style-type: none;
  display: none;
}

.seller-nav-links,
seller-logo {
  text-decoration: none;
  color: black;
}

.seller-main-nav li {
  text-align: center;
  margin: 15px auto;
}


.seller-logo {
  display: inline-block;
  font-size: 22px;
  margin-top: 10px;
  margin-left: 20px;
}

.seller-navbar-toggle {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  /* color: rgba(255, 255, 255, 0.8); */
  color: black;
  font-size: 24px;
}

#chkToggle {
  display: none;
}

#chkToggle:checked + ul.seller-main-nav {
  display: block;
}

@media screen and (min-width: 768px) {
  .seller-navbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    height: 70px;
    align-items: center;
    padding:2rem;
  }

  #chkToggle:checked + ul.seller-main-nav {
    display: flex;
  }

  .seller-main-nav {
    display: flex;
    margin-right: 30px;
    flex-direction: row;
    justify-content: flex-end;
  }

  .seller-main-nav li {
    margin: 0;
  }
  .seller-nav-links :hover {
    margin: 0;
    cursor:pointer;
 
  }
  .seller-nav-links {
    margin-left: 40px;
  }

  .seller-logo {
    margin-top: 0;
  }

  .seller-navbar-toggle {
    display: none;
  }

  
  .seller-nav-links:hover {
    /* color: black; */
    color: rgba(0, 129, 133, 1) ;
    text-decoration: underline;
    /* text-decoration: rgba(0, 129, 133, 1) ; */
    cursor:pointer;

  }
}
