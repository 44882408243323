.CustomNavBar{
    width: 125px;
}
.buyers-searchbar{
    position:relative;
}
.buyers-search-field{
    width:100%;

}

.buyers-search-suggestion-list {
    width: calc(100% - 2rem);
    position: absolute;
    top: 1.1rem;
    left: -40px;
    z-index: 2000;
    pointer-events: auto;

}


.buyers-search-suggestion:hover {
    cursor: pointer;
    /* color:#FD2583; */
    background:#dbc8c8;
}
 
.buyers-search-suggestion {
    width: 100%;
    list-style: none;
    background-color: white;
    /* border-top: 0.01px solid #777; */
    padding: 0.5rem 1rem;
    z-index:1000;
    pointer-events: auto;

    color: black;
    border-radius:1px;
}
 
.buyers-search-suggestion-list-container{
    border-top:0.1px solid #fe3c72;
    border-bottom:0.1px solid #fe3c72;
}
 
@media screen and (max-width: 680px){
    .CustomNavBar{
display: none;
 }
 .buyers-search-suggestion-list {
    width: calc(100% - 3rem);
    position: absolute;
    top: 2.6rem;
    left: -16px;
    z-index: 2000;
    /* border-radius: 10px; */
}
 
}
.buyers-searchbar
.css-1kcggdq-MuiInputBase-root .MuiInputBase-input {
    padding: 6px 18px 6px 18px;
    padding-left: calc(1em + 32px);
    -webkit-transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
    font-family: Poppins;
    font-size: 14px;
    color: rgba(119, 119, 119, 1);
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: -0.01em;
text-align: left;
 
}
.nav {
    position: fixed;
    top: 0;
    min-height: 64px;
    width: 100vw;
    background-color: #fe3c72;
    z-index:1000;
}
 
.nav-container {
    max-width: 1280px;
    width: 100%;
    height: 64px;
    background-color: #fe3c72;
    margin: 0 auto;
    display: flex;
}
 
.nav-img {
    width: 137.93px;
    height: auto;
    flex-shrink: 0;
    margin: 6px 65px 6px 40px;
    cursor: pointer;
}
 
.nav-links {
    display: flex;
    margin-right: 105px;
    align-items: center;
 
}
 
.nav-link {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    text-transform: uppercase;
    margin: 25px 12px 25px 0px;
    cursor: pointer;
 
}
 
.nav-search {
    /* max-height: 32px; */
    /* padding: 0px 16px; */
    width: 288px;
    margin: 16px 24px 16px 0;
    /* padding-left:80px; */
    border-radius: 2px;
    background: #FFF;
    border: none;
    display: flex;
    align-items: center;
    position:relative;
    padding: 8px 16px;
    gap: 8px;
    font-size: 14px;
}
 
.nav-search-bar {
    /* max-height: 32px; */
    /* padding: 16px 40px; */
    /* margin: 16px 24px 16px 0; */
    /* padding-left:80px; */
    /* border-radius: 8px; */
    /* background: #FFF; */
    border: none;
    width:100%;

 
}
 
.nav-search-bar:focus {
    outline: none;
}
 
.nav-btn {
    height: 24px;
    background-color: #fe3c72;
    color: white;
    margin: 20px 24px;
    border: none;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    cursor: pointer;
 
 
}
 
.nav-icons {
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
 
.nav-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
 
}
 
.icon-container {
    display: flex;
    align-items: center;
    gap: 12px;
}
 
.hidden-icon {
    margin-left: 16px;
    display: none;
}
 
.secondary-search-cont {
    /* min-height:64px; */
    /* margin-top:64px; */
    z-index:1000;
    position: fixed;
    top: 64px;
    display: none;
    width: 100vw;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
    border-bottom: 0.1px solid #dbc8c8;
}
 
.secondary-search-bar {
    width: calc(100% - 70px);
    margin: 16px auto;
    border-radius: 8px;
    background: #FFF;
    border: none;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    font-size: 14px;
    border-radius: 128px;
    border: 1px solid #DDD;
    background: #FFF;
}
 
.secondary-category-bar {
    position: fixed;
    top: 64px;
    width: 100vw;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
    border-bottom: 0.1px solid #dbc8c8;
    min-height:64px;
    z-index:900;
    position: fixed;
    min-height: 64px;
    /* width: 100vw; */
    display: flex;
    justify-content: center;
    /* position: relative; */
    
    
 
}
 
.secondary-category-cont {
    max-width: 1280px;
    /* margin-left:40px;
    margin-right: 40px; */
    /* padding-left: 40px; */
    /* margin: 0 auto; */
   
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    scroll-behavior: smooth;
    gap: 16px;
    /* margin-top: 10px; */
    /* position: relative; */
}
 
.category-block {
    /* min-width: 144px; */
    font-size: 16px;
    min-width: 221px;
    padding: 8px 0px 8px 0px;
    margin: 0px 0;
    border-radius: 120px;
    color: #EB4D80;
    background-color: #FFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    border-radius: 0;
    border-right: 1px solid #EB4D80;
    text-align: center;
    
}
.category-block:last-child {
    border-right: none;
 
}
 
.secondary-category-cont::-webkit-scrollbar {
    scrollbar-width: none;
 
    display: none;
}
.secondary-category-bar {
    overflow-x: auto;
    /* position: relative; */
  }
  
  .secondary-category-bar .caret-left,
  .secondary-category-bar .caret-right {
    display: none; /* Hide the arrows by default */
  }
  .CaretLeftArrowNav1{
    height: 26px;
    width: 26px;
          background: #ffffff8a;
          /* padding: 5px; */
          border-right: 1px solid #ffffff8a ;
          cursor: pointer;
          z-index: 2; 
          
          margin-left: 0px;
          
          transform: translateY(-50%);
          color:#EB4D80;

          /* border-right: 1px solid; */
          /* padding-right: 10px; */
          
  }
  .CaretArrowSecNav2{
    height: 26px;
    width: 26px;
          background: #ffffff8a;
          /* padding: 5px; */
          border-right: 1px solid #ffffff8a ;
          cursor: pointer;
          z-index: 1001; 
          transform: translateY(-50%);
          color:#EB4D80;
  }
 .secondary-category-bar-all {
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    margin-top: 10px;
    position: fixed;
}
  @media screen and (min-width: 700px) {
    .secondary-category-bar .caret-left,
    .secondary-category-bar .caret-right {
      display: block; /* Show the arrows for screen widths 700px and above */
    }
    /* .secondary-category-cont {
        padding-left: 172px;
    } */
  }
  
@media screen and (max-width: 1100px) {
    .nav-links {
        display: none;
    }
}
 
@media screen and (max-width: 900px) {
    .nav-btn {
        display: none;
    }
 
    .nav-container {
        justify-content: space-between;
    }
}
 
@media screen and (max-width: 700px) {
    .nav-search {
        display: none;
    }
 
    .hidden-icon {
        display: block;
    }
 
    .secondary-search-cont {
 
        display: block;
    }
 
    .secondary-category-bar {
        top: 128px;
 
    }
 
    .secondary-category-cont {
        /* padding-left: 172px; */
        
    align-items: center;
    justify-content: flex-start;
    }
 
    .category-block {
        font-size: 14px;
        padding: 4px 16px;
        margin: 16px 0;
        border-radius: 120px;
        color: #fff;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: linear-gradient(90deg, rgba(253, 38, 130, 0.80) 4.65%, rgba(254, 83, 95, 0.80) 93.02%);
    }
 .CaretArrowSecNav2{
    background: none;
    height: 0px;
    width: 0px;
    color: transparent;
 }

 .CaretLeftArrowNav1{
    background: none;
    height: 0px;
    width: 0px;
    color: transparent;
          
  }
}
 
@media screen and (max-width: 480px) {
    .nav-img {
        margin: 8px 0px;
        width: 150px;
    }
    .nav-img {
        width: 130px;
        height: auto;
        flex-shrink: 0;
        margin: 6px 25px 6px 25px;
        cursor: pointer;
    }
 
 
}
.ArrowNav{
    padding: 20px 8px 8px 4px;
    text-align: center;
    /* margin-top: 12px; */
    width: 120px;
    color: #EB4D80;
    z-index: 3;
    background: #ffffff8a;
            padding: 5px;
            border-radius: 100px;
            cursor: pointer;
     
}
/* @media (min-width: 768px) {
    .secondary-category-bar .caret-left,
    .secondary-category-bar .caret-right {
      display: block;
    }
  } */
 
  