.customer-profile-container {
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0;
    margin-top:80px;
}
 
.customer-profile-container-tabs {
    border-radius: 8px;
    width: 272px;
    margin: 16px;
    max-height: 445px;
    padding: 16px 0;
    background: #FFF4EB;
 
}
.customer-profile-container-tabs .tab-background{
    background: #FD416D;
    color: white;
 
}
.profile-page-head{
    padding: 1.5rem;
}
.customer-profile-container-tab {
    width: 216px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    background: white;
    margin: 16px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 12px;
 
 
}
.profile-wishlist-card-container{
   padding: 1rem;
}
.customer-profile-container-tab:hover {
    cursor:pointer;
    background: #fd416d;
    color:white;
 
}
 
.customer-profile-container-tab-panel {
    min-height: 1500px;
    max-width: 1280px;
    width:90%;
}
.customer-details-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
}
.help-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.personal-details-head{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 23px;
    /* margin-left: 32px; */
    margin-top: 0px;
 
}
.profile-page-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    text-align: left;
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    /* margin-left: 32px; */
    margin-bottom: 24px;
    margin-top: 40px;
}
.personal-details-head-sub{
font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 30px;
letter-spacing: -0.01em;
text-align: left;
 
}
.personal-details-head-sub-span
{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(119, 119, 119, 1);
}
 
.customer-wishlist-cards{
    display: flex;
    flex-wrap: wrap;
    /* max-width: 900px; */
    gap: 0.3rem;
    padding: 0.5rem;
}
.edit-button-myprofile{
    width: 95%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 20px;  
}
@media screen and (max-width: 900px){
.customer-profile-container-tab {
    width: 25px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    background: white;
    margin: 16px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
}
.customer-profile-container-tab-name{
    display: none;
}
.customer-profile-container {
      /* max-width: 1200px; */
      margin: 1rem auto;
      /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10); */
      display: flex;
      min-height: 1000px;
      height: 100%;
      width:95%;
      margin:0 auto;
      min-width: 320px;
      /* margin: 0;s */
      padding: 0;
      margin-top:0px;
}
.customer-profile-container-tabs {
    border-radius: 8px;
    width: 80px;
    margin: 16px;
    padding: 16px 0;
    background: #FFF4EB;
    display: none;
}
.contact-seperator{
    display: none;
 
}
.profile-page-head {
    font-size: 23px;
    font-weight: 500;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding: 0rem;
    max-width: 330px;
    text-align: left;
    margin-top: 20px;
    /* margin-left: 10px; */
    margin-bottom: 16px;
}
.personal-details-head {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 23px;
    margin-left: 18px;
    margin-top: 0px;
}
.edit-button-myprofile{
    display: flex;
    align-items: flex-end;
}
}
.byr-btn
.MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium
.css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* width: 1em; */
    /* height: 1em; */
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    /* font-size: 1.5rem; */
    width: 16px;
    height: 16px;
    color: rgb(253, 42, 128);
}
 