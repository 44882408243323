
.contact-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 25rem;
}


.contact-container > *:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.contact-email {
  /* position: relative;
  margin-bottom:2rem;
  width: 30%;
  min-width:300px;
  height:100%;
  border:1px solid black;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin-top: 2rem;
  padding: 2rem 1rem;
  transform:scale(0.8); */
  width: 269px;
height: 274px;
border-radius: 10px;
border: 1px solid rgba(0, 0, 0, 0.2);
align-items: center;
}
.contact-phone {
  /* position: relative;
  margin-bottom:2rem;
  width: 30%;
  min-width:300px;
  min-width:18rem;
  border:1px solid black;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin-top: 2rem;
  padding: 2rem 1rem;
  transform:scale(0.8); */
  width: 269px;
height: 274px;
border-radius: 10px;
border: 1px solid rgba(0, 0, 0, 0.2);
align-items: center;

}
.contact-bot {
  /* position: relative;
  margin-bottom:2rem;
  width: 30%;
  min-width:300px;
  min-width:18rem;
  border:1px solid black;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 2rem;
  padding: 2rem 1rem;
  transform:scale(0.8); */

  width: 269px;
  height: 274px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  align-items: center;
}
.contact-card {
  /* width: 100%;
  min-width:300px;
  padding: 2rem;
  padding-left:0; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 10rem;
  margin:2rem auto; */
  /* margin-top:2rem; */
  /* margin-bottom: 2.2rem; */
  /* border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

}

.contact-title {
  margin-bottom: 1.2rem;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.66);
  letter-spacing: 0.02em;

}

.contact-icon {
  font-size: 3.4rem !important;
  /* position: absolute;
  top: -15%;
  left: 38%; */
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  z-index: 10;
  background-color: #ffffff;
  border-radius: 50px;
  margin-top: -15%;
}

.contact-text {
  font-size: 15px;
  width: 75%;
  font-weight: 600;
  line-height: 18px;
  margin: 0 auto;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.66);
}
.contact-text-number {
  color: rgba(255, 129, 97, 1);
  font-size: 23px;
  font-weight: 600;
}
.contact-button {
  /* margin:2rem auto; */
  /* display:flex; */
  /* align-items:center; */
  /* justify-content: center; */
  /* width:20%; */
  /* margin-top: 4%; */
  /* margin-left:25%; */
  font-size: 18px;
  padding: 0.5rem 1rem;
  background: rgba(255, 97, 85, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  color: white;
  /* min-height: 50px; */
  /* height: 2.5rem;  */
  /* margin-left: 1%; */
  align-self: center;
  /* margin-top: 2rem; */
}
.contact-email-section {
  /* width: 60%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}
.contact-email-section-form {
  /* width: 80%; */
  min-width:40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  /* min-width:60rem; */
    /* border: 1px solid rgba(0, 0, 0, 0.2);' */
  /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2); */
  margin:0 auto;
}

.contact-email-section-icon {
  font-size: 2.5rem !important;
  /* position:absolute; */
  /* color: azure; */
  /* top:-7%; */
  /* left:40%; */
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  z-index: 10;
  background-color: #ffffff;
  border-radius: 50px;
  margin-top: 0%;
}
.Contact-Fields-head{
  display: flex;
  justify-content: space-evenly;
  margin-top: 2%;
  align-items: flex-start;
  /* margin: 0 auto; */
  width: 428px;
height: 46px;
font-size: 20px;
color: rgba(0, 0, 0, 0.8);


}
.contact-button-email-section {
  align-self: center;
}
.email-section-form-field {
  width: 70%;
  display: flex;

}
.contact-seperator{
  display: flex;
  justify-content: center;
  align-items: center;
  margin:32px auto;
}
.contact-field-name {
  align-self: center;
  width: 80%;
  border-radius: 100px;
  margin-top: 2rem;
  height: 2.5rem;
  align-self: flex-start;
  background-color: white;
  background: none;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.8);
}
.contact-methods-field-set{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  /* margin-bottom: 16px; */
}
.contact-button-field{
  display: flex;
  justify-content: flex-end;
  width: 65%;

}
.MuiOutlinedInput-root .email-field {
  align-self: center;
  width: 80%;
  /* border-radius: 50px; */
  /* min-height: 50px; */
  /* height: 2.5rem; */
  background-color: white;
  background: none;
  /* padding-left: 1%; */
  /* background-size: 3%; */
  font-weight: 600;
  font-size: 1.2rem;
  /* margin: 1rem; */
  color: rgba(112, 112, 112, 0.72);
  border: 0.5px solid rgba(112, 112, 112, 0.5);
  /* padding:0.5rem; */
}
/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 4px !important;
} */

#email-section-fullname{
    padding:1rem !important;
}
#email-section-email{
    padding:1rem !important;
}
#email-section-instructions{
  min-height: 100px;
  border-radius: 4px;
  padding-left: 2rem;
  padding-top: 2rem;
  font-size: 18px;
}
#email-section-instructions:focus{
    outline: 2px solid #ff5569;

}
#email-section-fullname-helper-text{
    position:absolute;
    left: 0%;
    bottom: -55%;
    font-size: 12px;
    letter-spacing: 0.03em;
}
#email-section-email-helper-text{
    position:absolute;
    left: 0%;
    bottom: -55%;
    font-size: 12px;
    letter-spacing: 0.03em;
}
#email-section-instructions-helper-text{
    position:absolute;
    left: 0%;
    bottom: -55%;
    font-size: 12px;
    letter-spacing: 0.03em;
}
.contact-container-field{
  max-width: 1057px;
    max-height: 100%;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

@media only screen and (max-width: 900px) {
  .contact-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
   flex-direction: column;
   gap: 2rem;
   min-height: 45rem;

}
.contact-container-field {
  min-width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  min-height: 30rem;
}
.contact-email-section-form {
  min-width: 25rem;
}
.contact-button-field {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}
.contact-seperator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px auto;
}
}
@media only screen and (max-width: 480px) {
  .contact-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
   flex-direction: column;
   margin-top: 4%;
}
.contact-container-field {
  min-width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  min-height: 30rem;
}
.contact-email-section-form {
  min-width: 260px;
  padding: 10px;
}
.contact-button-field {
  display: flex;
  justify-content: flex-end;
  width: 160px;
  align-items: flex-end;
}
.contact-methods-field-set {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  flex-direction: column;
}
.contact-seperator{
  display: none;
}
}
.Chat-live-icon{
  font-size: 3.4rem !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  z-index: 10;
  background-color: #ffffff;
  border-radius: 50px;
}
.get-help-gmail{
  color: rgba(255, 97, 85, 1);
font-size: 20px;

}
.contact-us-name-field{
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.contact-us-name-field
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  width: 272px;
  height: 48px;
  border-radius: 8px;
}
.contact-details-field{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;


}
.contact-details-form{
  width: 800px;
height: 328.5px;
display: flex;
justify-content: space-around;
flex-direction: column;
align-items: flex-start;
gap: 1rem;
}
.contact-textarea{
  width: 525px;
  height: 127px;
  border: 1px solid rgba(176, 176, 176, 1);
  border-radius: 4px;
  resize: none;
  padding: 1rem;


}
.contact-textarea:focus{
  outline-color:  #FF5569;
}
.manage-form-field-textarea{
  width: 80%;
}
.contact-methods-field{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 auto; */
  width: 100%;
}
.contact-methods{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:100%;
  /* margin: 0 auto; */
  max-width: 700px;
  gap: 1rem;

}
.logo-icon-field{
  display: flex;
  align-items: center;
}
.phone-container{
  width: 272px;
  height: 48px;
  /* border: 1px solid rgba(176, 176, 176, 1); */
  border-radius: 8px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 24px;
/* margin: 0 auto; */
}

.phone-container-whatsapp{
  width: 272px;
height: 48px;
/* background: linear-gradient(0deg, #25D366, #25D366),
linear-gradient(0deg, #B0B0B0, #B0B0B0); */
/* border: 1px solid rgba(176, 176, 176, 1); */
border-radius: 8px;
color: #000;
display: flex;
justify-content: center;
text-align: center;
align-items: center;
gap: 24px;
/* margin: 0 auto; */
}
.logo-name-field{
  text-align: left;
  width: 60%;
}
.contact-us-name-field
.css-1wc848c-MuiFormHelperText-root.Mui-error {
  position: absolute;
  color: #d32f2f;
  bottom: -39%;
  font-size: 12px;
  left: 5%;
}
.manage-form-field-textfield
.css-1wc848c-MuiFormHelperText-root.Mui-error {
  position: absolute;
  color: #d32f2f;
  bottom: -44%;
  font-size: 12px;
  left: 3%;
}
@media screen and (max-width: 480px){
  .help-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0rem;
    width: 330px;
}

.contact-us-name-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  width: 300px;
}
.contact-details-field{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.contact-details-form{
  width:265px;
  margin-top: 10rem;
}
.contact-textarea{
  width: 260px;
}
.contact-methods-field {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* margin: 0 auto; */
  width: 230px;
  flex-direction: column;
}
.phone-container {
  width: 300px;
  height: 48px;
  /* border: 1px solid rgba(176, 176, 176, 1); */
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  margin: 8px auto;
  gap: 8px;
}
.phone-container-whatsapp {
  width: 300px;
  height: 48px;
  /* background: linear-gradient(0deg, #25D366, #25D366), linear-gradient(0deg, #B0B0B0, #B0B0B0); */
  /* border: 1px solid rgba(176, 176, 176, 1); */
  color: #000;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  margin: 8px auto;
  gap: 8px;
}
.manage-form-field-textarea {
  width: 100%;
}

.contact-methods {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 300px;
  gap: 1rem;
  padding: 10px;
}
}