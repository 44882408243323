.customer-login-modal-container {
    width: 392px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.customer-login-modal-login {
    margin: 0 auto 16px auto;
    width: 100%;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    text-transform: uppercase;
    background: linear-gradient(90deg, #FD2A80 0%, #FE575C 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    /* padding:32px; */
}

.customer-login-modal-field-name {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

}

.customer-login-modal-field-signup {
    color: #1A1A1A;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 13px auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.customer-login-modal-field-terms {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 333px;
    margin: 16px auto;
}

.customer-login-forgot-password {
    color: #FD2A80 !important;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-color: #FD2A80;
    position: relative;
    bottom: 16px;
    align-self: flex-end;
    text-decoration: underline !important;

}
.forgot-otp-content{
    display: flex;
    justify-content: center;
    text-align: center;
    color: #1A1A1A;
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
margin-bottom: 32px;
line-height: 23px;
opacity: 0.6; 
}
.customer-login-forgot-password:hover {
    cursor: pointer;
    text-decoration-line: underline;
    color: #FD2A80;
    text-decoration-color: #FD2A80;

}

.customer-login-modal-field-divider {
    text-align: center;
    margin: 12px auto;
}

.customer-login-btn {
    border-radius: 8px;
    background: linear-gradient(90deg, rgba(253, 42, 128, 0.70) -5.66%, rgba(254, 87, 92, 0.70) 103.62%) !important;    
    width: 379px;
    height: 24px;
    padding: 10px 8px;
    display: flex;
justify-content: center;
align-items: center;
gap: 8px;
color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight:semi-bold;
line-height:  14px;
letter-spacing: -0.14px;
text-transform: uppercase;
}

.customer-login-btn:hover {
    background: linear-gradient(90deg, rgba(253, 42, 128, 0.70) 0%, rgba(254, 87, 92, 0.70) 100%) !important;
}

.customer-login-sec-button {
    color: #FD2A80 !important;
    border: 1px solid #FD2A80 !important;
    width: 379px;
    height: 24px;
    padding: 10px 8px;
    display: flex;
justify-content: center;
align-items: center;
gap: 8px;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight:semi-bold;
line-height:  14px;
letter-spacing: -0.14px;
text-transform: uppercase;
border-radius: 8px;

}

.customer-login-sec-button:hover {
    background: linear-gradient(90deg, rgba(253, 42, 128, 0.70) 0%, rgba(254, 87, 92, 0.70) 100%) !important;
    color: white !important;
}

.customer-login-signup-button {
    color: #FD2A80 !important;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 8px;    
    cursor: pointer;

}

.customer-login-signup-button:hover {
    background-color: white !important;
    /* color:white; */
}


.customer-signup-modal-container {
    width: 918px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.customer-signup-modal-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

}
.customer-signup-modal-row-gender {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-left:40px;
margin-bottom:24px;

}

.customer-signup-modal-field-terms-button{
    width:calc(97% - 56px);
    margin:24px 40px 0px 40px;;
    display:flex;
    justify-content:space-between;
    
}

.customer-signup-modal-field-terms{
    max-width:400px;
    font-size: 12px;
}

.customer-signup-modal-field-login{
    color: #1A1A1A;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px ;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.customer-signup-btn{
    background: linear-gradient(90deg, rgba(253, 42, 128, 0.70) 0%, rgba(254, 87, 92, 0.70) 100%) !important;
    width:90% !important;
    margin:0 auto !important;
}
.textfield-login-modal-fields{
    width: 390px;
    margin-bottom: 16px;
}
.para-otp-verify{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:968px){
    .customer-signup-modal-row {
       
    width: 85%;
    margin: 0 auto;
    max-width: 400px;
    flex-direction: column;

    }
    .customer-signup-modal-row-gender {
        /* width:95%; */
        /* margin:0 auto; */
        max-width:400px;
        /* flex-direction: column; */
        gap: 16px;
    }
    .customer-signup-modal-container {
        width: 400px;
        /* overflow: scroll; */
        /* padding-bottom:500px; */

    }
    .customer-signup-modal-field-terms-button{
        flex-direction: column;
    }
    .customer-login-modal-container {
        width: 330px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .customer-login-btn {
        border-radius: 8px;
        background: linear-gradient(90deg, rgba(253, 42, 128, 0.70) -5.66%, rgba(254, 87, 92, 0.70) 103.62%) !important;
        width: 317px;
        height: 24px;
        padding: 10px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: semi-bold;
        line-height: 14px;
        letter-spacing: -0.14px;
        text-transform: uppercase;
    }
    .customer-login-sec-button {
        color: #FD2A80 !important;
        border: 1px solid #FD2A80 !important;
        width: 317px;
        height: 24px;
        padding: 10px 8px;
        display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight:semi-bold;
    line-height:  14px;
    letter-spacing: -0.14px;
    text-transform: uppercase;
    border-radius: 8px;
    
    }
    .customer-signup-modal-field-terms {
        max-width: 400px;
        font-size: 12px;
    }
    .customer-signup-modal-field-login {
        color: #1A1A1A;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        margin-top: 16px;
    }
    .customer-login-signup-button {
        color: #FD2A80 !important;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        /* margin: 0 auto; */
        display: flex;
        justify-content: space-between;
        gap: 8px;
    }
    .customer-login-modal-login {
        margin: 0 auto 16px auto;
        width: 100%;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.32px;
        text-transform: uppercase;
        background: linear-gradient(90deg, #FD2A80 0%, #FE575C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        /* padding: 32px; */
    }

}
.otp-numbers-Buyers{
    width: 99%;
    display: flex;
    justify-content: center;
}
.otp-verify-error-buyers{
    color: #FF5569;
    font-size: 12px;
    position: relative;
    left: 106px;
    top: -34px;
}
@media screen and (max-width:780px){
    .textfield-login-modal-fields{
        width: 330px;
        margin-bottom: 16px;

    }
    .customer-login-modal-container {
        width: 330px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .customer-signup-modal-row-gender{
        width: 300px;
        display: flex;
        /* flex-direction: column; */
        gap: 16px;
    }
    .customer-signup-btn {
        background: linear-gradient(90deg, rgba(253, 42, 128, 0.70) 0%, rgba(254, 87, 92, 0.70) 100%) !important;
        width: 85% !important;
        margin: 0 auto !important;
    }
    .customer-login-btn {
        border-radius: 8px;
        background: linear-gradient(90deg, rgba(253, 42, 128, 0.70) -5.66%, rgba(254, 87, 92, 0.70) 103.62%) !important;
        width: 317px;
        height: 24px;
        padding: 10px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: semi-bold;
        line-height: 14px;
        letter-spacing: -0.14px;
        text-transform: uppercase;
    }
    .customer-login-sec-button {
        color: #FD2A80 !important;
        border: 1px solid #FD2A80 !important;
        width: 317px;
        height: 24px;
        padding: 10px 8px;
        display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight:semi-bold;
    line-height:  14px;
    letter-spacing: -0.14px;
    text-transform: uppercase;
    border-radius: 8px;
    
    }
    .form-gender-fields{
        display: inline-block;
        width: 300px;
    }.customer-signup-modal-field-terms {
        max-width: 400px;
        font-size: 12px;
    }
    .customer-signup-modal-field-login {
        color: #1A1A1A;
        font-family: Poppins;
        /* font-size: 16px; */
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-top: 16px;
        width: 91%;
    }
    .customer-login-signup-button {
        color: #FD2A80 !important;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        /* margin: 0 auto; */
        display: flex;
        justify-content: space-between;
        gap: 8px;
    }
    .customer-login-modal-login {
        margin: 0 auto 16px auto;
        width: 100%;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.32px;
        text-transform: uppercase;
        background: linear-gradient(90deg, #FD2A80 0%, #FE575C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        /* padding: 32px; */
    }
    
}
.register-privacy-link{
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
  }
  .para-otp-verify{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px auto;
  }
  .tooltip-trigger {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1000;
    /* Additional styling for your tooltip */
    /* Adjust position, color, font size, etc. as needed */
  }
  
  .tooltip-container:hover .tooltip-content {
    display: block;
  }
  .css-tn359l {
    /* width: 330px; */
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}