.seller-otp-login-right-content{
    width: 100%;
    /* max-width: 1200px; */
    /* max-height: 1000px; */
    /* height: 100vh; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    gap: 25px;

}
.seller-otp-login-leftScreen {
    flex: 1 1;
    height: 100vh;
    max-height: 1900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 2rem; */
    margin: 0 auto;
    background: linear-gradient(359.79deg, #FF6155 -9.58%, rgba(252, 38, 120, 0.3875) 104.55%, rgba(255, 97, 85, 0) 126.21%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.seller-otp-login-leftScreen-content{
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;gap: 2rem;
margin: 19% auto;
}
.seller-otp-login-title{
font-weight: 600;
font-size: 30px;
line-height: 42px;
color: white;

}
.tri-img{
    width: 469px;
    height: 302px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 20px; */
    margin: -10% auto;
}
.seller-otp-login-container {
    max-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;

}
.seller-otp-login-left-desc{
font-weight: 600;
font-size: 18px;
line-height: 24px;
text-align: center;
color: white;
display: flex;
justify-content: center;
align-items: center;
margin: 0 auto;
    width: 95%;
}

.seller-otp-login-rightScreen{
    flex: 1 1;
    max-height: 1900px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: white;
}
.seller-otp-login-content-title{
    color: rgba(0, 0, 0, 0.60);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
}
.seller-Otplogin-textfield{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 8px;
    margin: 0 auto;
    width:100%
  }
.seller-otp-login-content-desc{
    /* min-width: 80%; */
    width: 65%;
    /* line-height: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
    color: rgba(112, 112, 112, 0.72);
    text-align: center;
}
.seller-otp-leftScreen-image{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.seller-otplogin-request{
    width: 32%;
font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
text-align: center;
text-decoration-line: underline;
color: #FF2171;
cursor: pointer;

}
.seller-otp-card {
    /* margin: 2% 3%; */
    width: 35%;
    min-width: 400px;
    /* min-height: 85%; */
    max-width:700px;
    background: #ffffff;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);s */
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    padding-top:5%;
    transform: scale(0.8);
    /* transform-origin: 0 0; */
}
.ResetPassword_LeftScreen_Image{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
}
.seller-otp-head {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    margin: 5% auto;
    color: rgba(248, 152, 128, 1);
    margin-top:5rem;
    text-align: center;
}

.seller-otp-auth-text {
    /* color: rgba(248, 152, 128, 1); */
    /* font-size:1.3vw; */
    font-size: 1.6rem;
    max-width: 65%;
    font-weight: 400;
    margin: 5% auto;
    text-align: center;

}
.seller-otp-page-form{
    width:100%;
    display:flex;
    flex-direction:column;
}
.seller-otp-page-email-field{
    width:100%;
    position:relative;
    margin:3rem auto;
}
.seller-otp-email .MuiOutlinedInput-root{
    align-self: center;
    width: 60%;
    /* border-radius: 100px; */
    min-height: 50px;
    height: 2.5rem; 
    background-color:white;
    padding-left: 150%;
    background-size: 8%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3% ;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
#number-helper-text{
    position: absolute;
    bottom: -20%;
    left: 18%;
    font-size: 12px;
}
#number{
    padding:  1rem;
}

.seller-otp-page-phone-field {
    width: 75%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    margin:0 auto;
    text-align: center;
    margin-bottom:1.5rem;
}
/* .seller-otp-phone-icon {
    position: absolute;
    left: 20%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
    top: 40%;
} */
.seller-otp-phone-validation-text {
    position: absolute;
    bottom: -8%;
    color: red;
    opacity:0.7;
    font-size: 0.8rem;
    padding-top: 1rem;
    text-align: center;
}
.seller-otp-number {
    width: 100%;
    /* border-radius: 100px; */
    /* min-height: 50px; */
    height: 7vh;
    padding-left: 15%;
    /* background: url("/image 6.png") no-repeat 2vw; */
    background-size: 8%;
    font-weight: 400;
    /* font-size: 1.2vw; */
    font-size: 1.2rem;
    line-height: 2vw;
    margin: 5% auto;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

.seller-otp-send-otp {
    margin: 5% 35%;;
    /* font-size: 1.3vw; */
    font-size: 1.3rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}

.seller-password-signin {
    margin: 3% auto;
    font-weight: 600;
    /* font-size: 1.6vw; */
    font-size: 1.5rem;
    line-height: 2vw;
    color: #000000;
}

    .seller-password-signin span {
        font-weight: 600;
        /* font-size: 1.4vw; */
        line-height: 2vw;
        text-decoration-line: underline;
        color: #019dbf;
    }

    .seller-password-signin span:hover {
        cursor:pointer;
    }
        .seller-otp-terms {
    margin: 5% 5%;
    font-style: normal;
    font-weight: 600;
    /* font-size: 1.4vw; */
    font-size: 1.4rem;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
}

    .seller-otp-terms span {
        color: #707070;
    }

.seller-otp-logo {
   /* position: fixed; */
   position:absolute;
   /* margin: 1% 1%; */
   /* bottom:-50%; */
   top:-2.5rem;
   /* left:43%; */
   width:10rem;
   height:10rem;
   /* z-index: 100;; */
}

.seller-otp-number:focus {
    outline: none;
}

.seller-otp-number[type=number] {
    -moz-appearance: textfield;
}

.seller-otp-number::-webkit-outer-spin-button,
.seller-otp-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@media only screen and (min-width: 1300px) {
    .seller-otp-head {
        font-size: 2.0rem;
    }
    .seller-Otplogin-textfield {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 8px;
        margin: 0 auto;
        width: 90%;
    }
}
.seller-otp-number .MuiOutlinedInput-root {
    align-self: center;
    width: 50%;
    min-height: 50px;
    height: 2.5rem;
    background-color: #efefef;
    padding-left: 9%;
    background-size: 5%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3%;
    min-width: 250px;
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
}


@media only screen and (max-width: 480px) {
.seller-otp-login-rightScreen{
    width: 100%;
}
.seller-otplogin-request {
    width: 60%;
}
.seller-otp-login-content-desc {
    width: 100%;}
.seller-otp-login-leftScreen{
display: none;
}
.seller-otp-login-content-title {
    font-weight: 500;
    font-size: 20px;
    color: #707070;
    margin: 0 auto;
}
.admin-otp-page-phone-field {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1.5rem;
}
.seller-reset-page-email-field
.seller-otp-phone-icon {
    position: absolute;
    left: -9%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.4rem;
    top: 37%;
}
.seller-reset-page-email-field
.css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    color: #d32f2f;
    bottom: -23%;
    font-size: 10px;
    left: 4%;
}
.seller-Otplogin-textfield {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 8px;
    margin: 0 auto;
    width: 80%;
}
}
.requet-to-change-phone-number{
font-size: 18px;
color: #FF2171;
margin-bottom: 38px;
}
@media only screen 
    and (device-width : 414px) 
    and (device-height : 896px) 
    and (-webkit-device-pixel-ratio : 2) {
 .seller-otp-phone-icon {
        position: absolute;
        left: 15%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.6rem;
        top: 38%;
    }
    .seller-otp-login-content-title {
        font-family: 'Barlow';
        font-weight: 600;
        font-size: 30px;
        color: #707070;
        width: 68%;
    }
    .seller-Otplogin-textfield {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 8px;
        margin: 0 auto;
        width: 77%;
    }
     }
     @media only screen and (min-width: 375px){
        .seller-otp-phone-icon {
            position: absolute;
            left: 2%;
            color: rgba(112, 112, 112, 0.72);
            font-size: 1.4rem;
            top: 37%;
        }
     }
     @media only screen and (min-width: 429px){
        .seller-otp-phone-icon {
            position: absolute;
            left: -1%;
            color: rgba(112, 112, 112, 0.72);
            font-size: 1.4rem;
            top: 37%;
        }
        #number-helper-text {
            position: absolute;
            bottom: -20%;
            /* left: 19%; */
            font-size: 12px;
        }

     }
     .seller-otp-phone-icon {
        position: absolute;
        left: 12%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.4rem;
        top: 37%;
    }
    @media only screen and (max-width: 480px){

.admin-reset-page-phone-field
.css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    color: #d32f2f;
    bottom: -12%;
    font-size: 12px;
    left: 15%;
    width: 100%;
}
}