.react-toast{
    /* color:green; */
    /* width:10%; */
    width:100%;
    height: 100px;
    position: fixed;
    top: 50px;
    /* left: 45%; */
    /* padding: 8px 24px; */
    /* border-radius: 8px; */
    /* background: #FFF; */
    z-index: 100000;
    transform: translateY(-500px);
    transition: 1.4s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-toast-container{
    /* color:green; */
    /* width:10%; */
    /* width:30%; */
    min-width:300px;
    min-height: 30px;
    margin:0 auto;
    /* position: fixed; */
    /* top: 50px; */
    /* left: 45%; */
    padding: 8px 24px;
    border-radius: 8px;
    background: #FFF;
    z-index: 1000;
    /* transform: translateY(-500px); */
    /* transition: 1.4s; */
    display: inline-block;
    align-items: center;
    justify-content: space-between;
}
.react-toast.show {
  transition: 1.4s;
    transform: translateY(0px);
}


/* Success toast styles */
.react-toast-container.success {
    /* background-color: #029F4A; */
    background-color: rgb(2, 159, 74 ) !important;
    color: white;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.16px;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.16px;
display: flex;
justify-content: center;
gap: 1rem;
align-items: center;
  }
  
  /* Failure toast styles */
  .react-toast-container.error {
    background-color: red !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    color: #fff;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.16px;
display: flex;
justify-content: center;
gap: 1rem;
align-items: center;
  }
  
  /* Warning toast styles */
  .react-toast-container.warning {
    align-items: center;
    background-color: #ffff0075;
    display: flex;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    gap: 1rem;
    justify-content: center;
    letter-spacing: -.16px;
    line-height: normal;
  }
  
  /* Icon styles (adjust based on your icon component) */
  .react-toast-container .icon {
    margin-right: 10px;
  }