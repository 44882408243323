.size-icon{
    font-family: "Barlow";
    border:1px solid black;
    font-size:20px;
    padding:0.5rem;
    margin:0.5rem;
    border-radius:100%;
    width:2rem;
    height: 2rem;
    min-width:2rem;
    display:flex;
    align-items:center;
    justify-content:center;
    
}
.size-icon:hover{
    cursor: pointer;

}
@media only screen and (max-width: 480px) {
.size-icon {
    font-family: "Barlow";
    border: 1px solid black;
    font-size: 18px;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 100%;
    width: 2rem;
    min-width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}}