.buyers-my-account{
  font-family: 'Barlow';

}

.buyers-my-account-container{
  /* width:1300px; */
  /* min-width:996px;  */
  margin:0 auto;
}
  .my-account-head{
      font-size:2rem;
      margin: 1rem auto 2rem auto;
  }

  /* .buyers-page-footer{
    padding-top:120vh;
  } */

  .helper-my-account-container {
    width: 100%;
    max-width: 1100px;
    margin: 2rem auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5rem;
    /* justify-content: flex-start; */
    /* margin-left: 2rem; */
    /* align-content: flex-start; */
    align-items: flex-start;
    justify-content: center;
}

  .my-account-action-card{
    font-family: "Barlow";
    width:250px;
    background-color: rgb(245, 242, 242);
    border: 1px solid rgba(0, 0, 0, 0.2);
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem;
    /* min-height:10rem; */
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    font-size:1.6rem;
    text-align:center;
  }

  .my-account-action-card:hover{
    cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  }

  .login-modal {
    /* padding:1rem; */
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    /* min-height:300px; */
    min-width:400px;
    width:35%;
    margin:2rem auto;
    /* margin-left:0; */
    transform:scale(0.8,0.8);
    max-height: 850px;
    overflow: scroll;
    box-sizing: border-box;
  }




  @media screen and (max-width:480px) { 
    .buyers-my-account-container{
      /* width:1300px; */
      width:100%;
      min-width:0;
      margin:0 auto;
    }
    .css-oi8s4w {
    min-width:0px;
    width:400px !important;
    }
    .login-modal {
      /* padding:1rem; */
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      /* min-height:300px; */
      min-width:500px;
      width:60%;
      margin:2rem auto;
      /* margin-left:0; */
      transform:scale(0.8,0.8);
      max-height: 850px;
      overflow: scroll;
      box-sizing: border-box;
    }
  
  }