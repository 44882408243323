

.seller-otp-verify-container {
    font-family: "Barlow";
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: conic-gradient(from 0deg at 50% 50%, rgba(212, 240, 240, 0.52) 0deg, #FFFFFF 154.87deg, rgba(212, 240, 240, 0.52) 360deg);
}

.seller-otp-top-left-rect {
    position: fixed;
    min-width: 450px;
    /* width: 45vw; */
    height: 30%;
    left: -378px;
    top: -9.16px;
    z-index: -1;
    background: linear-gradient(119.13deg, rgba(79, 231, 235, 0.35) 62.17%, rgba(79, 231, 235, 0) 90.1%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transform: matrix(0.98, -0.16, 0.19, 0.99, 0, 0);
}

.seller-otp-verify-logo-container{
    position:absolute;
    /* margin: 1% 1%; */
    /* bottom:-50%; */
    top:-7.5rem;
    /* left:43%; */
    width:10rem;
    height:10rem;
    background: linear-gradient(161.76deg, #FFFFFF 60.25%, rgba(1, 186, 191, 0) 94.72%);
mix-blend-mode: normal;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border: 1px solid white;
padding:2rem;
border-radius:50%;;
}



.seller-otp-top-right-rect {
    position: fixed;
    width: 30%;
    height: 30%;
    right: -50px;
    top: -50px;
    opacity: 0.2;
    background: linear-gradient(252.56deg, #4FE7EB 82.37%, rgba(79, 231, 235, 0) 93.95%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transform: matrix(0.98, -0.16, 0.19, 0.99, 0, 0);
}

.seller-otp-verify-card {
    /* margin: 2% 3%; */
    width: 35%;
    min-width: 400px;
    /* min-height: 85%; */
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    transform: scale(0.8);
    /* transform-origin: 0 0; */
}

.seller-otp-verify-head {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2% 5%;
    /* font-size: 2.0vw; */
    font-size: 1.8rem;
    margin: 5% auto;
    color: rgba(52, 108, 116, 1);
    margin-top:10rem;

}

.seller-otp-verify-auth-text {
    color: rgba(52, 108, 116, 1);
    /* font-size:1.3vw; */
    font-size: 1.3rem;
    max-width: 85%;
    font-weight: 600;
    margin: 5% auto;
}
.seller-otp-numbers{
    /* max-width:400px; */
}
.seller-otp-verify-verify-otp {
    /* max-width:400px; */
    margin: 5% auto;
    /* font-size: 1.3vw; */
    font-size: 1.3rem;
    padding: 0.5rem 0.5rem;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
        padding:0.5rem 1.5rem;

}
    .seller-otp-verify-verify-otp:hover{
        cursor:pointer;
    }
    .seller-otp-verify-signUp {
        margin: 3% auto;
        font-weight: 600;
        /* font-size: 1.6vw; */
        font-size: 1.6rem;
        color: #000000;
    }

    .seller-otp-verify-signUp span {
        font-weight: 600;
        /* font-size: 1.4vw; */
        font-size: 1.4rem;
        text-decoration-line: underline;
        color: #019dbf;
    }
        .seller-otp-verify-signUp span:hover{
            cursor:pointer;
        }

        .seller-otp-verify-resend-otp {
            min-height: 13%;
            margin: 2% auto;
            font-weight: 600;
            /* font-size: 1.2vw; */
            font-size: 1.2rem;
            color: #000000;
        }

    .seller-otp-verify-resend-otp span {
        display: none;
        font-weight: 600;
        text-decoration-line: underline;
        color: #FF5E5E;
    }
.seller-otp-verify-error{
    color:red;
    font-size:1rem;
}
.seller-otp-verify-terms {
    min-height: 13%;
    margin: 2% 5%;
    font-style: normal;
    font-weight: 600;
    /* font-size: 1.4vw; */
    font-size: 1.4rem;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
}

    .seller-otp-verify-terms span {
        color: #707070;
    }

.seller-otp-verify-logo {
    /* position: fixed; */
    position:absolute;
    /* margin: 1% 1%; */
    /* bottom:-50%; */
    top:-2.5rem;
    /* left:43%; */
    width:10rem;
    height:10rem;
    /* z-index: 100;; */
}

.seller-otp-verify-otp {
    width: 80%;
    /* align-self: center; */
    margin: 1% 5%;
    /* display:none; */
        padding:0.5rem 1rem;
/*  */
}

.seller-otp-verify-otp-verify {
    color: #000000;
    /* font-size:2vw; */
    font-size: 1rem;
    display: inline-block;
    margin: 2rem 1.2rem;
    width: 2rem;
    /* padding:0.5rem 1rem; */
    height: 12%;
    /* width:2rem; */
    /* height:1rem; */
    text-align: center;
    border-right: none;
    border-left: none;
    border-top: none;
}

    .seller-otp-verify-otp-verify:focus {
        outline: none;
    }

    .seller-otp-verify-otp-verify[type=number] {
        -moz-appearance: textfield;
    }

    .seller-otp-verify-otp-verify::-webkit-outer-spin-button,
    .seller-otp-verify-otp-verify::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
