.landing-page-trending{
    width:100%;
    background-color: rgba(244, 242, 242, 1);
    display:flex;
    flex-direction:column;
    margin: 3rem auto;
    padding:2rem 0;
    
}

.landing-page-trending-products{
    display:flex;
    align-items:center;
    justify-content:space-around;

}
.landing-page-trending-products-text{
    width:22%;
    /* max-width:300px; */
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    padding:1.5%;
    gap:1rem;
}

.landing-page-trending-products-head{
    font-size:60px;
    text-align:center;
}
.landing-page-trending-products-desc{
    font-size:20px;
    text-align:center;

}
.landing-page-trending-products-carousel{
    overflow: hidden;
    width:70%;
    /* flex:1; */
    flex-grow:1;
}


