.cart-layout {
  display: flex;
  /* align-items: flex-start; */
  /* align-items: center; */
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #ffffff;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  /* box-shadow: 4px 0px 4px 4px rgba(0, 0, 0, 0.08); */
  /* flex-direction: column; */
}

.cart-card-layout {
  /* min-width: 1000px; */
  margin-top: 2rem;
 
}
.cart-checkout-layout {
  min-width: 300px;
  /* height: 100%; */
  /* min-height: 306px; */
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  margin-top: 4.5rem;
  /* margin-left: 7%; */
  /* padding: 1.5rem; */
  /* transform: scale(0.9); */
  /* transform-origin: 0 0; */
}
.my-cart-head{
  font-weight:600;
    font-size:2rem;
    margin: 1rem 5%;
    position:relative;
    /* right:2.5rem; */
}
.cart-order-summary-text {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
}
.items-count {
  font-size: 1rem;
  margin: 1rem 1rem;
}
.cart-checkout-row {
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 1rem;
  color: rgba(0, 0, 0, 1);
}
.cart-checkout-row-dark {
  display: flex;
  font-size: 12px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 1rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
}
.cart-checkout-layout .css-9mgopn-MuiDivider-root {
  width: 85%;
  margin: 0 auto;
  margin-top: 1rem;

  color: rgba(0, 0, 0, 0.2);
}
.checkout-button {
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 20px;
  background: rgba(248, 152, 128, 1);
  border-radius: 15px;
  width: 95%;
  gap: 2rem;
  margin: 0.5rem auto;
  /* margin-left: 30%; */
  padding: 0.8rem 0rem;
  color: white;
}

.checkout-button-disabled {
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 1.4rem;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    rgba(248, 152, 128, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 95%;
  margin: 2rem auto;
  /* margin-left: 30%; */
  padding: 0.5rem 0;
}
.checkout-button:hover {
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.cart-card-layout-border {
  min-width: 0 !important;
  max-width: 1000px !important;
  /* margin-left: 5%; */
  max-width: 1200px;
  margin-bottom: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  /* margin-left: 10rem; */
  padding: 2rem;
  /* margin-left: 0rem; */
  width: 620px;
  /* height: 96%; */
  max-height: 100%;
}

.cart-redirect-login {
  width: 45%;
  font-size: 2rem;
  display: flex;
  margin: 1rem;
  margin-left: 18%;
  padding: 0.7rem;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.warning-icon-red {
  color: red;
  font-size: 2rem;
}
.login-link {
  text-decoration-line: underline;

  color: #019dbf;
}
/* .selected-address-card > * {
  background: rgba(248, 152, 128, 1);
} */
/* .selected-address-card > *:hover {
  background: rgba(248, 152, 128, 1);
} */

.cart-email-heading {
  margin-left: 2rem;
  font-size: 30px;
  letter-spacing: 0.02em;
  font-weight: bolder;
}

.cart-address-cards{
  /* display:grid;
  grid-template-columns: auto auto; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  flex-direction: column;
}

.cart-address-heading {
  margin-left: 2rem;
  font-size: 20px;
}

.payment-page-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.payment-page-form-field {
  width: 100%;
  position: relative;
}
.payment-form-field-row .MuiOutlinedInput-root {
  align-self: center;
  width: 90%;
  /* border-radius: 100px; */
  min-height: 40px;
  height: 1.6rem;
  background-color: white;
  background: none;
  padding-left: 3%;
  background-size: 3%;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0.5rem 0rem;
  color: rgba(112, 112, 112, 0.72);
  /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
}

.payment-field-heading {
  font-size: 20px;
  margin: 0.5rem 5%;
}
.payment-expiry-cvv {

    display: flex;
    margin: 0.5rem 5%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
}
.payment-zipcode {
  display: flex;
  margin: 0.5rem 5%;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
/* .payment-expiry .MuiOutlinedInput-root {
  width: 50%;
  border-radius: 100px;
  min-height: 40px;
  height: 1.6rem;
  background-color: white;
  background: none;
  padding-left: 3%;
  background-size: 3%;
  font-weight: 600;
  font-size: 1.2rem;
  color: rgba(112, 112, 112, 0.72);
  border: 0.5px solid rgba(112, 112, 112, 0.5);
}
.payment-cvv .MuiOutlinedInput-root {
  width: 50%;
  border-radius: 100px;
  min-height: 40px;
  height: 1.6rem;
  background-color: white;
  background: none;
  padding-left: 3%;
  background-size: 3%;
  font-weight: 600;
  font-size: 1.2rem;
  color: rgba(112, 112, 112, 0.72);
  border: 0.5px solid rgba(112, 112, 112, 0.5);
}
.payment-zip .MuiOutlinedInput-root {
  width: 80%;
  border-radius: 100px;
  min-height: 40px;
  height: 1.6rem;
  background-color: white;
  background: none;
  padding-left: 3%;
  background-size: 3%;
  font-weight: 600;
  font-size: 1.2rem;
  color: rgba(112, 112, 112, 0.72);
  border: 0.5px solid rgba(112, 112, 112, 0.5);
} */
.pincode {
  align-self: flex-end;
}
.payment-field-dual {
  display: flex;
  margin: 0.5rem 2.5%;
}

.payment-form-total {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
  /* font-size:rem; */
  width:100%;
}
.form-total {
  font-size:20px;
  font-weight: bold;
}

.field-spacing {
  margin: 1rem 0%;
}

/* helpertext */

#payment-form-card-number-helper-text,
#payment-form-card-holder-name-helper-text,
#payment-form-firstName-helper-text,
#payment-form-lastName-helper-text,
#payment-form-street-helper-text,
#payment-form-landmark-helper-text {
  position: relative;
  left: 5%;
  top: 75%;
  font-size: 1rem;
}
#payment-form-pincode-helper-text,
#payment-form-expiry-date-helper-text,
#payment-form-cvv-helper-text {
  position: relative;
  left: 0%;
  top: 75%;
  font-size: 1rem;
}

.return-to-shipping {
  font-size: 20px;
  margin: 1rem;
  font-weight: 900;
  letter-spacing: 0.01em;
  text-decoration: underline;
  color: #000000A8;
  margin-left: 2rem;
  
}
.return-to-shipping:hover {
  cursor: pointer;
  text-decoration: underline;
}
.mandatory{
  color: rgba(0, 0, 0, 0.4);
  /* margin: 1rem 40%; */
  font-size:1.2rem;
text-align: center;
}

.payment-error{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-around;
  min-height:35rem;
}

.payment-error-message{
  font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 15px;
/* line-height: 30px; */

color: rgba(0, 0, 0, 0.8);
}

.payment-error-order-id{
  font-weight: 600;
font-size: 1.6rem;
text-align: center;

color: rgba(0, 0, 0, 0.8);
}

.error-payment-field{
width:90%;
}
.error-payment{
  font-size:1.8rem;
}

.payment-success{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-around;
  min-height:30rem;
}

.verified-icon {
  color:rgba(248, 152, 128, 1);
  font-size:8rem !important;
}
.payment-success-title{
  font-size:30px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;

}
.payment-success-message{
  font-size: 20px;
  color: rgba(0, 0, 0, 0.4);
  margin: 0 auto;
  /* width: 100%; */
  align-self: center;
  text-align: center;
}

@media screen and (max-width:1400px) {
.cart-card-layout-border{
  width: 570px;
  margin-left: 2rem;
}
.login-email-cart .MuiOutlinedInput-root {
  align-self: center;
  width: 550px;
  max-width: 550px;
  min-width: 220px;
  min-height: 50px;
  height: 2.5rem;
  background-color: white;
  background-size: 3%;
  font-weight: 300 !important;
  font-size: 1.2rem;
  border-radius: 10px;
  color: rgba(112, 112, 112, 0.72);
  border: 0.5px solid rgba(112, 112, 112, 0.11);
  margin: 0 auto;
}
}



/* //tracker styles */

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
  margin-bottom: 20px;
  width: 60%;
  transform: scale(0.8);
}
.step-name {
  font-size: 20px;
  color: #000000A8;
  font-weight: bold;
    letter-spacing: 0.02em;

}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}



.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #FC2975;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}
.step-counter.default {
  background-color: #FC2975;
  /* transform:scale(1.4)s */
}
.step-counter.active {
  background-color: #FC2975;

}
.stepper-item.completed .step-counter {
  background-color: #FC2975;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #FC2975;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.login-email-cart .MuiOutlinedInput-root {
  align-self: center;
  width: 550px;
  max-width: 855px;
  min-width: 220px;
  min-height: 50px;
  height: 2.5rem;
  background-color: white;
  /* padding-left: 8%; */
  background-size: 3%;
  font-weight: 300 !important;
  font-size: 1.2rem;
  border-radius: 10px;
  /* margin: 3%; */
  color: rgba(112, 112, 112, 0.72);
  border: 0.5px solid rgba(112, 112, 112, 0.11);
  margin: 0 auto;
  margin-left: 1rem;
}
@media screen and (max-width: 1000px) {
  .cart-layout {
  flex-direction: column;
  }
  /* .cart-checkout-layout{
    width:90%;
    margin-bottom:2rem;
  } */
  .cart-card-layout{
    width:100%;
  }
  .cart-card-layout-border{
  margin:0rem auto;
  }
  .payment-page-form {
    width:100%;
  }
}

@media screen and (max-width: 480px) {
  .page-content{
    width:380px;
    min-width:0px;
    margin:0 auto;
    background: #FFFFFF;
border-radius: 10px;
  }
  .cart-card-layout {
    min-width: 0px;
    margin-top: 2rem;
    margin-left: -8%;
}
.cart-checkout-layout {
  min-width: 360px;
  height: 100%;
  /* min-height: 22rem; */
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  margin-top: 2rem;
  margin-left: -3%;
  padding: 1rem;
  transform: scale(0.9);
  transform-origin: 0 0;
}
.cart-address-cards {
  display: grid;
  /* grid-template-columns: auto auto; */
}
.payment-zipcode {
  display: flex;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  flex-direction: column;
}
.login-email-cart .MuiOutlinedInput-root {
  align-self: center;
  width: 400px;
  min-width: 220px;
  min-height: 50px;
  height: 2.5rem;
  background-color: white;
  /* padding-left: 8%; */
  background-size: 3%;
  font-weight: 300 !important;
  font-size: 1.2rem;
  border-radius: 10px;
  color: rgba(112, 112, 112, 0.72);
  border: 0.5px solid rgba(112, 112, 112, 0.11);
  margin: 0 auto;
}
.cart-email-heading {
  /* margin-left: 2rem; */
  font-size: 30px;
  letter-spacing: 0.02em;
  font-weight: bolder;
}
.cart-address-heading {
  /* margin-left: 2rem; */
  font-size: 20px;
}
.cart-email-field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  flex-direction: column;
  /* margin-left: -3.2rem; */
}
}

.shipping-information-fields{
  max-width: 1059px;
height: 789px;
margin: 0 auto;
display: flex;
justify-content: center;
gap: 2rem;
align-items: center;
flex-direction: column;
}
.cart-address-button-field{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}