.byr-register-img{
    width: 80%;
}
.byr-signup-head{
    font-weight: 600;
    font-size: 40px;
    color: #FF6155;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 24%;
    margin-top: 5%;
}
.signup-signin-byr{
    font-weight: 600;
    font-size: 19px;
    letter-spacing: 0.01rem;
    margin-left: 16%;
    width: 100%;
    margin: 10% auto;
    letter-spacing: 0.1px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 0.6rem;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0.03em;
}
.byr-signup-card-container {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transform: scale(0.8);
    transform-origin: 0 0;
    height: 100%;
    align-self: center;
    text-align: center;
}
.byr-registration-gender {
    font-weight: 600;
    font-size: 1.6rem;
    margin: 0.5rem 0.5rem;
    color: #FF6155;
    align-self: flex-start;
    margin-left: 3%;
}
.signup-gender-radio-byr .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #FF6155 !important;
}
.signup-first
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
}
.signup-number-field
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
}

.signup-email-field
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
}
.signup-calendar-field
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
}
.signup-password-field
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
}
/* .signup-calendar-seller {
    color: #70707054;
    font-size: 18px;
    border-radius: 8px;
} */
.signup-password-field
#enter-password-helper-text {
    position: absolute;
    bottom: -25%;
    left: 10%;
    font-size: 16px;
    letter-spacing: 0.02em;
}

.signup-password-field
#resetPassword-helper-text {
    position: absolute;
    bottom: -25%;
    left: 10%;
    font-size: 16px;
    letter-spacing: 0.02em;
}
.signup-number-field
#signup-number-helper-text {
    position: absolute;
    bottom: -25%;
    left: 10%;
    font-size: 1rem;
}
.signup-register-byr {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.01rem;
    /* margin-left: 16%; */
    width: 100%;
    /* margin-top: 10%; */
    letter-spacing: 0.02em;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    color: rgba(0, 0, 0, 1);
    margin: 0 auto;

}
.buyer-register-terms-text {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    /* width: 100%; */
    letter-spacing: 0.1px;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
    /* margin-bottom: 0.5rem; */
}
@media (min-width: 480px){
    .sigup-button-fields-byr {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }
.byr-signup-page-form {
    width: 115%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-left: 30%;
    margin-top: 1%;
}
.byr-signup-page-form {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.signup-row-byr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* margin: 2% 0%; */
    /* gap: 1rem; */
}
.byr-registration-gender {
    font-weight: 600;
    font-size: 1.6rem;
    margin: -0.5rem 0.5rem;
    color: #FF6155;
    align-self: flex-start;
    margin-left: 3%;
}
.byr-signup-page-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    /* margin: 0 auto; */
    justify-content: center;
    align-items: center;
    /* margin-left: 10%; */
}
.byr-signup-card-container {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transform: scale(0.8);
    transform-origin: 0 0;
    height: 100%;
    align-self: center;
    text-align: center;
}
.signup-row-byr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 2% 0%; */
    gap: 1rem;
}
.registration-terms-text-byr{
    /* margin: 3rem 1rem; */
    font-size: 1.2rem;
    color: rgba(112, 112, 112, 0.72);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* gap: 1rem; */
    /* margin-top: 9%; */
    /* margin-left: 6%; */
}
.sigup-button-fields-byr {
    display: flex;
    justify-content: flex-start;
    /* width: 75%; */
}
}

@media only screen and (max-width: 912px){
.signup-row-byr{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 2% 0%; */
    /* gap: 1rem; */
    min-width: 264px;
    margin-left: 15%;
    flex-direction: column;
}
.signup-row-byr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    /* margin: 2% 0%; */
    gap: 1rem;
}
.registration-terms-text-byr {
    /* margin: 3rem 1rem; */
    font-size: 1.2rem;
    color: rgba(112, 112, 112, 0.72);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* gap: 1rem; */
    /* margin-top: 9%; */
    /* margin-left: 6%; */
}.byr-signup-page-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
}
.sigup-button-fields-byr {
    display: flex;
    justify-content: flex-start;
    /* width: 75%; */
}
}
.byr-signup-page-form {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.signup-row-byr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    /* margin: 2% 0%; */
    /* gap: 1rem; */
}
.registration-terms-text-byr{
    margin: 3rem 1rem;
    line-height: 22px;
    font-size: 1.2rem;
    color: rgba(112, 112, 112, 0.72);
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 1rem; */
    /* margin-top: 5%; */
}
@media only screen and (max-width: 620px){
.registration-terms-text-byr {
    margin: 0rem 1rem;
    font-size: 1.2rem;
    color: rgba(112, 112, 112, 0.72);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* gap: 1rem; */
    /* margin-top: 9%; */
    margin-left: 6%;
}}

.sigup-row-fields-byr{
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 auto;
}
.signup-first-title{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);

}
.byr-signup-firstName .MuiOutlinedInput-root {
    align-self: center;
    width: 100%;
    border-radius: 4px;
    min-height: 50px;
    height: 2.5rem;
    background-color: white !important;
    background: none;
    padding-left: 15%;
    background-size: 3%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3%;
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
.signup-number-byr .MuiOutlinedInput-root {
    align-self: center;
    width: 100%;
    border-radius: 4px;
    min-height: 50px;
    height: 2.5rem;
    background-color: white !important;
    background: none;
    padding-left: 15%;
    background-size: 3%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3%;
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
.signup-email-byr .MuiOutlinedInput-root {
    align-self: center;
    width: 100%;
    border-radius: 4px;
    min-height: 50px;
    height: 2.5rem;
    background-color: white !important;
    background: none;
    padding-left: 15%;
    background-size: 3%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3%;
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
.register-footer-content-byr{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
    margin-left: 20%;
    margin-top: 16%;
}
.byr-register-page-terms{
    margin: 0 auto;
    width: 100%;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: rgba(112, 112, 112, 0.72);
}
.sigup-button-fields-byr{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115%;
}
.signup-email-field-byr {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signup-password-field-byr{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signup-number-field-byr {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signup-first-byr{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signup-first-byr
.css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    color: #d32f2f;
    bottom: -14%;
    font-size: 15px;
   left:0;
    letter-spacing: 0.03em;
}
.signup-number-field-byr
.css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    color: #d32f2f;
    bottom: -14%;
    font-size: 15px;
   left:0;
    letter-spacing: 0.03em;
}
.signup-email-field-byr
.css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    color: #d32f2f;
    bottom: -14%;
    font-size: 15px;
   left:0;
    letter-spacing: 0.03em;
}
.signup-password-field-byr
.css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    color: #d32f2f;
    bottom: -14%;
    font-size: 15px;
   left:0;
    letter-spacing: 0.03em;
}