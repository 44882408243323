.wallet-error-msg{
    color: red;
    margin-top: 2%;
}
.wallet-main{
    display: flex;
    flex-direction: column;
    max-width: 520px;
    height: 664px;
    top: 157px;
    left: 344px;
    border-radius: 0px, 8px, 8px, 0px;
}
.wallet-gc{
width: 520px;
height: 30px;
top: 258px;
font-size: 20px;
font-weight: 500;
line-height: 30px;
letter-spacing: 0.05rem;
text-align: left;
font-weight: bold;
}
.wallet-balance{
height: 21px;
top: 287.8px;
left: 374px;
color:rgba(119, 119, 119, 1);
display:flex ;
flex-direction: row;
padding-bottom: 30px;
padding-top: 10px;
}
.wallet-gcadd{
padding-top: 45px;
font-size: 20px;
font-weight: 800;
line-height: 30px;
letter-spacing: -0.01em;
text-align: left;
padding-bottom: 10px;
}
.wallet-voucher{
    width: 261px;
    margin: 8px;
}
.wallet-eg{
color:rgba(119, 119, 119, 1);
font-size: 10px;
font-weight: 500;
line-height: 18px;
letter-spacing: -0.01em;
text-align: left;
padding-left: 4px;
margin-top: 1px;
}
.wallet-common-btn {
font-size: 18px;
padding: 0.5rem 1rem;
border-radius: 20px;
border: none;
color: white;
background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: 10px;
width:254px;
height: Hug (34px);
padding: 10px, 8px, 10px, 8px;
border-radius: 8px;
gap: 8px;
cursor: pointer;
margin-top: 32px;
  }
.wallet-warnoptn{
    display: flex;
    align-items: center;
    padding-top: 10px;
}
.wallet-option{
font-size: 12px;
font-weight: 500;
line-height: 18px;
letter-spacing: -0.01em;
text-align: left;
color:rgba(119, 119, 119, 1);
}
@media only screen and (max-width:900) {
.wallet-main{
    display: flex;
    flex-direction: column;
    max-width: 420px;
    height: 664px;
    top: 157px;
    left: 344px;
    border-radius: 0px, 8px, 8px, 0px;
    }
    .wallet-gc{
    width: 420px;
    height: 30px;
    top: 258px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.05rem;
    text-align: left;
    font-weight: bold;
    }
    .wallet-balance{
    height: 21px;
    top: 287.8px;
    left: 374px;
    color:rgba(119, 119, 119, 1);
    display:flex ;
    flex-direction: row;
    padding-bottom: 30px;
    padding-top: 10px;
    }
    .wallet-gcadd{
    max-width: 420px;
    padding-top: 45px;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: left;
    padding-bottom: 10px;
    }
    .wallet-voucher{
        width: 261px;
        margin: 8px;
    }
    .wallet-eg{
    color:rgba(119, 119, 119, 1);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    padding-left: 20px;
    }
    .wallet-common-btn {
    font-size: 18px;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width:254px;
    height: Hug (34px);
    padding: 10px, 8px, 10px, 8px;
    border-radius: 8px;
    gap: 8px;
      }
    .wallet-warnoptn{
        display: flex;
        align-items: center;
        padding-top: 10px;
    }
    .wallet-option{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color:rgba(119, 119, 119, 1);
    }
}
@media only screen and (max-width:600px){
    .wallet-gc{
        width: 300px;
        height: 30px;
        top: 258px;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.05rem;
        text-align: left;
        font-weight: bold;
        }
    .wallet-voucher{
        width: 261px;
        margin: 8px;
    }
    
}
@media only screen and (max-width:355px){ 
.wallet-main{
    display: flex;
    flex-direction: column;
    max-width: 355px;
    height: 664px;
    top: 157px;
    border-radius: 0px, 8px, 8px, 0px;
    }
.wallet-gc{
    width: 200px;
    height: 30px;
    top: 258px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.05rem;
    text-align: left;
    font-weight: bold;
    padding-left: 10px;
    }
    .wallet-balance{
    height: 21px;
    top: 287.8px;
    padding-left: 15px;
    color:rgba(119, 119, 119, 1);
    display:flex ;
    flex-direction: row;
    padding-bottom: 30px;
    padding-top: 10px;
    }
    .wallet-gcadd{
    padding-top: 45px;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: left;
    padding-bottom: 10px;
    padding-left: 10px;
    }
    .wallet-voucher{
        width: 261px;
        margin: 8px;
    }
    .wallet-eg{
    color:rgba(119, 119, 119, 1);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    padding-left: 10px;
    }
    .wallet-common-btn {
    font-size: 18px;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: none;
    color: white;
    background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width:254px;
    height: Hug (34px);
    padding: 10px, 8px, 10px, 8px;
    border-radius: 8px;
    gap: 8px;
      }
    .wallet-warnoptn{
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-left: 15px;
    }
    .wallet-option{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color:rgba(119, 119, 119, 1);
    padding-left: 15px;
    }   
}
