.customer-order-modal-login {
    color: #000;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
}
.uploaded-review-image{
    width: 100px;
    height: 100px;
}
.choose-file-btn-review{
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: 1px solid black;
    color: #000;
    background: none;
    border: 1px solid white;
    border-radius: 5px;
    margin-right: 1rem;
    cursor: pointer;
}

.order-details-subhead {
    color: #FE3C72;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 8px;
    margin-bottom: 8px;
}

.order-details-product-info{
    width:382px;
    display:flex;
    gap:16px;
}

.order-details-product-image{
    width:85px;
}
.order-details-product-details{
    display:flex;
    flex-direction: column;
    align-items: flex-start;;
    justify-content: space-evenly;
}
.order-details-product-name{
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    width: 200px;
    }
.order-details-product-size-color-qty{
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
.byr-cancel-order-container{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
}
.modal-submit-button{
    display: flex;
max-width: 320px;
padding: 10px 8px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
border: none;
margin-top: 24px;
color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 14px ;
letter-spacing: -0.14px;
text-transform: uppercase;
cursor: pointer;
}
.modal-submit-button:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
}
.delivered-date-field{
    color: #029F4A;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.16px;
}
.buyer-cancel-order-date-info{
    margin-bottom: 16px;
  
}
.ordered-date-lighter-field{
    color: #777;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.14px;
text-transform: uppercase;
}
.odered-item-number-field{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.14px;
}
.cancel-odrder-reason-container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;

}
.cancel-odrder-reason-head{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 16px;
}
.cancel-reason-dropdown
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    /* width: 560px; */
    width:60%;
    min-width:300px;
    max-width:600px;
    height: 36px;
    flex-shrink: 0;
    border-color: rgba(0, 0, 0, 0.20);
}
.dropdown-return-order
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    flex-shrink: 0;
    border-color: rgba(0, 0, 0, 0.20);
    width: 560px;
    height: 36px;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.70);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.add-return-reason-textarea{
    width: 525px;
    min-height: 110px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    color: rgba(0, 0, 0, 0.30);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
padding: 1rem;
resize: none;
}
.return-order-radioGroup
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #FD2A80;
}
.return-order-radioGroup
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
}
.return-order-question-head{
    color: #000000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 16px; 
margin-top: 15px;
/* margin-bottom: 15px; */
}
.return-order-question-field{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;

}
.return-order-answer-field{
    color: #868686;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 19.5px;
}
.return-order-address-field{
    color: rgba(0, 0, 0, 0.80);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 22.5px;
}
.return-change-address-link{
    text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
color: #FD2A80;
}
.retrun-order-addressContainer{
    display: flex;
    width: 384px;
    justify-content: space-between;
    align-items: flex-end;
}
.review-modal-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 4px;
}
.rating-container-field{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    margin: 0 auto;
}
.track-ordershipping-Address{
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items:  flex-start;
}.shipping-Address-Order-Id{
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.14px;
}
.shipping-Address-track--desc{
    color: rgba(0, 0, 0, 0.66);
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.4px;
    width: 300px;
}
.shipping-Address-head{
    color: #FE3C72;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}
.tracking-status-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }
  /* //tracker styles */
  .stepper-wrapper-track-order{
    display: flex;
  }
  .tracking-status {
    /* background-color: #f0f0f0; */
    padding: 8px 16px;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
  }

  .stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin: 1rem auto;
    margin-bottom: 20px;
    width: 60%;
    transform: scale(0.8);
  }
  .step-name {
    font-size: 20px;
    color: #000000A8;
    font-weight: bold;
      letter-spacing: 0.02em;
  
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  
  
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #FC2975;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
  }
  
  .stepper-item.active {
    font-weight: bold;
  }
  .step-counter.default {
    background-color: #FC2975;
  
    /* transform:scale(1.4)s */
  }
  .step-counter.active {
    background-color: #FC2975;
  
  
  }
  .stepper-item.completed .step-counter {
    background-color: #FC2975;
  
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #FC2975;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
  
@media screen and (max-width: 800px){
    .dropdown-return-order .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        width: 300px;
    }
    .add-return-reason-textarea {
        width: 295px;
    }
    .retrun-order-addressContainer {
        width: 300px;
    }
    .customer-order-modal-login {
        font-size: 25px;
        margin-bottom: 25px;
    }
    .rating-container-field{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .cancel-reason-dropdown .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
        color: rgba(0, 0, 0, 0.87);
        box-sizing: border-box;
        position: relative;
        cursor: text;
        display: inline-flex;
        align-items: center;
        position: relative;
        border-radius: 8px;
        max-width: 320px;
        height: 36px;
        flex-shrink: 0;
        border-color: rgba(0, 0, 0, 0.20);
    }
}
@media (min-width: 0px){
.MuiBox-root .css-t983dg{
    width: 320px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
}