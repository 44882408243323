.admin-new-giftcard{
    margin-left: 29px;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
}
.admin-new-giftcard-detailcontainer{
    width: 992px;
    height: 309px;
    margin-top:32px;
    margin-left:29px; 
}
.admin-new-giftcard-name{
    width: 133px;
    height: 48px;
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
}
.admin-added-allnewgiftcard{
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}
.admin-add-new-giftcard-box{
    width: 320px;
    height: 245px;
    border-radius: 8px;
    border-style: dashed;
    border: 1px dashed rgba(167, 167, 167, 1); 
    display: flex;
    justify-content: center; 
    margin-top: 16px;
   
}
.updatedGiftCard{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 16px;
}
.admin-added-old-giftcard{
    width: 320px;
    height: 245px;
    border-radius: 8px; 
    border-color: black;
    margin-top: 16px;  
}

.admin-add-new-card{
    width: 67px;
    height: 86.11px;
    margin-top: 79px;
    margin: 79px 127px 79px 126px;
}
.admin-add-new-giftcard{
    width: 320px;
    height: 245px;
    border-radius: 8px;
    margin-top: 15px;
    
}