.secondary-category-cards{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-around;
    border-radius:10px;
}

.secondary-category-card{
    display:flex;
    flex-direction:column;
    align-items:center;
}

.secondary-category-card-img{
    /* width:350px; */
    /* height:auto; */
    /* max-height:200px; */
    width:400px;
    height:300px;
    border-radius:10px;


}
.secondary-category-card-name{
    margin-top:1rem;
    font-size:28px;
}