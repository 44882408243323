.seller-subs-card-layout {
  width: 300px;
  height: 100%;
  min-height: 75rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  /* min-width:15rem; */
  padding:1rem;
}
.seller-subs-card-layout:hover {
    cursor: pointer;
}
/* .selected{
  background: rgba(144, 222, 224, 0.2);
  border: 1px solid rgba(1, 186, 191, 1);
  ;
} */
.seller-subs-card-head{
  min-height:12rem;
}
.seller-subs-card-plan-name {
  font-size: 1.5rem;
  margin: 1rem auto;
  /* align-self: center; */
  text-align: center;
  /* width: 50%; */
  /* position:absolute; */
  /* left:35%; */

}
.subs-plan-styling-underline {
  /* position: absolute; */
  background: rgba(11, 113, 135, 1);
  height: 0.4rem;
  width: 50%;
  border-radius: 80%;
  margin: 0.5rem auto;
  /* left: 20%; */
  /* top:10%; */
}
.subs-plan-cost{
  color: rgba(1, 186, 191, 1);
  font-size:2rem;
  text-align: center;
  margin:1rem auto 0 auto;
}
.subs-plan-duration{
  text-align:center;
  color: #707070CC;

}
.subs-plan-duration-annual{
  text-align:center;
  font-size:1.2rem;
  margin-top:0.5rem;
}
.subs-benefit-list{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:flex-start;
  line-height:1.2rem;
  font-size:1rem;
  margin:0;
}
.seller-subscription-select-card-feature
.css-1hmoco7-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  margin-right: 1rem;
  background: none;
  color: gray;
}