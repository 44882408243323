.landing-page{
    width:100%;
    margin:0 auto;
}
.landing-page-heading{
    margin-top: 2rem;
    font-size:2rem;
    margin-left:2rem;

}

.moving-text-container {
    width: 100%;
    overflow: hidden;
    margin:2rem auto;
  }
  
  .moving-text {
    /* width:50px; */
    /* color:pink; */
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    animation: moveText 10s linear infinite;
    /* white-space: nowrap; */
  }
  .moving-text>* {
    width:1000px;
  }
  
  @keyframes moveText {
    0% {
      transform: translateX(100%);
    }
    
    100% {
      transform: translateX(-100%);
    }
  }