.Order-details-container{
    width: 90%;
    min-width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 5% auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.order-card{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    gap: 1.5rem;
}
.freight-calculator{
display: flex;
justify-content: space-around;
/* align-items: center; */
width:100%;
margin: 0 auto;
}
.lv-order-image{
    width: 220px;
    height: 200px;

}
.freight-calculator-container{
    width: 90%;
    height:1140px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.pickup-area{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    min-width: 300px;
    
}
.styled-table {
    border-collapse: collapse;
    width: 230px;
    height: 280px;
  }
  
  .styled-table th,
  .styled-table td {
    padding: 8px;
    text-align: left;
  }
  
  .styled-table th {
    background-color: none;
    font-weight: bold;
  }
  
  .styled-table tr {
    border-bottom: 1px solid #ddd;
  }
  
  .styled-table tr:last-child {
    border-bottom: none;
  }
  .th-freight-calculator{
    background:none;
    font-size: 18px;
    display: table-cell;
  }
  .lv-field-dropdown
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: auto;
    min-width: 240px;
    min-height: 1.4375em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.dimensions-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}
@media only screen and (max-width: 1300px) {
    .Order-details-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .freight-calculator {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        /* flex-direction: column; */
        margin: 0 auto;
    }
}
@media screen and (max-width: 480px) {
.Order-details-container{
    min-width: 300px;
   
}
.freight-calculator-container{
    width: 95%;
    height:1190px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.pickup-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    min-width: 300px;
    margin: 0 auto;
}
.freight-calculator {
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    flex-direction: column;
    margin: 2% auto;
}
.order-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2% auto;
}
.lv-thirdpart-table{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    justify-content: space-around;
    /* gap: 0rem; */
    width: 90%;
}
}