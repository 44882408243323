/* CSS code for Customer Gift Card Landing page */
/* .customer-heading-container{
  margin-top: 0%;
  padding-top: 0%;
}
.customer-heading-text{
   margin-top:0%;
   padding-top: 0%;
} */
.gc-mainCardContainer{
    max-width: 1280px;
    margin:0px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.gc-Heading-container{
    width:100px;
    height:64px; 
    margin-top:20px;
    display: flex;
    /* padding-left: 15px; */
    margin-left: 64px;
    align-items: center;
}
.Logo-image{
    height:64px;
    width:260px;
}
.Logo-text{
    width: 335px;
    height: 48px;
    display:inline-block;
    white-space: nowrap;
    font-size:46px;
    line-height: 46px;
    margin-left: 3px;
    padding-top: 8px;
}
.gc-Text-container{
    width: auto;
    font-size:20px;
    font-weight:100px;
    line-height:20px;
    letter-spacing:-1%;
    margin-left: 64px;
    margin-top: 8px;
    margin-bottom: 50px;
}
.carcontainer1{
    height: 100%;
    width: 1280px;
    background-color: rgb(255, 255, 255);
    border-radius: 24px;
    /* margin: 2rem auto; */
    display: flex;
    justify-content: center;
}
#Card-container2{
    height: 434px;
    width: 97%;
    padding-left: 20px;
    /* padding-right: 20px; */
    margin:10px auto;
}

@media only screen and (max-width:1200px){
.mainCardContainer{
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
}
.Heading-container{
    max-width:1200px; 
    margin-top:2px;
    margin-right: auto;
    display:flex;
}

.Logo-image{
    display: flex;
    justify-content: center;
    height:44px;
    margin-top:1rem;
}
.Logo-text{
    display: flex;
    align-items: baseline;
    font-size:25px;
    margin-right: auto;
    padding-top: 30px;
}
.Text-container{
    display: inline-block;
    text-align: left;
    margin: 0px;
    width: max-content;
    font-size:15px;
    font-weight:100px;
    line-height:20px;
}
.Card-container1{
    height: 164px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#Card-container2{
    height: 214px;
    width: 95%;
    margin: 1rem auto;
    display: inline-block;
}
}
 

@media only screen and (max-width:380px) {
    .gc-Text-container {
        width: auto;
        font-size: 20px;
        font-weight: 100px;
        line-height: 20px;
        letter-spacing: -1%;
        margin-top: 8px;
        margin-left: 15px;
    }
    .gc-Heading-container {
        width: 100px;
        height: 64px;
        margin-top: 20px;
        display: flex;
        /* padding-left: 15px; */
        align-items: center;
        margin-left: 15px;
        margin-bottom: 20px;
    }
    .Logo-text
    {
        margin-top: 3px;
        padding-top: 16px;
    }
    .mainCardContainer{
        padding-bottom: 25%;
        display: flex;
        flex-direction: column;

    }
    .Heading-container{
        max-width:380px;
        height:34px; 
        display:flex;
        justify-content: left;
        align-items:center;
    }
    .Logo-image{
        height:34px;
        width:180px;
        margin-left:-5px;
        margin-right: 1px;
        margin-top:1rem;
    }
    .Logo-text{
        display:inline-block;
        white-space: nowrap;
        font-size:22px;
    }
    .Text-container{
        width: max-content;
        font-size:12px;
        font-weight:100px;
        line-height:20px;
        margin-top: 5px;
        letter-spacing:-1%;
        display: flex;
        justify-content: left;
    }
    .Card-container1{
        height: 100%;
        width: 100%;
        background-color: rgb(252, 248, 248);
        border-radius: 24px;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    #Card-container2{
        height: 164px;
        width: 90%;
        margin: 1rem auto;
        display: inline-block;
    }
}