.Buyer_product_card_layout{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
}
.customer-product-card2-fav {
    /* color: red !important; */
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
}
.Buyer_img_field{
    position: relative;
}