.seller-login-container {
  font-family: "Barlow";
    width: 100%;
    /* max-width:1300px; */
    /* min-height: 100vh; */
    display: flex;
    position: relative;
    /* background: conic-gradient(from 219.36deg at 50% 50%, rgba(186, 252, 255, 0) 0deg, rgba(56, 197, 204, 0.202739) 224.84deg, rgba(56, 197, 204, 0) 360deg); */
    overflow: hidden;
    z-index: 2;
    /* color: #008185; */
    /* align-items:center; */
    justify-content: space-between;
}

.seller-login-left{
  width: 100%;
  /* height: 100vh; */
  margin: 0 auto;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* max-height: 2000px; */

}
.seller-login-left-content {
  width: 100%;
    /* max-width: 1200px; */
    /* max-height: 1000px; */
    margin:  auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    /* gap: 1rem; */
    

    align-items: center;
}
.seller-login-right {
  width: 50%;
      /* height: 100vh; */
      /* max-width: 2000px; */
      max-height: 1300px;
      /* display: flex; */
      justify-content: space-evenly;
      align-items: center;
      /* margin: 0 auto; */
      flex-direction: column;
  /* background: conic-gradient(from 131.32deg at 49.93% 49.93%, rgba(254, 0, 122, 0) 0deg, rgba(255, 134, 134, 0.202739) 224.84deg, rgba(239, 29, 117, 0) 360deg); */
}
.seller-login-right-content{
  width: 100%;
  max-width: 1200px;
  max-height: 1900px;
  height: 100vh;
  /* background: conic-gradient(from 131.32deg at 49.93% 49.93%, rgba(254, 0, 122, 0) 0deg, rgba(255, 134, 134, 0.202739) 224.84deg, rgba(239, 29, 117, 0) 360deg); */

}
.seller-login-right-img{
  width:100%;
  height:100%;
}
.myhraki-logo {
  max-width: 30%;
}
.usertype-switch{
  font-size: 18px;
    color: rgba(112, 112, 112, 0.72);
    display: flex;
    /* gap: 1rem; */
    margin-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.usertype-switch 
.css-1y3v1v7-MuiSwitch-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 58px;
  height: 38px;
  overflow: hidden;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  z-index: 0;
  vertical-align: middle;
  margin-top: -2%;
}
.seller-login-page-checkbox {
  width: 60%;
  font-style: normal;
  display: inline-block;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  align-self: flex-start;
  margin-left: 19%;
  color: #FF5569;
  margin-bottom: 1rem;
  margin-top: 3%;
}
.sellerSignIn-Layout-head {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 1.9rem;
  color: #FF2171;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 14% auto; */
  margin-bottom: 2px;
}

.sellerSignIn-form-field {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.sellerSignIn-input-form-fields {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

}
.seller-login-textfield{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;
  width:78%
}

.sellerSignIn-form-field-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 40px;
 
}
.sellerSignIn-form-field-textfield {
  width: 70%;
  gap: 3rem;
}
.sellerSignIn-accept-terms {
  display: flex;
  justify-content: flex-start;
  width: 70%;
  gap: 1rem;
  font-size: 1.2rem;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-decoration-line: underline;
  color: #FF2171;
  
  margin: 0 auto;
}
.sellerSignIn-btn-field {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
.sellerSignIn-Forget-terms {
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  text-decoration-line: underline;
  margin-bottom: 10px;
  color: #FF5569;
}
.sellerSignIn-Button {
  background: #FF7891;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  height: 46px;
  width: 100%; 
  max-width: 200px;
}
.seller-SignIn-Terms-Conditions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: rgba(112, 112, 112, 0.72);
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  width: 70%;
  margin: 0 auto;
}
.sellerSignIn-Myhraki-Logo {
  display: flex;
  justify-content: flex-start;
  margin-right: 60%;
}
.seller-login-img{
  /* width: 93%; */
  /* height: 110%;
  margin: 0 auto; */
}
.sellerSignIn-welcome-layout-heading {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  color: #E62361;
  width: 100%;
  font-weight: 600;
  text-align: center;
  font-size:2.5rem;
}
.sellerSignIn-welcome-layout{
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content:space-around;
  height:100%;
 
}
.sellerSignIn-welcome-layout-desc {
  color: #F54565;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  width: 50%;
  margin: 0 auto;
}
.sellerSignIn-Welcome-Image {
  width: 100%;
  display: flex;
  justify-content: center;
  transform: rotate(8deg);
}
.phone-icon{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1.5rem;
  color: gray;
}
.css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: gray;
  position: relative;
  /* bottom: 3px;
  left: 2px; */
}

/* .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Barlow";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;
  padding-left: 25px;
} */


.submit-button-signIn-button{
  padding: 0.75rem 1rem;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 1.1rem;
  align-self: center;
  margin: 1rem;
  border-radius: 5px;
  float: right;
  text-align: center;
  background: #FF7891;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

}
.seller-login-page-left{
  width: 50%;
  background-color: white;
}
.submit-forget-fields{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 1rem;
}
.admin-login-page-button-page-terms {
  display: flex;
  justify-content: center;
  margin: 0% auto;
  flex-direction: column;
}
@media only screen and (max-width: 480px) {
  
  .seller-login-left {
    width: 100%;
    background-color: white;

}
  .seller-login-right {
   display: none;
  }
  .sellerSignIn-input-form-fields {
    width: 117%;}
    

}
