.header-container{
    width:100%;
    background: #F2F2F2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header-filter{
    display:flex;
    flex-direction: column;
    align-items:center;
    margin: 0 auto;
}