/*Card 6 */
.CustomerLandingCard6Slidesection {
  padding: 0px 90px 0px 80px;
}
.CustomerLandingCard6Slidearrowleft,
.CustomerLandingCard6Slidearrowright {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;
}

.CustomerLandingCard6Slidearrowright {
  right: -50px;
  font-size: xx-large;
  color: hsla(0, 0%, 85%, 1);
}
.CustomerLandingCard6Slidearrowleft {
  left: -50px;
  font-size: xx-large;
  color: hsla(0, 0%, 85%, 1);
}
.CustomerLandingCard6-subtite-section {
  padding: 0px 0px 0px 90px;
  display: flex;
  align-items: baseline;
  gap: 15px;
}
.CustomerLandingCard6-subtite-section-design {
  font-size: 14px;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  padding: 5px 20px 5px 20px;
  color: white;
  border-radius: 20px;
}
.CustomerLandingCard6-images-slide {
  padding: 10px 0px 10px 0px;
  height: 70vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.CustomerLandingCard6-title-para-slide {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 22px;
}
.CustomerLandingCard6-para-slide-title {
  font-family: Mulish;
  font-size: 22px;
  font-weight: 700;

  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerLandingPageCard6-para {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 600;

  letter-spacing: 0.04em;
  width: 80%;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 29%, 1);
}
.CustomerLandingCard6-para-slide-readmore {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;

  letter-spacing: -0.01em;
  text-align: left;

  text-decoration-skip-ink: none;
  color: #fe585b;
  cursor: pointer;
}
/*Card 4 */
.CustomerLandingCard4Slidearrowleft,
.CustomerLandingCard4Slidearrowright {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;
}

.CustomerLandingCard4Slidearrowright {
  right: -50px;
  font-size: xx-large;
  color: hsla(0, 0%, 85%, 1);
}
.CustomerLandingCard4Slidearrowleft {
  left: -50px;
  font-size: xx-large;
  color: hsla(0, 0%, 85%, 1);
}

.CustomerLandingCard4Slidesection {
  padding: 0px 90px 0px 90px;
}
.customerNewlandingCard4-image {
  width: 100%;
  height: 60vh;
}
.CustomerLandingCard4-tabsSection {
  flex-basis: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  padding: 0px 90px 30px 90px;
}

.CustomerLandingCard4-tabsSection-text {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;

  letter-spacing: 0.04em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  padding: 5px 18px 5px 18px;
  color: white;
  border-radius: 20px;
}
.customerNewlandingCard4 .slick-track {
  display: flex !important; /* Force flexbox layout for slick-track */
  gap: 10px; /* Add space between slides */
}

@media screen and (max-width: 598px) {
  .CustomerLandingCard6-subtite-section {
    padding: 0px 0px 0px 56px;
  }
  .CustomerLandingCard4Slidearrowleft {
    left: -40px;
  }
  .CustomerLandingCard4Slidearrowright {
    right: -38px;
  }
  .customerNewlandingCard4-image {
    height: 50vh;
  }
  .CustomerLandingCard4-tabsSection-text {
    font-size: 9px;
    width: 32%;
  }
  .customerNewlandingCard4 .slick-track {
    gap: 0px; /* Add space between slides */
  }
  .CustomerLandingCard4-tabsSection {
    padding: 0px 52px 15px 52px;
  }
  .CustomerLandingCard4Slidesection {
    padding: 0px 50px 0px 50px;
  }
  .CustomerLandingCard6-subtite-section-design {
    font-size: 9px;
  }

  .CustomerLandingCard6Slidesection {
    padding: 0px 50px 0px 50px;
  }
  .CustomerLandingCard6-images-slide {
    height: 25vh;
  }
  .CustomerLandingCard6-title-para-slide {
    padding: 0px;
    gap: 10px;
  }
  .CustomerLandingCard6Slidearrowleft {
    left: -40px;
  }
  .CustomerLandingCard6Slidearrowright {
    right: -38px;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .customerNewlandingCard4-image {
    width: 100%;
    height: 35vh;
  }

  .CustomerLandingCard4-tabsSection {
    flex-basis: calc(33.33% - 10px); /* Adjust for gap */
    padding: 0px 60px 10px 60px;
  }
  .CustomerLandingCard4Slidesection {
    padding: 0px 60px 0px 60px;
  }
  .CustomerLandingCard6-images-slide {
    height: 30vh;
  }
  .CustomerLandingCard6Slidesection {
    padding: 0px 60px 0px 60px;
  }
  .CustomerLandingCard6-title-para-slide {
    padding: 10px;
    gap: 10px;
  }
  .CustomerLandingCard6-subtite-section {
    padding: 0px 80px 0px 60px;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  .customerNewlandingCard4-image {
    width: 100%;
    height: 35vh;
  }
  .CustomerLandingCard6-images-slide {
    height: 30vh;
  }

  .CustomerLandingCard6-title-para-slide {
    padding: 10px;
    gap: 10px;
  }
  .CustomerLandingCard6-subtite-section {
    padding: 0px 80px 0px 70px;
  }
}
