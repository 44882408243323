
.seller-register-container{
width: 100%;
height: 100vh;
margin: 0 auto;
}
.seller-register-right-screen{
  width: 50%;
  height: 100vh;
  max-width: 2000px;
  max-height: 1300px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* margin: 0 auto; */
  flex-direction: column;
  background-color: white;
}
.seller-register-welcome-layout {
  width: 80%;
  height: 100%;
  background: linear-gradient(359.64deg, #FF6155 -1.76%, rgba(252, 38, 120, 0.3875) 106.36%, rgba(255, 97, 85, 0) 135.36%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto;
}
.seller-registration-content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transform: scale(0.9);
  /* transform-origin: 0 0; */
  height: 100%;
  align-self: center;
  /* margin-left: 5%; */
  /* width: 90%; */
  max-width: 2000px;
  max-height: 1600px;
  overflow: hidden;
  gap: 1rem;
}
.signup-card-container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transform: scale(0.8);
  transform-origin: 0 0;
  height: 100%;
  align-self: center;
  text-align: center;
}
.seller-register-welcome-layout-desc {
  /* font-family: 'Barlow'; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  width: 60%;
}
.signup-gender-radio 
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #FF2171 !important;
}
.seller-register-right-screen {
  width: 100%;
  margin: 0 auto;
  background-color: white;
  display: flex;
  justify-content: right;
  align-items: center;
}
.register-page-main-logo{
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;
  position: relative;
  right: 48%;
}
.register-page-logo{
  width: 200px;
  text-align: left;
  margin-left: 35%;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: rgba(0, 0, 0, 0.7);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.03rem;
}
.register-terms-text{
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* width: 100%; */
letter-spacing: 0.1px;
text-align: center;
color: rgba(112, 112, 112, 0.72);
/* margin-bottom: -1rem; */

}
.seller-register-button-field{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.seller-registration-head {
  font-weight: 600;
  font-size: 2.4rem;
  margin: 3% auto;
  color: white;
  /* margin-left: 50%; */
  margin-bottom: 3%;
}
.seller-registration-head-text {
  /* margin-bottom: 1rem; */
  font-size:2rem;
}
.seller-registration-head-underline {
  position: absolute;
background: white;
  height: 0.4rem;
  width: 10%;
  border-radius: 80%; 
}
.seller-registration-card {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  min-height:300px;
  min-width:700px;
  width:60%;
  margin:0rem auto;
  /* margin-left:0; */
}

.seller-form-field {
    display: flex;
    width: 100%;
    /* flex-direction:column; */
    margin: 0.5rem 1rem;
    align-items: center;
    position: relative;
    gap: 0.5rem;
  }
.seller-register-welcome-layout-heading {
/* font-family: 'Barlow'; */
font-style: normal;
font-weight: 800;
font-size: 35px;
line-height: 40px;
text-align: center;
letter-spacing: 0.01em;
color: #FFFFFF;
width: 90%;
}
  .seller-field .MuiOutlinedInput-root {
    /* align-self: center; */
    width: 80%;
    /* border-radius: 100px; */
    /* min-height: 50px; */
    height: 2.5rem;
    background-color: white;
    background: none;
    padding-left: 1%;
    background-size: 3%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 1rem auto;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
  }

  .seller-page-form{
    /* font-family: "Barlow"; */
    padding: 1rem;
    /* min-height: 60rem; */
    display: flex;
    width: 70%;
    /* height:100%; */
    flex-direction: column;
    /* min-width: 80rem; */
    /* margin:0 auto; */
  }
  .register-button{
    align-self:flex-end !important;
    position:relative;
    /* bottom:4rem; */
    /* right:10rem; */
  }
  #rePassword-helper-text {
    position: absolute;
    bottom: -25%;
    left: 9%;
    font-size: 1rem;
}
.seller-invite-table{
  background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.2);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
border-radius: 20px;
width: 80%;
  margin:0 auto;
  position: relative;
  left: 2rem; 
  padding:1rem;
}
.seller-reset-phone-icon {
  position: absolute;
  left: 10%;
  color: rgba(112, 112, 112, 0.72);
  font-size: 1.6rem;
  top: 35%;
}
/* .css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: gray;
  position: relative;
  bottom: 3px;
  left: 0px;
} */
.seller-password-icon {
  position: absolute;
  left: 12%;
  color: rgba(112, 112, 112, 0.72);
  font-size: 1.6rem;
}

th {
  height: 50px;
  /* border-radius: 30px; */
  /* color:"white" */
color: rgba(0, 0, 0, 0.8);
font-size:25px;
background: rgba(244, 244, 244, 1);

  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
}
th:first-child{
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
th:last-child{
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.seller-discount-thead {
  background: #F6F9FC;
  font-size:1rem;
}
.seller-invite-table-head{
  min-width:50px;
    color: rgba(0, 0, 0, 0.8);
    background: rgba(212, 240, 240, 0.57);

}
tr{
    /* border-bottom: 1px solid rgba(212, 36, 36, 0.2); */

}

td{
    height: 40px;
    text-align: center;
    font-size:1.2rem;
    padding:0 0.5rem;
    /* border-bottom: 1px solid black; */
      /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
/*  */

}
.registration-gender {
  font-weight: 600;
  font-size: 20px;
  margin: 0.5rem 0.5rem;
  color: #FF2171;
  align-self: flex-start;
  margin-left: 0%;
}
.registration-terms-text {
  /* margin: 3rem 1rem; */
  line-height:22px;
  font-size: 1.2rem;
  color: rgba(112, 112, 112, 0.72);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* gap: 1rem; */
  margin-top: 2%;
}
@media (min-width: 480px) {
  .signup-page-form {
 width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-left: 15%;
    /* margin-top: 1%; */
}
.seller-register-right-screen {
  width: 100%;
  margin: 0 auto;
  background-color: white;
  overflow: hidden;
  display: flex;
  justify-content: right;
  /* overflow: scroll; */
}
.seller-register-button-field{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
}
.signup-password-field-seller
.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-bottom: -7px;
}
.css-1jaw3da
.css-1a5icme.Mui-checked {
  color: #FF5569 !important;
}
.registration-terms-text
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: rgba(112, 112, 112, 0.72);
}
.register-privacy-link{
  color: #000;
  text-decoration: underline;
}
.signup-calendar{
  color:
  rgba(112, 112, 112, 0.72);

}
.signup-terms
.css-zun73v.Mui-checked, .css-zun73v.MuiCheckbox-indeterminate
.css-vubbuv {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: rgba(112, 112, 112, 0.72);
}
.signup-password-field-seller
#enter-password-helper-text {
  position: absolute;
  bottom: -39%;
  left: 5%;
  font-size: 12px;
  letter-spacing: 0.02em;
}

.signup-password-field-seller
#resetPassword-helper-text {
  position: absolute;
  bottom: -39%;
  left: 5%;
  font-size: 12px;
  letter-spacing: 0.02em;
}
.signup-password-field-seller
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
}
@media screen and (max-width: 1024px) and (max-height: 600px) {
  .seller-login-left {
    width: 480px;
    margin: 0 auto;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.sellerSignIn-input-form-fields {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.card-content-img-first{
  display: none;
}
}