.admin-invite-card {
  width: 80%;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display:flex;
  flex-direction:column;
  padding:1rem;
  /* align-items:center; */
  /* justify-content:space-around; */
  max-width:850px;
}
.admin-invite-dropdown{
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2% auto;
}
.admin-invite-form-field{
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin:1rem 0;
}
.admin-invite-form-field-name{
    width:45%;
    color: rgba(0, 0, 0, 0.8);
    font-size:20px;
}
.admin-invite-form-field-textfield{
    width:100%;
}


.admin-invite-textfield .MuiOutlinedInput-root {
    align-self: center;
    width: 80%;
    /* border-radius: 100px; */
    /* min-height: 60px; */
    /* height: 2.5rem; */
    background-color: white;
    /* background: none; */
    /* padding-left: 8%; */
    /* background-size: 3%; */
    font-weight: 300 !important;
    /* font-size: 1.5rem; */
    /* margin: 3%; */
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  }
  .admin-invite-button{
    display:flex;
    justify-content: flex-end;
    margin-top:2rem;
  }
  .admin-invite-textfield .css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    color: #d32f2f;
    bottom: -1.5rem;
    left:2.5rem;
  }

@media screen and (max-width:480px){
    .admin-invite-card {
        width: 100%;
       
        padding:1rem;
        /* align-items:center; */
        /* justify-content:space-around; */
      }
    .admin-invite-form-field{
        flex-direction: column;
    }
    .admin-invite-form-field-name{
        width:100%;
        margin-bottom:0.5rem;
    }
    .admin-invite-textfield .MuiOutlinedInput-root {
        align-self: center;
        width: 100%;
        /* border-radius: 100px; */
        /* min-height: 60px; */
        /* height: 2.5rem; */
        background-color: white;
        /* background: none; */
        /* padding-left: 8%; */
        /* background-size: 3%; */
        font-weight: 300 !important;
        /* font-size: 1.5rem; */
        /* margin: 3%; */
        /* color: rgba(112, 112, 112, 0.72); */
        /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
        /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
      }
}

