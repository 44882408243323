.containerHead {
    font-size: 4rem;
    font-family: Barlow;
    font-weight: bold;
    color: #ff7891;
    text-align: center;
}
.card1 {
    width: 80%;
    height: 60%;
    min-width: 360px;
    min-height: 360px;
    background: rgb(255, 255, 255, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 5;
}

.cardLogo{
    height: auto;
    width: 60%;
    min-width:'200px',
}

.containerDesc {
    font-size: 2rem;
    font-family: Barlow;
    font-weight: bold;
    color: #ff7891;
    text-align: center;
    width: 70%;
    margin: 2rem auto;
}

.constructionContact {
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}

@media screen and (max-width: 750px) {
    .containerHead {
        font-size: 3rem;
    }
}

@media screen and (max-width: 650px) {
    .containerDesc {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 550px) {
    .containerHead {
        font-size: 2.3rem;
    }
    .cardLogo{
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .containerDesc {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 360px) {
    .card1 {
        min-width: 276px;
        min-height: 240px;
    }
    .containerHead {
        font-size: 1.8rem;
    }
    .containerDesc{
        font-size: 1rem;
    }
}