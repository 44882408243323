.SearchNotMatch{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 0% auto;
}
.SNF-text1{
color: #000;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: flex;
flex-direction: column;
width: 400px;
}
.SNF-text2{
    color: #000;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
}
.SNF-btn{
    width: 254px;
}
.SNF-button{
color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 14px */
letter-spacing: -0.14px;
text-transform: uppercase;
display: inline-flex;
padding: 10px 8px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
border-width: 0;
border-style: none;
padding: 10px 50px;
width: 254px;
margin-top: 16px;
}

.SNF-Image{
    height: 450px;
    width: 450px;
}
@media screen and (max-width:850px) and (min-width:700px){
    .SNF-text1{
        color: #000;
        font-family: Poppins;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        flex-direction: column;
        width: 350px;
        }
        .SNF-text2{
            color: #000;
        font-family: Poppins;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        }
        .SNF-Image{
            height: 350px;
            width: 350px;
        }
        .SearchNotMatch{
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: row;
            padding: 0% 0 10% 0;
        }
    
}
@media screen and (min-width:600px) and (max-width:699px){
    .SNF-text1{
        color: #000;
        font-family: Poppins;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        flex-direction: column;
        width: 300px;
        }
        .SNF-text2{
            color: #000;
        font-family: Poppins;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        }
        .SNF-Image{
            height: 300px;
            width: 300px;
        }
        .SearchNotMatch{
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: row;
            padding: 12% 0 15% 0;
        }
}
@media screen and (max-width:599px){

    .SearchNotMatch{
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    margin: 79px auto 91px auto;
}
.SNF-Image{
    height: 236px;
    width: 236px;
}
.SNF-maintext{
    width: 264px;
    height: 110px;
    
}
.SNF-text1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0% auto;
    color: #000;
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 254px;
}
.SNF-text2{
    color: #000;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
width: 254px;
}
.SNF-btn{
    width: 254px;
}
.SNF-button{
    border-radius: 8px;
background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
display: inline-flex;
padding: 10px 8px;
justify-content: center;
align-items: center;
gap: 8px;
color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 14px */
letter-spacing: -0.14px;
text-transform: uppercase;
padding: 10px 15px;
}
}
