/* terms-condition.css */

.terms-condition-content {
    padding: 32px;
    display: flex;
    flex-direction: column;
    margin-top: 7rem;
    justify-content: center;
  }
  
  .terms-condition-content h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .terms-condition-content h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .terms-condition-content ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .terms-condition-content p {
    margin-bottom: 20px;
   
  }
  .terms-condition-content p{
    font-size: 18px;
  }
  
  .terms-condition-content a {
    color: blue;
  }
  
  .terms-condition-content li {
    margin-bottom: 10px;
  }
  
  .terms-condition-content li ul {
    list-style-type: circle;
    margin-left: 20px;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 880px) {
    .terms-condition-content {
      padding: 16px;
     
    }
    .terms-condition-content ul {
      list-style-type: disc;
     margin: 0 auto;
      
  }
  .terms-condition-content h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .terms-condition-content h1 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .terms-condition-content li ul {
    list-style-type: disc;
   margin: 0 auto;
}
.terms-condition-content p {
  font-size: 16px;
}
  }
  @media screen and (max-width: 780px) {
    .terms-condition-content {
      padding: 16px;
      margin-top: 10rem;
     
    }
  }
  @media screen and (max-width: 480px) {
    .terms-condition-content {
      padding: 16px;
      margin-top: 12rem;
    }
    .terms-condition-content ul {
      list-style-type: disc;
     margin: 0 auto;
      
  }
  .terms-condition-content h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .terms-condition-content h1 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .terms-condition-content li ul {
    list-style-type: disc;
   margin: 0 auto;
}
.terms-condition-content p {
  font-size: 16px;
}
  }