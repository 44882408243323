.seller-personal-data{
   
    width:100%;
    border:none !important;

}
.seller-personal-data-page-content{
    width:100%;
    /* min-width:1300px; */
    display:flex;
    margin:10px auto;

}
.seller-personal-tabs{
    width:95%;
    margin: 0  auto;

}
.seller-personal-details-header{
    text-align:center;
    font-size:25px;
    font-weight: 600;

    margin:2rem auto 1rem auto;
}

.css-1dom0vg-MuiLinearProgress-root {
  background-color:#f3dee2 !important;
}
.css-5xe99f-MuiLinearProgress-bar1 {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  -webkit-transition: -webkit-transform .4s linear;
  transition: transform .4s linear;
  transform-origin: left;
  /* background-color: rgb(255, 120, 145); */
  background-color:rgb(255, 120, 145) !important;

}
.seller-personal-details-section{
display:flex;
flex-direction:column;
    font-size:1.5rem;
    font-weight:600;
    margin:2rem auto;
    position:relative;
    width:200px;
    margin:2rem auto 1rem auto;

}

.seller-personal-details-section-header{
    margin-bottom:1rem;
    align-self:center;
    /* font-size:1rem; */

}
.seller-personal-details-section-underline{
    position:absolute;
    background: rgba(11, 113, 135, 1);
    height:0.3rem;
    width:100%;
    align-self: center;
    border-radius:80%;
    top:100%;
}

  .seller-personal-data-form-field {
    display: flex;
    width: 100%;
    /* flex-direction:column; */
    margin: 0rem 0rem;
    align-items: center;
    position: relative;
  }
  .seller-personal-data-field-name {
    /* width: 35%; */
    /* margin-right:2rem; */
    min-width: 300px;
    font-size: 18px;
    font-weight: 500;
    /* margin-top:1rem; */
  }
  .seller-personal-data-files{
    display:flex;
    justify-content: space-between;
  }
  .seller-personal-data-info{
    text-align:center;  
    margin-top:2rem;
    font-size :1.3rem;
    color: rgba(0, 0, 0, 0.5);

  }
  .seller-personal-data-button{
    position:relative;
    right: 0rem;
  }
  .seller-personal-data-note{
    /* text-align:center;   */
    margin-top:2rem;
    font-size :1.2rem;
    color: rgba(0, 0, 0, 0.5);
    margin:0 auto;
  }

  .aadhar-otp-container{
    margin:0 auto;
    /* border:1px solid black; */
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
  }
.aadhar-verified-text{
  margin-top:2rem;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  /* width: 87%; */
}
.aadhar-verified-text>*{
  margin-bottom: 1rem;
  margin-left: 0rem;
  font-size:14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(2, 159, 74, 1);
  /* position: relative;
  left: 68px;
  top: -14px; */
}
.button-fields-Otp{
  display: flex;
  align-items: center;
}
.seller-personal-data-form-field-new-Otp{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 10px; */
  width: 400px;
}

  .aadhar-otp-numbers{
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
    min-height: 6rem;
    display: flex;
    flex-direction: column;
    /* position: relative; */
    /* right: 0.75rem; */
    align-items: center;
    justify-content: center;
  }
.seller-personal-tabs-nav{
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-top:3rem;
}

.seller-personal-data-cert-msme-section{
  display:flex;
  justify-content:space-between;
}
.seller-personal-data-cert-msme-section-text{
  width:75%;
  align-items:center;
  margin-top:3rem;
  justify-content:flex-start;
}

.seller-personal-data-cert-msme-section-heading{
/* font-size:1.5rem; */
font-size:20px;
margin-bottom:1.5rem;

}
.seller-personal-data-cert-msme-section-buttons{
  width:25%;
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:5rem;
  justify-content: space-between;
  min-width:200px;
}
.seller-personal-data-msme-cert-info{
  margin-top:2rem;
    font-size :1.3rem;
    color: rgba(0, 0, 0, 0.5);
    display:flex;

}
.seller-personal-data-cert-msme-section input[type="file"] {
  display: none;
}
.file-submitted{
  color:#ff5569 !important;
}
.seller-personal-data-form-field-dropdown {
  display: flex;
  width: 80%;
  /* flex-direction:column; */
  margin: 0rem 0rem;
  align-items: center;
  position: relative;
}
.seller-personal-data-form-field {
  display: flex;
  width: 100%;
  /* flex-direction:column; */
  margin: 0rem 0rem;
  align-items: center;
  position: relative;
}
.seller-personal-data-form-field-dropdown .MuiOutlinedInput-root{
  /* align-self: center; */
  min-height: 50px;
  background-color: white;
  background: none;
  padding-left: 1%;
  font-weight: 400;
  font-size: 16px;
  /* margin: 12px auto; */
  border-radius: 8px;
  color: rgba(112, 112, 112, 0.72);
  width: 340px;
  padding: 14px 19px;
}
@media screen and (max-width:700px){
  .seller-personal-data-cert-msme-section{
  display:block;
  }
  .seller-personal-data-cert-msme-section-buttons{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-top:5rem;
    justify-content: space-between;
  }
  .seller-personal-data-cert-msme-section-buttons>*{
    margin-top:2rem;
  }
  .seller-personal-data-form-field-new-Otp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 10px; */
    width: 300px;
    flex-direction: column;
}
.otp-verify-error-aadhar {
  color: #FF5569;
  font-size: 12px;
  position: relative;
  top: -15px;
  left: -31px;
  min-width: 200px;
}.seller-personal-data-form-field-dropdown{
  display: flex;
  flex-direction: column;
  }

}

.seller-form-field-dropdown .MuiOutlinedInput-root {
  width: 341px;
  /* background-color: white; */
  border-radius: 8px;
  color: #000;
  font-weight: 300;
  padding: 12px 14px;
  font-size: 16px;
  height: 53px;

}

  .seller-form-field .MuiOutlinedInput-root {
    /* align-self: center; */
    width: 100%;
    /* border-radius: 100px; */
    /* min-height: 50px; */
    height: 2.5rem;
    background-color: white;
    background: none;
    /* padding-left: 1%; */
    background-size: 3%;
    font-weight: 600;
    font-size: 16px;
    margin: 12px auto;
    border-radius: 8px;
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
  }

  .seller-form-field-disabled .MuiOutlinedInput-root {
    /* align-self: center; */
    width: 100%;
    border-radius: 4px;
    /* min-height: 50px; */
    height: 2.5rem;
    background-color: white;
    padding-left: 1%;
    background-size: 3%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 1rem auto;
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
  }
  .subs-submit-icon{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .subs-submit-icon-style{

    width: 100px;
    height: 98.41px;
  }
.subs-submit-note-head{
width: 100%;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}
.subs-submit-note-desc{
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
  
  
}
.submit-models-container {
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 2rem;
  margin: 1rem auto;
}
.sellerPersonalData-form-field {
  display: flex;
  width: 100%;
  /* flex-direction: column; */
  margin: 0.5rem 1rem;
  align-items: center;
  position: relative;
  gap: 1rem;
}
  .proceed-button{
/* position:relative; */
    font-size: 1.3rem;
padding: 0.5rem 1rem;
border-radius: 20px;
border: none;
color: black;
/* align-self: center; */
margin: 1rem auto;
background: rgba(248, 152, 128, 1);
border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: 5px;
position:relative;
width:20%;
float:right;
/* left:40%; */
 }
 .proceed-button:hover{
    cursor:pointer;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); }
 
.file-input{
  position:relative;
  /* display:flex; */
}
.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
   
  }
  
  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    padding: 10px 12px;
    background-color: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    position:relative;
    right: 5rem;
  }

  .seller-account-type .MuiInputBase-input{
    /* color:green; */
    color: rgba(112, 112, 112, 0.72);
    font-size:1.2rem !important;
    border-color:#00ed42 !important;

  }

  .seller-account-type.MuiFormControl-root {
    margin: 0;
    margin-top:1rem;
    display: inline-flex;
    padding: 0 1rem;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 27.5rem;
    border: 1px solid black;
    border-radius: 20px;
    right: 0rem;
    margin-right: 3.5rem;
    border-color:#00ed42 !important;

  }
  .MuiInput-underline{
    border-bottom: none !important;
  }


.seller-personal-details-buttons-container{
  margin-top:5rem; 
  width:100%;
  justify-content: space-between;
  display:flex;
  align-items:center;
}
  

  .data-tracker-wrapper {
    margin-top: auto;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    margin: 1rem auto;
    margin-bottom: 20px;
    width: 60%;
    transform: scale(0.8);
  }
  .data-name {
    font-size: 1.5rem;
  }
  .data-tracker-item {
    position: relative;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    flex: 1;
  }
  
  
  
  .data-tracker-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }
  
  .data-tracker-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }
  
  .data-tracker-item .step-counter {
    position: relative;
    z-index: 5;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
  }
  
  .data-tracker-item.active {
    font-weight: bold;
  }
  .data-counter.default {
    background: rgba(1, 186, 191, 0.56);    /* transform:scale(1.4)s */
  }
  .data-counter.active {
    background: rgba(1, 186, 191, 0.56);  
  }
  .data-tracker-item.completed .data-counter {
    background: rgba(1, 186, 191, 0.56);  }
  
  .data-tracker-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid  rgba(1, 186, 191, 0.56);;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }
  
  .data-tracker-item:first-child::before {
    content: none;
  }
  .data-tracker-item:last-child::after {
    content: none;
  }
  /* Tracker styles */









  .order-track {
    padding: 0 1rem;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
  }
    .order-track-step {
      display: flex;
      height: 65px;
  }
      .order-track::last-child {
        overflow: hidden;
        height: 4rem;
  }
        .order-track .order-track-status span::last-of-type {
          display: none;
        }
      
    
    .order-track-status {
      margin-right: 1.5rem;
      position: relative;
      content:"A";
        color:white;
    }
      .order-track-status-dot {
        
        display: flex;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        /* background: rgba(1, 186, 191, 1); */
        background:#D9D9D9;
        /* text-align:center; */
        align-items:center;
        justify-content: center;
        color: white;
        

      }
      .completed_step{
        background: rgba(255, 120, 145, 1) !important;
                color:#fff;

      }
    
      .order-track-status-line {
        display: block;
        margin: 0 auto;
        width: 5px;
       height: 5rem;
        /* background: rgba(1, 186, 191, 1); */
      background:#D9D9D9;

      }

  
    
    
       .order-track-text  .order-track-text-stat {
        font-size: 16px;
    font-weight: 500;
    margin-bottom: 3px;
    position: relative;
    bottom: 0.5rem;
    }

    #seller-accountType-helper-text{
        font-size: 20rem;;
    }
  
    .MuiInput-underline:after{
        border-bottom: none !important;
    }


    .file-uploaded-successfully{
      position:absolute;
      top:0.5rem;
      right:3rem;;
    }

    .seller-submission-success{
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:space-around;
      min-height:30rem;
    }
    
    .verified-icon {
      color: rgba(1, 186, 191, 1) ;
            font-size:8rem !important;
    }
    .seller-submission-success-title{
      font-size:28px;
      color: rgba(0, 0, 0, 0.8);
    
    }
    .seller-submission-success-message{
      font-size:24px;
      color: rgba(0, 0, 0, 0.8);
      margin:0 auto;
      /* width:100%; */
      align-self:center;
      text-align:center;

    }
    .seller-submission-success-note{
      font-size:18px;
      color: rgba(0, 0, 0, 0.8);
      margin:0 auto;
      /* width:100%; */
      align-self:center;
      text-align:center;
    
    }
    .otp-verify-error-aadhar {
      color: #FF5569;
      font-size: 12px;
      position: relative;
      top: 40px;
      left: -277px;
      min-width: 200px;
  }
  .Otp-Head-Aadhar{
    font-size: 16px;
    text-align: "center";
  display: "flex";
   justify-content: "center";
color: "#000";
   margin: 0;
  }
    .seller-personal-data-note{
      font-size: 16px;
      color: #8D8D8D;
      display: flex;
      /* justify-content: center; */
      width: 100%;
      text-align: left;
      margin-bottom: 6%;
    }
     .seller-form-field
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
      height: auto;
      min-height: 1.4375em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      position: absolute;
      left: 0rem;
      bottom: -1rem;
  }
  .back-to-button
  .css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: -4%; */
}
 
  /* .seller-personal-tabs-nav
  .css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    color: gray;
    position: relative;
    bottom: 0px;
    left: 1px;
} */
  .css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    color: gray;
    position: relative;
    bottom: 0px;
    left: 1px;
}
    @media screen and (max-width:480px){
      .seller-personal-tabs-nav {
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        width: 330px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .seller-form-field {
    width:100%;
    flex-direction:column;;
    
    }
    .seller-my-account-field-name{
      width:100%;
    }
    .seller-personal-data-buttons{
      gap: 1rem;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }
    .seller-personal-data-form-field-new-Otp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* gap: 10px; */
      width: 300px;
      flex-direction: column;
  }
  .otp-verify-error-aadhar {
    color: #FF5569;
    font-size: 12px;
    position: relative;
    top: -15px;
    left: -31px;
    min-width: 200px;
}
.seller-personal-data-form-field-dropdown{
display: flex;
flex-direction: column;
}
.order-track {
  margin-top: 0;
  padding: 0px 1rem;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
}
.order-track-step {
  display: flex;
  height: 5rem;
}
    }
    .seller-form-field
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding-right: 12px;
      line-height: 0rem;
  }
  .seller-form-field{
    width: 150%;
  }


  .seller-personal-data-form-field .css-1wc848c-MuiFormHelperText-root.Mui-error{
    position: absolute;
    color: #d32f2f;
    bottom: -13%;
    font-size: 0.8rem;
    left: 0%;
  }
  .seller-form-field
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 16.5px 14px;
    font-size: 18px;
}
.Existing-image-container{
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  flex-direction: column;
  width: 100%;
  gap: 16px;
}
.Existing-image-container-buttons{
  display: flex;
  justify-content:space-between;
  align-items: center;
  gap: 8px;
}
.existingImage-field{
  width: 300px;
  height: 300px;
}
/* .seller-subscription-checkbox
input[type="checkbox"] {
  accent-color: #FF6155 !important;
} */