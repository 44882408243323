.search-bar-field{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-bar-component-icon{
    position: relative;
    left: 3rem;
    top: 0rem;
}
.MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .search-bar-component-icon .css-i4bv87-MuiSvgIcon-root{
    position: relative;
    left: 3rem;
    top: 0rem;
}
.search-input{
    background: #F2f2f2;
    color: white;
    border-radius: 4px;
    border: none;
    /* width: 80%; */
    min-height: 2rem;
    padding-left: 3.5rem;
    outline: #FFFFFF;
    min-width: 300px;
    align-items: center;
    height: 44px;
    font-size: 14px;
    width: 100%;
    max-width: 350px;
    font-weight: 500;
    /* padding: 0 1rem; */
    border-radius: 8px;
    color: #7D879C;
    background-color: #f2f2f29c;
    

}
.search-bar-component-icon
.css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    color: gray;
    position: relative;
    /* bottom: 3px; */
    /* left: 2px; */
}