.dropzone-container {
  border: 0.5px solid rgba(112, 112, 112, 0.5); /* padding: 20px; */
  text-align: center;
  cursor: pointer;
  max-width: 1200px;
  /* width:80%; */
  margin: 0 auto;
  background-image: url("../pricedown.png");
  /* background-size: cover; */
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.dropzone-message {
  font-size: 16px;
  color: #666;
}

.files-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.file-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
}

.file-preview {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  overflow: hidden;
}

.file-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-details {
  flex-grow: 1;
}

.file-name {
  font-size: 14px;
  margin-bottom: 10px;
}
/* 
.cancel-button {
  padding: 5px 10px;
  border: none;
  background-color: #ddd;
  color: rgb(51, 51, 51);
  cursor: pointer;
} */
