.landing-page-carousel{
    /* display:flex; */
    /* flex-direction:column; */
    /* width:100%; */
    z-index:0;
}
.carousel-image{
    width:100%;
    height:20rem;
    /* margin-top:1rem; */
}
.react-carousel-responsive__control{
    background-color: #ddd!important;
}
.react-carousel-responsive__control:hover{
    background-color: #fff!important;
}
.react-carousel-responsive__controlNext{
    color:black !important;
}
.react-carousel-responsive__controlPrev{
    color:black !important;
}

