@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
.product-banner-main{
    display: flex;
    flex-direction: column;
}
.product-banner1{
   width: 100%;
   height: 477px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   background-color:rgba(255, 242, 246, 1);
}
.banner-clip{
    width: 795px;
    height: 413px;
    flex-shrink: 0;
    border-radius: 8px;
    margin-left: 40px;
}
.banner-prdct-name{
    color: #901913;
    font-family: satisfy, cursive !important;
    font-style: normal;
    font-size: 48px;
    font-weight: 400;
    line-height: 140%; /* 67.2px */
    
}
.banner-prdct-detail1{
    height: 213px;
    width: 378px;
    margin-right: 41px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 21px;
    margin-bottom: 146px;
    margin-top: 113px;
}
.banner-prdct-desc1{
    width: 130px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-prdct-origin {
    position: absolute;
    top: 46px;
    left: 30px;
    width: 130px;
}
.product-banner2{
    width: 100%;
    height: 477px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(239, 231, 225, 1);
}
.banner-image{
    width: 394px;
    height: 469px;
    flex-shrink: 0;
    margin-right: 0px;
}
.banner-prdct-detail2{
    height: 213px;
    width: 378px;
    margin-left: 141px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 103px;
    align-items: center;
}
.banner-prdct-desc2{
    color: #868686;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
}
@media screen and (max-width:1050px) {
    .banner-clip{
        width: 500px;
        height: 313px;
        flex-shrink: 0;
        border-radius: 8px;
        margin-left: 40px;
    }
}
@media screen and (max-width:960px) {
    .banner-prdct-detail2 {
        height: 213px;
        width: 378px;
        margin-left: 55px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 103px;
        align-items: center;
    }
    .banner-prdct-detail1 {
        height: 213px;
        width: 300px;
        margin-right: 41px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 21px;
        margin-bottom: 146px;
        margin-top: 113px;
    }
}
@media screen  and (max-width:850px) {
    .banner-clip {
        width: 430px;
        height: 258px;
        flex-shrink: 0;
        border-radius: 8px;
        margin-left: 40px;
    }
    .banner-prdct-detail2 {
        height: 213px;
        width: 378px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 103px;
        align-items: center;
    }

}
@media screen and (max-width:700px){
    .product-banner1{
        width: 100%;
        height: 670px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        background-color:rgba(255, 242, 246, 1);
     }
     .product-banner2{
        width: 100%;
        height: 880px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(239, 231, 225, 1);
    }
    .banner-clip {
        width: 430px;
        height: 258px;
        flex-shrink: 0;
        border-radius: 8px;
        margin-left: 0px;
        margin-bottom: 55px;
    }
    .banner-prdct-detail1 {
        height: 213px;
        width: 300px;
        margin-right: 41px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 21px;
        margin-bottom: 90px;
        margin-top: 113px;
    }
    .banner-prdct-desc2 {
        color: #868686;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
    
    .banner-prdct-detail2 {
        height: 213px;
        width: 378px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 103px;
        align-items: flex-start;
        margin-top: 39px;
    }
    
    
}
@media screen and (max-width:450px){
    .banner-clip {
        width: 367px;
        height: 230px;
        flex-shrink: 0;
        border-radius: 8px;
        margin-left: 0px;
        margin-bottom: 55px;
    }
    .banner-image {
        width: 355px;
        height: 417px;
        flex-shrink: 0;
        margin-right: 0px;
    }
    .product-banner2 {
        width: 100%;
        height: 824px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(239, 231, 225, 1);
    }
}