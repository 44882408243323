.customer-footer{
    background: linear-gradient(180deg, #FFF 0.13%, #FFD6D7 35.19%, #FEB9BC 83.76%, #F16EA4 127.25%, rgba(252, 131, 182, 0.77) 306.11%);
    margin: 20px auto 0px auto;
}
.customer-footer-contact-us-social{
   max-width: 1220px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    gap: 100px;
}
.customer-footer-lists{
    width:auto;
    height: 200px;
    display:flex;
    flex-direction: row;
    align-items:start;
    gap: 230px;
    margin-top:38px;  
 }
 .cutomer-footer-divider{
    background-color:  rgba(102, 81, 82, 1);
    border: 1px solid rgba(102, 81, 82, 1);
    margin-right: 280px;
}
.customer-footer-list{
    height: 207px;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400px;
}
.customer-footer-list-item{
    margin-top: 0px 0px;
    font-weight: 400;
    line-height: 21px;
    padding-top: 2px;
    padding-bottom: 12px;
    font-size:14px;
    list-style: none;
    opacity: 0.7;
    display:flex;
    justify-content:space-between;
   width:230px;
}
.customer-footer-list-item-name{
    margin-top: 0px;
    font-weight: 400px;
    font-size: 16px;
    line-height: 24px;
    list-style: none;
    padding-bottom: 20px;
}
.customer-footer-list li{
    cursor: pointer;
}
 .customer-footer-icons{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
 }
.customer-footer-content{
    max-width:1280px;
    margin:0 auto;
    display:flex;
    flex-direction: column;;
    align-items:center;
    justify-content:center;
    margin-top: 300px;
}
.customer-footer-logo{
    width:200px;
    margin-bottom:16px;
}
.customer-footer-about-myhraki{
    color: #000;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom:16px;
 
}
.customer-footer-about-myhraki-desc{
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    opacity: 0.6;
}
.customer-footer-lists{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-evenly;
}
.customer-footer-lists:nth-child(0){
    font-weight:bold;
    font-size:16px;
}
.customer-footer-list-item{
    font-size:14px;
    list-style: none;
    opacity: 0.7;
    display:flex;
    justify-content:space-between;
   width:230px;
}
.customer-footer-content .bold{
    font-weight:bold;
    font-size:16px;
    opacity: 1;
}
.customer-footer-content .stylish{
    opacity: 0.7;
    font-size: 14px;
}
.customer-footer-contact-us{
    margin-bottom: 24px;
    width: 98%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}
.customer-footer-contact-us-email{
    display:flex;
    flex-direction:column;
    gap:1rem;
}
.customer-footer-email-field{
    display:flex;
}
.customer-footer-icon{
    width:24px;
    height:24px;
    margin:0rem 0.5rem;
}
.customer-footer-copyrights-container{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-evenly;
}
.customer-footer-icons-all{
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}
.customer-footer-copyrights1{
color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-bottom: 10px;
}
.customer-footer-copyrights2{
color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-bottom: 10px;
}

.icon-a{
    text-decoration: none; /* Remove underlines */
    color: inherit;
}
@media screen and (max-width: 1080px) {
    
}
 
 @media screen and (max-width: 1161px ) and (min-width:808px) {
    .customer-footer{
        margin-top: 230px;
    }
    .customer-footer-content {
        max-width: 800px;
        margin: 0 auto;
        margin-top: 40px;
        height: 532px;
        gap: 50px;
    }
    .customer-footer-list{
        height: 100px;
        margin-top: 0px;
        margin-left: 0px;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400px; 
    }
    .customer-footer-lists{
        gap: 50px;
        display: flex;
        justify-content: flex-start;
    }
    .customer-footer-contact-us-social {
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: space-between;
        gap: 100px;
    }   
 }
@media screen and (max-width: 808px) and (min-width:635px) {
    .customer-footer{
        background: linear-gradient(180deg, #FFF 0.13%, #FFD6D7 35.19%, #FEB9BC 83.76%, #F16EA4 127.25%, rgba(252, 131, 182, 0.77) 306.11%);
        margin: 0 auto;
        padding-bottom: 5px;
        height: 1000px;
    }
    .customer-footer-content {
        margin-top: 40px;
        height: 532px;
        gap: 50px;
    }
    .customer-footer-list {
        height: 1px;
        margin-top: 0px;
        margin-left: 0px;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400px;
    }
    .customer-footer-lists {
        width: 85%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .customer-footer-contact-us-social {
        display: flex;
        align-items: flex-end;
        padding: 8px;
        width: 93%;
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 30px;
        margin-top: 333px;
    }
    .customer-footer-contact-us {
      flex-direction: column;
      align-items: center; /* Center the contact items vertically */
    }
    .customer-footer-contact-us-email {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 220px;
    }
    .customer-footer-email-field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;
    }
    .customer-footer-about-myhraki-desc {
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        opacity: 0.6;
    }
    .customer-footer-content .stylish{
        opacity: 0.7;
        font-size: 12px;
    }
    .customer-footer-icons {
        display: flex;
        justify-content: center;
        padding-left: 86px;
    }
    .customer-footer-copyrights{
        color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 20px;
    display: flex;
    justify-content: right;
    padding-left: 30px;
    }
    .customer-footer-copyrights1{
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 10px;
        /* margin-left: 65px; */
        }
  }
@media screen and (min-width:635px) and (max-width:807px){
    .customer-footer-list {
        height: 1px;
        margin-top: 0px;
        margin-left: 0px;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400px;
    }
    .customer-footer-lists {
        width: 80%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .customer-footer-contact-us-social {
        display: flex;
        align-items: flex-end;
        padding: 8px;
        width: 93%;
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 30px;
        margin-top: 366px;
    }
    .customer-footer {
        background: linear-gradient(180deg, #FFF 0.13%, #FFD6D7 35.19%, #FEB9BC 83.76%, #F16EA4 127.25%, rgba(252, 131, 182, 0.77) 306.11%);
        margin: 148px auto 0px auto;
        padding-bottom: 5px;
        height: 1035px;}
    
}

  @media screen and (max-width:634px) {
    .customer-footer-list {
        height: 1px;
        margin-top: 0px;
        margin-left: 0px;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400px;
    }
    .customer-footer-lists {
        width: 80%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .customer-footer-contact-us-social {
        display: flex;
        align-items: flex-end;
        padding: 8px;
        width: 93%;
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 30px;
        margin-top: 456px;
    }
  }
  @media screen and (max-width:450) {
    .customer-footer{
        background: linear-gradient(180deg, #FFF 0.13%, #FFD6D7 35.19%, #FEB9BC 83.76%, #F16EA4 127.25%, rgba(252, 131, 182, 0.77) 306.11%);
        margin: 400px auto;
        padding-bottom: 5px;
     
    }
  }
 
  @media screen and (min-width: 481px) and (max-width: 788px) {
    /* Add styles for screens between 481px and 788px here */
  }
 
  @media screen and (min-width: 789px) and (max-width: 1280px) {
    /* Add styles for screens between 789px and 1280px here */
  }
  .customer-footer-email-field
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    width: 190px;
}
