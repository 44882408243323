.wishlist-container{
  max-width:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    margin:0 auto;
  }

.wishlist-filter{

    display:flex;
    align-items:center;
    /* justify-content:center; */
    margin: 1rem 1rem;
    }

    .wishlist-heading{
        font-weight:600;
        font-size:2rem;        
         /* width:60%; */
         position:relative;
         left:7.5%;
         margin: 1rem 0;
  }
  .wishlist-content{
    width:75%;
    margin:0 auto;
  }

  @media screen and (max-width:480px) {
    .page-content{
      width:95%;
      margin:0 auto;
    }
  
  }
  