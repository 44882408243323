.UC{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.UC-text1{
    color: #000;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: flex;
flex-direction: column;
}
.UC-text2{
    color: #000;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.UC-Image{
    width: 600px;
    height: 500px;
}
@media screen and (max-width:990px) {
    .UC-text1{
        color: #000;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: column;
    }
    .UC-text2{
        color: #000;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    }
    .UC-Image{
        width: 500px;
        height: 450px;
    }
    
}
@media screen and (max-width:840px) and (min-width:700px) {
    .UC{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding: 10% 0 10%;
    }
    .UC-text1{
        color: #000;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: column;
    }
    .UC-text2{
        color: #000;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    }
    .UC-Image{
        width: 400px;
        height: 350px;
    }
    
}
@media screen and (min-width:600px) and (max-width:699px) {
    .UC{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding: 20% 0;
    }
    .UC-text1{
        color: #000;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: column;
    }
    .UC-text2{
        color: #000;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    }
    .UC-Image{
        width: 300px;
        height: 250px;
    }
}
@media screen and (max-width:599px) {
    .UC{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        margin: 20% auto;
    }
    .UC-text1{
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .UC-text2{
        color: #000;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
    }
    .UC-Image{
    width: 300px;
    height: 200px;
    flex-shrink: 0;
    }
}