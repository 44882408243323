.bc-buyer-hero-container{
    display:flex;
}
.bc-buyer-hero-container-text{
    display:flex;
    flex-direction: column;
    align-items: flex-start;;
    /* justify-content: space-between; */
    gap:1rem;
    width:40%;
    padding:5%;
}
.bc-buyer-hero-container-img{
    display:flex;
    flex-direction: column;
    align-items: flex-start;;
    justify-content: space-between;
    gap:2rem;
    width:40%;
    padding:5%;
}
.bc-landing-page-carousel-img{
    width:100%;
    max-width:400px;
}
.bc-buyer-hero-container-title{
    max-width:80%;
    font-size:50px;
}

.bc-buyer-advantage-cards{
    display:flex;
    box-shadow: rgba(43, 52, 69, 0.1) 0px 4px 16px;
    margin-bottom:60px;
    background-color: white;
    padding:0.5%;
}



.bc-buyer-advantage-card{
    display:flex;
    max-width:300px;
    margin:0 auto;
    align-items:center;
    justify-content: space-between;
}
.bc-buyer-advantage-image{
    max-width:200px;
}
.bc-buyer-advantage-image-img{
    max-width:150px;
    max-height:75px;
}
.bc-buyer-advantage-features-head{
    font-size:17px;
    font-weight: bold;
}
.bc-buyer-advantage-features-desc{
    font-size:14px;
    color: rgb(125, 135, 156);

}
.bc-category-cards{
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    margin-bottom:40px;
    flex-wrap:wrap;
    gap:20px;
}
.bc-buyer-category-card {
    position: relative;
    overflow: hidden; /* To clip the zoomed part of the image */
}

.bc-category-card-image {
    width: 300px;
    height: 300px;
    transition: transform 0.3s ease; /* Adding a transition for smooth zoom effect */
}

.bc-category-card-btn {
    position: absolute;
    top: 80%;
    left: 10%;
    width: 80%;
    z-index:10;
    background-color: #ffffffab;
    color: #e4cabe;
    padding: 3%;
    font-size:16px;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease; /* Adding a transition for background color and text color change */
}

.bc-buyer-category-card:hover .bc-category-card-image {
    cursor:pointer;
    transform: scale(1.1); /* Zoom the image by 10% on hover */
}

.bc-buyer-category-card:hover .bc-category-card-btn {
    cursor:pointer;

    background-color: #e4cabe; /* Change background to black on hover */
    color: #ffffffab; /* Change text color to white on hover */
}

.bc-product-card-image {
    max-width: 300px;
    height: 300px;
    overflow:hidden;
    transition: transform 0.3s ease; /* Adding a transition for smooth zoom effect */
}
.bc-buyer-product-card:hover .bc-product-card-image {
    transform: scale(1.1, 1); /* Adjust the value as needed for the desired zoom effect */
    z-index: 0;
  }
  .bc-buyer-product-card .bc-product-card-btn,
.bc-buyer-product-card .bc-product-card-cart-icon,
.bc-buyer-product-card .bc-product-card-wishlist-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.bc-buyer-product-card:hover .bc-product-card-btn,
.bc-buyer-product-card:hover .bc-product-card-cart-icon,
.bc-buyer-product-card:hover .bc-product-card-wishlist-icon {
  opacity: 1;
}
.bc-buyer-product-card:hover{
    /* border:1px solid black; */
    box-shadow: rgba(43, 52, 69, 0.5) 0px 4px 16px;

}

.bc-product-card-category{
    max-width:280px;
    text-align: center;
    font-size:12px;
    color:rgb(52, 48, 48);
    overflow: hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
    margin:0.5rem auto;
}
.bc-buyer-product-card{
    position:relative;
    overflow: hidden;
    margin-bottom:2rem;
    max-height:500px;
}
.bc-product-card-title{
    max-width:280px;
    text-align: center;
    font-size:18px;
    color:black;
    overflow: hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
    margin:0.5rem auto;

}
.bc-product-card-btn{
    /* position: absolute; */
    /* top: 60%; */
    /* left: %; */
    width: 100%;
    color: #ffffffab;
    margin:0 auto;
    background-color: #e4cabe;
    padding: 3% 0;
    font-size:16px;
    text-align: center;
    z-index:10;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.bc-product-card-cart-icon{
    position:absolute;
    left:calc(100% - 60px);
    top:6%;
}
.bc-product-card-wishlist-icon{
    position:absolute;
    left:calc(100% - 75px);
    top:12%;
}

.bc-offers-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 60px;
}
.bc-offers-card{
    overflow: hidden;
    border-radius: 10px;

}
  
.bc-offers-card-img-bg {
    width: 400px;
    min-height: 200px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    background-size: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.3s; /* Add a transition for smooth zooming effect */
}
  
.bc-offers-card-img-bg:hover {
    transform: scale(1.1); /* Zoom in the background image on hover */
}
  
.bc-offers-card-offer-amount {
    font-size: 14px;
    font-weight: 600;
}
  
.bc-offers-card-offer-name {
    font-size: 34px;
    max-width: 200px;
    font-weight: 600;
}

.bc-buyer-offer-poster{
    background-repeat: no-repeat;
    /* min-height:10rem; */
    object-fit: cover;
    width:calc(100% - 4rem);
    background: gray;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    /* padding:6rem 2rem; */
    padding: 6rem 2rem;

}
.bc-buyer-offer-poster-value{
    font-size:30px;
    color:white;

}
.bc-buyer-offer-poster-name{
 font-size:50px;
    color:white;
}

.bc-story-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 60px;
}
.bc-story-card{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    width:350px;
    gap:1rem;
    position: relative;
    overflow:hidden;
    border:0.2px solid #bbbbbb;
    padding:1rem;
}
.bc-story-card:hover .bc-story-card-img{
    overflow:hidden;
    max-width:350px;
    transform: scale(1.1, 1.17);
}
.bc-story-card:hover {
    cursor:pointer;
}
.bc-story-card-img{
    width:100%;
    max-width:350px;
    max-height:200px;
}
.bc-story-card-head{
    font-weight:600;
    font-size:22px;
    max-width:280px;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
}
.bc-story-card-desc{
    font-weight:400;
    font-size:16px;
    max-width:280px;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
}
.bc-story-card-nav{
    font-weight:400;
    font-size:16px;
}
.bc-story-card-date{
    text-align:center;
    padding:1rem 1.2rem;
    background-color: gray;
    position:absolute;
    top:20px;
    left:20px;
}


@media screen and (max-width:780px){
    .bc-buyer-hero-container{
        display:flex;
        flex-direction:column;
    }
    .bc-buyer-hero-container-text{
        width:80%;
        align-items:flex-start;
        margin:0 auto;
    }
    .bc-buyer-hero-container-img{
        
        width:90%;
        padding:5%;
    }
    .bc-landing-page-carousel-img{
        width:100%;
        /* max-width:500px; */
    }
    .bc-buyer-advantages-container{
        display:flex;
        flex-direction:column;
    }
    .bc-buyer-advantage-cards{
        flex-direction:column;
        max-width:350px;
        margin:0 auto;
    }
    .bc-buyer-advantage-card{
        justify-content: flex-start;
        margin:0;
    }

}