.admin-discount-layout {
    min-width: 300px;
    width: 90%;
    margin: 1rem auto;
    min-height: 300px;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(0.6, 0, 0, 0.6);
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: auto;
    min-height: 1.4375em;
    width: 100%;
    /* min-width: 400px; */
}

.discount-field-name1 {
    padding: 20px;
    width: 38%;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
}


.discount-head-text {
    font-size: 2.5rem;
    margin: 0 auto 1.5rem auto;
    text-align: center;
}

.discount-page-form {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.discount-form-field {
    display: flex;
    width: 100%;
    margin: 0.8rem 0.8rem;
    align-items: center;
    position: relative;
}

.discount-field-name {
    padding: 20px;
    width: 60%;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
}

.discount-text-field .css-1wc848c-MuiFormHelperText-root.Mui-error {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
}

.discount-text-field .MuiOutlinedInput-root {
    width: 443px;
    max-width: 500px;
    border-radius: 8px;
    background-color: white;
    font-weight: 600;
    font-size: 14px;
    color: #000;
}

.discount-offer .css-1wc848c-MuiFormHelperText-root {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
}

.start-end-date{
    width: 100%;
    text-align: left;
}

.calender{
    width: 70%;
    color: #1c1a1a54;
    font-size: 18px;
    border-radius: 4px;
    min-width: 280px;
    border: 1px solid #70707054;
    border-radius: 4px;
    max-height: 54px;
    margin: 0 auto;
    padding: 1rem auto;
    padding: 1rem;
    border-radius: 8px;
}

.offer-type .css-1wc848c-MuiFormHelperText-root{
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
}

.start-end-date .css-1d1r5q-MuiFormHelperText-root {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
}

.discount-message {
    width:100%;
    border-radius: 40px;
    font-weight: 600;
    font-size: 1.2rem;
    color: rgba(19, 18, 18, 0.72);
    resize: none;
    text-align: left;
}

.discount-message-field {
    width: 70%;
    padding: 20px;
    min-height: 200px;
    border-radius: 10px;
    resize: none;
}

.discount-message .css-1d1r5q-MuiFormHelperText-root {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
}

.add-coupon-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: none;
    margin-top: 37px;
    margin: 0.8rem;
    background: linear-gradient(90deg, #FA068A 0%, #FE505F 100%);
    color: white;
    border-radius: 10px;
}

@media screen and (max-width:1200px){
    .calender{
        width: 90%;
    }

    .discount-date-icon-admin {
        position: absolute;
        top: 1rem;
        right: 4rem;
    }

    .discount-message-field {
        width: 90%;
    }
}

@media screen and (min-width:1200px){
    .calender{
        width: 80%;
    }

    .discount-date-icon-admin {
        position: absolute;
        top: 1rem;
        right: 8rem;
    }

    .discount-message-field {
        width: 80%;
    }
}

@media screen and (min-width:1300px){
    .calender{
        width: 70%;
    }

    .discount-date-icon-admin {
        position: absolute;
        top: 1rem;
        right: 14rem;
    }

    .discount-message-field {
        width: 70%;
    }
}

@media screen and (max-width:1000px){
    .discount-text-field .MuiOutlinedInput-root {
        width: 430px;
    }
    .calender{
        width: 90%;
    }
    .discount-date-icon-admin {
        position: absolute;
        top: 1rem;
        right: 4rem;
    }
}

@media screen and (max-width:850px){
    .discount-text-field .MuiOutlinedInput-root {
        width: 400px;
    }
    .calender{
        width: 90%;
    }
    .discount-date-icon-admin {
        position: absolute;
        top: 1rem;
        right: 4rem;
    }
}

@media screen and (max-width:800px){
    .discount-text-field .MuiOutlinedInput-root {
        max-width: 380px;
    }
}

@media screen and (max-width:750px){
    .discount-text-field .MuiOutlinedInput-root {
        max-width: 350px;
    }
}

@media screen and (max-width:650px){
    .discount-text-field .MuiOutlinedInput-root {
        max-width: 310px;
    }
}

@media screen and (max-width:600px){
    .discount-text-field .MuiOutlinedInput-root {
        max-width: 270px;
    }
    .discount-date-icon-admin {
        position: absolute;
        top: 1rem;
        right: 2rem;
    }
    .discount-text-field .MuiOutlinedInput-root {
        max-width: 300px;
    }
}

@media screen and (max-width:570px){
    .discount-text-field .MuiOutlinedInput-root {
        max-width: 260px;
    }
    .calender{
        min-width: 220px;
    }
    .discount-date-icon-admin {
        position: absolute;
        top: 1rem;
        right: 2rem;
    }
    .discount-message-field {
        width: 80%;
    }
}

@media screen and (max-width:530px){
    .discount-form-field{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .discount-field-name{
        width: 90%;
        text-align: center;
    }
    .discount-date-icon-admin {
        position: absolute;
        top: 5rem;
        right: 2rem;
    }
}

@media screen and (max-width:370){
    .start-end-date{
        width: 10px;
    }
}
