/* Title Section */
.customer-landing-card1 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.CustomerLandingCard1titesection {
  padding: 20px 0px 20px 90px;
  display: flex;
  align-items: baseline;
  gap: 15px;
}

.CustomerLandingCard1titesection-title {
  font-family: Mulish;
  font-size: 32px;
  font-weight: 700;
  line-height: 40.16px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.CustomerLandingCard1titesection-subtitle {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fd2583;
  cursor: pointer;
}

/* Slider Section */

.CustomerLandingCard1Slidesection {
  padding: 0px 90px 20px 80px;
}

.CustomerLandingCard1Slide {
  display: flex;
  justify-content: center;
}

.CustomerLandingCard1Slidearrowleft,
.CustomerLandingCard1Slidearrowright {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);

  cursor: pointer;
}
.CustomerLandingCard1Slidesection .CustomerLandingCard1Slidearrowleft {
  left: -50px;
  font-size: xx-large;
  color: hsla(0, 0%, 85%, 1);
}

.CustomerLandingCard1Slidearrowright {
  right: -50px;
  font-size: xx-large;
  color: hsla(0, 0%, 85%, 1);
}
.customerNewlandingCard-titlesection {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 2px;
}

.customerNewlandingCard1 img {
  width: 100%;
  object-fit: cover;
}
.customerNewlandingCard1 .slick-track {
  display: flex !important; /* Force flexbox layout for slick-track */
  gap: 10px; /* Add space between slides */
}

.customerNewlandingCard1-title {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.59px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 33%, 1);
}
.customerNewlandingCard1-subtitle {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fd2583;
}

.customerNewlandingCard1-best-seller-label {
  position: absolute;
  background-color: #fd2583;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;

  text-transform: uppercase;
  z-index: 10;
}

.customerNewlandingCard1-overlay-container {
  position: relative;
  display: inline-block;
}

/* Rating on the left */
.CustomerLandingPageCard1-imageRating {
  position: absolute; /* Absolute positioning within parent */
  bottom: 10px; /* Adjust to desired position */
  left: 10px; /* Align to the left */
  border-radius: 21px;
  background: #ffffff99;
  display: flex;
  align-items: center;
  text-align: center;
  border: none;
  padding: 0px 12px 0px 10px;
  gap: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 10;
}

/* Heart button on the right */
.heart-button {
  position: absolute; /* Absolute positioning within parent */
  bottom: 10px; /* Adjust to desired position */
  right: 10px; /* Align to the right */
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: none;
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 10;
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 598px) {
  .CustomerLandingCard1titesection {
    padding: 0px 0px 0px 56px;
    gap: 15px;
  }
  .CustomerLandingCard1titesection-title {
    font-size: 20px;
    padding-bottom: 15px;
  }
  .CustomerLandingCard1Slidesection {
    padding: 0px 50px 0px 50px;
  }
  .CustomerLandingCard1Slidesection .CustomerLandingCard1Slidearrowleft {
    left: -36px;
  }

  .CustomerLandingCard1Slidearrowright {
    right: -40px;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .CustomerLandingCard1Slidesection .CustomerLandingCard1Slidearrowleft {
    left: -36px;
  }

  .CustomerLandingCard1Slidearrowright {
    right: -36px;
  }
  .CustomerLandingCard1Slidesection {
    padding: 0px 50px 0px 50px;
  }
  .CustomerLandingCard1titesection {
    padding: 20px 0px 20px 60px;
  }
}
