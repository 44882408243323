
.seller-forgot-password-container{
    display: flex;
    background: white;
    overflow: hidden;
    margin: 0 auto;
    height: 100vh; 
    width: 100vw; 
  }
  .seller-forgot-password-left {
    flex: 1 1;
    height: 100vh;
    max-height: 1900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    margin: 0 auto;
    /* background: conic-gradient(from 131.32deg at 49.93% 49.93%, rgba(254, 0, 122, 0) 0deg, rgba(255, 134, 134, 0.202739) 224.84deg, rgba(239, 29, 117, 0) 360deg); */
    background: linear-gradient(358.67deg, #FF6155 -0.13%, rgba(252, 38, 120, 0.3875) 102.79%, rgba(255, 97, 85, 0) 130.4%);
}
  .seller-forgot-left-content {
    width: 100%;
    max-width: 1200px;
    max-height: 1000px;
    margin: 8% auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
       /* background: conic-gradient(from 131.32deg at 49.93% 49.93%, rgba(254, 0, 122, 0) 0deg, rgba(255, 134, 134, 0.202739) 224.84deg, rgba(239, 29, 117, 0) 360deg); */

  }
  .seller-forgot-password-right {
    flex: 1;
    width: 100%;
    max-height: 1900px;
    display: flex;
    justify-content: center; 
    align-items: center;
    margin: 0 auto;
background-color: white;
  }
  .seller-forgot-right-content{
    width: 100%;
    max-width: 1200px;
    max-height: 1000px;
    margin: 0 auto;
background-color: white;  
display: flex;
align-items: center;
flex-direction: column;
justify-content: space-around;
  }
.seller-reset-head {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin: 20px auto;
    min-width: 65%;
    width: 100%;
    color: #707070;
    display: flex;
    justify-content: center;
    text-align: center;

}

.seller-reset-page-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    /* margin-top: 10px;
    margin-bottom: 10px; */
}

.seller-reset-page-email-field{
    width:80%;
    margin: 0 auto;
    position:relative;
    margin:2rem auto;
    /* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1)); */
    /* margin-bottom: 3%;
    margin-top: 13%; */
}
.seller-reset-email .MuiOutlinedInput-root{
    align-self: center;
    width: 80%;
    /* border-radius: 100px; */
    min-height: 50px;
    height: 2.5rem; 
    background-color:white;
    padding-left: 20%;
    background-size: 8%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3% ;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.reset-password-img {
    width:120%;
    animation: fadeIn 1s ease-in-out;
    /* height: 350px;
    display: flex;
    justify-content: center; */
}

@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

#email {
    padding: 10px !important;
    font-size: 1.1rem;
    color: gray !important;
}

    .seller-reset-email-icon {
        position: absolute;
    top: 37%;
    left: 20%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
        }
.login-email .MuiOutlinedInput-root {
    align-self: center;
    width: 80%;
    min-width: 224px;
    min-height: 50px;
    height: 2.5rem;
    background-color: white;
    padding-left: 8%;
    background-size: 3%;
    font-weight: 300 !important;
    font-size: 1.2rem;
    border-radius: 4px;
    /* margin: 3%; */
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.11); */
}
.seller-reset-page-email-field
.login-email .MuiOutlinedInput-root {
    align-self: center;
    width: 80%;
    min-width: 194px;
    max-width: 450px;
    min-height: 50px;
    height: 2.5rem;
    background-color: white;
    padding-left: 8%;
    background-size: 3%;
    font-weight: 300 !important;
    font-size: 18px;
    border-radius: 4px;
    /* margin: 3%; */
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.11); */
}
.login-email .MuiOutlinedInput-root {
    align-self: center;
    width: 80%;
    min-width: 224px;
    min-height: 50px;
    height: 2.5rem;
    background-color: white;
    padding-left: 8%;
    background-size: 3%;
    font-weight: 300 !important;
    font-size: 1.2rem;
    border-radius: 4px;
    /* margin: 3%; */
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.11); */
}
.seller-reset-logo{
    display: flex;
    justify-content: center;
    margin: 2px auto;
}
/* .seller-reset-auth-text {
 text-align: center;
color: rgba(112, 112, 112, 0.72);
    font-size: 1rem;
    max-width: 70%;
    font-weight: 600;
    margin: 4% auto;
} */
.seller-reset-auth-text {
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
    font-size: 14px;
    min-width: 60%;
    font-weight: 400;
    margin: 2% auto;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 65%;
 
}
.seller-reset-email-field {
    width: 75%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    margin:0 auto;
}
.seller-reset-email-validation-text {
    position:absolute;
    bottom:-8%;
    color: red;
    opacity:0.7;
    font-size: 0.8rem;
    padding-top:1rem;
}
.seller-reset-email {
    width: 100%;
    /* border-radius: 100px; */
    padding-left: 15%;
    background-size: 8%;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2vw;
    margin: 5% auto;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

    .seller-reset-email:focus {
        outline: none;
        border: 0.5px solid rgba(112, 112, 112, 0.5);
    }

.seller-reset-send-otp {
    margin: 8% 25%;
    font-size: 1.4rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}
.seller-reset-page-send-instructions {
    margin: 2rem auto;
    width:45%;
    font-size: 1.4rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}
.seller-reset-signUp {
    margin: 3% auto;
    font-weight: 600;
    font-size: 1.4rem;
    color: #000000;
}

.seller-reset-login span {
    font-weight: 600;
    font-size: 14px;
    text-decoration-line: underline;
    color: #FF5569;
 display: flex;
 justify-content: center;
 margin: 0 auto;
 cursor: pointer;
}

.seller-reset-page-terms {
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    margin: 8% 5%;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
    margin-bottom: 5%;
}
        .seller-reset-page-terms span {
            color: #707070;
        }

.seller-reset-card span:hover{
    cursor:pointer;
}
::placeholder{
    opacity:1;
    /* font-size:2vw; */
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media screen and (max-width:760px){
    .seller-forgot-password-left{
      display: none;
    }
    .seller-forgot-password-right{
        width: 100%;
        background: #ffffff;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }
    .seller-otp-phone-icon {
        position: absolute;
        left: 8%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.6rem;
        top: 40%;
    }
    .seller-reset-email-icon {
        position: absolute;
        top: 35%;
        left: 10%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.8rem;
    }
    .seller-reset-page-email-field {
        width: 73%;
        margin: 0 auto;
        position: relative;
        margin: 2rem auto;
    }
    .seller-reset-head{
        font-size: 25px;

    }
    .seller-reset-auth-text {
        text-align: center;
        color: rgba(112, 112, 112, 0.72);
        font-size: 12px;
        font-weight: 400;
        margin: 5% auto;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 74%;
    }
}

@media only screen and (max-width: 280px){
.seller-reset-head {
    font-size: 25px;
}
.seller-reset-auth-text {
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1rem;
    min-width: 100%;
    font-weight: 600;
    margin: 3% auto;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50%;
    /* margin-bottom: -3%;
    margin-top: 7%; */
}
.login-email .MuiOutlinedInput-root {
    align-self: center;
    width: 65%;
    min-width: 188px;
    min-height: 50px;
    /* height: 2.5rem; */
    background-color: white;
    padding-left: 8%;
    background-size: 3%;
    font-weight: 300 !important;
    font-size: 1.2rem;
    /* margin: 3%; */
    color: rgba(112, 112, 112, 0.72);
    /* border: 0.5px solid rgba(112, 112, 112, 0.11); */
}
.seller-reset-page-email-field
.seller-otp-phone-icon {
    position: absolute;
    left: -10%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.4rem;
    top: 34%;
}
}
  
@media screen and (min-width:5000px){
    .seller-forgot-password-left {
        flex: 1 1;
        height: 100vh;
        max-height: 1900px;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        padding: 2rem;
        margin: 0 auto;
        background: conic-gradient(from 131.32deg at 49.93% 49.93%, rgba(254, 0, 122, 0) 0deg, rgba(255, 134, 134, 0.202739) 224.84deg, rgba(239, 29, 117, 0) 360deg);
    }
}
.admin-otp-page-phone-field
#number-helper-text {
    position: absolute;
    bottom: -38%;
    left: 23%;
    font-size: 12px;
}
.admin-login-page-email-field #login-page-email-helper-text {
    position: absolute;
    bottom: -8%;
    left: 23%;
    font-size: 12px;
}.admin-login-page-password-field #login-page-password-helper-text {
    position: absolute;
    bottom: -8%;
    left: 22%;
    font-size: 12px;
}
.seller-forgot-phone-field{
    display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 0.3rem;
  margin: 0 auto;
}