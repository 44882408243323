.loading-container {
    position: relative;
    color:black;
    font-size:2rem;
    text-align:center;
    display:flex;
    flex-direction: column;
    /* gap:5rem; */
  }
  
  .loading-overlay {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    backdrop-filter: blur(0px); /* Adjust the blur strength as needed */
    /* background-color: rgba(255, 255, 255, 0.8); Adjust the background color and opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* width: 920px; */
  }
  
  .loading-content {
    text-align: center;
    color:rgba(0, 0, 0, 0.7);
    margin-top:100px;
    /* color: pink; */
    /* width: 100px; */
    /* height: 100px; */
  }

  
@media screen and (max-width: 480px) {

  .loading-overlay {
      max-width: 400px;
  }
}