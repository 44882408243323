

.admin-forget-password {
    font-family: "Barlow";
    width: 100%;
    /* min-height: 100vh; */
    display: flex;
    position: relative;
    overflow: hidden;
    /* z-index: 2; */
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.admin-forgot-password-left{
    width: 50%;
    height: 100vh;
    /* max-width: 2000px; */
    max-height: 1900px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #F2F2F2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.admin-forgot-password-right{
    width: 50%;
    height: 100vh;
    background: white;
    max-height: 1900px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.admin-reset-card {
    /* margin: 2% 3%; */
    width: 35%;
    min-width: 400px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    padding-top:3%;
    transform: scale(0.8);
    /* transform-origin: 0 0; */

}

.admin-reset-head {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 2.0rem;
    margin: 5% auto;
    margin-top:5rem;
    color: #707070;

}
.admin-reset-page-form{
    width:100%;
    display:flex;
    flex-direction:column;
}
.admin-reset-page-email-field{
    width:100%;
    position:relative;
    margin:3rem auto;
}
.admin-reset-email .MuiOutlinedInput-root{
    align-self: center;
    width: 60%;
    /* border-radius: 100px; */
    min-height: 50px;
    height: 2.5rem; 
    background-color:white;
    padding-left: 15%;
    background-size: 8%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3% ;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
#email-helper-text{
    position:absolute;
    bottom:-15%;
    left:28%;
    font-size:1rem;
}


.admin-reset-email-icon {
    position: absolute;
    top: 35%;
    left: 20%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.8rem;
}

.admin-reset-auth-text {
    color: rgba(248, 152, 128, 1);
    font-size: 1.2rem;
    max-width: 85%;
    font-weight: 600;
    margin: 4% auto;
}
.admin-reset-email-field {
    width: 75%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    margin:0 auto;
}
.admin-reset-email-validation-text {
    position:absolute;
    bottom:-8%;
    color: red;
    opacity:0.7;
    font-size: 0.8rem;
    padding-top:1rem;
}
.admin-reset-email {
    width: 100%;
    /* border-radius: 100px; */
    padding-left: 15%;
    background-size: 8%;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2vw;
    margin: 5% auto;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

    .admin-reset-email:focus {
        outline: none;
        border: 0.5px solid rgba(112, 112, 112, 0.5);
    }

.admin-reset-send-otp {
    margin: 8% 25%;
    font-size: 1.4rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}
.admin-reset-page-send-instructions {
    margin: 2rem auto;
    width:45%;
    font-size: 1.4rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}
.admin-reset-signUp {
    margin: 3% auto;
    font-weight: 600;
    font-size: 1.4rem;
    color: #000000;
}

    .admin-reset-signUp span {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2vw;
        text-decoration-line: underline;
        color: #019dbf;
    }

.admin-reset-login {
    margin: 2rem auto;
    font-weight: 600;
    /* font-size: 1.4vw; */
    font-size: 1.4rem;
    /* line-height: 2vw; */
    /* margin-bottom: 5rem; */
    color: #000000;
}

.admin-reset-login span {
    font-weight: 600;
    /* font-size: 1.4vw; */
    font-size: 1.2rem;
    line-height: 2vw;
    text-decoration-line: underline;
    /* color: #019dbf; */
    color: #FF5569;
    cursor: pointer;
}

    .admin-reset-page-terms {
        font-style: normal;
        font-weight: 600;
        font-size: 1.3rem;
        margin: 3% 5%;
        text-align: center;
        color: rgba(112, 112, 112, 0.72);
    }
    
        .admin-reset-page-terms span {
            color: #707070;
        }

.admin-reset-logo {
    /* position: fixed; */
    position:absolute;
    /* margin: 1% 1%; */
    top:1rem;
    left:43%;
}
.admin-reset-card span:hover{
    cursor:pointer;
}
::placeholder{
    opacity:1;
    /* font-size:2vw; */
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media screen and (max-width:480px){
        .admin-reset-logo{
            top:-3.5rem;
            left:35%;
        }
        
.admin-forgot-password-right{
    display: none;

}
.admin-forgot-password-left {
    width: 100%;
    /* height: 100vh; */
    background-color: white;
}
.admin-reset-text {
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
    font-size: 18px;
    min-width: 65%;
    font-weight: 600;
    margin: 3% auto;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 82%;
}
.admin-login-email .MuiOutlinedInput-root {
    align-self: center;
    width: 75%;
    min-width: 230px;
    min-height: 50px;
    height: 2.5rem;
    /* background-color: white; */
    /* padding-left: 8%; */
    /* background-size: 3%; */
    /* font-weight: 300 !important; */
    /* font-size: 1.2rem; */
    /* margin: 3%; */
    /* color: rgba(112, 112, 112, 0.72); */
    /* border: 0.5px solid rgba(112, 112, 112, 0.11); */
}

}
@media(max-width: 320px){ 
    .seller-reset-email-icon {
        position: absolute;
        top: 35%;
        left: -5%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.8rem;
    }
  }
  @media only screen and (min-width: 820px) and (max-width: 1180px){
    .seller-reset-email-icon {
        position: absolute;
        top: 37%;
        left: 14%;
        color: rgba(112, 112, 112, 0.72);
        font-size: 1.6rem;
    }
  }
  @media only screen and  (max-width: 1130px){
.seller-reset-email-icon {
    position: absolute;
    top: 37%;
    left: 20%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
}
.admin-reset-email-icon {
    position: absolute;
    top: 36%;
    left: 13%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
}}
@media only screen and (max-width: 480px){
.seller-reset-email-icon {
    position: absolute;
    top: 37%;
    left: 13%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.6rem;
}}
/* 
.toast-message {
    background:  #8ee988;
    color: white;
    font-size: 18px;
    padding: 4px 4px;
    min-width: 382px;
    width: 48%;
    left: 0.3rem;
    border-radius: 10px;
    margin: 10px auto;
}

.Toastify__toast {
    position: relative;
    min-height: var(--toastify-toast-min-height);
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 4px;
    /* box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05); */
    /* display: flex; */
    /* justify-content: space-between;
    max-height: var(--toastify-toast-max-height);
    overflow: hidden;
    font-family: var(--toastify-font-family);
    cursor: default;
    direction: ltr;
    z-index: 0; */
/* }  */
.admin-reset-text{
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
    font-size: 18px;
    min-width: 65%;
    font-weight: 600;
    margin: 3% auto;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50%;
}