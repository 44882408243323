.CustomerNewCart-heading {
  display: flex;
  justify-content: center;
  font-family: Mulish;
  font-size: 32px;
  font-weight: 700;
  line-height: 40.16px;
  letter-spacing: 0.04em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
  padding: 20px;
}
.CustomerNewCart-subheading-section {
  display: flex;
  padding: 40px 90px 40px 90px;
  gap: 10px;
}
.CustomerNewCart-subheading-section2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.CustomerNewCart-Adress-section2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.CustomerNewCart-subheading-section-itemlist {
  display: flex;
  flex-direction: column;

  border: 1px solid hsla(0, 0%, 74%, 1);
}
.CustomerNewCart-subheading-section-itemlist-ForAdress {
  display: flex;
  flex-direction: column;

  width: 100%;
  border: 1px solid hsla(0, 0%, 74%, 1);
}
.CustomerNewCart-subheading-section-paymentDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.CustomerNewCart-subheading-section-itemlist-sub1 {
  display: flex;

  padding: 10px;
  gap: 16px;
}
.CustomerNewCart-subheading-itemlist-img {
  width: 20%;
}
.CustomerNewCart-subheading-itemlist-deatils {
  width: 80%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.CustomerNewCart-subheading-itemlist-deatils-head {
  display: flex;
  justify-content: space-between;
}
.CustomerNewCart-subheading-itemlist-deatils-heading {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewCart-subheading-itemlist-deatils-price {
  display: flex;
  gap: 10px;
}
.CustomerNewCart-subheading-itemlist-deatils-buttons {
  display: flex;
  gap: 10px;
  width: 90%;
  align-items: center;

  position: relative;
}
.CustomerNewCart-subheading-itemlist-deatils-price1 {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 800;

  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewCart-subheading-itemlist-deatils-price2 {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;

  letter-spacing: 0.04em;
  text-align: left;
  text-decoration-line: line-through;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 4%, 72%, 1);
}
.CustomerNewCart-subheading-itemlist-deatils-price-d {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;

  letter-spacing: 0.04em;
  text-align: left;

  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 4%, 72%, 1);
}
.CustomerNewCart-subheading-itemlist-deatils-button1 {
  display: flex;
  border: 1px solid hsla(336, 98%, 58%, 1);
  padding: 5px;
  border-radius: 30px;
  align-items: center;
  justify-content: space-around;
  width: 60%;
}
.CustomerNewCart-subheading-itemlist-deatils-button2 {
  align-items: center;
  justify-content: space-between;
  display: flex;
  border: 1px solid hsla(336, 98%, 58%, 1);
  border-radius: 25px;
  padding: 4px 10px 4px 10px;
  gap: 10px;
  font-size: 18px;
}
.cust-myacc-wishlist-sub-btn {
}
.CustomerNewCart-subheading-itemlist-deatils-button1-img {
  width: 15%;
}

.CustomerNewCart-subheading-itemlist-deatils-button1-dropdown-menu {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.CustomerNewCart-subheading-itemlist-deatils-button1-dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
  font-family: Mulish;
  font-size: 14px;
}

.CustomerNewCart-subheading-itemlist-deatils-button1-dropdown-item:hover {
  background: hsla(336, 98%, 90%, 1);
  color: hsla(336, 98%, 58%, 1);
}
/* Container for dropdown to handle positioning */
.CustomerNewCart-size-dropdown-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
}

/* Style for the dropdown */
.CustomerNewCart-subheading-itemlist-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.CustomerNewCart-subheading-itemlist-dropdown-item {
  cursor: pointer;
  font-family: Mulish;
  font-size: 12px;
  padding: 8px;
  text-align: left;
}

.CustomerNewCart-subheading-itemlist-dropdown-item:hover {
  background: hsla(336, 98%, 90%, 1);
  color: hsla(336, 98%, 58%, 1);
}
.CustomerNewCart-subheading-itemlist-deatils-button1-color-size {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 25%, 1);
}
.CustomerNewCart-subheading-itemlist-deatils-button1-colorName-sizeName {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.CustomerNewCart-subheading-section-AmountSection {
  display: flex;

  padding: 8px;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.59px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewCart-accordian-sec-1 {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewCart-subheading-section-AmountDetailsSection-main {
  display: flex;
  flex-direction: column;
}
.CustomerNewCart-subheading-section-AmountDetailsSection {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.CustomerNewCart-subheading-section-AmountDetailsSection-text1 {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewCart-subheading-section-AmountDetailsSection-text1-span {
  font-size: 12px;
}
.CustomerNewCart-subheading-section-AmountDetailsSection-text2 {
  font-family: Mulish;
  font-size: 20px;
  font-weight: 500;
  line-height: 25.1px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewCart-subheading-section-AmountDetailsSection-text2-span {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.06px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 33%, 1);
  padding-left: 10px;
}
.CustomerNewCart-subheading-section-AmountDetailsSection-text3 {
  font-family: Mulish;
  font-size: 19px;
  font-weight: 800;
  line-height: 23.85px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewCart-proceedtopay-section {
  padding-top: 20px;
}
.CustomerNewCart-proceedtopay-btn {
  cursor: pointer;
  display: flex;
  border-radius: 25px;
  justify-content: center;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 100%, 1);
  padding: 10px;
}
.customernewcart-p-t-p {
  padding: 8px;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(148, 98%, 32%, 1);
}
.CustomerNewCart-subheading-section-applycoupon {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.CustomerNewCart-subheading-section-applycouponSection {
  display: flex;
  justify-content: space-between;
}
.CustomerNewCart-subheading-section-applycouponSection-flex {
  display: flex;
  flex-direction: column;
}
.CustomerNewCart-subheading-section-applycouponSection-text1 {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.59px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewCart-subheading-section-applycouponSection-text2 {
  font-family: Mulish;
  font-size: 12px;

  line-height: 15.06px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 52%, 1);
}
.CustomerNewCart-subheading-section-applycouponSection-text2-span {
  font-weight: 700;
}
.CustomerNewCart-subheading-section-applycouponSection-apply {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe575c;
}
.CustomerNewCart-apply-coup-ip-sec {
  padding-bottom: 20px;
}

.CustomerNewCart-apply-coup-ip {
  padding: 0; /* Remove padding for full coverage */
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align elements vertically */
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  overflow: hidden; /* Ensure no overflow for rounded corners */
}

.CustomerNewCart-apply-coup-ip-text {
  flex: 1; /* Take up all available space */
  padding: 10px 15px; /* Add padding inside the input */
  border: none; /* Remove input border */
  border-radius: 0; /* Ensure no border radius */
  outline: none; /* Remove input focus outline */
  font-size: 16px; /* Adjust text size */
  background: hsla(0, 0%, 100%, 1); /* White background */
}

.CustomerNewCart-apply-coup-ip-btn {
  padding: 10px 20px; /* Add padding for the button */
  background: linear-gradient(90deg, #fd2a80 0%, #fe575c 100%);
  border: none; /* Remove border */
  color: white; /* White text */
  font-size: 16px; /* Adjust text size */
  cursor: pointer; /* Pointer cursor on hover */
  outline: none; /* Remove focus outline */
}

.CustomerNewCart-apply-coup-ip-btn:hover {
  opacity: 0.9;
}
.CustomerNewCart-adress-sectionMain {
  padding: 20px;
}
.CustomerNewCart-adress-Heading {
  font-family: Mulish;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.12px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewCart-adress-Details-Section {
  padding-top: 20px;
  display: flex;
  align-items: center;
}

.CustomerNewCart-adress-Details-Section > div:first-child {
  margin-right: 10px;
}

.CustomerNewCart-adress-Details-Section > div:nth-child(2) {
  flex-grow: 1;
}

.CustomerNewCart-adress-Details-Section > div:nth-child(2) > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CustomerNewCart-adress-Details-Name {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 0.66);
}
.CustomerNewCart-adress {
  width: 80%;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 0.66);
}
.Customer-Cart-Add-Adress-Sec {
  padding: 10px;
  display: flex;
  align-items: center;
}
.Customer-Cart-Add-Adress- {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
  cursor: pointer;
}
.Add-shipping-adress-modal-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Add-shipping-adress-modal {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.add-deveilveryadressbutton-sec {
  padding-top: 20px;
}
.add-deveilveryadressbutton {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 100%, 1);
  cursor: pointer;
  padding: 5px;
  border-radius: 25px;
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  display: flex;
  justify-content: center;
}
.widthofipline-develiveradresscom {
  width: 400px;
}
.Add-shipping-adress-title {
  font-family: Mulish;
  font-size: 28px;
  font-weight: 400;
  line-height: 35.14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(341, 61%, 11%, 1);
}
.Add-shipping-adress-subtitle {
  font-family: Mulish;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.1px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(341, 61%, 11%, 1);
}
.CustomerNewCart-subheading-itemlist-deatils-number {
  font-size: 18px;
}
.CustomerNewCart-subheading-section2-scroll {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  border: 1px solid #ccc;
}

@media screen and (max-width: 598px) {
  .CustomerNewCart-subheading-itemlist-deatils-number {
    font-size: 12px;
  }
  .CustomerNewCart-subheading-section-itemlist-sub1 {
    padding: 6px;
    gap: 8px;
  }
  .CustomerNewCart-subheading-itemlist-deatils-heading {
    font-size: 10px;
  }
  .CustomerNewCart-subheading-itemlist-deatils-price1 {
    font-size: 10px;
  }
  .CustomerNewCart-subheading-itemlist-deatils-price2 {
    font-size: 10px;
  }
  .CustomerNewCart-subheading-itemlist-deatils-price-d {
    font-size: 8px;
  }
  .CustomerNewCart-subheading-itemlist-deatils-price {
    gap: 5px;
  }
  .CustomerNewCart-subheading-itemlist-deatils-button1-img {
    width: 20%;
  }
  .CustomerNewCart-subheading-itemlist-deatils-button1-color-size {
    font-size: 8px;
    padding-left: 5px;
  }
  .CustomerNewCart-subheading-itemlist-deatils-button1-colorName-sizeName {
    font-size: 8px;
    padding-left: 2px;
  }
  .CustomerNewCart-subheading-itemlist-deatils {
    width: 80%;
    padding: 0px;
  }
  .CustomerNewCart-subheading-section {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .CustomerNewCart-adress {
    width: 100%;
  }
  .widthofipline-develiveradresscom {
    width: -webkit-fill-available;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .CustomerNewCart-subheading-itemlist-deatils {
    padding: 0px;
  }
  .CustomerNewCart-subheading-section {
    padding: 10px;
  }
}
@media (min-width: 901px) and (max-width: 1200px) {
  .CustomerNewCart-subheading-section {
    padding: 10px;
  }
}
