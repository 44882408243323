.seller-product-list {
    width:70%;
    margin: auto;
    min-width: 350px;
    max-width: 100%;
     border-collapse:separate; 
    border-spacing: 0 1em;
  }
  
  .seller-product-list tr:first-child {
    /* border-top: none; */
    text-align: center;
    background: white;
    color: black;
  }
  
  .seller-product-list tr {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .seller-product-list tr:nth-child(odd):not(:first-child) {
    background: #FFFFFF;
    border: 1px solid 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .seller-product-list th {
    display: none;
  }
  
  .seller-product-list td {
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .seller-product-list td:first-child {
    margin-top: .5em;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .seller-product-list td:last-child {
    margin-bottom: .5em;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  
  }
  
  .seller-product-list td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 120px;
    display: inline-block;
    color: black;
  }
  
  .seller-product-list th,
  .seller-product-list td {
    /* text-align: left; */
  }
  
  .seller-product-list {
    color: #333;
    border-radius: .4em;
    overflow: hidden;
  }
  
  .seller-product-list tr {
    background: #FFFFFF;
   }
  
  .seller-product-list th,
  .seller-product-list td {
    padding: 1rem 1em;
  }
  
  .seller-product-list tr:first-child , .seller-product-list td:first-child { border-top-left-radius: 10px; }
  .seller-product-list tr:first-child , .seller-product-list td:last-child { border-top-right-radius: 10px; }
  
  .seller-product-list tr:last-child ,.seller-product-list td:first-child { border-bottom-left-radius: 10px; }
  .seller-product-list tr:last-child,  .seller-product-list td:last-child { border-bottom-right-radius: 10px; }
  
  @media screen and (max-width: 601px) {
   .seller-product-list tr:nth-child(2) {
      border-top: none;
      
    }
  
    .seller-product-list tr {
      background: #fafafa;
      border: 1px solid rgba(0, 0, 0, 0.2);
  
     }
  
    .seller-product-list td {
        display: flex;
        border: none !important;
        justify-content: space-evenly;
        max-width: 370px;
    
    }
    .seller-product-list td>* {
        /* display:block; */
        align-self: flex-start;
    }
    .seller-product-list td:first-child {
      border: none !important;
    
    }
    
    .seller-product-list td:last-child {
      border: none !important;
    
    }
    
    .seller-product-list {
      width:95%;
      margin:0 auto;
      border-collapse: collapse;
      border-spacing: 0 1em;
    }
  }
  @media screen and (min-width: 600px) {
  
    
  
    .seller-product-list {
      width:95%;
      margin:0 auto;
    }
    .seller-product-list tr:hover:not(:first-child) {
      /* background-color: #d8e7f3; */
      cursor:pointer;
    }
    .seller-product-list td:before {
      display: none;
    }
    .seller-product-list th,
   .seller-product-list td {
      display: table-cell;
      padding: .25em .5em;
    }
    .seller-product-list th:first-child,
  .seller-product-list td:first-child {
      padding-left: 0;
    }
   .seller-product-list th:last-child,
   .seller-product-list td:last-child {
      padding-right: 0;
    }
    .seller-product-list th,
    .seller-product-list td {
      padding: 0.3em !important;
    }
  
  
  }
    