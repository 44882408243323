.admin-view-order-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin-view-order-details-container {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 800px;
  margin: auto;
}

.admin-view-order-details-order-container {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 15px;
  background-color: white;
  border-radius: 6px;
}

.admin-view-order-details-section {
  margin-bottom: 15px;
}

.admin-view-order-details-section h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #0056b3;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.admin-view-order-details-item {
  margin-bottom: 8px;
}

.admin-view-order-details-item span {
  font-weight: bold;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .admin-view-order-details-container {
    padding: 10px;
  }
}
.new-modal-of-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 130px;
}
.new-model-of-admin-head {
  display: flex;
  align-items: center;
  gap: 10px;
}
.admin-all-order-dropdown-wrapper {
  position: relative;
  display: inline-block;
  width: 220px; /* Ensure consistent width for the dropdown */
}

.admin-all-order-dropdown {
  width: 100%;
  padding: 12px 40px 12px 12px; /* Adjust padding for better alignment */
  font-size: 16px;
  font-family: "Arial", sans-serif;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 8px;
  color: #333;
  outline: none;
  transition: all 0.3s ease;
  cursor: pointer;
  appearance: none;
  position: relative;
  height: 48px; /* Fixed height to align the icon properly */
}

.admin-all-order-dropdown:hover {
  background-color: #e2e2e2;
}

.admin-all-order-dropdown:focus {
  border-color: pink; /* Highlight when focused */
}

.dropdown-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent interaction with the icon */
  font-size: 18px; /* Icon size */
  color: #333; /* Ensure icon color matches text color */
}

.dropdown-icon svg {
  vertical-align: middle; /* Align the icon vertically */
}
