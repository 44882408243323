.invite-seller-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  }
  .get-link-invite{
    color: #000;
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0em;
text-align: left;
text-decoration: underline;
color:rgba(254, 60, 114, 1);
cursor: pointer;
  }
  .InviteSellerLink-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .invite-table {
    border-collapse: collapse;
    width: 60%;
    padding:1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin:2rem auto;
    border-radius:20px;
    font-size:1.2rem;

  }
  .invite-table-header{
    background: white;
    border-radius: 10px;

  }
  .invite-seller-table-header td, th {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.8);
    align-items: center;
}
  .invite-table-header
  .invite-table-cell {
    border: 1px solid #ffffff;
    /* text-align: left; */
    padding: 8px;
    font-size: 32px;
  }
  .invite-table-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: rgba(255, 97, 85, 0.2);
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
}
.invite-table-head {
  background: rgba(255, 97, 85, 0.2);
  font-size: 1.2rem;
  width: 25%;
  /* border-radius: 10px; */
}
  .invite-table-cell {
    background-color: white;
    text-align: center;
    }
  
    .invite-get-button-field{
      display: flex;
      justify-content: flex-end;
      align-items: center;

    }
  .invite-form-card {
    display: flex;
    gap: 5px;
    flex-direction:column;
     border: 1px solid black;
     background: #FFFFFF;
     border: 1px solid rgba(0, 0, 0, 0.2);
     /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
     border-radius: 10px;  
       width:60%;
    max-width:800px;
    margin:0 auto;
    padding:1rem;
  }
  
  .invite-form-card.invite-table-cell:last-child {
    display: flex;
    justify-content: space-evenly;
  }
  
  .invite-form-card-field{
    display:flex;
    align-items: center;
    margin-bottom:1.2rem;;
  }
  .invite-form-card-field .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    height:1rem;
  }
  
  .invite-form-card-field-name{
    width:60%;
    font-size:19px;
  }
  .invite-form-card-input-field{
    width:100%;
    position:relative;
  }
  .invite-table-row{
    margin:0.5rem auto;  
    background-color: white;
  }
  .invite-table-row:first-child() {
      background: rgba(255, 97, 85, 0.2);
    }
  .invite-seller-button{
    align-self: flex-end;;
  }
.invite-table-body{
  
background: #FFFFFF;
/* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
}
  @media screen and (max-width:480px){
.invite-table{
  width:90%;
  margin: 0 auto;
}
    .invite-form-card{
      width:80%;
    }
    .invite-form-card-field{
      flex-direction: column;
    }

    .invite-form-card-field-name{
      width:90%;
      font-size:1.2rem;
    }

    .invite-table-row{
      flex-direction: column;
      padding:0;
      margin:0;
      background-color: white;
      
    }
 
    .invite-table-cell {
      /* border: 1px solid #ffffff; */
      /* text-align: left; */
      padding: 8px;
      font-size: 12 px;
    }
    .invite-table-head {
      background: rgba(255, 97, 85, 0.2);
      font-size:12px;
      width:25%;
      border-radius: 10px;
    }

  }
  
  .invite-seller-note{
    display: flex;
    justify-content: center;
    font-size: 20px;
line-height: 24px;
color: rgba(112, 112, 112, 0.72);
margin-top: -55px;
  }
 .invite-seller-card-seller-field.Mui-input-root{
    border-radius: 0!important;
  }

  .invite-seller-error-message{
    position: absolute;
    color: #d32f2f;
    font-size: 13px;
    /* text-align:center; */
    
  }

  .invite-table-body > .invite-table-row:nth-child(1){
    display:none;
  }
  
  .inviteSeller-Invite-Button-Field{
display: flex;
justify-content: right;
align-items: center;
width: 80%;
  }