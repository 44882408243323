
.customer-category-bar {
    width: 100%;
    height: 60px;
    overflow-x: hidden;
    position: fixed;
    /* margin: 0.3rem auto 0 auto; */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
    margin-top: 55px;
    z-index: 1000;
    color: rgba(255, 255, 255, 1);
}
.Customer_wishlist_card_container{
    min-height: 100px;
    /* background: #666; */
    width: 270px;
    height: 430px;
    padding: 10px;
    margin-bottom: 16px;
    /* background: #FAF7F5; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0rem;
    border-radius: 8px;
    margin: 0px auto;
}
.CustomerMarqueeDesc{
    font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.96px;
background: linear-gradient(180deg, #FB0D85 0%, #FF5E56 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
 
  .overlay-container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 8px 8px;
  }
  
  .overlay-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px !important;
  }
  
  .overlay-image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    pointer-events: none;
  }
  
  .customer-tale-of-crafts-card-head,
  .customer-tale-of-crafts-card-desc {
    position: relative; /* Ensure text is on top of the overlay */
    z-index: 1; /* Place text on top of the overlay */
  }
  
.custom-nav-icon
.index-module_Button__hkx4Z{
    opacity: 0.5;
    width: 38px;
    height: 37px;
    transition: all 200ms ease-in-out;
    color: #FFF;
}
.customer-hero-carousel-button {
    width: 130px;
    height: 35px;
    color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(255, 255, 255, 1);
    background: transparent;
    border-radius: 8px;

}
.index-module_Button__hkx4Z {
    width: 100%;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}
.index-module_Button__hkx4Z svg {
    opacity: 0.5;
    width: 60px;
    height: 60px;
    transition: all 200ms ease-in-out;
    color: #FFF;
}
.customer-hero-carousel-button:hover {
    width: 130px;
    height: 35px;
    color: rgba(255, 255, 255, 1);
    border:none;
    background: transparent;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);


}

.customer-category-bar::-webkit-scrollbar {
    display: none;
}

.category-item {
    color: #EB4D80;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.16px;
    padding: 8px 24px 8px 24px;
    align-self: center;
    /* width: 122px; */
    border-left: 1px solid #DEDEDE;
}

.category-container {
    height: 60px;
    display: flex;
    /* gap: 1rem; */
    /* max-width: 1280px; */
    align-items: center;
    justify-content: center;
    color: rgba(235, 77, 128, 1);
    /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20); */
    overflow-x: scroll;
    margin: 0 auto;
    position: fixed;
    background: white;
    width: 100%;
}

.category-container::-webkit-scrollbar {
    width: 0rem !important;
    /* Set the width of the custom scrollbar */
}

.category-container::-webkit-scrollbar-thumb {
    background: white;
}

.customer-marquee {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100vw;
    height: 70px;
    margin: 0 auto;
    background: #FFEDE3;
    color: #FA0D85;
    font-size: 24px;
    letter-spacing: 0.96px;
    margin-bottom: 16px;

}

.customer-product-card {
    min-height: 100px;
    /* background: #666; */
    width: 288px;
    height: 460px;
    padding: 16px;
    margin-bottom: 16px;
    /* background: #FAF7F5; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0rem;
    border-radius: 8px;
    margin: 0px auto;
}
.Product_card_img_layout{
    width: 100%;
    height: 288px;
    border-radius: 15px;
    object-fit: fill;
}


.customer-product-card-img {
    height: 148px;
    border-radius: 8px;
    max-width: 100%;
    margin-bottom: 16px;
    position: relative;
    width: 100%;
}

.customer-product-card-title {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.2px;
    margin-bottom: 4px;
    margin-bottom: 4px;
    width: calc(100% - 18px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}

  .customer-tale-of-crafts-card {
    position: relative;
    background-size: 'cover';
     background-repeat: 'no-repeat';
     border-radius:"8px" ;
     height:340px;
  }

  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 200px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    border-radius: 8px;
    pointer-events: none;
  }


  /* -------------------------------------CHANGES IN TALE OF CRAFTS-------------------------------------- */
  .cutomer-tale-craft-box{
    height: 440px;
    width: 370px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
    /* margin-left: 8px;
    margin-right: 8px; */
    padding-bottom: 8px;
    /* margin-left: 5px; */

  }
.cust-craft-image{
    height: 324px;
    width: 338px;
    border-radius: 8px;
    margin: 16px auto 16px auto;
}
.customer-tale-of-crafts-card-head1{
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.2px;
    line-height: 100%;
    margin-left: 16px;
    margin-top: 16px;
    text-transform: uppercase;
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.customer-tale-of-crafts-card-desc1{
    color: #868686;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4; /* 140% */
    letter-spacing: -0.16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    margin-left: 16px;
    margin-top: 16px;


}
.tale-of-craft-alldata{
    width: 390px;
    margin-left: 8px;
    
}
.customer-landing-page-section-container-tale{
    width: 95%;
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    padding-bottom: 39px;
}
.tale-of-craft-cust-main{
    max-height:646px ;
}
.tale-of-craft-container{
    width: 100%;
    /* height: 646px; */
}
@media screen and (min-width:1055px) and ( max-width:1260) {
    .customer-landing-page-section-container-tale {
        display: flex;
        flex-wrap: wrap;
    }
    
}
/* ---------------------------------------------------------------------------------------------------------- */
.customer-product-card-category {
    color: #868686;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.12px;
    margin-bottom: 8px;

}

.customer-product-card-price {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.18px;
    margin-bottom: 8px;

}

.customer-product-card-review {
    color: #1A1A1A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

}

.customer-product-card-quantity {
    color: #868686;
    font-family: Poppins;
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.12px;
    margin-top: 8px;
    margin-bottom: 16px;

}

.customer-product-card-button {
    background: linear-gradient(90deg, #FD2A80 0%, #FE575C 100%);
    color: #fff !important;
cursor: pointer;
}
.customer-product-card-button:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.customer-product-card-fav {
    /* color:red !important; */
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
}

.customer-hero-carousel-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 100px;
    left: 250px;
    margin: auto;
    color: rgba(255, 255, 255, 1);
}
.customer-hero-carousel-offer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    font-weight: 900;
    font-size: 40px;
}

.customer-tale-of-crafts {
    background-color: #FFF4EB;
}

.customer-tale-of-crafts-card {
    width: 380px;
    height: 400px;
    min-height: 200px;
    flex-shrink: 0;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff4eb;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 24px;
    margin-bottom: 24px;
    background-size: 'cover';
    background-repeat: 'no-repeat';
    border-radius:"8px" ;

}

.customer-tale-of-crafts-card-head {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 20px */
    letter-spacing: -0.2px;
    text-transform: uppercase;
    position: relative;
    top: 190px;
    left: 12px;
}

.customer-tale-of-crafts-card-desc {
    color: #FFF2F6;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4; /* 140% */
    letter-spacing: -0.16px;
    position: relative;
    top: 215px;
    width: 332px;
    left: 12px;
    overflow: hidden;
    /* white-space: nowrap; */ /* Remove this line */
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Number of lines to show */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

}

.GCBanner{
    width: 100%;
    height: 428px;
    flex-shrink: 0;
    border-radius: 8px;
    margin: 0 auto;
    /* background-image: url("../Images/GCBanner.jpg");
    background-size: contain;
    background-repeat: no-repeat; */
}
.custom-nav button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: white;
    transition: color 0.3s ease;
  }
  
  .custom-nav button:hover {
    color: #ff69b4; /* Change the color on hover */
  }
  
.customer-reviews-section {
    width: 90%;
    background-color: #FFF5E1;
    border-radius: 10px;
    padding: 2rem 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* min-height:400px; */
    padding: 5rem auto 2rem auto;
    ;
    background: #FDF4ED;
}

.customor-heading {
    font-size: 60px;
    text-align: center;
    margin: 2rem auto;
    position: relative;
    width: 100%;

}

.customer-heading-styling-underline {
    position: absolute;
    background-color: #F54565;
    height: 0.2rem;
    width: 10%;
    border-radius: 80%;
    /* margin-bottom:1rem; */
    left: 45%;
    margin: 1rem auto;
    ;
}

.customer-review-section {
    width: 25%;
    min-width: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 280px;
    padding: 0 2.5%;
    /* border-right: 1px solid #A0A0A0; */
    background: #FDF4ED;
}

.customer-review-section:last-child {
    border-right: none;
}

.customer-review-section-img {
    width: 85px;
    height: 85px;
    border-radius: 50px;
}

.h3-customer-marquee {
    padding-right: 2rem;
    margin-right: 2rem;
    font-size: 40px;
    border-right: 1px solid rgba(253, 42, 128, 1);

}

.customer-craft-story-card {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    height: 265px;
    border-radius: 10px;
background: #FDF4ED;
margin-top: 24px;
margin-bottom: 40px;
width: 390px;
margin: 0 auto;

}

.customer-craft-story-card-cover-image {
    max-height: 136px;
    border-radius: 10px 10px 0 0;
    /* width: 374px; */
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    margin-left: 8px;
    margin-right: 8px;

}

.customer-craft-story-card-seller-image {
    position: absolute;
    top: 80px;
    left: 156px;
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.customer-craft-story-card-seller-image>img {
    width: 100%;
    height: 100px;
    border-radius: 100px;

}

.customer-craft-story-card-text {
    width: 100%;
    height: 115px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 14px;
}

.customer-craft-story-card-text-head {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 20px */
    letter-spacing: -0.2px;
    text-transform: uppercase;
    margin-bottom: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    width: 190px;

}

.customer-craft-story-card-text-link {
    color: #3E3E3E;
    text-align: right;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    /* 14px */
    letter-spacing: -0.14px;
    text-decoration-line: underline;
    cursor: pointer;
}

.customer-social-card {
    width: 100%;
    max-width: 286px;
    height: 395px;
    position: relative;
    border-radius: 8px;
}

.customer-social-card-img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
}

.customer-social-card-logo {
    position: absolute;
    top: 16px;
    right: 16px;
}

.insta-join {
    color: rgba(26, 26, 26, 1);
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.14px;
    opacity: 0.6;
}

.insta-featured {
    color: #1A1A1A;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.logo-style {
    background: linear-gradient(90deg, #FD2A80 26.97%, #FE575C 58.99%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.landing-page-featured-content{
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 48px;
}
.landing-page-share-btn-container {
    width: 100%;
    max-width: 400px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;

}

.landing-page-share-btn {
    color: white !important;
    display: flex;
width: 270px !important;
padding: 10px 8px !important;
justify-content: center;
align-items: center;
font-size: 14px;
letter-spacing: -0.14px;
text-transform: uppercase;
gap: 8px;
    background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%) !important;
    border-radius: 8px;


}

.customer-login-alert-component {
    width: 50%;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 48px;
    min-width: 300px;
    margin:5rem auto;

}
.customer-login-alert-component-button{
    background:linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    border:none;
    color:white;
    padding:10px, 8px, 10px, 8px;
    margin:1rem auto;
    border-radius:8px;
    cursor:pointer;
    text-align: center;
    width: 254px;
    height: 34px;
}
.customer-login-alert-component-head{
    font-size:24px;
}


.LinearProgress-buyers
.css-5xe99f-MuiLinearProgress-bar1 {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    transition: transform .4s linear;
    transform-origin: left;
    background-color: #EB4D80 !important;
}
.LinearProgress-buyers
.css-eglki6-MuiLinearProgress-root {
    position: relative;
    overflow: hidden;
    display: block;
    height: 4px;
    z-index: 0;
    background-color: #EDEDED !important;
}
/* -----------------------------------------SHOP BY CATEGPRY NEW DESIGN------------------- */
.landingpage-category-tail{ 
     display: flex;
width: 288px;
padding: 9px 36px;
justify-content: center;
align-items: center;
gap: 8px;
position: absolute;
bottom: 0px;
border-radius: 0px 0px 10px 10px;
background: #9e534c;
opacity: 100%;
}

/* -------------------------------------------------------------------------------------- */
.customer-landing-page-section-container{
    width: 95%;
    max-width: 1280px;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 24px;
    background:white;
}
.customer-landing-page-section-container-tale-of-craft {
    width: 100%;
    max-width: 1280px;
    margin: 24px auto;
    background: #FFF4EB;
}




@media screen and (max-width: 1024px) {
.CustomerMarqueeDesc{
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.96px;
}
    .category-item {
        color: #EB4D80;
        font-family: Poppins;
        font-size: 6px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.16px;
        /* border-right:1px solid black; */
        padding: 0px 10px;
        align-self: center;
        border-left: 1px solid #DEDEDE;
    }
    .customer-tale-of-crafts-card {
        width: 270px;
        height: 375px;
        flex-shrink: 0;
        flex-shrink: 0;
        border-radius: 8px;
        background: #fff4eb;
        cursor: pointer;
        margin: 0 auto;
        margin-top: 10px;
    }
    .customer-landing-page-section-container {
        width: 90%;
        /* max-width: 1280px; */
        margin: 0 auto;
        margin-bottom: 40px;
        margin-top: 24px;
        background: white;
        border-radius: 8px;
    }
    .customer-marquee {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100vw;
        height: 70px;
        margin: 0 auto;
        background: #FFEDE3;
        color: #FA0D85;
        font-size: 10px;
        letter-spacing: 0.96px;
        margin-bottom: 16px;

    }

    .h3-customer-marquee {
        padding-right: 2rem;
        padding-left: 1rem;
        margin-right: 1rem;
        font-size: 25px;
        border-right: 1px solid rgba(253, 42, 128, 1);
    }

    .customer-hero-carousel-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 0px;
        margin: auto;
    }

    .customer-review-section {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 250px;
        padding: 0 2.5%;
        background: #FDF4ED;
    }
    .customer-login-alert-component {
        width: 50%;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 20px;
        min-width: 250px;
        margin: 1rem auto;
    }
    .customer-login-alert-component-head {
        font-size: 18px;
    }
    .customer-craft-story-card-cover-image {
        max-height: 136px;
        border-radius: 10px 10px 0 0;
        width: 304px;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 8px;
        margin-left: 8px;
        margin-right: 8px;
    }
   
    .landingpage-category-tail {
        /* opacity: 0.8;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        color: rgb(255, 255, 255);
        text-align: right;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: -0.14px;
        text-transform: uppercase;
        border-radius: 0px 0px 10px 10px;
        background: rgba(158, 83, 76, 0.7);
        padding: 5px 25px; */
        display:flex;
         width:100%;
          padding-top:8px; 
          margin:0px;
           background-color: #9e534c;
            position:absolute;
             bottom:2%;
              z-index: 100;
               opacity:100%
    }
    .customer-product-card {
        min-height: 100px;
        /* background: #666; */
        width: 115px;
        height: 290px;
        padding: 16px;
        margin-bottom: 16px;
        background: none;
        border-radius: 8px;
        margin: 5px;
    }

    .customer-product-card-img {
        height: 100px;
        border-radius: 8px;
        max-width: 100%;
        margin-bottom: 16px;
        position: relative;
    }
    .customer-product-card {
        min-height: 100px;
        /* background: #666; */
        width: 288px;
        /* height: 470px; */
        padding: 8px;
        /* margin-bottom: 16px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3rem;
        border-radius: 8px;
        margin: 0px auto;
    }
    .Customer_wishlist_card_container{
        min-height: 100px;
        /* background: #666; */
        width: 288px;
        /* height: 470px; */
        padding: 8px;
        /* margin-bottom: 16px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3rem;
        border-radius: 8px;
        margin: 0px auto;
    }
    .Product_card_img_layout {
        width: 100%;
        height: 151px;
        border-radius: 15px;
        object-fit: fill;
    }
    .landingpage-category-tail {
        /* border-radius: 0px 0px 10px 10px;
        background: rgba(158, 83, 76, 0.70);
        opacity: 0.8;
        text-align: 'center';
        display: flex;
        width: 100%;
        padding: 5px 6px;
        justify-content: center;
        align-items: center;
        color: 'white';
        color: #FFF;
        text-align: right;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: -0.14px;
        text-transform: uppercase;
        margin: 0 auto; */
        display:flex;
         width:100%;
          padding-top:8px; 
          margin:0px;
           background-color: #9e534c;
            position:absolute;
             bottom:2%;
              z-index: 100;
               opacity:100%
    }
    .customer-product-card-quantity {
        color: #868686;
        font-family: Poppins;
        font-size: 8px;
        font-style: italic;
        font-weight: 300;
        line-height: 100%;
        letter-spacing: -0.12px;
        margin-bottom: 8px;
    }
    .customer-product-card-title {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.2px;
        margin-bottom: 4px;
        margin-bottom: 4px;
        width: calc(100% - 18px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .customer-product-card-category {
        color: #868686;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.12px;
        margin-bottom: 10px;
    }
    .customer-product-card-price {
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.18px;
        margin-bottom: 10px;
    }
    .customer-product-card-review {
        color: #1A1A1A;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 5px;
    }
    .customer-product-card-button {
        background: linear-gradient(90deg, #FD2A80 0%, #FE575C 100%);
        color: #fff !important;
        font-size: 10px !important;
        cursor: pointer;
    }
    .customer-craft-story-card-seller-image {
        position: absolute;
        top: 80px;
        left: 110px;
        width: 97px;
        height: 100px;
        border-radius: 100px;
    }
    .customer-craft-story-card-text {
        width: 100%;
        height: 122px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: -20px;
    }
    .customer-craft-story-card-text-head {
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.2px;
        text-transform: uppercase;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 185px;
    }
    .customer-craft-story-card-text-link {
        color: #3E3E3E;
        text-align: center;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        letter-spacing: -0.14px;
        text-decoration-line: underline;
        cursor: pointer;
        
    }
    .customer-craft-story-card {
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        width: 320px;
        height: 245px;
        border-radius: 10px;
        background: #FDF4ED;
    }
    .customer-tale-of-crafts-card-head {
        color: #FFF;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        /* 20px */
        letter-spacing: -0.2px;
        text-transform: uppercase;
        position: relative;
        top: 130px;
        left: 12px;
    }
    
    .customer-tale-of-crafts-card-desc {
        color: #FFF2F6;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4; /* 140% */
        letter-spacing: -0.16px;
        position: relative;
        top: 160px;
        width: 230px;
        left: 12px;
        overflow: hidden;
        /* white-space: nowrap; */ /* Remove this line */
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    
    }
}
@media  screen and (max-width:950px) {
    .GCBanner{
        height: 320px;
        /* background-image: url("../Images/GCBanner.jpg");
        background-size: contain;
        background-repeat: no-repeat; */
    }
}
@media screen and (max-width: 800px) {
    .CustomerMarqueeDesc{
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.96px;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 200px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
        border-radius: 8px;
        pointer-events: none;
      }
    .category-item {
        color: #EB4D80;
        font-family: Poppins;
        font-size: 6px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.16px;
        /* border-right:1px solid black; */
        padding: 0px 10px;
        align-self: center;
        border-left: 1px solid #DEDEDE;
    }

    .customer-marquee {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100vw;
        height: 70px;
        margin: 0 auto;
        background: #FFEDE3;
        color: #FA0D85;
        font-size: 10px;
        letter-spacing: 0.96px;
        margin-bottom: 16px;

    }

    .h3-customer-marquee {
        padding-right: 2rem;
        padding-left: 1rem;
        margin-right: 1rem;
        font-size: 25px;
        border-right: 1px solid rgba(253, 42, 128, 1);
        background: linear-gradient(180deg, #FA0D85 0%, #FE5A59 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }

    .customer-hero-carousel-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 0px;
        margin: auto;
    }

    .customer-review-section {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 250px;
        padding: 0 2.5%;
        background: #FDF4ED;
    }
    .customer-login-alert-component {
        width: 50%;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 20px;
        min-width: 250px;
        margin: 1rem auto;
    }
    .customer-login-alert-component-head {
        font-size: 18px;
    }
    .customer-craft-story-card-cover-image {
        max-height: 136px;
        border-radius: 10px 10px 0 0;
        width: 300px;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 8px;
        margin-left: 10px;
        margin-right: 8px;
    }
    .landingpage-category-tail {
        /* opacity: 0.8;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        color: rgb(255, 255, 255);
        text-align: right;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: -0.14px;
        text-transform: uppercase;
        border-radius: 0px 0px 10px 10px;
        background: rgba(158, 83, 76, 0.7);
        padding: 5px 25px; */
        display:flex;
         width:100%;
          padding-top:8px; 
          margin:0px;
           background-color: #9e534c;
            position:absolute;
             bottom:2%;
              z-index: 100;
               opacity:100%
    }
   
    .customer-product-card-img {
        height: 100px;
        border-radius: 8px;
        max-width: 100%;
        margin-bottom: 16px;
        position: relative;
    }
   
    .customer-product-card {
        min-height: 100px;
        width: 140px;
        height: 295px;
        padding: 4px;
        margin-bottom: 16px;
        border-radius: 8px;
        margin: 0px auto;
    }
    .Customer_wishlist_card_container{
        min-height: 100px;
        width: 80%;
        height: 100%;
        padding: 4px;
        margin-bottom: 16px;
        border-radius: 8px;
        margin: 0px auto;
    }
    .customer-product-card-quantity {
        color: #868686;
        font-family: Poppins;
        font-size: 8px;
        font-style: italic;
        font-weight: 300;
        line-height: 100%;
        letter-spacing: -0.12px;
        margin-bottom: 8px;
    }
    .customer-product-card-title {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.2px;
        margin-bottom: 4px;
        margin-bottom: 4px;
        width: calc(100% - 18px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .customer-product-card-category {
        color: #868686;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.12px;
        margin-bottom: 10px;
    }
    .customer-product-card-price {
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.18px;
        margin-bottom: 10px;
    }
    .customer-product-card-review {
        color: #1A1A1A;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 5px;
    }
    .customer-product-card-button {
        background: linear-gradient(90deg, #FD2A80 0%, #FE575C 100%);
        color: #fff !important;
        font-size: 10px !important;
        cursor: pointer;
    }
    .customer-craft-story-card-seller-image {
        position: absolute;
        top: 80px;
        left: 110px;
        width: 97px;
        height: 100px;
        border-radius: 100px;
    }
    .customer-craft-story-card-text {
        width: 100%;
        height: 122px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: -20px;
    }
    .customer-craft-story-card-text-head {
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.2px;
        text-transform: uppercase;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 185px;
    }
    .customer-craft-story-card-text-link {
        color: #3E3E3E;
        text-align: center;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        letter-spacing: -0.14px;
        text-decoration-line: underline;
        cursor: pointer;
        
    }
    .customer-craft-story-card {
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        width: 320px;
        height: 245px;
        border-radius: 10px;
        background: #FDF4ED;
    }
    .customer-tale-of-crafts-card-head {
        color: #FFF;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        /* 20px */
        letter-spacing: -0.2px;
        text-transform: uppercase;
        position: relative;
        top: 70px;
        left: 12px;
    }
    
    .customer-tale-of-crafts-card-desc {
        color: #FFF2F6;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4; /* 140% */
        letter-spacing: -0.16px;
        position: relative;
        top: 80px;
        width: 200px;
        left: 12px;
        overflow: hidden;
        /* white-space: nowrap; */ /* Remove this line */
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
    .GCBanner{
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        border-radius: 8px;
        /* background-image: url("../Images/GCBanner.jpg");
        background-size: contain;
        background-repeat: no-repeat; */
    }
}
@media  screen and (max-width:700px) {
    .GCBanner{
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        border-radius: 8px;
        /* background-image: url("../Images/GCMobile.jpg");
        background-size: contain;
        background-repeat: no-repeat; */
    }
    
}
@media screen and (max-width: 480px) and (orientation: portrait){
    .customer-landing-page-section-container {
        width: 85%;
        margin: 0 auto;
        margin-bottom: 20px;
        margin-top: 12px;
        background: white;
        border-radius: 8px;
    }
    .customer-landing-page-section-container-tale{
        width: 95%;
        max-width: 1280px;
        margin: 0 auto;
        margin-top: 8px;
        display: flex;
        justify-content: center;
    }
        .tale-of-craft-container {
          height: 230px;
        }
      
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 200px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
        border-radius: 8px;
        pointer-events: none;
      }
      .GCBanner{
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        /* background-image: url("../Images/GCMobile.jpg");
        background-size: contain;
        background-repeat: no-repeat; */
    }
    
    .landingpage-category-container{
        display: flex;
        justify-content: center;
    }
    
    
}

.customer-landing-page-section-container-hero{
    margin: 0 auto;
    background:white;
    max-width: 100vw;
    width: 98%;
    margin-bottom: 34px;
}
.shop-now-btn{
    width: 169px;
height: 42px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid #FFF;
background:transparent;
cursor: pointer;
color: white;
}
.shop-now-btn:hover{
    width: 169px;
height: 42px;
flex-shrink: 0;
border-radius: 8px;
border:none;
background: linear-gradient(90deg, #FD2A80 -0.21%, #FE575C 102.25%);
}
.customer-product-card-title::first-letter {
    text-transform: capitalize; 
}