.coming-soon-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 400px;
  /* height: 100vh; */
  /* background: rgb(255, 229, 225); */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  margin: 3% auto;
  border-radius: 20px;
}

.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.title {
  font-size: 3rem;
  color: #eb9797;
  margin-bottom: 1rem;
  text-align: center;
  align-items: center;
  /* font-family: "Bodoni"; */
}

.description {
  font-size: 1.5rem;
  color: #eb9797;
  margin-bottom: 2rem;
  text-align: center;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .card-content {
    padding: 1rem;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
}