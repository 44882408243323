


.otp-screen-container {
    font-family: "Barlow";
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: conic-gradient(from 0deg at 50% 50%, rgba(212, 240, 240, 0.52) 0deg, #FFFFFF 154.87deg, rgba(212, 240, 240, 0.52) 360deg);
}

.otp-top-left-rect {
    position: fixed;
    min-width: 450px;
    /* width: 45vw; */
    height: 30%;
    left: -378px;
    top: -9.16px;
    z-index: -1;
    background: linear-gradient(119.9deg, rgba(255, 134, 82, 0.35) 63.99%, rgba(255, 140, 91, 1 ) 93.94%);
/* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
transform: matrix(0.98, -0.16, 0.19, 0.99, 0, 0);
}

.otp-btm-left {
    position: fixed;
    width: 220.4px;
    height: 79px;
    left: -3vw;
    /* top: 700px; */
    bottom: 3vh;
    background: linear-gradient(269.41deg, rgba(255, 77, 0, 0.43) 10.17%, rgba(255, 175, 140, 0.3) 94.57%);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 40px;
    transform: rotate(-60deg);
}

.otp-btm-left-top {
    position: fixed;
    width: 177.19px;
    height: 79px;
    left: -5vw;
    /* top: 762.45px; */
    bottom: 3vh;
    /* z-index: -1; */
    background: linear-gradient(337.31deg, rgba(65, 72, 243, 0.58) 11.67%, rgba(175, 178, 255, 0.8) 93.51%);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 40px;
    transform: rotate(-60deg);
}

.otp-btm-left-bottom {
    position: fixed;
    width: 177.19px;
    height: 79px;
    /* left: 18px; */
    /* top: 822.45px; */
    bottom: -3vh;
    /* z-index: -1; */
    left: 0vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: linear-gradient(192.25deg, #B3FDFF 8.92%, rgba(11, 230, 236, 0.3) 80.44%);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 40px;
    transform: rotate(-60deg);
}

.otp-circle {
    position: fixed;
    width: 209px;
    height: 214px;
    right: 20vw;
    bottom: 1vh;
    border-radius: 50%;
    background: linear-gradient(207.66deg, #67DCDF 37.17%, rgba(79, 231, 235, 0) 81.8%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: -1;
}

.otp-top-right-rect {
    position: fixed;
    width: 30%;
    height: 30%;
    right: -50px;
    top: -50px;
    opacity: 0.2;
    background: linear-gradient(252.56deg, rgba(254, 103, 130, 0.82) 82.37%, #FF006B 93.95%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: matrix(0.98, -0.16, 0.19, 0.99, 0, 0);   
}
.otp-card {
    /* margin: 2% 3%; */
    width: 35%;
    min-width: 400px;
    /* min-height: 85%; */
    max-width:700px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    padding-top:5%;
    transform: scale(0.8);
    /* transform-origin: 0 0; */
}

.otp-head {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    margin: 5% auto;
    color: rgba(248, 152, 128, 1);
    margin-top:5rem;
    text-align: center;
}

.otp-auth-text {
    color: rgba(248, 152, 128, 1);
    /* font-size:1.3vw; */
    font-size: 1.6rem;
    max-width: 65%;
    font-weight: 400;
    margin: 5% auto;
    text-align: center;

}
.otp-page-form {
    width: 80%;
    display: flex;
    flex-direction: column;
}
.otp-page-email-field{
    /* width: 85%; */
    position: relative;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.otp-email .MuiOutlinedInput-root{
    align-self: center;
    width: 60%;
    /* border-radius: 100px; */
    min-height: 50px;
    height: 2.5rem; 
    background-color:white;
    padding-left: 10%;
    background-size: 8%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3% ;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
#number-helper-text{
    position:absolute;
    bottom:-15%;
    left:28%;
    font-size:1rem;
}

#number{
    padding:  0px;
}
.otp-email-icon {
    position: absolute;
    top: 38%;
    left: 24%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.4rem;
}
.otp-number-field {
    width: 75%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    margin:0 auto;
    text-align: center;
}

.otp-phone-icon {
    position: absolute;
    left: 12%;
    color: rgba(112, 112, 112, 0.72);
    font-size: 1.3rem;
    top: 35%;
}
.otp-phone-validation-text {
    position: absolute;
    bottom: -8%;
    color: red;
    opacity:0.7;
    font-size: 0.8rem;
    padding-top: 1rem;
    text-align: center;
}
.otp-number {
    width: 100%;
    /* border-radius: 100px; */
    /* min-height: 50px; */
    height: 7vh;
    padding-left: 15%;
    /* background: url("/image 6.png") no-repeat 2vw; */
    background-size: 8%;
    font-weight: 400;
    /* font-size: 1.2vw; */
    font-size: 1.2rem;
    line-height: 2vw;
    margin: 5% auto;
    color: rgba(112, 112, 112, 0.72);
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.otp-send-otp {
    margin: 5% 35%;;
    /* font-size: 1.3vw; */
    font-size: 1.3rem;
    padding: 2% 5%;
    background: rgba(248, 152, 128, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: white;
}

.otp-signUp {
    margin: 3% auto;
    font-weight: 600;
    /* font-size: 1.6vw; */
    font-size: 1.6rem;
    line-height: 2vw;
    color: #000000;
}

    .otp-signUp span {
        font-weight: 600;
        /* font-size: 1.4vw; */
        line-height: 2vw;
        text-decoration-line: underline;
        color: #019dbf;
    }

    .otp-signUp span:hover {
        cursor:pointer;
    }
        .otp-terms {
    margin: 5% 5%;
    font-style: normal;
    font-weight: 600;
    /* font-size: 1.4vw; */
    font-size: 1.4rem;
    text-align: center;
    color: rgba(112, 112, 112, 0.72);
}

    .otp-terms span {
        color: #707070;
    }

.otp-logo {
   /* position: fixed; */
   position:absolute;
   /* margin: 1% 1%; */
   /* bottom:-50%; */
   top:-2.5rem;
   /* left:43%; */
   width:10rem;
   height:10rem;
   /* z-index: 100;; */
}

.otp-number:focus {
    outline: none;
}

.otp-number[type=number] {
    -moz-appearance: textfield;
}

.otp-number::-webkit-outer-spin-button,
.otp-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@media only screen and (min-width: 1300px) {
    .otp-head {
        font-size: 2.0rem;
    }
}
.seller-otp-number .MuiOutlinedInput-root {
    align-self: center;
    width: 60%;
    min-height: 50px;
    height: 2.5rem;
    background-color: #efefef;
    padding-left: 10%;
    background-size: 8%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 3%;
    color: rgba(112, 112, 112, 0.72);
   
}