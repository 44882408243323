.GiftCardLayout{
    display: flex;
    justify-content: start;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 300px;
    margin-top: 32px;
    max-width: 1280px;
    flex-direction: column;
}
.gc-heading{
    margin-top: 32px;
    width: 180px;
    height: 32px;
    font-size: 32px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -1%;
    margin-left: 40px;
}
.gc-main-container{
    display: flex;
    flex-direction: row;
    height: 800px;
    gap: 45px;
    width: 100%;
}
.gc-box-container{
    margin-top: 42px;
    margin-left: 40px;
    height: 423px;
    width: 491px;
    /* width: 50%; */
    border-top-left-radius: 20px;
    background-color: rgba(255, 244, 235, 1);
    position: relative;
}
.Box-image{
    height: 307px;
    width: 491px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    position: absolute;
    top: 0%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
}
.gc-tr1{
    height: 60px;
    width: 491px;
    display: flex;
    flex-direction: row;
    /* padding-top: -10px; */
    /* margin-top: -1px; */
    position: absolute;
    top: 72.5%;
    background-color:rgba(255, 244, 235, 1) ;
   
}
.gc-td1{
    width: 288px;
    padding-right: 41px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-wrap: wrap;
    /* position: absolute;
    top: 0%; */
    border-left: 0px;
    border-top: 0px;
    height: 48px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 20px;
    gap:5px;
    font-weight: 500;
    line-height: 20px;
}
.gc-td2{
    width: 203px;
    height: 48px;
    display: flex;
    justify-content: start;
    align-items: center;
    /* padding-right:70px ;
    position: absolute;
    top: 0%;
    left: 75%; */
    font-weight: 500;
    border-right: 0px;
    border-top: 0px;
    background-color:rgba(255, 244, 235, 1) ;
    overflow: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
}
.Box-message{
    width: 491px;
    height: 50px;
    margin-top: 26px;
    /* padding-left: 0px;
    padding-right: 10px; */
    position: absolute;
    top: 82%; 
    /* margin-left: 6px; */
    padding-left:20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow-y: scroll;
    box-sizing: border-box; 

}
.Box-message::-webkit-scrollbar {
    width: 2px; /* Width of the scrollbar */
    height: 5px; 
  }
  
.gc-pay{
    height: 20px;
    /* width: 250px; */
    padding-bottom: 8px;
    

}
.eGift-card{
   max-width: 665px;
   height: 500px;
   margin-top: 42px;
   /* margin-left: 16px; */
}
.My-text1{
  width: 400px;
  height: 32px;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -1%;
  font-weight: 400;
}
.My-text2{
    width: 100px;
    height: 16px;
    margin-top: 15px;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -1%;
    margin-bottom: 15px;
}
.Cardrating{
    width: 149px;
    height: 24px;
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.gc-divider{
    margin-top: 16px;
    width: 665px;
    flex-shrink: 0;
}

/* ------------------------------EMAIL FORM STYLING---------------------- */
.table-detail{
    width: 100%;
    margin-top: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
   
}
::placeholder{
    color: rgba(89, 89, 89, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    padding: 12px 10px 12px 10px;
    }
 #gc-error{
        padding-left: 125px;
        font-size: 10px;
    }
.gc-e-input:focus{
        outline: 2px solid #FE575C;
        border: none;
        border-radius: 8px;
        padding-left: 10px;
    }
    .gc-e-inputnum:focus{
        outline: 2px solid #FE575C;
        border: none;
        border-radius: 8px;
        padding-left: 5px;
    }
.gc-withimage-input:focus{
        height: 34px;
        outline: 2px solid #FE575C;
        border: none;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-left: 10px;
    }
    
.gc-label{
    margin-top: 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -1%;
    width: 110px;
    /* padding-right: 40px; */
    text-align: right;
}
.gc-date-label{
    margin-top: 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -1%;
    width: 110px;
    /* padding-right: 40px; */
    text-align: left;
    display: flex;
    flex-direction: row;
    width: 110px;
}
.gc-icon{
    height: 40px;
    width: 40px;
}
.gc-withimage-input{
    height: 36.5px;
    width: 424px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border:1px solid #888C8C;
    /* border-left: 0px; */
    padding-right: 10px;
}
.gc-img-input{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 10px; */
    height: 44px;
}
.gc-div-input{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: start;
    margin-top: 16px;
    gap: 17px;
   
}
.gc-to-grid{
    margin-right: 10px;
    height: 180px;
}
.gc-toinput{
    height: 46px;
    width: 463px;
    margin-bottom: 20px;
    padding-left: 0px;
}
.gc-toinput #gc-error{
    padding-left: 0px;
    height: 1px;
}
.gc-e-input{
    height: 40px;
    width:463px ;
    padding-left: 10px;
    border-radius: 8px;
    border:1px solid #888C8C;
}
.gc-e-inputfrom{
    height: 40px;
    width:458px ;
    /* margin-left: 19px; */
    padding-left: 14px;
    border-radius: 8px;
    border:1px solid #888C8C;
}
.gc-e-inputfrom:focus{
    outline: 2px solid #FE575C;
    border: none;
    border-radius: 8px;
}
.e-messagelabel{
    font-weight: 600;
    margin-top: 4px;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -1%;
    width: 110px;
    text-align: right;

}
.gc-msginput-field{
    height: 48px;
  width: 474px;
  padding-bottom: 50px;
  border-radius: 8px;
  font-size: 16px;
  border: 1px solid #888C8C;
  resize: vertical; 
  box-sizing: border-box; 
  overflow-y: auto; 
  word-wrap: break-word; 
  white-space: pre-wrap; 
  padding-top:12px;
  padding-left: 14px;
  scrollbar-width: 0px;
    
   
}
.gc-msginput-field::-webkit-scrollbar {
    width: 0px; /* Width of the scrollbar */
    height: 0px; 
  }
  
.gc-msginput-field:focus{
    outline: 2px solid #FE575C;
    border: none;
    border-radius: 8px;
}
.gc-msginput-field::placeholder{
    color: rgba(89, 89, 89, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    padding: 0px 10px 0px 10px;
    
    }
.cardBuy{
    width: 475px;
    height: 34px;
    border-radius: 8px;
    /* margin-right: auto;
    margin-left: auto; */
    margin-left: 125px;
    padding: 10px 0px;
    border-radius: 8px;
    background: linear-gradient(90deg, #FD2A80 0%, #FE575C 106.43%);
    border: none;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor:pointer;
}
.gc-withimage-dateinput{
    height: 36.5px;
    width: 422px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border:1px solid #888C8C;
    border-left: 0px;
    /* padding-left: 10px; */
    padding-right: 10px;
}
.gc-withimage-dateinput:focus{
    height: 34px;
    margin-top: 2px;
    outline: 2px solid #FE575C;
        border: none;
}
.react-datepicker__input-container {
    width: 40px;
    height: 40px; /* Set the width as needed */
  }
  
  

.react-datepicker {
    width: 250px;
    border: 1px solid #030303; /*Border color for the calendar*/
    background-color: #fff;/* Background color for the calendar*/
  }
  .react-datepicker__month-container {
    background-color: #ffffff ;/* Change the background color of the calendar*/
   border: 1px solid #ccc; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    width: 234px;
    height: 267px;
  }
  .react-datepicker__current-month {
    padding-top: 15px; 
    padding-bottom: 15px;/* Increase the space above the month name */
  }
  .react-datepicker-popper .react-datepicker__navigation--previous {
    margin-top: 21px;
    margin-bottom: 10px; 
  }
  .react-datepicker-popper .react-datepicker__navigation--next {
    margin-top: 20px;
    margin-bottom: 10px; 
  }
  .react-datepicker-popper{ /* Set the maximum width of the calendar*/
    max-width: 20px;
    display: flex;
    justify-content: right;
  }
.custom-calendar {
    transform: translateY(-600%);
    left: 200px;
    height:50px;
    margin-left: auto;
    margin-right: auto;
    font-size: 64px; 
  }
.gc-msginput-field {
    resize: none; /* Prevent resizing */
    /* Add other styles as needed */
  }


@media only screen and (max-width:1214px) {
    .custom-calendar {
        transform: translateY(-750%);
        left: 200px;
        height:40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 64px; 
      }
      .Box-image {
        height: 217px;
        width: 100%;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        position: absolute;
        top: 0%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .gc-tr1 {
        height: 60px;
        width: 100%;
        display: flex;
        flex-direction: row;
        /* margin-top: -1px; */
        position: absolute;
        top: 51.5%;
        background-color: rgba(255, 244, 235, 1);
    }
    .gc-box-container {
        margin-top: 42px;
        margin-left: 40px;
        height: 359px;
        width: 400px;
        /* width: 50%; */
        border-top-left-radius: 20px;
        background-color: rgba(255, 244, 235, 1);
        position: relative;
    }
    .gc-td1 {
        width: 65%;
        padding-right: 0px;
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        flex-wrap: wrap;
        border-left: 0px;
        border-top: 0px;
        height: 48px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
        gap: 5px;
        font-weight: 500;
        line-height: 20px;
    }
    .gc-td2 {
        width: 35%;
        height: 48px;
        display: flex;
        justify-content: start;
        align-items: center;
        font-weight: 500;
        border-right: 0px;
        border-top: 0px;
        background-color: rgba(255, 244, 235, 1);
        overflow: hidden;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    
    .Box-message {
        width: 100%;
        height: 50px;
        margin-top: 26px;
        position: absolute;
        top: 60%;
        /* margin-left: 6px; */
        padding-left: 20px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        overflow-y: scroll;
        box-sizing: border-box;
    }

}

@media only screen and (max-width:950px) {
    .gc-main-container{
        display: flex;
        flex-direction: column;
        height: 1500px;
    }

    .gc-label {
        margin-top: 4px;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -1%;
        width: 110px;
        /* padding-right: 40px; */
        text-align: right;
    }
    .table-detail {
        width: 600px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0px;
        align-items: normal;
        margin-left: 40px;
    }
    
    .custom-calendar {
            transform: translateY(-750%);
            left: 410px;
            height:40px;
            margin-left: auto;
            margin-right: auto;
            font-size: 64px; 
    }
    .gc-box-container{
        margin-top: 42px;
        margin-left: 40px;
        height: 435px;
        width: 460px;
        border-top-left-radius: 20px;
        background-color: rgba(255, 244, 235, 1);
        position: relative;
    }
    .Box-image{
        height: 280px;
        width: 460px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        position: absolute;
        top: 0%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;  
    }
    .gc-tr1{
        height: 70px;
        width: 460px;
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 60%;
    }
    .gc-td1 {
        width: 218.5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        border-left: 0px;
        border-top: 0px;
        background-color: rgba(255, 244, 235, 1);
        padding-right: 61px;
    }
    .gc-td2{
        width: 150.5px;
        display: flex;
        justify-content: start;
        align-items: center;
        border-right: 0px;
        border-top: 0px;
        background-color:rgba(255, 244, 235, 1) ;
        overflow: hidden;
    }
    .Box-message{
        width: 460px;
        height: 90px;
        margin-top: 26px;
        position: absolute;
        top: 71%; 
        padding-left: 6px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        overflow-y: scroll;
        box-sizing: border-box; 
    }
    .Box-message::-webkit-scrollbar {
        width: 2px; /* Width of the scrollbar */
        height: 5px; 
      }
      .eGift-card {
        max-width: 1000px;
        height: 100px;
        margin-top: 47px;
        margin-left: 40px;
    }
    .custom-calendar {
        transform: translateY(-750%);
        left: 155px;
        height:40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 64px; 
    }
    .MailCard-table{
        margin-left: -32px;
    }
    
   
}
@media screen and (max-width:730px) {
    /* .customer-layout-children-cont {
        top: 175px;
    } */
    .gc-heading{
        width: 180px;
        height: 32px;
        font-size: 32px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -1%;
        margin-left: 35px;
    }
    .gc-box-container {
        margin-left: 35px;
    }
    .custom-calendar {
        transform: translateY(-750%);
        left: 150px;
        height:40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 64px; 
    }
    .GiftCardLayout{
        display: flex;
        justify-content: center;
    }
    .table-detail {
        width: 550px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0px;
        align-items: flex-start;
        margin-left: 40px;
    }
   
    .gc-e-input {
        height: 40px;
        width: 400px;
        padding-left: 10px;
        border-radius: 8px;
        border: 1px solid #888C8C;
    }
    .gc-e-inputfrom {
        height: 40px;
        width: 400px;
        /* margin-left: 19px; */
        padding-left: 14px;
        border-radius: 8px;
        border: 1px solid #888C8C;
    }
    .gc-withimage-dateinput {
        height: 36.5px;
        width: 356px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid #888C8C;
        border-left: 0px;
        /* padding-left: 10px; */
        padding-right: 10px;
    }
    .cardBuy {
        width: 407px;
        height: 34px;
        border-radius: 8px;
        margin-left: 125px;
        padding: 10px 0px;
        border-radius: 8px;
        background: linear-gradient(90deg, #FD2A80 0%, #FE575C 106.43%);
        border: none;
        color: white;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;
    }
    .gc-msginput-field {
        height: 48px;
        width: 415px;
        padding-bottom: 50px;
        border-radius: 8px;
        font-size: 16px;
        border: 1px solid #888C8C;
        resize: vertical;
        box-sizing: border-box;
        overflow-y: auto;
        word-wrap: break-word;
        white-space: pre-wrap;
        padding-top: 12px;
        padding-left: 14px;
        scrollbar-width: 0px;
    }
    .gc-withimage-input {
        height: 36.5px;
        width: 360px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid #888C8C;
        /* border-left: 0px; */
        padding-right: 10px;
    }
}

@media screen and (max-width:480px){
    .gc-box-container{
        margin-top: 42px;
        margin-left: auto;
        margin-right: auto;
        height: 355px;
        width: 350px;
        border-top-left-radius: 20px;
        background-color: rgba(255, 244, 235, 1);
        position: relative;
    }
    .gc-divider{
        margin-top: 16px;
        width: 400px;
        flex-shrink: 0;
    }
    .Box-image{
        height: 220px;
        width: 350px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        position: absolute;
        top: 0%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;  
    }
    .gc-tr1{
        height: 50px;
        width: 350px;
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 60%;
    }
    .gc-td1 {
        width: 301.5px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* flex-direction: column; */
        flex-wrap: wrap;
        border-left: 0px;
        border-top: 0px;
        background-color: rgba(255, 244, 235, 1);
        padding-left: 6px;
        padding-right: 25px;
        /* margin-top: 0px; */
    }
    .gc-td2{
        width: 115.5px;
        height: 50px;
        display: flex;
        justify-content: start;
        align-items: center;
        border-right: 0px;
        border-top: 0px;
        background-color:rgba(255, 244, 235, 1) ;
        overflow: hidden;
    }
    .Box-message {
        width: 350px;
        height: 90px;
        margin-top: 26px;
        position: absolute;
        top: 70%;
        padding-left: 6px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        overflow-y: scroll;
        box-sizing: border-box;
    }
    #gc-error {
        padding-left: 20px;
        font-size: 10px;
    }
    .Box-message::-webkit-scrollbar {
        width: 2px; /* Width of the scrollbar */
        height: 5px; 
      }
      .gc-main-container {
        height: 1200px;
        max-width: 380px;
        margin-bottom: 100px;
        display: flex;
        align-items: center;
    }
    .My-text1 {
        width: 330px;
        font-size: 24px;
        line-height: 24px;
        /* margin: 0 0px 0px 10px; */
    }
    .gc-div-input .gc-label {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        letter-spacing: -1%;
        width: 21px;
        text-align: left;
    }
    .eGift-card {
        /* max-width: 1000px; */
        height: 100px;
        margin-top: 0px;
        /* margin-left: 315px; */
        width: 344px;
    }
    .table-detail {
        width: 388px;
        margin: 0 auto;
    }
    .gc-withimage-input {
        width: 240px;
        padding-left: 5px;
    }
    .gc-div-input {
        width: 380px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        margin-left: 18px;
    }
    .My-text2 {
        width: 100px;
        height: 16px;
        margin-top: 15px;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -1%;
        margin-bottom: 15px;
        margin-left: -14px;
    }
    .My-text1{
        margin-left: -13px;
    }
    .gc-e-input {
        width: 283px;
    }
    .gc-e-inputfrom{
        width: 283px;
        line-height: 1;
    }
    .gc-to-grid{
        display: flex;
        justify-content: right;
        flex-direction: column;
        width: 300px;
    }
    .gc-toinput{
        width: 300px;
    }
    .e-messagelabel {
        font-weight: 600;
        margin-top: 4px;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -1%;
        width: 82px;
        text-align: left;
        width: 289px;
        display: flex;
        align-items: start;
    }
    .gc-date-label {
        margin-top: 4px;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -1%;
        width: 60px;
        /* padding-right: 40px; */
        text-align: left;
        display: flex;
        flex-direction: row;
        width: 289px;
    }
    .gc-msginput-field{
        height: 40px;
        width: 302px ;
        border-radius: 8px;
        padding-bottom: 100px;
        border:1px solid #888C8C;
    }
    .gc-withimage-dateinput{
        height: 36.5px;
        width: 241px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border:1px solid #888C8C;
        border-left: 0px;
        padding-left: 3px;
    }
    .cardBuy {
        width: 290px;
        height: 34px;
        border-radius: 8px;
        margin-left: 22px;
        padding: 10px 0px;
        border-radius: 8px;
        background: linear-gradient(90deg, #FD2A80 0%, #FE575C 106.43%);
        border: none;
        color: white;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;
    }
    .gc-label{
        font-weight: 600;
    margin-top: 4px;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -1%;
    width: 64px;
    text-align: right;
    }
    .custom-calendar {
        transform: translateY(-500%);
        left: 100px;
        height:40px;
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        font-size: 64px; 
      }
}



