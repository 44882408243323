
.SellerThankYouScreen-container{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    height: 100vh;
    overflow: hidden;
    margin:  0 auto;
}
.RejectScreenDescription{
  font-family: Barlow;
  font-size: 27px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 99, 93, 1);
}
.Myhraki-logo-thankYou{
width: 200px;
}
.SellerThankYouScreen-LeftScreen {
    width: 50%;
    max-width: 2900px;
    max-height: 2000px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 100px;
    margin: 0 auto;
    gap: 3rem;
}
.SellerThankYouScreen-MyhrakiLogo{
    margin-bottom: 10%;
}
.Myhraki-logo {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 70%;
}
.SellerThankYouScreen-RightScreen-content{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
}
.SellerThankYouScreen-RightScreen {
    width: 50%;
    max-width: 2900px;
    max-height: 2000px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: linear-gradient(358.67deg, #FF6155 -0.13%, rgba(252, 38, 120, 0.3875) 102.79%, rgba(255, 97, 85, 0) 130.4%);
    /* gap: 3rem; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.SellerThankYouScreen-Title {
    font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 42px;
color:black;
}
.SellerThankYouScreen-desc {
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    width: 40%;
    color: rgba(250, 0, 142, 0.9);

}
.SellerThankYouScreen-RightScreen-title{
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 28px;
    color: #FF2171;
    margin: 0 auto;
    display: flex;
    justify-content: left;
    /* animation: fadeIn 1s ease-in-out; */
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); */

}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.SellerThankYouScreen-RightScreen-desc {
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: center;
    color: #FF2171;
    width: 60%;
   
}
.SellerThankYouScreen-RightScreen-desc{
    width: 80%;
    text-align: justify;
    margin: 0 auto;
    color: #FF2171;
}
.Community-img{
    min-width: 200px;
    height: 195px;
}
.happy-face-img{
    min-width: 100px;
    height: 100px;
}
.shopping-img{
     /* width: 100%; */
    /* height: 90%;  */
    animation: fadeIn 1s ease-in-out;
}
.sad-logo{
  width: 30%;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-right: 48%;
    margin: 0 auto;
}
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
@media only screen and (max-width: 480px) {
    .SellerThankYouScreen-container {
      flex-direction: column;
    }
    .SellerThankYouScreen-LeftScreen {
        width: 100%;
        height: 100vh;
    }
    .SellerThankYouScreen-RightScreen {
     display: none;
    }
    .SellerThankYouScreen-RightScreen-title{
        font-family: 'Barlow';
        font-weight: 600;
        font-size: 3rem;
        color: #FF2171;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
     
       
    }
  }
  @media (min-width: 3840px) {
    .SellerThankYouScreen-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .SellerThankYouScreen-LeftScreen {
    
      width: 50%;
    }
  
    .SellerThankYouScreen-RightScreen {
    
      width: 50%;
    }
  
  }
  