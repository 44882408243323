.CustomerLandingPageCard2-container {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomerLandingPageCard2-Section {
  display: flex;
  padding: 10px 90px 10px 90px;
  gap: 10px;
  width: 100%;
}
.CustomerLandingPageCard2-Section1-gallery {
  display: flex;
  flex-wrap: wrap;
}
.CustomerLandingPageCard2-arrow-left,
.CustomerLandingPageCard2-arrow-right {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}

.CustomerLandingPageCard2-arrow-left {
  left: 25px;
  font-size: xx-large;
  color: hsla(0, 0%, 85%, 1);
}

.CustomerLandingPageCard2-arrow-right {
  right: 33px;
  font-size: xx-large;
  color: hsla(0, 0%, 85%, 1);
}

.CustomerLandingPageCard2-Section1 {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 10px;
}

.CustomerLandingPageCard2-Section1-title {
  font-family: Mulish;
  font-size: 32px;
  font-weight: 700;
  line-height: 40.16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: hsla(0, 0%, 0%, 1);
}

.CustomerLandingPageCard2-Section1-subtitle {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: 0.04em;
  text-align: left;
  color: hsla(0, 0%, 0%, 1);
}

.CustomerLandingPageCard2-Section1-readme {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-decoration: underline;
  color: #fe585b;
}

.CustomerLandingPageCard2-Section1-sub1 {
  display: flex;
  flex-wrap: wrap;
}

.CustomerLandingPageCard2-Section1-image {
  width: 45%;
  object-fit: cover;
  padding: 2px 12px 12px 0px;
}

.CustomerLandingPageCard2-Section2 {
  width: 100%;
}

.CustomerLandingPageCard2-Section2-image {
  width: 100%;
  max-height: 51vh;
  object-fit: cover;
  padding: 4px;
}

@media screen and (max-width: 598px) {
  .CustomerLandingPageCard2-Section2-image {
    width: 93%;

    padding: 0px;
  }
  .CustomerLandingPageCard2-Section {
    padding: 0px 28px 0px 50px;
    display: flex;
    flex-direction: column;
  }
  .CustomerLandingPageCard2-Section1 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .CustomerLandingPageCard2-Section1-title {
    font-size: 24px;
  }
  .CustomerLandingPageCard2-arrow-left {
    left: 10px;
  }
  .CustomerLandingPageCard2-arrow-right {
    right: 10px;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .CustomerLandingPageCard2-Section1-image {
    width: 46%;
    object-fit: cover;
    padding: 0px 6px 12px 0px;
  }
  .CustomerLandingPageCard2-Section1-title {
    font-size: 24px;
  }
  .CustomerLandingPageCard2-arrow-right {
    right: 10px;
  }
  .CustomerLandingPageCard2-arrow-left {
    left: 10px;
  }
  .CustomerLandingPageCard2-Section {
    display: flex;
    padding: 10px 60px 10px 60px;
    gap: 10px;
    width: 100%;
  }
  .CustomerLandingPageCard2-Section1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 4px;
  }
}
@media (min-width: 901px) and (max-width: 1200px) {
  .CustomerLandingPageCard2-arrow-right {
    right: 10px;
  }
  .CustomerLandingPageCard2-arrow-left {
    left: 10px;
  }
  .CustomerLandingPageCard2-Section {
    display: flex;

    gap: 10px;
    width: 100%;
  }
  .CustomerLandingPageCard2-Section1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 4px;
  }
}
