.footer-container{
    width:100%;
    margin-top:25rem;
    position:relative;
    width: 1280px;
height: 794px;
/* top: 1713px; */
}

.seller-footer-color{
background: rgba(255, 71, 104, 0.25);
background-color:white;
box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
}
.admin-footer-color{
    /* background-color:#F2F2F2; */
    /* background: rgba(255, 71, 104, 0.25); */
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
}
/* background: rgba(45, 11, 22, 1); */

.buyer-footer-color{
background: rgba(45, 11, 22, 1);

}
.footor-logo{
    width: 332px;
    height: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3% auto;
    }
    .p-footer{
        width: 90%;
        font-size: 14px;
        line-height: 19.6px;
        align-items: center;
        text-align: justify;
        margin: 0 auto;
        color: rgba(255, 255, 255, 1);

    }
.footer-content{
    max-width:1890px;
    min-width: 325px;
    display:flex;
    flex-direction:column;
    margin:0 auto;
    /* background: rgba(9, 10, 31, 1); */
    /* background: #232F3E; */
    color: white;
}

.footer-image {
    width: 10rem;
    height: 10rem;
    align-self: center;
    position: relative;
    /* bottom: 5rem; */
}

.footer-social-and-payment{
    display:flex;
    justify-content:space-between;
    /* width:95%; */
    margin:1rem auto;
    position: relative;
    /* bottom: 7.5rem; */
}

.footer-icons-subtext{
    font-size:16px;
    opacity:0.8;
}
.footer-icons{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
}
.footer-social-icon{
    height:2.5rem;
    width:2.5rem;
    margin:0.5rem 0.5rem;
    padding:0.5rem; 
background:rgba(255, 97, 85, 1);
    border-radius:50px;
}
.footer-payment-icon{
    margin:0.5rem 0.5rem;
    /* padding:0.5rem; */
    /* background-color:red; */
    border-radius:10px;
    height: 2rem;
    width: 3.2rem;
    border-radius: 10px;
}
.footer-payment-icon-mestro{
    margin: 0.1rem 0.1rem;
    padding: 0.5rem;
    /* background-color: red; */
    border-radius: 10px;
    height: 3.8rem;
    width: 3.8rem;
    border-radius: 10px;
    margin-bottom: -4%;

}
.footer-social-icon:first-child{
    margin-left:0;
}

.footer-payment-icon:first-child{
    margin-left:0;
}

.footer-payment-icon-gpay{
    width: 3rem;
    height: 2.6rem;
    margin: 0.5rem 0.5rem;
    margin-bottom: 1.5%;
}.footer-payment-icon-visa{
    width: 3rem;
    height: 2rem;
    border-radius: 10px;
    margin: 0.5rem 0.5rem;
}
.footer-payment-icon-paypal{
    width: 3.3rem;
    height: 2.4rem;
    border-radius: 10px;
    margin:0.5rem 0.5rem;
}
.footer-payment-icon-cash{
    width: 2.5rem;
    height: 2rem;
    border-radius: 10px;
    margin:0.5rem 0.5rem;
}
.footer-terms{
    display:flex;
    justify-content:space-around;
    align-items:center;
    font-size:18px;
    gap: 2rem;
    margin: 0 auto;
    position:relative;
    /* width: 100%; */
    /* bottom:5rem; */
}
.footer-links{
    color:white;
    font-family: 'Barlow';
font-size: 18px;
letter-spacing: 0.02em;
align-items: center;
}

.footer-payment-cash{
    width: 2rem;
    height: 1rem;
}
.footer-descriptions{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.footer-part1-content{
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 90%;
}
.footer-part2-content{
    display: flex;
    justify-content: space-evenly;
    color: white;
    width: 100%;
    margin: 0 auto;

}
.footer-bottom-content{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0rem auto;
    /* background: rgba(0, 0, 0, 0.8); */
color: white;
width: 100%;
}
.footer-links-part2{
    color: black;
}
.footer-links-part2 {
    display: flex;
    width: 85%;
    margin: 0 auto;
  }
  
  .footer-column {
    list-style: none;
    padding: 5px;
    margin: 20px;
}
  
  .footer-column li {
    margin-bottom: 5px;
    font-size: 15px;
    letter-spacing: 0.02em;
  }
  .strong{font-weight: 700;
    font-size: 25px;
    text-align: justify;
    line-height: 20px;
  }
  .footer-bottom-content{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    width: 85%;
  }
  .footer-column span{
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 0.02em;
    display: flex;
  }
  @media screen and (max-width: 988px) {
    .footer-social-and-payment{
        flex-direction:column;
    }

    .footer-terms{
        flex-direction: column;
        gap:1rem;
    }
    /* .footer-part1-content {
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        width: 90%;
        flex-direction: column;
    }
    .footer-part2-content {
        display: flex;
        justify-content: space-evenly;
        color: white;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
    }
    .footer-bottom-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
        width: 85%;
        flex-direction: column;
    }
    .footer-links {
        color: white;
        font-family: 'Barlow';
        font-size: 18px;
        letter-spacing: 0.02em;
        align-items: center;
        text-align: center;
    } */

}
@media screen and (max-width:480px) {
    .footer-part1-content {
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        width: 90%;
        flex-direction: column;
    }
    .footer-part2-content {
        display: flex;
        justify-content: space-evenly;
        color: white;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
    }
    .footer-bottom-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
        width: 85%;
        flex-direction: column;
    }
    .footer-links {
        color: white;
        font-family: 'Barlow';
        font-size: 18px;
        letter-spacing: 0.02em;
        align-items: center;
        text-align: center;
    }
}