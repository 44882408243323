.CustNavBarNew-section {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  padding-left: 60px;
}
.cust-land-new1 {
  color: #000000;
}
.custom-arrow.arrow-left > .cust-land-new1,
.custom-arrow.arrow-right > .cust-land-new1 {
  font-size: 24px !important;
}

.CustNavBarNew-section1 {
  display: flex;
  gap: 80px;
  align-items: center;
}
.CustNavBarNew-section1-title {
  font-family: Rounded Mplus 1c;
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fd2583;
}

.CustNavBarNew-sectionsub-1 {
  display: flex;
  gap: 30px;
  align-items: center;
  position: relative;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.CustNavBarNew-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: none;
}

.CustNavBarNew-sectionsub-1 div:hover {
  cursor: pointer;
}

.CustNavBarNew-dropdown div {
  padding: 5px 10px;
}

.CustNavBarNew-sectionsub-1:hover .CustNavBarNew-dropdown {
  display: block;
}
.CustNavBarNew-section2 {
  display: flex;
  gap: 30px;
  align-items: center;
}
.CustNavBarNew-section-sub2 {
  display: flex;
  gap: 20px;
  padding-right: 60px;
}
.CustNavBarNew-search-container {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 30px;
  background-color: #f0f0f0;
}

.CustNavBarNew-search-input {
  padding: 6px;
  border-radius: 20px;
  font-size: 14px;
  background: #f2f2f2;
  color: white;
  border: none;
  padding-left: 34px;
  outline: #ffffff;
  font-size: 14px;
  font-weight: 500;
  color: #7d879c;
}

.CustNavBarNew-search-input::placeholder {
  color: #888;
}
.search-icons-cuslandnew {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
/* Sidebar styles for mobile view */
.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  transition: left 0.3s ease;
  z-index: 100;
}

.sidebar-open {
  left: 0;
}

.close-sidebar {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.CustNavBarNew-sidebar-content {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.CustNavBarNew-mobile-menu-icon {
  display: none;
  cursor: pointer;
}
.CustNavBarNew-section1-Mob-title {
  font-family: Rounded Mplus 1c;
  font-size: 32px;
  padding: 7px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fd2583;
}
/* Show menu icon only in mobile view */
@media screen and (max-width: 598px) {
  .custom-arrow.arrow-left > .cust-land-new1,
  .custom-arrow.arrow-right > .cust-land-new1 {
    font-size: 12px !important;
  }
  .CustNavBarNew-mobile-menu-icon {
    display: block;
  }

  .CustNavBarNew-section {
    flex-direction: row;
    padding-left: 10px;
    align-items: center;
  }

  .CustNavBarNew-section1 {
    display: none;
  }

  .CustNavBarNew-section-sub2 {
    display: flex;
    gap: 10px;
    padding-right: 0px;
  }
  .CustNavBarNew-section1-Mob-subtitle {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 9px;
  }
  .CustNavBarNew-section1-Mob-section {
    padding: 10px;
  }
  .CustNavBarNew-section2 {
    gap: 5px;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .CustNavBarNew-section {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    padding-left: 10px;
  }
  .CustNavBarNew-section1 {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  .CustNavBarNew-section-sub2 {
    padding-right: 10px;
  }
  .CustNavBarNew-section1-title {
    font-size: 20px;
  }
  .CustNavBarNew-sectionsub-1 {
    gap: 10px;
    font-size: 12px;
  }
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    font-size: 12px !important;
    text-decoration: none !important;
  }
  .CustNavBarNew-section2 {
    gap: 10px;
  }
  .custom-arrow.arrow-left > .cust-land-new1,
  .custom-arrow.arrow-right > .cust-land-new1 {
    font-size: 24px !important;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  .CustNavBarNew-section {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    padding-left: 10px;
  }
  .CustNavBarNew-section1 {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  .CustNavBarNew-section-sub2 {
    padding-right: 10px;
  }
  .CustNavBarNew-section1-title {
    font-size: 24px;
  }
  .CustNavBarNew-sectionsub-1 {
    gap: 10px;
    font-size: 16px;
  }
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    font-size: 16px !important;
    text-decoration: none !important;
  }
  .CustNavBarNew-section2 {
    gap: 10px;
  }
}

/*Notification*/
.CustomerLandingPage-Notification {
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
}
.CustomerLandingPage-Notification-text {
  margin-top: 4px;
  color: hsla(0, 0%, 100%, 1);
}
/*Slideer 1*/
.CustomerLandingPageNewSlide {
  max-width: 100%;

  overflow: hidden;
  position: relative;
  background: hsla(0, 0%, 98%, 1);
}

.picsstart {
  width: 100%;
}

.CustomerLandingPageNewSlide .slick-prev:before,
.CustomerLandingPageNewSlide .slick-next:before {
  display: none;
}

.custom-arrow {
  display: flex;
  background: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  top: 50%;
  border: 2px solid black;
  transform: translateY(-50%);
  cursor: pointer;
}

.custom-arrow.arrow-left {
  left: 50px;
}
.custom-arrow.arrow-right {
  right: 50px;
}
.CustomerLandingPageNewSlide-textSection {
  background: hsla(0, 0%, 98%, 1);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
}
.CustomerLandingPageNewSlide-textSection-title {
  font-family: Mulish;
  font-size: 48px;
  font-weight: 900;
}
.CustomerLandingPageNewSlide-textSection-subtitle {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
}
.CustomerLandingPageNewSlide-buttonsection {
  padding: 10px;
}
.CustomerLandingPageNewSlide-textSection-button {
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  border-radius: 16px !important;
}
.iconstylingscln {
  color: black;
  width: 36px;
  height: 36px;
}

/*GIFT CARD*/

.CustomerLandingPageNewGiftCard-section {
  padding: 10px 0px 10px 0px;
  display: flex;
  align-items: center;
  text-align: left;
  background: #dfbca9;
}
.CustomerLandingPageNewGiftCard-section-img1 {
  width: 100%;
  object-fit: cover;
}
.CustomerLandingPageNewGiftCard-section-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
}
.CustomerLandingPageNewGiftCard-section-title {
  font-family: Mulish;
  font-size: 48px;
  font-weight: 700;

  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #800011;
}
.CustomerLandingPageNewGiftCard-section-subtitle {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;

  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #800011;
}
.CustomerLandingPageNewGiftCard-section-button {
  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  width: 20%;
  border-radius: 10px;
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;

  letter-spacing: 0.04em;
  padding: 4px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  cursor: pointer;
}

/*FOOTER*/
.customerfootermain-bg {
  background: linear-gradient(
    180.14deg,
    #ffffff 0.13%,
    #ffd6d7 106.9%,
    #feb9bc 161.31%,
    #f16ea4 282.94%,
    rgba(252, 131, 182, 0.77) 306.11%
  );
}
.CustomerFooterNew-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;

  padding-bottom: 30px;
}

.CustomerFooterNew-main-logo {
  width: 30%;
}
.CustomerFooterNew-main-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;

  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-bottom: 10px;
}
.CustomerFooterNew-main-subtitle {
  width: 90%;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;

  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.CustomerFooterNew-2ndmain {
  display: flex;
  justify-content: space-between;
  padding: 50px 140px 20px 140px;
}

.CustomerFooterNew-2ndmain-sub1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.CustomerFooterNew-2ndmain-sub111 {
  display: flex;
  gap: 40px;
}

.CustomerFooterNew-2ndmain-sub1 div.empty {
  min-height: 12px;
  min-width: 24px;
}
.CustomerFooterNew-2ndmain-sub123 {
  display: flex;
  flex-direction: column;
}
.CustomerFooterNew-2ndmain-sub1 hr {
  margin: 0px;
  width: 300px;

  color: #000000;
}
.CustomerFooterNew-2ndmain-sub1-title {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 20px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}
.CustomerFooterNew-2ndmain-sub1-subtitle {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}
.gaps-bootom {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bottomenvelop {
  padding-top: 24px;
}

.bottomcall {
  padding-top: 2px;
}

.bottomwhatsapp {
  padding-top: 6px;
}

.CustomerFooterNew-3ndmain {
  display: flex;
  justify-content: space-between;
  padding: 50px 140px 20px 140px;
}
.CustomerFooterNew-3ndmain--col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.CustomerFooterNew-3ndmain--col-flex {
  display: flex;
  justify-content: flex-end;
}

.butn-to-suscribe {
  border-radius: 20px;
  display: flex;
  gap: 3px;
  padding: 5px 20px 5px 20px;
  align-items: center;
  border: 1px solid hsla(0, 0%, 0%, 1);
}

.butn-to-suscribe-input {
  padding: 4px;
  font-size: 16px;
  border: none;
  background: transparent;
}
.butn-to-suscribe-btn {
  padding: 8px 20px 8px 20px;
  border-radius: 20px;
  background: transparent;
  border: 1px solid hsla(0, 0%, 100%, 1);
  color: hsla(0, 0%, 100%, 1);
}
/*WISHLIST CSS DROPDOWN*/
.custnavbarnew-heart-dropdown {
  position: absolute;
  top: 75px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 600px;
  z-index: 1000;
  border-radius: 5px;
  padding: 30px;
}

.custnavbarnew-dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.custnavbarnew-dropdown-item:hover {
  background-color: #f5f5f5;
}
.custnavbarnew-heart-dropdown-section {
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custnavbarnew-heart-dropdown-wishlist-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 480px) {
  /*TOP SLIDER*/
  .picsstart {
    width: 100%;
    height: 20vh;
    object-fit: cover;
  }
  .custom-arrow {
    width: 20px;
    height: 20px;
  }
  .custom-arrow.arrow-left {
    left: 50px;
  }
  .custom-arrow.arrow-right {
    right: 50px;
  }
  .iconstylingscln {
    width: 16px;
    height: 16px;
  }
  /*GIFTCARD*/
  .CustomerLandingPageNewGiftCard-section-title {
    font-size: 24px;
  }
  .CustomerLandingPageNewGiftCard-section-text {
    gap: 0px;
  }

  .CustomerLandingPageNewGiftCard-section-subtitle {
    font-size: 10px;
  }
  .CustomerLandingPageNewGiftCard-section-button {
    width: 40%;
    font-size: 7px;
  }
  .CustomerLandingPageNewGiftCard-section {
    padding: 10px;
  }

  /*Footer*/
  .CustomerFooterNew-main-logo {
    width: 50%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  /*TOP SLIDER*/
  .picsstart {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
  .custom-arrow {
    width: 40px;
    height: 40px;
  }
  .custom-arrow.arrow-left {
    left: 50px;
  }
  .custom-arrow.arrow-right {
    right: 50px;
  }

  /*GIFTCARD*/
  .CustomerLandingPageNewGiftCard-section-title {
    font-size: 34px;
  }
  .CustomerLandingPageNewGiftCard-section-button {
    width: 35%;
  }
}
@media (min-width: 769px) and (max-width: 1204px) {
  /*TOP SLIDER*/
  .picsstart {
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }
  .custom-arrow {
    width: 40px;
    height: 40px;
  }
  .custom-arrow.arrow-left {
    left: 50px;
  }
  .custom-arrow.arrow-right {
    right: 50px;
  }
  .iconstylingscln {
    width: 16px;
    height: 16px;
  }
  /*GIFTCARD*/
  .CustomerLandingPageNewGiftCard-section-button {
    width: 25%;
  }
  .CustomerLandingPageNewGiftCard-section-title {
    font-size: 42px;
  }
}
/*FOOTER MEDIA QUERY*/

@media screen and (max-width: 598px) {
  /*FOOTER*/
  .CustomerFooterNew-2ndmain {
    flex-direction: column;
    padding: 1px 7px 1px 17px;
  }
  .CustomerFooterNew-3ndmain {
    flex-direction: column;
    padding: 1px 7px 1px 17px;
  }
  .CustomerFooterNew-2ndmain-sub1-title {
    padding-top: 20px;
    font-size: 20px;
  }
  .CustomerFooterNew-2ndmain-sub111 {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    /* gap: 40px; */
  }
  .bottomcall {
    padding-top: 22px;
  }
  .bottomwhatsapp {
    padding-top: 22px;
  }
  .bottomenvelop {
    padding-top: 52px;
  }
  .CustomerFooterNew-3ndmain--col {
    gap: 20px;
  }
  .CustomerFooterNew-3ndmain--col-flex {
    padding-top: 10px;
  }
  .butn-to-suscribe-btn {
    padding: 6px;
  }
  .custnavbarnew-heart-dropdown {
    width: 351px;
    padding: 8px;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .CustomerFooterNew-3ndmain {
    padding: 20px;
  }
  .CustomerFooterNew-2ndmain {
    padding: 20px;
  }
  .CustomerFooterNew-2ndmain-sub111 {
    gap: 0px;
  }
  .CustomerFooterNew-main-subtitle {
    width: 90%;
  }
  .CustomerFooterNew-2ndmain-sub1 hr {
    width: 200px;
  }
  .bottomcall {
    padding-left: 12px;
  }
  .bottomwhatsapp {
    padding-left: 12px;
  }
  .bottomenvelop {
    padding-left: 12px;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  .CustomerFooterNew-3ndmain {
    padding: 20px;
  }
  .CustomerFooterNew-2ndmain {
    padding: 20px;
  }
  .CustomerFooterNew-2ndmain-sub111 {
    gap: 0px;
  }
  .CustomerFooterNew-main-subtitle {
    width: 90%;
  }
  .CustomerFooterNew-2ndmain-sub1 hr {
    width: 200px;
  }
  .bottomcall {
    padding-left: 12px;
  }
  .bottomwhatsapp {
    padding-left: 12px;
  }
  .bottomenvelop {
    padding-left: 12px;
  }
}
