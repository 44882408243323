.start-shopping-btn{
    width: 203px;
    height: 45px;
    padding: 12px 44px 12px 44px;
    border-radius: 80px;
    background: linear-gradient(90deg, #FD2A80 -0.21%, #FE575C 102.25%);
    color: white;
    font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: center;
    border: none;
    

   
    
}
.empty-page-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    flex-direction: column;
    gap: 1rem;
    /* width: 840px; */
    /* height: 100%; */
    margin: 2% auto;


}
.empty-page-content{
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0px;
text-align: center;

}