.Seller-sign-in-btn{
    display: flex;
    width: 375px;
    padding: 10px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    color: white;
    color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height:  14px;
letter-spacing: -0.14px;
text-transform: uppercase;
border: none;
margin-top: 16px;
margin-bottom: 16px;
background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);
}
.Seller-sign-in-plain-btn{
    display: flex;
    width: 375px;
    padding: 10px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    color: #FE426E;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height:  14px;
letter-spacing: -0.14px;
text-transform: uppercase;
border: none;
margin-top: 16px;
margin-bottom: 16px;
background: white;
border: 1px solid #FE2E7E;
}
