.single-product {
  font-family: "Barlow";
  display: flex;
  justify-content: space-around;
  margin: 5rem auto;
}
.single-product-layout {
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  margin-bottom: 3rem;
  font-family: "Barlow";
  margin-left:1rem;
  /* width: 1400px;  */
  max-width: 1400px;
/* min-width: none; */

}

.single-product-description-layout {
  font-family: "Barlow";

  width: 50%;
  min-height: 30rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem;
  /* margin:2rem; */
  /* z-index: 10; */
  background-color: #ffffff;
  /* border-radius: 50px; */
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 5rem;
  transform:scale(0.9, 0.9);
  transform-origin: 0 0;

}
.single-prod-name {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.colors-select {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.colors-select-red {
  height: 0.5rem;
  width: 0.5rem;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 4px;
  background-color: red;
}
.colors-select-green {
  height: 0.5rem;
  width: 0.5rem;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 4px;
  background-color: green;
}
.colors-select-blue {
  height: 0.5rem;
  width: 0.5rem;
  padding: 0.3rem;
  margin: 0.5rem;

  border-radius: 4px;
  background-color: blue;
}
.colors-select-yellow {
  height: 0.5rem;
  width: 0.5rem;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 4px;
  background-color: yellow;
}
.single-sizes-icons{
  min-width: 48px;
  min-height: 48px;
  flex-shrink: 0;
  background-color: white;
  border-radius: 100%;
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.increment-button:hover,
.decrement-button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.single-product-taxes-text {
  margin: 1.2rem 0;
  font-size: 1.6rem;
}
.single-product-description-points {
  width: 85%;
  font-size: 17px;
  letter-spacing: 0.02em;
  text-align: justify;
  align-items: center;

}



.single-product-item-header {
  font-size: 18px;
  margin-bottom: 1rem;
  display: flex;
  color: #000;
font-style: normal;
font-weight: 500;
line-height: 18px;
letter-spacing: -0.18px;
}
.single-product-size-modal {
  position: absolute;
  top: 50%;
  left: 10%;
  /* transform: translate(-50%, -50%); */
  width: 30rem;
  background-color: white;
  border: 1px solid #000;
  padding: 2rem;
}
.single-product-item-header > * {
  margin-right: 5rem;
}

.image-gallery-thumbnail {
  min-width: 85px !important;}
.image-gallery-thumbnail-image {
  max-height: 85px;
  min-width: 85px;
}
.main-image {
  flex: 2; 
}
.selected-number {
  font-size: 2.2rem;
}
.single-product-rating {
  font-size: 1.3rem;
  display: flex;
  padding: 0.5rem;
  background-color: rgba(248, 152, 128, 1);
  border-radius: 0.2rem;
  width: 5rem;
  margin: 8 rem;
}
.single-prices {
  display: flex;
  font-size: 15px;
  margin-top: -1rem;
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem;
}
.single-price {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.2px;

}
.single-actual-price {
  /* margin-right: 2rem; */
  text-decoration: line-through;
  color:rgba(112, 112, 112, 1);
}
.single-discount {
  color: white;
  border-radius: 8px;
  background: #FD2A80;
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.single-quantity {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
.single-product-stars {
  font-size: 25px;
  display: flex;
  margin: 1.5rem 0;
  color: rgba(248, 152, 128, 1);
}
.single-sizes{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -1rem;
}
.single-product-review-count {
  margin-bottom: 1rem;
  color: black;
}
.single-review-text {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: black;
}
.single-review-textarea{
  height:99%;
  width:99%;
}

.single-product-star-number {
  margin-left: 1rem;
  color: black;
  font-size:1.6rem; 
}
.single-product-review-count {
  margin-left: 1rem;
  font-size:1.6rem; 

}
.single-item-size-chart{
  text-decoration: underline;
}
.single-item-size-chart:hover{
  cursor:pointer;
}
.single-item-five-star {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 25px;
  margin-bottom: 1rem;
}
.single-item-five-star > * {
  margin-right: 2rem;
}
.single-page-submit-review {
  display: flex;
  flex-direction: column;
  /* width:1400px; */
  /* transform:scale(0.8, 0.8); */
  /* transform-origin: 0 0; */
  margin:2rem auto;
}
.single-review-field {
  font-family: "Barlow";

  width: 60%;
  height: 2rem;
  /* border:1px solid black; */
  border-radius: 40px;
  /* background: rgba(248, 152, 128, 1); */
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  /* border-radius: 10px 0px 0px 10px; */
}
.single-review-field-filled {
  width: 12.5%;
  height: 2rem;
  border-radius: 40px;
  background: rgba(248, 152, 128, 1);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px 0px 0px 10px;
}
.single-review-desc {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* width: 100%; */
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.single-review-desc-img {
  width: 50%;
  /* margin-left:10rem; */
}
.single-review-desc-img > img {
  height: 20rem;
  margin: 0 5rem;
  padding: 1rem;
  /* border: 0.5px solid rgba(0, 0, 0, 0.2); */
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.single-review-desc-desc {
  font-family: "Barlow";

  min-width: 50%;
}

.single-review-desc-desc > .single-product-description-points {
  width: 80%;
}

/* .single-review-field{
  width:80%;
  margin:0auto;
  display:flex;
  flex-direction: column;
  
} */
.single-rate-and-review {
  display: flex;
  /* margin-top:3rem; */
  /* width: 80%; */
  margin: 3rem auto;
  justify-content: space-between;
}

.single-review-review {
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  height: 20rem;
  width: 25rem;
  margin-top: 2.5rem;
  
}
.single-review-textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.rating-box {
  font-size: 2rem;
  min-width: 15rem;
  height: 20rem;
  width: 25rem;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  /* padding: 4rem; */
  text-align:center;
 
}
.star-rating-text {
  margin: 2rem auto;
}
.rating-box > .css-iljtu2-MuiRating-root {
  color: #45cadf;
  font-size: 3rem;
}
.single-upload-files {
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  height: 20rem;
  width: 25rem;
  margin-top: 2.5rem;
  /* margin-left: 55%; */
  /* width:80%; */
  /* align-self: right; */
  /* display:flex; */
  
}
.submit-upload-files{
  /* background: #FF7891;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
 font-size: 18px;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none; */
}

.review-submit {
  margin-left: 80%;
  display:flex;
}
.related-styling {
  /* align-self:center; */
  font-size: 3rem;
  font-weight: 600;
  margin: 3rem auto;
  position: relative;
  width: 30rem;
}
.related-styling-text {
  margin-bottom: 1rem;
  margin-left: 2rem;
}
.related-styling-underline {
  position: absolute;
  background: rgba(248, 152, 128, 1);
  height: 0.4rem;
  width: 100%;
  border-radius: 80%;
  margin-bottom: 1rem;
}
.single-sizes{
  display:flex;
}
.selected-number{
  padding: 0rem;
  /* background: #FFFFFF; */
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  font-size: 19px;
  background: linear-gradient(180deg, #FD2A80 0%, #FE575C 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.quantity{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 25px;
  width: 130px;
  padding: 9px 24px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #FD2A80;
  width: 100px;
}
@media only screen and (max-width: 1400px) {
.single-product-layout {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3rem;
  font-family: "Barlow";
  margin-left: 2rem;
  /* width: 1200px; */
  /* max-width: 1400px; */
  /* min-width: 600px; */
}
.single-product-image-layout {
  width: 60%;
  min-height: 60rem;
  height: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  /* padding: 1rem; */
  /* margin: 2rem; */
  /* z-index: 10; */
  background-color: #ffffff;
  /* border-radius: 50px; */
}

}
@media only screen and (max-width: 480px) {
.single-product-layout {
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
  margin-left: 0rem;
  flex-direction: column;
  font-family: "Barlow";
}
.single-product-image-layout {
  width: 100%;
  min-height: 25rem;
  height: 100%;
  z-index: 10;
  /* margin-left: -2%; */
  background-color: #ffffff;
  border-radius: 50px;
}
.single-product-description-layout {
  font-family: "Barlow";
  width: 74%;
  min-height: 30rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  margin: 0 auto;
  z-index: 10;
  min-width: 430px;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 1rem;
  transform: scale(0.9, 0.9);
  transform-origin: 0 0;
}
.single-sizes {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.size-icon {
  font-family: "Barlow";
  border: 1px solid black;
  font-size: 1rem;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 100%;
  width: 2rem;
  min-width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.single-price {
  /* margin-right: 1rem; */
  font-size: 16px;
  /* width: 61px; */
}
.single-actual-price {
  margin-left: 0.2rem;
  text-decoration: line-through;
  font-size: 14px;
  color: rgba(112, 112, 112, 1);
}
.single-discount {
  color: #FD2A80;
  font-size: 15px;
  background: none;
  padding: 0px;
  width: 75px;
  font-weight: bolder;
}
.single-prices {
  display: flex;
  font-size: 12px;
  margin-bottom: 0rem;
  justify-content: space-around;
  text-align: center;
  gap: 5px;
  width: 100%;
}
.image-gallery-slides {
  line-height: 0;
  overflow: scroll;
  position: relative;
  top: 0rem;
  bottom: 0;
   white-space: nowrap; 
  text-align: center;
  height: 240px;
}
.single-prod-name {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}
.single-product-star-number {
  margin-left: 1rem;
  color: black;
  font-size: 20px;
}
.single-product-review-count {
  margin-left: 1rem;
  font-size: 20px;
}
.single-product-stars {
  font-size: 25px;
  display: flex;
  margin: 1.5rem 0;
  color: rgba(248, 152, 128, 1);
}
.single-product-item-header {
  margin-top: 1.4rem;
  font-size: 20px;
}
.single-product-taxes-text {
  margin: 1.2rem 0;
  font-size: 25px;
}
.single-item-five-star {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  margin-bottom: 1rem;
}
.quantity {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 25px;
  width: 130px;
  padding: 6px 17px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #FD2A80;
  width: 300px;
  margin-left: -2%;
}
.Product-details-container-right{
background-color: none;
}
.reviews-single-product {
  display: inline-flex;
  padding: 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #FFF;
  color: rgba(0, 0, 0, 1);
  margin-bottom: -8px;
  margin-top: -15px;
}
.media-image {
  width: 72px;
  flex-shrink: 0;
}
}
.single-product-stars
.css-15jcz73-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgba(255, 180, 161, 1);
}

.image-gallery-content {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  width: 592px;
  height: 592px;
align-items: flex-start;
}
.image-gallery-slides
.image-gallery-slide
 .image-gallery-image {
  width: 100%;
  object-fit: cover;
  height: 400px !important;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 35px !important;
  width: 35px !important;
  background: #FFFFFF;
  color: black;
  border-radius: 100%;
  border: none;
  opacity: 81%;
  flex-shrink: 0;
}