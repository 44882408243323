.Customer-reviews-container{
    /* min-width: 632px; */
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 0.5rem;
}
.User-name{
display: flex;
justify-content: flex-start;
gap: 1rem;
align-items: flex-start;

}
.Verified-user{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}
.product-detail-review-image{
    width: 186px;
/* height: 203px; */
flex-shrink: 0;
border-radius: 8px;
display: flex;

background:  lightgray 50% / cover no-repeat;
}
.review-description{
    max-width: 491px;
    color: rgba(0, 0, 0, 0.70);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.review-images{
    width: 186px;
    min-height: 100px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}
.account-icon{
width: 24px;
height: 24px;
}
.star-filled {
    fill: #029F4A; 
  }
  .review-start-count{
    display: inline-flex;
    align-items: center;
    gap: 4px;
    width: 188px;
  }
.Verified-user
.css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    fill: currentColor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.3rem;
    color: steelblue;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.review-start-count
.css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    fill: currentColor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.3rem;
    color: #029F4A;
}
.account-icon
.css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    fill: currentColor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 2rem;
    color: gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
