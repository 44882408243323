.card-container {
  font-family: "Barlow";
  display: flex;
  flex-direction: column;
  width: 680px;
  min-width: 450px;
  /* max-width: 1200px; */
  /* min-width: 635px; */

  /* min-height:200px; */
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  /* border: 1px solid #00000033; */
  /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); */
  /* margin: 0 7.5%; */
  /* margin: 38px 12.5%; */
  margin-bottom: 1.5rem;
  position: relative;
  border-style: solid;
  border-color: rgba(255, 244, 235, 1);
  border: 1px solid rgba(255, 244, 235, 1);
  border-radius: 8px 8px 8px 8px;


}

/* .card-container:hover {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.20);
  }
   */
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 1.5rem;
  font-size: 0.8rem;
  margin: 0 1rem;
}

.card-order-no {
  color: #707070;
}

.order-card-status {
  color: rgba(0, 0, 0, 0.8);
}

.card-links {
  color: rgba(0, 0, 0, 0.8);
  display: flex;
}

.card-links a:visited {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
}

.card-links a:hover {
  color: blue;
}

.card-links-divider {
  padding: 0 5px;
}

.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left:1.5rem; */
}

.card-product-image {
  width: 15%;
  height: 60%;
  /* min-height:6rem; */
  margin-left: 1rem;
  margin-top: 1rem;
  align-self: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.card-product-image-big {
  width: 25%;
  height: 60%;
  /* min-height:6rem; */
  margin-left: 1rem;
  /* margin-top: 1rem; */
  align-self: center;
  margin-right: 1rem;
  margin-bottom: 3rem;
}

Ī .big-image {
  margin-left: 1rem;
  margin-top: 1rem;
  /* align-self:center; */
  width: 25% !important;
}

.card-product-details {
  max-width: 60%;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-left: 1rem;
  align-items: flex-start;
  min-width: 40%;
}

/* .card-product-details>* {
  align-self:
  } */

.card-product-total {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.card-product-dates {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  width: 747px;


}

.track-package-link {
  /* width: 103px; */
  font-size: 14px;
  font-weight: 400;
  color: #FD2A80;
  text-decoration: underline;
  cursor: pointer;

}

.order-card-content-field {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}

.card-container-content-field {
  margin-bottom: 16px;
  margin-left: 32px;
  margin-top: 16px;
}

.order-container-image {
  width: 85px;
  height: 82px;
  flex-shrink: 0;
  border-radius: 8px;
}

.card-container-head-fields-title {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  text-transform: uppercase;
}

.tab-panel {
  padding: 0;
}

a.review-link {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(254, 60, 114, 1) !important;
  color: inherit;
  text-decoration: none;
}

.card-order-placed-date {
  margin-right: 5rem;
}

/* .card-action-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2rem;
  } */

.card-button {
  border-radius: 10px;
  border: none;
  color: white;
  font-weight: 600;
  margin: 0rem 1rem;
  width: 8rem;
  padding: 0.8rem 1rem;
  text-align: center;
  background: linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%);

}

.card-button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.card-product-name {
  font-size: 20px;
  display: flex;
  margin-bottom: 1rem;
  text-align: left;
  color: black;
  font-weight: bold;


}

.card-product-name img {
  margin-left: 1rem;
  /* margin-bottom: 1rem; */
}

.card-product-rating {
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  background-color: rgba(248, 152, 128, 1);
  border-radius: 0.2rem;
  width: 2.5rem;
  margin-bottom: 1rem;
}

.card-prices {
  display: flex;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.card-price {
  margin-right: 2rem;
}

.card-actual-price {
  margin-right: 2rem;
  text-decoration: line-through;
}

.card-discount {
  color: rgba(248, 152, 128, 1);
}

.card-quantity {
  font-size: 15px;
  color: #000000A8;
  margin-bottom: 1rem;
}

.wishlist-actions {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

/* //////////////////////////////////////////////// */
.card-details-and-delete {
  display: flex;
  /* flex-direction: column; */
  margin-top: 0.5rem;
  /* float:right;
       */
  position: absolute;
  right: 0;
}

.card-details {
  font-size: 1rem;
  margin-right: 2rem;
  text-decoration: underline;
}

.card-details:hover {
  cursor: pointer;
  /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); */
  color: #555;
}

.card-delete {
  font-size: 1.2rem;
  margin-right: 2rem;
  text-decoration: underline;
}

.card-delete:hover {
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}

.card-product-stars {
  display: flex;
  margin: 1.5rem 0;
}

.card-product-review-count {
  margin-bottom: 1rem;
}

.card-review-text {
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 400;
}

.card-product-star-number {
  margin-left: 1rem;
}

.card-product-review-count {
  margin-left: 1rem;
}

.card-product-name-history {
  font-size: 1rem;
  display: flex;
  /* margin-bottom: 1rem; */
  margin-left: 2rem;
}

.card-cart-wishlist {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  font-size: 1rem;
}

.card-product-color {
  font-size: 15px;
  margin-bottom: 1rem;
  color: #000000A8;

}

.card-product-size {
  font-size: 15px;
  color: #000000A8;
  margin-bottom: 1rem;
}

.card-cart-text {
  margin-bottom: 0.3rem;
  color: #000000A8;
  font-weight: bold;
  margin-left: 0.5rem;
  font-size: 20px;
}

@media screen and (max-width: 800px) {
  .card-container {
    max-width: 880px;
    flex-direction: column;
    align-items: center;
    /* padding: 1rem; */
    /* width: 75%; */
    min-width: 330px;
    text-align: left;
    /* margin-left: -3%; */
    margin: 0rem auto;
  }

  .card-product-image {
    margin: 0.5rem;
    padding: 0.5rem;
    align-self: center;
    width: 70%;
  }

  .card-content>* {
    flex-direction: column;
    margin-bottom: 1rem;
    font-size: 60%;
  }

  .card-content {
    flex-direction: column;
  }

  .card-product-details {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-right: 2rem; */
    /* margin-left: 1rem; */
    align-items: flex-start;
    min-width: 100%;
  }

  .card-header {
    flex-direction: column;
  }

  .card-product-dates {
    flex-direction: column;
    font-size: 1rem;
    margin-bottom: 1rem;
    width: 300px;
    display: flex;
    justify-content: flex-start;
  }

  .card-order-placed-date {
    margin-right: 0rem;
  }

  .card-links {
    flex-direction: column;
  }

  
  .css-heg063-MuiTabs-flexContainer {
    display: flex;
    width: 400px;
    /* flex-direction: column; */
    overflow: scroll;
    width: 100%;
}

  .card-product-stars {
    display: flex;
    margin: 1.5rem 0;
    flex-direction: column;
  }

  .card-content {
    width: 100%;
    margin: 0;
  }

  .card-container:hover {
    box-shadow: none;
  }

  .card-cart-wishlist {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    font-size: 18px;
    color: #707070;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -3.5rem;
  }

  .card-cart-text {
    margin-bottom: 0.3rem;
    margin-left: 0.2rem;
  }

  .card-content {
    padding: 1rem;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }

  .card-container {
    font-family: "Barlow";
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    position: relative;
    border-style: solid;
    border-color: rgba(255, 244, 235, 1);
    border: 1px solid rgba(255, 244, 235, 1);
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    margin: 0;
    padding: 5px;
    margin-bottom: 10px;
  }

  .card-container-header {
    width: 100%;
    height: 103px;
    border-radius: 8px 8px 0px 0px;
    background: rgba(255, 244, 235, 1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .card-container-content-field {
    margin-bottom: 16px;
    margin-left: 0;
    margin-top: 16px;
    width: 95%;
  }
}

.checkout-button .css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.3rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-container-header {
  width: 100%;
  height: 103px;
  border-radius: 8px 8px 0px 0px;
  background: rgba(255, 244, 235, 1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 0 auto;

}

.card-container-header-fields {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  align-items: flex-start;
}

.close-modal-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bc-order-card-items-card {
  margin: 24px auto;
  ;
}

.review-modal .css-9xjdt6-JoyModal-backdrop {
  backdrop-filter: blur(8px) !important;
  background-color: rgba(217, 217, 217, 0.1) !important;
}

.card-container-head-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.card-container-head-fields-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.shipping-today-active {
  color: #029F4A;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
}

.order-details-img-content-fields {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.order-back-link {
  color: #FD2A80;
  text-decoration: underline;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  letter-spacing: -0.14px;
  margin: 1rem auto;
  letter-spacing: -0.14px;
}

.orders-dropdown-mobile {
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px;
}

.orders-dropdown-mobile .css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 320px;
  max-height: 45px;
  position: relative;
  border-radius: 8px;
}

.cust-order-details-product-name {
  font-size: 16px;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;  
  white-space: nowrap;
  word-wrap: break-word;
}

.order-detail-title{
  font-size: 16px;
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;  
  white-space: nowrap;
  word-wrap: break-word;
}
.order-card-product-detail-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
}

@media screen and (max-width: 480px) {

  .card-container-header {
    width: 325px;
    /* height: 82px; */
    border-radius: 8px 8px 0px 0px;
    background: rgba(255, 244, 235, 1);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    gap: 8px;
    /* margin: 0 auto; */
    font-size: 11px;
    /* margin-left: 1%; */
  }

  .order-detail-info-buttons {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    width: 310px;
  }

  .card-container-head-fields-title {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.08px;
    text-transform: uppercase;
  }
  .card-container-head-fields-details{
    display: none;
  }
  .orders-dropdown-mobile {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
  }

  .order-card-product-detail-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0px;
    width: 220px;
  }

  .cust-order-details-product-name {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    white-space: nowrap;
  }

  .cust-order-details-product-name {
    max-width: 375px;

  }
}
.cancel-reason-dropdown
.css-fvipm8 {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  width: 330px;
}