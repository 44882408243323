.CustomerNewGiftCard {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 10;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
}
.CustomerNewGiftCard-next-arrow {
  right: 10px;
}

.CustomerNewGiftCard-prev-arrow {
  left: 10px;
}
.CustomerNewGiftCard-mainsection {
  background: white;
  display: flex;
  gap: 10px;
}
.CustomerNewGiftCard-mainsection1 {
  width: 50%;
  padding: 8px;
  height: 100%;
  position: relative;
}
.CustomerNewGiftCard-mainsection2 {
  padding-top: 40px;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.fav-icons-CustomerNewGiftCard {
  position: absolute;
  display: flex;
  align-items: center;
  top: 24px;
  right: 24px;
  background: hsla(0, 0%, 100%, 1);
  z-index: 10;
  border: none;
  padding: 4px;
  border-radius: 30px;
  cursor: pointer;
}
.fav-icons-CustomerNewGiftCard2 {
  position: absolute;
  display: flex;
  align-items: center;
  top: 24px;
  right: 80px;
  background: hsla(0, 0%, 100%, 1);
  z-index: 10;
  border: none;
  padding: 4px;
  border-radius: 30px;
  cursor: pointer;
  color: #fd2583;
}
.fav-icons-CustomerNewGiftCard2:hover {
  background: #fd2583;
  color: hsla(0, 0%, 100%, 1);
}
.CustomerNewGiftCard1-mainsection1-slider {
  position: relative;
  width: 100%;

  object-fit: cover;
}
.CustomerNewGiftCard1-mainsection1-slider-images {
  width: 100%;
}
.share-icon-newgc {
  font-size: 25px;
}
.CustomerNewGiftCard-t1 {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe585b;
}
.CustomerNewGiftCard-t2 {
  font-family: Mulish;
  font-size: 32px;
  font-weight: 400;
  line-height: 40.16px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewGiftCard-t3-sec {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.CustomerNewGiftCard-t3-sec-t1 {
  font-family: Mulish;
  font-size: 24px;
  font-weight: 800;
  line-height: 30.12px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewGiftCard-t3-sec-t2 {
  font-family: Mulish;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.12px;
  letter-spacing: 0.04em;
  text-align: left;
  text-decoration-line: line-through;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 4%, 72%, 1);
}
.CustomerNewGiftCard-t3-sec-t3 {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: hsla(0, 4%, 72%, 1);
}
.CustomerNewGiftCard-t4-sec {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.CustomerNewGiftCard-t4 {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 0%, 1);
}
.CustomerNewGiftCard-t4-line {
  border: 1px solid hsla(0, 0%, 85%, 1);
}
.CustomerNewGiftCard-t5-sec {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  gap: 10px;
}
.CustomerNewGiftCard-t6-sec {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.CustomerNewGiftCard-t7-sec {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CustomerNewGiftCard-t7-line {
  border: 1px solid hsla(0, 0%, 85%, 1);
}
.CustomerNewGiftCard-t7-sec-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.CustomerNewGiftCard-t7-text {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgb(95, 93, 93);
}
.calender-icon-gc {
  padding-right: 10px;
  font-size: 22px;
}
.cust-new-land-gift-btnsec {
  display: flex;
  gap: 10px;
  padding-top: 20px;
  align-items: center;
}
.cust-new-land-gift-btnsec1 {
  width: 30%;
}
.cust-new-land-gift-btnsec2 {
  width: 70%;
  display: flex;
  justify-content: center;
  border-radius: 25px;
  align-items: center;

  background: linear-gradient(90deg, #fd2583 0%, #fe585b 100%);
  cursor: pointer;
  padding: 5px;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;

  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 100%, 1);
}
.giftcard-cut-new-land-inc-dec {
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  border: 1px solid hsla(336, 98%, 58%, 1);
  border-radius: 25px;
  padding: 4px 24px 4px 24px;
}
.giftcard-cut-new-land-inc-dec-num {
  color: hsla(336, 98%, 58%, 1);
}
.CustomerNewGiftCard-t8-line {
  margin-top: 20px;
  border: 1px solid hsla(0, 0%, 85%, 1);
}
.GiftCard-pincode-section {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Giftcard-product-pincode {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 25px;
  border: 1px solid hsla(0, 0%, 80%, 1);
}
.Giftcard-pincode-entry {
  flex: 1;
  padding: 8px;
  margin: 0 8px;
  border-radius: 8px;
  outline: none;
  min-height: 24px;
  cursor: text;
}
.Giftcard-pincode-entry:empty::before {
  content: attr(data-placeholder);
  color: #aaa;
}
.Giftcard-check-pc {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fe575c;
  padding-right: 10px;
}
.Giftcard-pincode-section-text {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: hsla(0, 0%, 10%, 1);
}
.CustomerNewGiftCard-accord-sec {
  width: 80%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 598px) {
  .CustomerNewGiftCard-mainsection {
    display: flex;
    flex-direction: column;
  }
  .CustomerNewGiftCard-mainsection1 {
    width: 100%;
    padding: 0px;
  }
  .CustomerNewGiftCard-mainsection2 {
    width: 100%;
    padding: 0px;
  }
  .CustomerNewGiftCard-mainsection2-padding {
    padding: 10px;
  }
  .CustomerNewGiftCard-t5-sec {
    width: 100%;
  }
  .CustomerNewGiftCard-accord-sec {
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
}
@media (min-width: 900px) and (max-width: 1200px) {
}
