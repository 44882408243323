.seller-products-list-navbar {
  width: 100%;
  display: flex;
}
.seller-products-list-add-products {
  width: 40%;
}

.seller-products-list-search-and-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0.5rem auto; */
  gap: 2rem;
  max-width: 1200px;
  resize: none;
  min-width: 900px;
 width: 80%;
}
.seller-order-details-textarea{
    width: 70%;
    min-height: 200px;
    min-width:300px;
    margin:1rem auto;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 5px;
    resize: none;
    border: 0.1px solid rgba(112, 112, 112, 0.5);
  }
  
  .seller-order-details-textarea:focus {
      outline-color:  #FF5569;
    
  }


@media screen and (max-width:800px){
  .seller-products-list-search-and-filter {
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
  }
  .seller-products-list-search-and-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem auto;
    gap: 2rem;
    max-width: 1200px;
    min-width: 370px;
    width: 95%;
}
.seller-order-details-textarea{
  max-width:350px;
}
}

.seller-dashboard-list-navbar-search-bar {
  position: relative;
  min-width: 20rem;
  width: 40%;
  margin-right: 5%;
  /* margin-left: -9%; */
}

.seller-products-list-navbar-search {
background: #F2F2F2;
border-radius: 30px;
  border: none;
  width: 80%;
  min-height: 2rem;
  padding-left: 3.5rem;
  outline: #FFFFFF;
}

.seller-products-list-navbar-search-icon {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  left: 2em !important;
  top: 0.5rem;
  color: rgba(112, 112, 112, 0.72);
}
/* .seller-products-list-navbar-search-bar
.css-vubbuv {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  position: relative;
} */
.seller-products-list-category {
  min-width: 10rem;
}

.seller-products-list-category > * {
  min-width: 10rem;
}
.Mui-selected:hover{
  color: white;

}
.seller-products-list-table-head {
  margin: 1rem auto 1rem auto;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #000;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.18px;
}
.seller-products-list-table-header{
  margin: 1rem auto 1rem auto;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #000;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.18px;
}
.seller-products-list-table-product-name {
  width: 60%;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;

}
.truncate {
  width: 200px; /* The maximum width of the text */
  white-space: nowrap; /* Prevents text from wrapping to a new line */
  overflow: hidden; /* Hides the text that overflows the container */
  text-overflow: ellipsis; /* Adds an ellipsis to indicate that the text has been truncated */
}

.seller-products-card {
  margin: 1.5rem auto;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 5rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* border-radius: 10px; */
  color: rgba(0, 0, 0, 0.7);
}
.seller-products-card:hover {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.seller-products-list-table-product-item {
  text-align: center;
  width: 25%;
  /* max-width: 50px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
}

.list-item-wrap {
  text-align: center;
  margin: 0 auto;
  width: 80%;
  /* max-width:50px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.seller-products-name-image-field {
  display: flex;
  align-items: center;
}

.seller-products-card-product-image {
  width: 4rem;
  height: 4rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.seller-products-card-desc {
  color: rgba(112, 112, 112, 0.72);
  font-size: 1rem;
}

.seller-products-modal {
  width: 50%;
  min-height: 500px;
  background: white;
  margin: 5rem auto;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.seller-products-modal-content {
  margin: auto auto;
  /* transform:scale(1.5, 1.5) */
}
.seller-products-modal-field {
  margin: 1rem;
  margin-left: 0;
}
.seller-products-modal-field-name {

  color: rgba(0, 0, 0, 0.65);
  min-width: 10rem !important;
}

.sellerLiveOrderId {
  margin: 1rem;
  font-size: 1.6rem;
}

.seller-live-order-details-product-details-and-cost {
  display: flex;
  justify-content: space-around;
}

.seller-live-order-details-order-details-box {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
  border-radius:  8px;
  min-height: 100px;
  width: 60%;
  margin: 2rem auto;
  padding-left: 1.5rem;
}
.seller-live-order-details-order-details-box2{
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
  border-radius:  8px;
  min-height: 100px;
  width: 60%;
  margin: 2rem auto;
  padding-left: 1.5rem;
}

.seller-live-order-details-order-price-box {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
  border-radius:  8px;
  max-height: 200px;
  width: 30%;
  margin: 2rem auto;
}
.seller-live-order-details-order-delivery-box {
  background: #ffffff;
  border:1px solid rgba(0, 0, 0, 0.2);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
  border-radius:  8px;
  min-height: 200px;
  width: 30%;
  margin: 2rem auto;
  /* max-width: 350px; */
  min-width: 377px;
}
.seller-live-order-details-order-price-box{
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  min-height: 350px;
  width: 30%;
  margin: 2rem auto;
  max-width: 350px;
  min-width: 350px;
}
/* Seller live orders detail page  */

.seller-products-list-table-content {
  margin: 1.5rem auto;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.seller-live-order-details-box-head {
  margin: 1rem;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
}
.seller-live-order-details-customer-details {
  display: flex;
    justify-content: space-between;
    margin: 1.5rem 2rem;
    font-size: 16px;
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.16px;
}

.seller-live-order-details-customer-details-small {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 1.5rem;
  text-align: left;
  /* width:60%; */
  align-items: center;
  font-size: 16px;
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;;
}

.seller-live-orders-updated-status {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size:18px;
  margin: 10px auto;
  width: 90%;
  padding:8px;
  background: #ffffff;
}

/* Admin manage users */

.admin-manage-users-card {
  /* width:80%; */
  margin: 1.2rem 1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 3rem;
  background: rgba(245, 245, 245, 0.1);
  border-radius: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 0.5rem;
  position: relative;
  /* padding-top:3rem; */
  min-width: 20rem;
}

.admin-manage-users-layout {
  /* width:80%; */
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5rem;
  justify-content: flex-start;
  /* margin-left:2rem; */
  align-content: flex-start;
}

.admin-manage-users-card:hover {
  /* background: rgba(212, 240, 240, 1); */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.users-list-form-heading {
  width: 100%;
  font-size: 1.5rem;
  margin: 2rem auto;
  align-self: flex-start;
}

.admin-manage-users-card-icons {
  position: absolute;
  top: 0;
  right: 0;
  float: right;
  z-index: 100;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
  background: rgba(212, 240, 240, 0.57);
  border-radius: 5px;
}
.admin-manage-users-card-icons > * {
  margin-right: 0;
  margin-left: 0.5rem;
}

.seller-products-list-table-product-item-small {
  text-align: center;
  width: 10%;
}
.admin-manage-users-id-and-name {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.admin-manage-users-details {
  display: flex;
  flex-direction: column;
}
.admin-manage-users-field {
  margin: 0.2rem 1rem;
  /* justify-content: flex-start;
   */
  align-self: flex-start;
}

.admin-manage-users-field-value {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.1rem;
  margin-left: 1rem;
}

.manage-users-heading-and-add-user {
  width: 90%;
  margin: 1rem auto 1rem auto;
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
}

.admin-add-new-user-modal {
  width: 60%;
  min-height: 60%;
  background: white;
  margin: 2rem auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  transform: scale(0.75, 0.75);
  padding: 2rem;
}

.admin-modal-add-new-user-form {
  font-family: "Barlow";
  /* min-height: 60rem; */
  display: flex;
  width: 60%;
  /* height:100%; */
  flex-direction: column;
  /* min-width: 80rem; */
  margin: 0 auto;
}

.admin-modal-add-new-user-form-card {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  min-height: 300px;
  min-width: 700px;
  width: 60%;
  margin: 2rem auto;
  /* margin-left:0; */
}

.coupon-code-bg {
  background: rgba(255, 153, 165, 0.3);
  /* width: 80%; */
  max-width: 240px;
  padding: 3rem auto;
  margin: 0 auto;
}

.seller-discount-status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.status-indicator-pending {
  min-height: 0.8rem;
  min-width: 0.8rem;
  background: #01BABF;
  border-radius: 100px;
  margin-right: 1rem;
  margin-left: 2rem;
}
.status-indicator-active {
  min-height: 0.8rem;
  min-width: 0.8rem;
  background: #00ed42;
  border-radius: 100px;
  margin-right: 1rem;
  margin-left: 2rem;
}
.status-indicator-expired {
  min-height: 0.8rem;
  min-width: 0.8rem;
  background: #ff5e5e;
  border-radius: 100px;
  margin-left: 2rem;
  margin-right: 1rem;
}

.coupon-modal {
  padding: 2rem 1rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  /* min-height:300px; */
  min-width: 700px;
  width: 50%;
  margin: 0rem auto;
  /* margin-left:0; */
  transform: scale(0.7, 0.7);
  max-height: 700px;
  overflow: scroll;
}
.coupon-modal-discount{
padding: 3rem 1rem;
background: #ffffff;
border: 1px solid rgba(0, 0, 0, 0.2);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
/* min-height: 300px; */
min-width: 700px;
width: 50%;
margin: -7rem auto;
/* margin-left: 0; */
transform: scale(0.6, 0.6);
max-height: 800px;
overflow: scroll;}
.seller-facts-desc {
  align-self: center;
  width: 80%;
  border-radius: 40px;
  height: 200px;
  background: none 0% 0% / 101%;
  /* padding: 5%; */
  /* padding-top: 10%; */
  font-weight: 600;
  font-size: 1rem;
  /* margin: 1rem auto 1rem auto; */
  /* margin-left: 8rem; */
  color: rgba(112, 112, 112, 0.72);
  /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
  resize: none;
  /* margin-bottom: 1.5rem; */
}

.textarea:focus {
  border-color: rgba(248, 152, 128, 1) !important;
}
.seller-facts-desc-field {
  align-self: center;
  width: 79%;
  max-width: 500px;
  min-width: 360px;
  border-radius: 4px;
  height: 80%;
  /* background: none 0% 0% / 101%; */
  padding: 5%;
  /* padding-top: 10%; */
  /* font-weight: 600; */
  /* font-size: 1.2rem; */
  /* margin: 1rem auto 1rem auto; */
  margin-left: -1.7rem;
  color: rgba(112, 112, 112, 0.72);
  border: 0.5px solid rgba(112, 112, 112, 0.5);
  resize: none;
  /* margin-bottom: 2rem; */
}

.facts-date-field {
  width: 100%;
  position: relative;
}

.facts-date-icon {
  position: absolute;
  top: 1.6rem;
  right: 4.5rem;
  font-size: 1.6rem;
color: rgba(0, 0, 0, 0.5);
}
.facts-date-icon-seller {
  position: absolute;
  top: 1.5rem;
  right: 8rem;
  font-size: 1.8rem;
  color: rgba(0, 0, 0, 0.5);
}
.facts-upload-files {
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); */
  height: 15rem;
  width: 80%;
  /* margin-top: 1rem; */
  /* margin-left: 55%; */
  margin-left: 3.5rem;
  /* width: 80%; */
  display: flex;
  border-radius: 4px;
  padding: 3%;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  min-width: 400px;
}
.seller-modal-head-text {
  font-size: 3rem;
  margin: 0 auto 3rem auto;
  text-align: center;
}

.address-field-small {
  width: 50%;
  border-radius: 100px;
  /* min-height: 50px; */
  height: 2.5rem;
  background-color: white;
  background: none;
  padding-left: 1%;
  background-size: 3%;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 1rem auto;
  color: rgba(112, 112, 112, 0.72);
  border: 0.5px solid rgba(112, 112, 112, 0.5);
}

.warning-modal {
  padding: 3rem 1rem;
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  max-height: 200px;
  min-width: 400px;
  width: 70%;
  margin: 0rem auto;
  /* margin-left:0; */
  transform: scale(0.7, 0.7);
  /* max-height: 800px; */
  text-align: center;
  font-size: 2rem;
  /* overflow: scroll; */
  position: relative;
  top: 20rem !important;
}

.warning-modal .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background: rgba(255, 153, 165, 0.3) !important;
}

.warning-modal-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.dashboard-section-head {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.dashboard-section-para {
  font-size: 1.3rem;
}
.seller-activities-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 3rem;
  background: #FFFFFF;
  width: 100%;
  height: 320px;
  border-radius: 15px;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  margin-left: 2%;
    /* border: 1px solid gray; */
}
.seller-activities-card {
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  min-height: 5rem;
  min-width: 6rem;
  max-width: 20rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  display: flex;
  /* padding:0.2rem; */
  justify-content: space-between;
  border-radius: 100%;
}

.visitors-card {
  /* width:100%; */
  background: rgba(255, 253, 216, 1);
  border-radius: 52%;
  justify-content: center;
  align-items: center;
  text-align: center;

}
.new-orders-card {
  background-color: rgba(212, 240, 240, 0.3);
}
.ready-to-ship-card {
  background-color: rgba(255, 239, 243, 1);
}
.in-delivery-card {
  background-color: rgba(239, 255, 233, 1);
}

.seller-activities-card-data {
  width: 50%;
  /* align-self:flex-start; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 1.2rem;
  text-align: center;
}

.seller-activities-icon {
  width: 50%;
  align-self: center;
  justify-content: center;
  font-size: 2.5rem !important;
  margin-left: 0.5rem;
}
.seller-activities-card-data .small-text {
  font-size: 0.8rem;
  letter-spacing: 0.03em;
}
.dashboard-chart-layout {
  border-radius: 20px;
  min-height: 15rem;
  /* margin: 2rem auto; */
  /* margin-bottom: 5rem; */
  /* padding: 2rem; */
  /* margin-right: 3%; */
  width: 90%;
  margin: 0 auto;
}

.dashboard-top-selling-products {
  /* border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 0; */

    margin: 2rem auto;

}

.product-in-stock-bg {
  background: rgba(144, 222, 224, 0.3);
  width:60%;
  margin:0 auto;
  padding:0.3rem;
  }
.product-out-of-stock-bg {
  background: rgba(253, 206, 206, 1);
  width:60%;
  margin:0 auto;
  padding:0.3rem;
}

.seller-dashboard-top-products-card {
  margin: 1rem auto;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* min-height: 5rem; */
  background: #ffffff;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 20px;
  background-color: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.seller-dashboard-table-head {
  /* margin: 3rem auto 1rem auto; */
  margin: 0.5rem auto;
  text-align: center;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.barChart {
  max-width: 70% !important;
  width: 644px;
  background-color: #FFFFFF;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  padding: 2rem;
  /* margin-left: -4%; */
  border-radius: 15px;
}

.pieChart {
  height: 320px !important;
  width: 300px !important;
  align-self: center;
  border-radius: 15px;
  max-width: 60% !important; 
  background-color: #FFFFFF;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

.seller-stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
  margin-bottom: 20px;
  width: 60%;
  transform: scale(0.8);
}
.seller-step-name {
  font-size: 1.5rem;
}
.seller-stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.seller-stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.seller-stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.seller-stepper-item .seller-step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.seller-stepper-item.active {
  font-weight: bold;
}
.seller-step-counter.default {
  background: #ff5569;
}
.seller-step-counter .active {
  background: #ff5569;
}
.seller-stepper-item.completed .seller-step-counter {
  background: #ff5569;
}

.seller-stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #ff5569;

  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.seller-stepper-item:first-child::before {
  content: none;
}
.seller-stepper-item:last-child::after {
  content: none;
}

.seller-verified-icon {
  background: #ff5569;
}

/* ::-webkit-scrollbar-thumb {
  background-color: #ff5569;
} */

.seller-my-account-non-texfield{
  font-size: 18px;
  width:80%;
  margin:1rem auto;
  /* margin-left:5rem; */
  color:rgba(112, 112, 112, 0.72);
  display:flex;
  align-items:center;
}
.seller-my-account-non-texfield>*{
margin-right:2rem;
}

.seller-my-account-field-name{
  width: 75%;
  font-size: 20px;
}
.sellerPersonalData-my-account-field-name {
  width: 55%;
  font-size: 1.2rem;
}
.seller-reset-password-field{
  position:relative;
}

.seller-accept-top-left{
  position: absolute;
  width: 533.3px;
  height: 265.21px;
  left: -399px;
  top: 9.84px;
  
  background: linear-gradient(116.79deg, rgba(79, 231, 235, 0.35) 69.06%, rgba(79, 231, 235, 0) 102.36%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transform: matrix(0.98, -0.16, 0.19, 0.99, 0, 0);
}



.seller-otp-number-change{
  font-size:1.2rem;
  line-height: 22px;
text-align: center;
text-decoration-line: underline;
width:40%;
color: #346C74;
}

.seller-personal-data-tracker{
  
    display: flex;
    flex-direction: column;
    border-right:  1px solid rgba(0, 0, 0, 0.2);  
    padding-right: 2rem;
  
}

.seller-account-type:focus{
  border-color:#00ed42 !important;
}

@media screen  and (max-width:1200px){
  .seller-personal-data-tracker{
display:none;
  }
  .seller-modal-head-text {
    font-size: 32px;
    margin: 0 auto 1rem auto;
    text-align: center;
}
  .seller-products-list-navbar-search-icon {
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    /* left: 0.5rem !important;
    top: 0.5rem !important; */
  }
  
  .seller-personal-tabs {
    width: 80%;
    margin: auto auto;
}
.seller-personal-data-page-content {
  width: 80%;
  min-width: 300px;
  display: flex;
  /* margin: 2rem auto; */
}
.seller-personal-data-form-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* flex-direction: column; */
  margin: 1rem 0.3rem;
  align-items: center;
  position: relative;
}
.seller-personal-data-form-field-dropdown{
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin: 0rem 4rem; */
  align-items: center;
  }

.seller-personal-data-field-name {
    width:90%;
    font-size:1rem;
}
.seller-personal-details-section {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight: 600;
  /* margin: 2rem auto; */
  position: relative;
  /* width: 200px; */
  margin: 1rem auto 3rem auto;
}
.seller-personal-details-section-underline {
  position: absolute;
  background: rgba(11, 113, 135, 1);
  height: 0.3rem;
  width: 80%;
  align-self: center;
  border-radius: 80%;
  top: 100%;
}
.seller-thanks-modal{
  max-width:300px;
  font-size: 1rem;
  margin:0.5rem auto;
  padding: 2rem;
}

.seller-account-type.MuiFormControl-root {
  width:80%;
  /* left:2rem; */
}
.seller-products-list-navbar-search
.seller-products-list-navbar-search-icon {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  left: 2rem !important;
  top: 0.5rem !important;
}
.seller-registration-head-underline {
  position: absolute;
  background: #FF5569;
  height: 0.4rem;
  width: 100%;
  border-radius: 80%;
}
.facts-date-icon-seller {
  position: absolute;
  top: 3.5rem;
  right: 8rem;
  font-size: 1.8rem;
  color: rgba(0, 0, 0, 0.5);
}
.seller-live-orders-approval-head {
  font-weight: 600;
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
}
}


@media screen and (max-width:480px){
.seller-personal-data-page-content {
    width: 100%;
    min-width: 350px;
    display: flex;
    /* margin: 2rem auto; */
}
.seller-modal-head-text {
  font-size: 32px;
  margin: 0 auto 1rem auto;
  text-align: center;
}
.seller-personal-tabs {
    width: 100%;
    margin: auto auto;
}
.seller-my-account-field-name{
  width: 100%;
  font-size: 1.2rem;
}
.seller-products-list-navbar-search-icon {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  left: 2rem !important;
  top: 0.5rem !important;
}
.facts-date-icon-seller {
  position: absolute;
  top: 3rem;
  right: 8rem;
  font-size: 1.8rem;
  color: rgba(0, 0, 0, 0.5);
}
.signup-calendar-admin {
  color: #70707054;
  font-size: 18px;
  border-radius: 4px;
  /* width: 75%; */
  min-width: 363px;
  border: 1px solid #70707054;
  border-radius: 4px;
  max-height: 54px;
  margin: 0 auto;
  padding: 1rem auto;
  padding: 1rem;
}
.seller-personal-data-form-field-dropdown{
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1rem;
  }
  .seller-form-field-dropdown .MuiOutlinedInput-root {
    width: 317px;
    /* background-color: white; */
    border-radius: 8px;
    color: #000;
    font-weight: 300;
    padding: 12px 14px;
    font-size: 16px;
    height: 53px;
}
.seller-live-orders-approval-head {
  font-weight: 600;
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
}
}

.seller-thanks-modal{
  padding:0.5rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  /* min-height:300px; */
  min-width:300px;
  width:100%;
  margin:0.5rem auto;
  /* margin-left:0; */
  transform:scale(0.8,0.8);
  /* overflow:scroll !important; */
}
.facts-date-icon-admin{
  position: absolute;
  top: 1.6rem;
  right: 5.5rem;
  font-size: 1.6rem;
}
@media screen and (max-width:480px) {
  .seller-products-card {
    margin: 1.5rem auto;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    min-height: 3.4rem;
    gap: 1rem;
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    /* border-radius: 20px; */
}
.coupon-code-bg{
  justify-content: space-around;
    gap: 6rem;
    margin: 0 auto;
    background: none;
}
.seller-products-list-table-head {
 display: none;
}
.seller-products-list-table-header {
  display: none;
 }
.seller-products-list-table-product-item {
  text-align: center;
  width: 95%;
  /* max-width: 50px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.seller-products-list-navbar-search-icon {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  left: 1rem !important;
  top: 0.5rem !important;
}

.seller-discount-status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.coupon-modal {
  padding: 3rem 1rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  /* min-height: 300px; */
  min-width: 530px;
  width: 50%;
  margin: 0rem auto;
  /* margin-left: 0; */
  transform: scale(0.7, 0.7);
  max-height: 650px;
  overflow: scroll;
  margin-left: -6rem;
}
.seller-form-field-dropdown .MuiOutlinedInput-root {
  width: 317px;
  /* background-color: white; */
  border-radius: 8px;
  color: #000;
  font-weight: 300;
  padding: 12px 14px;
  font-size: 16px;
  height: 53px;
}
}
.seller-discount-table {

  width: 40%;

  margin: 0 auto;

  max-width: 100%;

  border-collapse: separate;

  border-spacing: 0 1em;

  color: gray;



}



.seller-discount-table tr.first-child {

  text-align: center;

  background: white;

  color: gray;

}



.seller-discount-table tr {

  background: white;

  border: 1px solid gray;

}



.seller-discount-table th {

  background: white;

  color: gray;

}



.seller-discount-table th {

  display: none;

  margin: 0 auto;

}



.seller-discount-table td {

  display: flex;

  border-top: 1px solid gray;

  border-bottom: 1px solid gray;



}



.seller-discount-table td:first-child {

  margin-top: 1rem;

  border-left: 1px solid gray;

}



.seller-discount-table td:last-child {

  margin-top: 1rem;

  border-left: none;

  border-right: none;

}



.seller-discount-table td::before {

  content: attr(data-th)" :";

  font-weight: bold;

  display: inline-block;

  color: gray;

  gap: 2rem;

  width: 70%;

}



.seller-discount-table th,
td {
  padding: 5em 1em;
}
.seller-discount-table {
  color: gray;
  border-radius: 4rem;
}

.seller-discount-table tr {
  background: white;
}
.seller-discount-table th,
.seller-discount-table td {
  padding: 1em 1em;
}
.seller-discount-table tr:first-child,
.seller-discount-table td:first-child {
  border-top-left-radius: 0px;
}
.seller-discount-table tr:first-child,
.seller-discount-table td:last-child {
  border-top-left-radius: 0px;
}
.seller-discount-table tr:last-child,
.seller-discount-table td:first-child {
  border-bottom-left-radius: 0px;
}
.seller-discount-table tr:last-child,
.seller-discount-table td:last-child {
  border-bottom-right-radius: 0px;
  border-left: 1px solid gray;
}.seller-discount-table td:last-child {
  border-left: none;
}
.seller-discount-table td:nth-child(0) {
  border-left: none;
}
.seller-discount-table tr:first-child,
.seller-discount-table td:last-child {
  border-top-left-radius: 0;
}
.discount-date-icon-admin {
  position: absolute;
  top: 1rem;
  right: 6rem;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
}
@media screen and (max-width:601px) {
  .seller-discount-table tr:nth-child(2) {
      border-top: 1px solid gray;
      border-left: 1px solid gray;
  }
  .seller-discount-table th,
  .seller-discount-table td {
      padding: 0.5em 0.5em;
  }
  .seller-discount-table tr {
    background: white;
      margin: 0 auto;
      gap: 2rem;
  }
  .seller-discount-table td {
      display: block;
      border: none !important;
      border-left: none;
  }
  .seller-discount-table td:first-child {
      border: none !important;
  }
  .seller-discount-table td:last-child {
      border: none !important;
      border-left: none;
      border-right: none;
  } .seller-discount-table {
      width: 95%;
      margin: 0 auto;
      border-collapse: collapse;
      border-spacing: 0 1em;
  }
  .signup-calendar-admin {
    color: #70707054;
    font-size: 18px;
    border-radius: 4px;
    /* width: 75%; */
    min-width: 363px;
    border: 1px solid #70707054;
    border-radius: 4px;
    max-height: 54px;
    margin: 0 auto;
    padding: 1rem auto;
    padding: 1rem;
}
}
.seller-discount-table th {
  text-align: left;
}
@media screen and (min-width:600px) {
  .seller-discount-table {
    width: 95%;
    margin: 0 auto;
  }
  .seller-discount-table {
      width: 95%;
      margin: 0 auto;
  }
  .seller-discount-table tr:first-child,
  .seller-discount-table td:last-child {
      border-top-left-radius: 0;
      color: gray;
  }
  .seller-discount-table tr:last-child,
  .seller-discount-table td:last-child {
      border-bottom-right-radius: 0px;
      border-left: none;
      border-right: 1px solid gray;
  }
  .seller-discount-table td {
      border-top: 1px solid gray;
      border-bottom: 1px solid gray;
    display: flex;
      justify-self: center
  }
  .seller-discount-table td :last-child {
      width: 95%;
      margin: 0 auto;
      border-left: none;
  }
  .seller-discount-table tr:hover:not(:first-child) {
      cursor: pointer;
  }
  .seller-discount-table td::before {
      display: none;
  }
  .seller-discount-table th,
  .seller-discount-table td {
      display: table-cell;
      justify-content: center;
      margin: 0 auto;
  }
  .seller-discount-table th:first-child,
  .seller-discount-table td:first-child {
      display: flex;
      margin: 0 auto;
      padding: 0;
      justify-content: center;
  }
  .seller-discount-table th:last-child,
  .seller-discount-table td:last-child {
      padding: 0;
      border-left: none;
      display: flex;
      margin: 0 auto;
  }
.seller-discount-table th,
  .seller-discount-table td {
      padding: 1em !important;
  }
  .data-th {
      width: 50%;
      gap: 2rem;
  }
}
.offer-type-dropdown
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  width: 80%;
    margin: 0 auto;
    min-width: 421px;
}

.offer-type-dropdown .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
 
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(153, 151, 151, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  text-align: left;
  /* border: 0.5px solid rgba(112, 112, 112, 0.5); */
  display: inline-flex;
  align-items: flex-start;
  /* position: relative; */
  border-radius: 4px;
  /* left: 13px; */
  margin: 0 auto;
  /* width: 375x; */
  width: 92%;
  max-width: 100%;
  min-width: 300px;
}
.offer-type-dropdown{
width: 95%;
margin: 0 auto;
}
@media screen and (max-width:860px) {
.seller-products-list-navbar-search-icon {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  left: 1rem !important;
  /* top: 2rem !important; */
}}
.seller-activities-card
.css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 25px;
  height: 40px;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}
.search-icon{
  position: relative;
  left: 3rem;
  top: 0rem;
}
.seller-products-list-table-product-item-detail2 {
  /* text-align: center; */
  width: 25%;
  /* max-width: 50px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
}
.seller-products-list-table-product-item-details {
  /* text-align: center; */
  width: 25%;
  /* max-width: 50px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
}
/* Styles for screens wider than 1200px */
@media (min-width: 1200px) {
  .seller-live-order-details-product-details-and-cost {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
    justify-content: space-evenly;
    gap: 0.4rem;
    width: 988px;
    margin: 0 auto;
}

.seller-live-order-details-order-details-box {
  width: 80%;
  max-width: 100%;
  min-width: 300px;
}
.seller-live-order-details-order-details-box2 {
  width: 80%;
  max-width: 100%;
  min-width: 300px;
}
  .seller-live-order-details-order-price-box {
    width: 40%;
    min-width: 300px;
    max-width: 380px;
  }
}

/* Styles for screens between 601px and 1200px */
@media (min-width: 601px) and (max-width: 1200px) {
  .seller-live-order-details-product-details-and-cost {
    display: flex;
    flex-direction: column;
  }

  .seller-live-order-details-order-details-box,
  .seller-live-order-details-order-price-box {
    width: 70%;
    max-width: 1300px;
    min-width: 70%;
  }
  .seller-live-order-details-order-details-box {
    width: 70%;
    max-width: 1300px;
    min-width: 70%;
  }
}

/* Styles for screens up to 600px */
@media (max-width: 600px) {
  .seller-live-order-details-product-details-and-cost {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0;
  }
  .seller-live-order-details-order-delivery-box {
    min-width: 375px;
    max-width: 1000px;
}
.seller-products-list-table-product-item-details {
  /* text-align: center; */
  width: 25%;
  /* max-width: 50px; */
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
}
  .seller-live-order-details-order-details-box{
    min-width: 380px;
    margin: 1rem auto;
    padding-left: 1rem;
    display: flex;

  }
  .seller-live-order-details-order-details-box2{
    min-width: 380px;
    margin: 1rem auto;
    padding-left: 1rem;

  }
  .seller-live-order-details-order-price-box {
    min-width: 380px;
    margin: 1rem auto;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;

  }
  .seller-products-list-table-product-item-detail2{
    
      font-size: 16px;
      text-align: left;
      text-align: left;
      width: 25%;
      /* max-width: 50px; */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 18px;
      padding-bottom: 16px;
  
    
  }
 
    .seller-products-list-table-head {
      display: none;
    
  }
  .seller-products-list-table-header {
    display: flex;
    gap: 1%;
    flex-direction: column;
    width: 130px;
    justify-content: column;
    align-items: flex-start;
    font-size: 16px;
    gap: 6%;
}
.seller-products-card-product-image{
  padding-right: 0.5rem;
  padding-left: 0rem;
  display: none;
}

  .seller-products-list-table-content {
    border: 0px solid #ddd;
    padding: 1rem 0rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    width: 200px;
    align-items: baseline;
    margin-top: 0px;
    
}
.seller-prdct-item-title{
  font-size: 18px;
  padding-bottom: 20px;
}

  .seller-products-list-table-product-name {
    margin-bottom: 0rem;
    font-size: 18px;
    padding-bottom: 0px;
    width: 175px;
  }

  .seller-products-list-table-product-name img {
    width: 100%;
    height: auto;
  }
  .seller-products-name-image-field {
    display: flex;
    align-items: center;
}
  .cart-order-summary-text {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 18px;
    letter-spacing: 0.03em;
  }
  .seller-live-order-details-box-head {
    margin: 2rem;
    font-size: 20px;
    letter-spacing: 0.03em;
}
  .cart-checkout-row,
  .cart-checkout-row-dark {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 18px;
  }

  .cart-checkout-row-dark {
    background-color: #f5f5f5;
  }

  .divider {
    margin: 1rem 0;
  }
}
.Approve-reject-buttons{
  display: flex;
  align-items: center;
justify-content: center;
}
/* Styles for screens wider than 480px */
.seller-live-orders-approval-pending {
  display: flex;
  /* flex-direction: column; */
  resize: none;
  align-items: center;
  margin-top: 1rem;
  max-width: 985px;
}


@media (max-width: 480px) {
  .seller-live-orders-approval-pending {
    text-align: center;
    margin-top: 1rem;
  }
  .seller-live-orders-approval-pending {
    display: flex;
    flex-direction: column;
    align-items: center;
    resize: none;
    margin-top: 1rem;
  }
  .seller-live-orders-updated-status {
    margin-top: 1rem;
  }

  .seller-live-order-details-customer-details {
    display: flex;
    justify-content: space-between;
    margin: 2.5rem 1rem;
    font-size: 19px;
    width: 90%;
}
.discount-date-icon-admin {
  position: absolute;
  top: 2.5rem;
  right: 6rem;
  font-size: 1.9rem;
  color: rgba(0, 0, 0, 0.5);
}
.seller-live-order-details-order-price-box {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 250px;
  width: 35%;
  margin: 2rem auto;
  max-width: 350px;
  min-width: 350px;
}
}
.product-title-card{
  max-width: 1190px;
  height: 148px;
  background: #FFFFFF;
  border-radius: 10px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
@media (min-width: 600px){
.seller-navbar-sidebar
.css-i6s8oy {
  background-color: rgb(244, 244, 244) !important;
  /* box-shadow: 2px 2px 2px 2px rgba(0,0,0,.2); */
  min-height: 64px;
}
}
.signup-calendar-admin{
  color: #70707054;
  font-size: 18px;
  border-radius: 4px;
  width: 75%;
  min-width: 280px;
  border: 1px solid #70707054;
  border-radius: 4px;
  max-height: 54px;
  margin: 0 auto;
  padding: 1rem auto;
  padding: 1rem;
}

.seller-live-orders-approval-pending {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* flex-direction: column; */
  font-size: 20px;
  margin: 0rem auto;
  width: 100%;
  padding: 0.5rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
}
@media (min-width: 481px) {
  .seller-live-orders-approval-pending {
    display: flex;
    resize: none;
    align-items: center;
    margin-top: 1rem;
    min-width: 985px;
  }

  .seller-live-orders-updated-status {
    margin-top: 1rem;
  }
  .discount-date-icon-admin {
    position: absolute;
    top: 1rem;
    right: 6rem;
    font-size: 1.9rem;
    color: rgba(0, 0, 0, 0.5);
}
 
}
.seller-live-orders-approval-pending{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.reject-textarea{
  display: flex;
  flex-direction: column;
  /* min-width: 988px; */
  min-width:80%;
  padding: 1rem;
}
.seller-live-orders-approval-head{
  font-weight: 600;
  color: #000;
font-family: Poppins;
font-size: 20px;
font-style: normal;
line-height: normal;
letter-spacing: -0.2px;
}
.reject-button-field{
  width:100%;
  display: flex;
  align-items:center;
  justify-content:center;
}
.seller-navbar-sidebar 
.MuiDivider-root .MuiDivider-fullWidth .css-39bbo6
 {
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border: none;
}
.seller-navbar-sidebar 
.css-13h4d2q .MuiDrawer-paper {
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow-x: hidden;
  width: calc(57px);
  background-color: rgb(244, 244, 244) !important;
  border: none;
}